import {
    TableColumnHeaderProps as TableColumnHeaderPropsChakra,
    Th as ThChakra,
} from '@chakra-ui/react';
import React from 'react';
import { TableCellProps } from './TableCell';

export interface TableHeaderProps extends TableCellProps {}

export const TableHeaderImpl = (props: TableColumnHeaderPropsChakra) => (
    <ThChakra {...props} />
);

const TableHeader = (props: TableHeaderProps) => <TableHeaderImpl {...props} />;

export default TableHeader;
