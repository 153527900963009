// @ts-nocheck
import React from 'react';
import { css } from '@emotion/react';
import { IconName } from './IconName';
import { IconSize } from './IconSize';

import imagePlus from '../../../../shared/images/icons/plus.svg';
import imageUpload from '../../../../shared/images/icons/upload.svg';
import imageSearch from '../../../../shared/images/icons/search.svg';
import imageDropdownArrow from '../../../../shared/images/icons/dropdown_arrow.svg';
import imageLocation from '../../../../shared/images/icons/location.svg';
import imageMinus from '../../../../shared/images/icons/minus.svg';
import imageClose from '../../../../shared/images/icons/close.svg';
import rightArrowImage from '../../../../shared/images/icons/right.svg';
import imageOffsite from '../../../../shared/images/icons/offsite.svg';
import imageFilter from '../../../../shared/images/icons/filter.svg';
import imageDot from '../../../../shared/images/icons/dot.svg';
import imagePencil from '../../../../shared/images/icons/pencil.svg';
import imageInstagram from '../../../../shared/images/icons/instagram.svg';
import imageTrash from '../../../../shared/images/icons/trash.svg';
import imageLink from '../../../../shared/images/icons/link.svg';
import imageCopy from '../../../../shared/images/icons/copy.svg';
import imageDownload from '../../../../shared/images/icons/download.svg';
import imageInfo from '../../../../shared/images/icons/info-filled.svg';
import imageSuccess from '../../../../shared/images/icons/check-filled.svg';
import imageWarning from '../../../../shared/images/icons/warning-filled.svg';
import imageError from '../../../../shared/images/icons/error-filled.svg';
import outlinedCheck from '../../../../shared/images/icons/outlinedCheck.svg';
import halfCircle from '../../../../shared/images/icons/halfCircle.svg';
import outlinedCircle from '../../../../shared/images/icons/outlinedCircle.svg';
import lock from '../../../../shared/images/icons/lock.svg';

const imageIcon = new Map<IconName, string>([
    [IconName.Plus, imagePlus],
    [IconName.Upload, imageUpload],
    [IconName.Search, imageSearch],
    [IconName.DropdownArrow, imageDropdownArrow],
    [IconName.Location, imageLocation],
    [IconName.Minus, imageMinus],
    [IconName.Close, imageClose],
    [IconName.RightArrow, rightArrowImage],
    [IconName.Offsite, imageOffsite],
    [IconName.link, imageLink],
    [IconName.Filter, imageFilter],
    [IconName.Dot, imageDot],
    [IconName.Pencil, imagePencil],
    [IconName.Trash, imageTrash],
    [IconName.Instagram, imageInstagram],
    [IconName.Copy, imageCopy],
    [IconName.Download, imageDownload],
    [IconName.Info, imageInfo],
    [IconName.Warning, imageWarning],
    [IconName.Success, imageSuccess],
    [IconName.Error, imageError],
    [IconName.OutlinedCheck, outlinedCheck],
    [IconName.HalfCircle, halfCircle],
    [IconName.OutlinedCircle, outlinedCircle],
    [IconName.Lock, lock],
]);

export const icon = (name: IconName, size: IconSize, color: string) =>
    css`
        -webkit-mask: url(${imageIcon.get(name)});
        ${size === IconSize.Md && `width: 1rem; height: 1rem;`}
        ${size === IconSize.Xs && `width: 0.75rem; height: 0.75rem;`}
        ${size === IconSize.Sm && `width: 0.875rem; height: 0.875rem;`}
        ${size === IconSize.Lg && `width: 1.5rem; height: 1.5rem;`}
        ${size === IconSize.Xl && `width: 2rem; height: 2rem;`}
        background: ${color};
        -webkit-mask-size: contain;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: center;
    `;
