import { css } from '@emotion/react';
import { theme } from '../../../../../shared/theme';

export default css`
    input.form-control {
        font-size: ${theme.fontSizes.base};
        color: ${theme.colors.blueGray[650]};
        width: 100%;
        height: 2.5rem;
        padding: ${theme.space[2]} ${theme.space[4]};
        border: none;
        background: none;
        outline: none;
        line-height: ${theme.lineHeights[5]};
        :disabled {
            color: ${theme.colors.blueGray[400]};
        }
    }
`;
