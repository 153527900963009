import Datetime from 'react-datetime';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { theme, Theme } from '../../../shared/theme';
import { ComponentWidth } from '../../Legacy/Forms/Input';
import { ErrorVariant } from './DateTime.types';
import {
    bodyStyles,
    calendarStyles,
    floatingCalendarStyles,
    headerStyles,
    inputStyles,
} from './styles';
import { timePickerStyle } from './TimePicker.styles';

export const mainContainer = (size: ComponentWidth) => css`
    display: flex;
    flex-direction: column;
    ${size === ComponentWidth.ExtraSmall && `min-width: 64px; max-width:100px;`}
    ${size === ComponentWidth.Small && `width: 157px;`}
    ${size === ComponentWidth.Medium && `width: 250px;`}
    ${size === ComponentWidth.Large && `width: 343px;`}
    ${size === ComponentWidth.ExtraLarge && `width: 436px;`}
    ${size === ComponentWidth.Flexible && `width: 100%;`}
    @media (max-width: 576px) {
        width: 100%;
    }
`;

export const labelContainer = () => css`
    display: flex;
    justify-content: space-between;
    height: 1rem;
    margin-bottom: 10px;
`;

export const labelSpan = (theme: Theme) => css`
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeights.medium};
    line-height: 16px;
    color: ${theme.colors.blueGray.dark};
`;

export const errorSpan = (
    theme: Theme,
    variant: ErrorVariant = 'legacy'
) => css`
    box-sizing: border-box;
    font-size: ${theme.fontSizes.sm};

    ${variant === 'default'
        ? `
        padding-top: ${theme.space[2]};
        font-weight: ${theme.fontWeights.regular};
        line-height: ${theme.lineHeights[5]};
        color: ${theme.colors.red.coral};
    `
        : ``}

    ${variant === 'legacy'
        ? `
        padding-top: 9px;
        font-weight: ${theme.fontWeights.bold};
        line-height: 16px;
        color: ${theme.colors.red.trova};
    `
        : ``}
`;

export const detailSpan = (theme: Theme) => css`
    font-size: ${theme.fontSizes.sm};
    line-height: 16px;
    margin-top: 9px;
    font-weight: ${theme.fontWeights.regular};
    color: ${theme.colors.blueGray[400]};
`;

export const disabledSpan = () => css`
    box-sizing: border-box;
    font-size: 14px;
    line-height: 16px;
    padding-top: 9px;
    color: ${theme.colors.neutral[60]};
`;

const dateTimeStyle = (props: any) => {
    if (props.dateFormat) {
        return css`
            && {
                ${calendarStyles(props)}
                ${inputStyles} 
                ${headerStyles} 
                ${bodyStyles}
                ${props.input && floatingCalendarStyles}
            }
        `;
    }

    return timePickerStyle(props);
};

export const StyledDateTime = styled(Datetime)(dateTimeStyle);
