import isEmpty from 'lodash/isEmpty';
import { models, constants } from '@trova-trip/trova-models';
import { isWithinInterval } from 'date-fns';
import {
    getInitialPackage,
    isItineraryAvailableForDate,
} from './itinerary.utils';
import { getRoomsConfigForOperators } from './trip.utils';
import * as PricingCalculator from '../PricingCalculator';
import { convertItineraryPricesToUSD } from '../PricingCalculator/Utils/conversion.utils';
import { ItineraryCostDTO } from '../PricingCalculator';

type ValidityPeriod = models.itineraries.ValidityPeriod;
type HostSelectedOptionalServices = models.trips.HostSelectedOptionalServices;
type SavedItineraryInventoryItem =
    models.itineraryInventoryItems.SavedItineraryInventoryItem;
type HostRoom = models.tripRequest.HostRoom;

type SelectedPrePostServices = {
    preTripAccommodationServices: HostSelectedOptionalServices[];
    postTripAccommodationServices: HostSelectedOptionalServices[];
    preTripTransferServices: HostSelectedOptionalServices[];
    postTripTransferServices: HostSelectedOptionalServices[];
};

const { OperatorHostRoomType } = constants.tripRequest;
const {
    AccommodationOccupancy,
    PRE_POST_ACCOMMODATION_NAME_BY_OCCUPANCY,
    PRE_TRIP_SERVICES,
    POST_TRIP_SERVICES,
} = constants.services;

const allowedPackageLevelsForInstantApproval = [
    constants.itinerary.PackageLevel.STANDARD,
];

export const isTripRequestReadyForInstantApproval = (
    tripRequest: models.tripRequest.BaseTripRequest,
    itineraryValidityPeriods: ValidityPeriod[] = [],
    canOperatorInstantApprove: boolean = false,
    selectedPackage: constants.itinerary.PackageLevel,
): boolean => {
    const { additionalRequests, startDate: tripRequestStartDate } = tripRequest;
    if (!canOperatorInstantApprove) {
        console.warn('Operator cannot instant approve');
        return false;
    }

    if (!allowedPackageLevelsForInstantApproval.includes(selectedPackage)) {
        console.warn(
            `Package level [${selectedPackage}] is not allowed for instant approval`,
        );
        return false;
    }

    if (!isEmpty(additionalRequests)) {
        console.warn('Additional requests are not empty');
        return false;
    }

    // check if trip request start Date is inside itinerary validity period interval
    // and verify the validity period is not a blackout period and is instantApprovalAllowed
    const isTripRequestDateValid = itineraryValidityPeriods.find(
        ({
            startDate,
            endDate,
            instantApprovalAllowed,
            blackoutStartDate,
        }: ValidityPeriod) => {
            const isBlackoutPeriod =
                blackoutStartDate && blackoutStartDate.isBlackout;

            if (isBlackoutPeriod) {
                return false;
            }

            const isRequestDateInsideInterval = isWithinInterval(
                tripRequestStartDate,
                {
                    start: startDate,
                    end: endDate,
                },
            );

            return isRequestDateInsideInterval && instantApprovalAllowed;
        },
    );

    return !!isTripRequestDateValid;
};

export const getStartDateForPriceCalculation = (
    startDate: string | Date,
    packageAvailability: models.itineraries.Availability,
): string => {
    const selectedStartDate =
        typeof startDate === 'string'
            ? startDate
            : (startDate as Date).toISOString();

    let startDateForCalculation = selectedStartDate;

    const { firstAvailableDate, lastAvailableDate } = packageAvailability;

    if (
        firstAvailableDate &&
        lastAvailableDate &&
        !isItineraryAvailableForDate(packageAvailability, selectedStartDate)
    ) {
        startDateForCalculation = new Date(lastAvailableDate).toISOString();

        if (selectedStartDate >= startDateForCalculation) {
            startDateForCalculation = lastAvailableDate as string;
        }
    }

    return startDateForCalculation;
};

export const getSuggestedSellPriceFromTripRequest = (
    itinerary: models.itineraries.BaseItinerary,
    packageAvailability: models.itineraries.Availability,
    tripRequest?: PricingCalculator.TripRequestCostDTO,
): number => {
    const selectedPackage = getInitialPackage(itinerary.packages);

    const startDate = getStartDateForPriceCalculation(
        packageAvailability.firstAvailableDate,
        packageAvailability,
    );

    let tripRequestDTO: PricingCalculator.TripRequestCostDTO = tripRequest
        ? tripRequest
        : {
              hostRooms: [
                  {
                      roomType: constants.tripRequest.HostRoomType.ONE_BED,
                  },
              ],
              selectedPackage,
              startDate,
          };

    const itineraryWithMargin = convertItineraryPricesToUSD(
        itinerary as PricingCalculator.Itinerary,
        1,
        true,
    );

    tripRequestDTO = {
        ...tripRequestDTO,
        itinerary: itineraryWithMargin,
        tripLength: itinerary.servicesByDay?.length,
    };

    return (
        PricingCalculator.getSuggestedSellPriceFromTripRequest(
            tripRequestDTO,
            itineraryWithMargin as unknown as ItineraryCostDTO,
        )?.initialPrice || 0
    );
};

/**
 * Build the initial pre/post trip services object for the host, taking the prices from the selected {@link itineraryInventoryItem}.
 * @param itineraryInventoryItem
 * @param hostRooms
 * @param focQuantity
 */
export const buildPrePostHostOptionalServices = (
    itineraryInventoryItem: SavedItineraryInventoryItem,
    hostRooms: HostRoom[],
    focQuantity: number,
): SelectedPrePostServices => {
    const {
        preAccommodationPrice,
        postAccommodationPrice,
        preTransferPrice,
        postTransferPrice,
    } = itineraryInventoryItem;

    const defaultPrePostServices: SelectedPrePostServices = {
        preTripAccommodationServices: [],
        postTripAccommodationServices: [],
        preTripTransferServices: [],
        postTripTransferServices: [],
    };

    const roomsConfigs = getRoomsConfigForOperators(hostRooms);
    const selectedPrePostServices = roomsConfigs.reduce(
        (prePostServices, roomConfig) => {
            const { roomType, quantity } = roomConfig;
            const occupancyType =
                roomType === OperatorHostRoomType.SINGLE
                    ? AccommodationOccupancy.SINGLE
                    : AccommodationOccupancy.DOUBLE;
            const preAccPrice = preAccommodationPrice?.[occupancyType];
            if (preAccPrice) {
                prePostServices.preTripAccommodationServices.push({
                    activity: {
                        price: preAccPrice,
                        name: PRE_POST_ACCOMMODATION_NAME_BY_OCCUPANCY
                            .beforeTrip[roomType],
                    },
                    numberOptingIn: quantity,
                });
            }
            const postAccPrice = postAccommodationPrice?.[occupancyType];
            if (postAccPrice) {
                prePostServices.postTripAccommodationServices.push({
                    activity: {
                        price: postAccPrice,
                        name: PRE_POST_ACCOMMODATION_NAME_BY_OCCUPANCY
                            .afterTrip[roomType],
                    },
                    numberOptingIn: quantity,
                });
            }
            return prePostServices;
        },
        defaultPrePostServices,
    );
    if (preTransferPrice) {
        selectedPrePostServices.preTripTransferServices.push({
            activity: {
                price: preTransferPrice,
                name: PRE_TRIP_SERVICES.TRANSFER_NAME,
            },
            numberOptingIn: focQuantity,
        });
    }
    if (postTransferPrice) {
        selectedPrePostServices.postTripTransferServices.push({
            activity: {
                price: postTransferPrice,
                name: POST_TRIP_SERVICES.TRANSFER_NAME,
            },
            numberOptingIn: focQuantity,
        });
    }
    return selectedPrePostServices;
};
