type PromoteEmailStatusLabel =
    | 'Pending'
    | 'Scheduled'
    | 'Sent'
    | 'Disabled'
    | 'Canceled';

export const getStatusLabel = (
    isSent: boolean,
    isCampaignOver: boolean,
    isEmailActive: boolean,
    isEmailScheduled: boolean,
    isSkipped: boolean,
    isCanceled?: boolean,
): PromoteEmailStatusLabel => {
    if (isSent) {
        return 'Sent';
    } else if (isCampaignOver || isCanceled) {
        return 'Canceled';
    } else if (!isEmailActive) {
        return 'Disabled';
    } else if (isEmailScheduled) {
        return 'Scheduled';
    } else if (isSkipped) {
        return 'Canceled';
    } else {
        return 'Pending';
    }
};

/**
 * Determine if a drip has sent based on the provided parameters.
 * @param {string} mailgunId - the identifier of the send in mailgun.
 * @param {string} sendId  - the identifier of the send internal to trovatrip.
 * @returns
 */
export const isSent = (mailgunId?: string, sendId?: string) => {
    if (mailgunId || sendId) {
        return true;
    } else {
        return false;
    }
};
