import { createContext } from 'react';
import { DialogProps } from './Dialog';

interface DialogContextProps {
    showCloseButton?: boolean;
    position?: DialogProps['position'];
    onClose?: DialogProps['onClose'];
}

export const DialogContext = createContext<DialogContextProps>({});

export const ContextProvider = DialogContext.Provider;
