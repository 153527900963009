import invariant from 'tiny-invariant';
let env: any = {};
if (typeof process !== 'undefined' && process.env) {
    env = process.env;
}

export const commonConfig = {
    LOG_LEVEL: env.LOG_LEVEL || 'info',
};
export const PRODUCT_FEATURES_CONFIG_KEYS = {
    TROVA_TRIP_PAGES: 'TROVA_TRIP_PAGES',
};

export const setProcessEnvValues = (
    envValues: {
        [key: string]: string | boolean | number | undefined;
    },
    forceOverride: boolean = false,
) => {
    invariant(envValues, 'Inavlid argument [envValues]');
    invariant(
        process,
        'Node Process not available. If this is client-side execution; process.* must be shimmed/polyfilled',
    );
    Object.entries(envValues).forEach(([key, value]) => {
        if (!(key in process.env)) {
            process.env[key] = value?.toString();
        } else {
            if (forceOverride) {
                process.env[key] = value?.toString();
            }
        }
    });
};
