/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

import React from 'react';
import InputMask, { Props } from 'react-input-mask';

import { BaseBoxImpl } from '../../Layout/BaseBox';
import { IconImpl } from '../../Media/Icon';
import { outlined } from '../../Media/Icon/icon.constants';
import { FieldProps } from '../Field/Field.types';
import { input } from './InputMaskWrapper.styles';
export interface InputMaskProps
    extends Omit<FieldProps<string>, 'name' | 'value' | 'onChange' | 'onBlur'>,
        Props {
    error?: string | string[];
    icon?: keyof typeof outlined;
}

const InputMaskWrapper: React.FC<InputMaskProps> = ({
    className,
    mask = '99:99',
    maskChar = '0',
    icon = null,
    disabled = false,
    error = '',
    ...props
}) => {
    return (
        <BaseBoxImpl position='relative' css={input(disabled, error)}>
            <InputMask
                className={className}
                mask={mask}
                maskChar={maskChar}
                disabled={disabled}
                {...props}
            />
            {icon && (
                <IconImpl
                    as={icon}
                    position='absolute'
                    color={disabled ? 'neutral.60' : 'red.trova'}
                    size='xs'
                    right={4}
                    top='50%'
                    transform='translateY(-50%)'
                    pointerEvents='none'
                ></IconImpl>
            )}
        </BaseBoxImpl>
    );
};

export default InputMaskWrapper;
