import { BaseStatusError } from './baseError';

export const itineraryInventoryItemsErrors = {
    inventoryNotSupported: {
        message: (itineraryId: string) =>
            `This itinerary ${itineraryId} does not yet support inventory`,
        code: 1650,
    },
    inventoryNotFound: {
        message: (id: string) =>
            `Itinerary Inventory Item with id ${id} not found`,
        code: 1651,
    },
    inventoryNotModified: {
        message: (id: string) =>
            `No changes detected for itinerary inventory item with id ${id}`,
        code: 1652,
    },
    inventoryCannotBeDeletedAndModified: {
        message: (id: string) =>
            `Inventory ${id} cannot be deleted and modified`,
        code: 1653,
    },
    inventoryInUseCannotUpdate: {
        message: (id: string) =>
            `Cannot change inventory that is already in use ${id}`,
        code: 1654,
    },
    inventoryHasMoreConfirmedThanAvailable: {
        message: (id: string) =>
            `More trips are confirmed for dates than are available in inventory ${id}`,
        code: 1655,
    },
    invalidCostSchedule: {
        message:
            'Cost Schedule must have at least 1 item with number of travelers less than or equal to the minimum travelers on the itinerary',
        code: 1656,
    },
    duplicatedCostSchedule: {
        message: 'Cost Schedule cannot have duplicated number of travelers',
        code: 1657,
    },
    packageLevelNotEnabled: {
        message: (packageLevel: string) =>
            `Package level ${packageLevel} is not enabled`,
        code: 1658,
    },
    itineraryNotFound: {
        message: (itineraryId: string) =>
            `Itinerary with id ${itineraryId} not found`,
        code: 1659,
    },
    inventoryInvalidStartDate: {
        message: 'The Start Date for any package must be at least 2 months from now',
        code: 1660,
    },
};

export class InventoryNotSupported extends BaseStatusError {
    constructor(itineraryId: string) {
        super(
            itineraryInventoryItemsErrors.inventoryNotSupported.code,
            itineraryInventoryItemsErrors.inventoryNotSupported.message(
                itineraryId,
            ),
        );
    }
}

export class InventoryNotFound extends BaseStatusError {
    constructor(id: string) {
        super(
            itineraryInventoryItemsErrors.inventoryNotFound.code,
            itineraryInventoryItemsErrors.inventoryNotFound.message(id),
        );
    }
}

export class InventoryNotModified extends BaseStatusError {
    constructor(id: string) {
        super(
            itineraryInventoryItemsErrors.inventoryNotModified.code,
            itineraryInventoryItemsErrors.inventoryNotModified.message(id),
        );
    }
}

export class InventoryCannotBeDeletedAndModified extends BaseStatusError {
    constructor(id: string) {
        super(
            itineraryInventoryItemsErrors.inventoryCannotBeDeletedAndModified
                .code,
            itineraryInventoryItemsErrors.inventoryCannotBeDeletedAndModified.message(
                id,
            ),
        );
    }
}

export class InventoryInUseCannotUpdate extends BaseStatusError {
    constructor(id: string) {
        super(
            itineraryInventoryItemsErrors.inventoryInUseCannotUpdate.code,
            itineraryInventoryItemsErrors.inventoryInUseCannotUpdate.message(
                id,
            ),
        );
    }
}

export class InventoryHasMoreConfirmedThanAvailable extends BaseStatusError {
    constructor(id: string) {
        super(
            itineraryInventoryItemsErrors.inventoryHasMoreConfirmedThanAvailable
                .code,
            itineraryInventoryItemsErrors.inventoryHasMoreConfirmedThanAvailable.message(
                id,
            ),
        );
    }
}

export class InvalidCostSchedule extends BaseStatusError {
    constructor() {
        super(
            itineraryInventoryItemsErrors.invalidCostSchedule.code,
            itineraryInventoryItemsErrors.invalidCostSchedule.message,
        );
    }
}

export class DuplicatedCostSchedule extends BaseStatusError {
    constructor() {
        super(
            itineraryInventoryItemsErrors.duplicatedCostSchedule.code,
            itineraryInventoryItemsErrors.duplicatedCostSchedule.message,
        );
    }
}

export class PackageLevelNotEnabled extends BaseStatusError {
    constructor(packageLevel: string) {
        super(
            itineraryInventoryItemsErrors.packageLevelNotEnabled.code,
            itineraryInventoryItemsErrors.packageLevelNotEnabled.message(
                packageLevel,
            ),
        );
    }
}

export class ItineraryNotFound extends BaseStatusError {
    constructor(itineraryId: string) {
        super(
            itineraryInventoryItemsErrors.itineraryNotFound.code,
            itineraryInventoryItemsErrors.itineraryNotFound.message(
                itineraryId,
            ),
        );
    }
}

export class InventoryInvalidStartDate extends BaseStatusError {
    constructor() {
        super(
            itineraryInventoryItemsErrors.inventoryInvalidStartDate.code,
            itineraryInventoryItemsErrors.inventoryInvalidStartDate.message,
        );
    }
}
