import { BaseStatusError } from './baseError';
import { constants } from '@trova-trip/trova-models';

type InsuranceCoverage = constants.insurance.InsuranceCoverage;
type InsuranceStatus = constants.insurance.InsuranceStatus;
type InsuranceErrorContext = unknown;

export const insuranceErrors = {
    quoteUnavailable: { message: 'No insurance quote available', code: 1500 },
    quoteOutdated: {
        message: (travelerEmail: string, oldPrice: number, newPrice: number) =>
            `Quoted insurance policy price for traveler with email "${travelerEmail}"` +
            ` has changed from ${oldPrice} to ${newPrice}`,
        code: 1501,
    },
    policyUpdateProhibited: {
        message: (insurancePolicyId: string, status: InsuranceStatus) =>
            `Insurance policy with id ${insurancePolicyId} cannot be updated due to its ${status} status`,
        code: 1510,
    },
    policyNumberMissing: {
        message: (insurancePolicyId: string) =>
            `Insurance policy with id ${insurancePolicyId} is missing policy number`,
        code: 1511,
    },
    policyUpdateFailed: {
        message: (insurancePolicyId: string) =>
            `Insurance policy with id ${insurancePolicyId} could not be updated`,
        code: 1512,
    },
    policyNotFound: {
        message: (insurancePolicyId: string) =>
            `Insurance policy with id ${insurancePolicyId} not found`,
        code: 1513,
    },
    policyCancellationFailed: {
        message: (insurancePolicyIds: string[]) =>
            `Insurance policies could not be cancelled: ${insurancePolicyIds.join(
                ', ',
            )}`,
        code: 1514,
    },
    policyStatusUnsupported: {
        message: (insurancePolicyId: string, status: InsuranceStatus) =>
            `Insurance policy with id ${insurancePolicyId} has unsupported status ${status}`,
        code: 1515,
    },
    policyFieldUnpopulated: {
        message: (insurancePolicyId: string, field: string) =>
            `Insurance policy with id ${insurancePolicyId} has an unpopulated ${field}`,
        code: 1516,
    },
    policyCancellationNotAllowed: {
        message: (insurancePolicyId: string) =>
            `Insurance policy with id ${insurancePolicyId} is not cancellable`,
        code: 1517,
    },
    externalPolicyNotFound: {
        message: (policyNumber: string) =>
            `Travel Insured plan with number ${policyNumber} not found`,
        code: 1520,
    },
    externalPolicyDetailsMissing: {
        message: (policyNumber: string) =>
            `Travel Insured result for policy ${policyNumber} is missing details`,
        code: 1521,
    },
    planCoveragesRequired: {
        message: 'No insurance coverages provided',
        code: 1530,
    },
    planCoverageUnsupported: {
        message: (coverage: InsuranceCoverage) =>
            `Insurance plan with coverage ${coverage} is not supported`,
        code: 1531,
    },
    planNotFound: {
        message: (insurancePlanId: string) =>
            `Insurance plan with id ${insurancePlanId} not found`,
        code: 1532,
    },
    planNotAvailable: {
        message: (tripId: string, insurancePlanId: string) =>
            `Trip with id ${tripId} doesn't have insurance plan with id ${insurancePlanId}`,
        code: 1533,
    },
    plansNotAvailable: {
        message: (tripId: string) =>
            `Trip with id ${tripId} doesn't have any available insurance plans`,
        code: 1534,
    },
    countryInvalid: {
        message: 'Insurance is only available in the US',
        code: 1540,
    },
    locationRequired: {
        message: 'Traveler country and subdivision required',
        code: 1541,
    },
    bookingWaitlistProhibited: {
        message:
            'Cannot add insurance policies to waitlisted booking. Booking flow interrupted',
        code: 1550,
    },
    bookingQuoteRequestNotFound: {
        message: (email: string) =>
            `Quote request for traveler with email ${email} not found. Booking flow interrupted`,
        code: 1551,
    },
    bookingAddOnNotFound: {
        message: (addOnId: string) =>
            `Insurance policy add-on with id ${addOnId} not found`,
        code: 1552,
    },
    bookingPolicyNotFound: {
        message: (bookingId: string, insurancePolicyId: string) =>
            `Insurance policy with id ${insurancePolicyId} not found on booking with id ${bookingId}`,
        code: 1552,
    },
    manifestFieldMissing: {
        message: (field: string) =>
            `Record on insurance manifest is missing field "${field}"`,
        code: 1560,
    },
    manifestRequestTypeUnsupported: {
        message: (requestType: string) =>
            `Record on insurance manifest has unsupported request type "${requestType}"`,
        code: 1561,
    },
    manifestRequestInvalidEntry: {
        message: (requestType: string) =>
            `Record on insurance manifest is not the latest event "${requestType}"`,
        code: 1562,
    },
};

export class InsuranceQuoteUnavailableError extends BaseStatusError {
    constructor(readonly context: InsuranceErrorContext) {
        super(
            insuranceErrors.quoteUnavailable.code,
            insuranceErrors.quoteUnavailable.message,
        );
    }
}

export class InsuranceQuoteOutdatedError extends BaseStatusError {
    constructor(travelerEmail: string, oldPrice: number, newPrice: number) {
        super(
            insuranceErrors.quoteOutdated.code,
            insuranceErrors.quoteOutdated.message(
                travelerEmail,
                oldPrice,
                newPrice,
            ),
        );
    }
}

export class InsurancePolicyUpdateProhibitedError extends BaseStatusError {
    constructor(insurancePolicyId: string, status: InsuranceStatus) {
        super(
            insuranceErrors.policyUpdateProhibited.code,
            insuranceErrors.policyUpdateProhibited.message(
                insurancePolicyId,
                status,
            ),
        );
    }
}

export class InsurancePolicyNumberMissingError extends BaseStatusError {
    constructor(insurancePolicyId: string) {
        super(
            insuranceErrors.policyNumberMissing.code,
            insuranceErrors.policyNumberMissing.message(insurancePolicyId),
        );
    }
}

export class InsurancePolicyUpdateFailedError extends BaseStatusError {
    constructor(insurancePolicyId: string) {
        super(
            insuranceErrors.policyUpdateFailed.code,
            insuranceErrors.policyUpdateFailed.message(insurancePolicyId),
        );
    }
}

export class InsurancePolicyNotFoundError extends BaseStatusError {
    constructor(insurancePolicyId: string) {
        super(
            insuranceErrors.policyNotFound.code,
            insuranceErrors.policyNotFound.message(insurancePolicyId),
        );
    }
}

export class InsurancePolicyCancellationFailedError extends BaseStatusError {
    constructor(insurancePolicyIds: string[]) {
        super(
            insuranceErrors.policyCancellationFailed.code,
            insuranceErrors.policyCancellationFailed.message(
                insurancePolicyIds,
            ),
        );
    }
}

export class InsurancePolicyStatusUnsupportedError extends BaseStatusError {
    constructor(insurancePolicyId: string, status: InsuranceStatus) {
        super(
            insuranceErrors.policyStatusUnsupported.code,
            insuranceErrors.policyStatusUnsupported.message(
                insurancePolicyId,
                status,
            ),
        );
    }
}

export class InsurancePolicyFieldUnpopulatedError extends BaseStatusError {
    constructor(insurancePolicyId: string, field: string) {
        super(
            insuranceErrors.policyFieldUnpopulated.code,
            insuranceErrors.policyFieldUnpopulated.message(
                insurancePolicyId,
                field,
            ),
        );
    }
}

export class InsurancePolicyCancellationNotAllowedError extends BaseStatusError {
    constructor(insurancePolicyId: string) {
        super(
            insuranceErrors.policyCancellationNotAllowed.code,
            insuranceErrors.policyCancellationNotAllowed.message(
                insurancePolicyId,
            ),
        );
    }
}

export class InsuranceExternalPolicyNotFoundError extends BaseStatusError {
    constructor(policyNumber: string, readonly context: InsuranceErrorContext) {
        super(
            insuranceErrors.externalPolicyNotFound.code,
            insuranceErrors.externalPolicyNotFound.message(policyNumber),
        );
    }
}

export class InsuranceExternalPolicyDetailsMissingError extends BaseStatusError {
    constructor(policyNumber: string, readonly context: InsuranceErrorContext) {
        super(
            insuranceErrors.externalPolicyDetailsMissing.code,
            insuranceErrors.externalPolicyDetailsMissing.message(policyNumber),
        );
    }
}

export class InsurancePlanCoveragesRequiredError extends BaseStatusError {
    constructor() {
        super(
            insuranceErrors.planCoveragesRequired.code,
            insuranceErrors.planCoveragesRequired.message,
        );
    }
}

export class InsurancePlanCoverageUnsupportedError extends BaseStatusError {
    constructor(coverage: InsuranceCoverage) {
        super(
            insuranceErrors.planCoverageUnsupported.code,
            insuranceErrors.planCoverageUnsupported.message(coverage),
        );
    }
}

export class InsurancePlanNotFoundError extends BaseStatusError {
    constructor(insurancePlanId: string) {
        super(
            insuranceErrors.planNotFound.code,
            insuranceErrors.planNotFound.message(insurancePlanId),
        );
    }
}

export class InsurancePlanNotAvailableError extends BaseStatusError {
    constructor(tripId: string, insurancePlanId: string) {
        super(
            insuranceErrors.planNotAvailable.code,
            insuranceErrors.planNotAvailable.message(tripId, insurancePlanId),
        );
    }
}

export class InsurancePlansNotAvailableError extends BaseStatusError {
    constructor(tripId: string) {
        super(
            insuranceErrors.plansNotAvailable.code,
            insuranceErrors.plansNotAvailable.message(tripId),
        );
    }
}

export class InsuranceCountryInvalidError extends BaseStatusError {
    constructor() {
        super(
            insuranceErrors.countryInvalid.code,
            insuranceErrors.countryInvalid.message,
        );
    }
}

export class InsuranceLocationRequiredError extends BaseStatusError {
    constructor() {
        super(
            insuranceErrors.locationRequired.code,
            insuranceErrors.locationRequired.message,
        );
    }
}

export class InsuranceBookingWaitlistProhibitedError extends BaseStatusError {
    constructor() {
        super(
            insuranceErrors.bookingWaitlistProhibited.code,
            insuranceErrors.bookingWaitlistProhibited.message,
        );
    }
}

export class InsuranceBookingQuoteRequestNotFoundError extends BaseStatusError {
    constructor(email: string) {
        super(
            insuranceErrors.bookingQuoteRequestNotFound.code,
            insuranceErrors.bookingQuoteRequestNotFound.message(email),
        );
    }
}

export class InsuranceBookingAddOnNotFoundError extends BaseStatusError {
    constructor(addOnId: string) {
        super(
            insuranceErrors.bookingAddOnNotFound.code,
            insuranceErrors.bookingAddOnNotFound.message(addOnId),
        );
    }
}

export class InsuranceBookingPolicyNotFoundError extends BaseStatusError {
    constructor(bookingId: string, insurancePolicyId: string) {
        super(
            insuranceErrors.bookingPolicyNotFound.code,
            insuranceErrors.bookingPolicyNotFound.message(
                bookingId,
                insurancePolicyId,
            ),
        );
    }
}

export class InsuranceManifestFieldMissingError extends BaseStatusError {
    constructor(field: string) {
        super(
            insuranceErrors.manifestFieldMissing.code,
            insuranceErrors.manifestFieldMissing.message(field),
        );
    }
}

export class InsuranceManifestRequestTypeUnsupportedError extends BaseStatusError {
    constructor(requestType: string) {
        super(
            insuranceErrors.manifestRequestTypeUnsupported.code,
            insuranceErrors.manifestRequestTypeUnsupported.message(requestType),
        );
    }
}

export class InsuranceManifestRequestInvalidEntryError extends BaseStatusError {
    constructor(requestType: string) {
        super(
            insuranceErrors.manifestRequestInvalidEntry.code,
            insuranceErrors.manifestRequestInvalidEntry.message(requestType),
        );
    }
}
