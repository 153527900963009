import {
    AccordionButton as AccordionButtonChakra,
    CSSObject,
    FlexProps,
    ResponsiveValue,
} from '@chakra-ui/react';
import React from 'react';
import { useOmitForbiddenProps } from '../../../../shared/hooks';
import { InternalBaseProps } from '../../../../shared/theme/interfaces/Base.types';
import {
    BorderProps,
    BorderRadiusProps,
    DesignTokens,
    PaddingProps,
    SizeProps,
} from '../../../../shared/theme/types';
import { genDataTestId } from '../../../../shared/utils/helpers';
import Stack from '../../../Layout/Stack';
import { TypographyProps } from '../../../Typography/types';

export interface AccordionHeaderProps
    extends SizeProps,
        BorderProps,
        BorderRadiusProps,
        PaddingProps,
        TypographyProps {
    text?: string;
    leftElement?: React.ReactNode;
    rightElement?: React.ReactNode;
    bottomElement?: React.ReactNode;
    align?: FlexProps['alignItems'];
    justifyContent?: FlexProps['justifyContent'];
    hoverStyles?: CSSObject;
    focusStyles?: CSSObject;
    boxShadow?: ResponsiveValue<DesignTokens['shadows']>;
    color?: ResponsiveValue<DesignTokens['colors']>;
    background?:
        | ResponsiveValue<DesignTokens['colors']>
        | InternalBaseProps['background'];
}

export interface AccordionHeaderImplProps
    extends AccordionHeaderProps,
        Omit<
            InternalBaseProps<'button'>,
            | 'background'
            | 'color'
            | 'borderColor'
            | 'boxShadow'
            | 'fontSize'
            | 'fontWeight'
        > {}

export const AccordionHeaderImpl = (props: AccordionHeaderImplProps) => {
    const {
        text,
        leftElement,
        rightElement,
        bottomElement,
        hoverStyles,
        focusStyles,
        ...rest
    } = props;

    return (
        <Stack direction='column' spacing={0}>
            <AccordionButtonChakra
                data-testid={genDataTestId('accordion-header')}
                fontFamily='roboto'
                background='transparent'
                border='0'
                cursor='pointer'
                padding='0'
                alignItems='center'
                justifyContent='space-between'
                _hover={{ ...hoverStyles }}
                _focus={{ ...focusStyles }}
                {...rest}
            >
                {leftElement}
                {text}
                {rightElement}
            </AccordionButtonChakra>
            {bottomElement}
        </Stack>
    );
};

const AccordionHeader = (props: AccordionHeaderProps) => {
    const filteredProps = useOmitForbiddenProps(props);
    return <AccordionHeaderImpl {...filteredProps} />;
};

export default AccordionHeader;
