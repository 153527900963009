import { v4 as uuid } from 'uuid';
import ShortUniqueId from 'short-unique-id';

let uniqueTimestampId = new Date().getTime();

/**
 * Generates a v4 uuid
 * 
 * @returns 
 */
export const generateUuid = (): string => uuid();

/**
 * Generates a short unique id with random characters
 * 
 * @param shortIdLength 
 * @returns 
 */
export const generateShortId = (shortIdLength = 6): string => {
    const createShortId = new ShortUniqueId({
        dictionary: 'alphanum_upper',
        length: shortIdLength,
    });
    return createShortId();
};

/**
 * Generates a unique id based on a timestamp ms
 *
 * @returns
 */
export const generateUniqueTimestampedId = (): string =>
    String(++uniqueTimestampId);
