import moment from 'moment';

const validOperators: string[] = ['$eq', '$ne', '$gte', '$lte'];

export const serializeQuery = (query: Record<string, unknown>): string => {
    let filterFields = '';
    Object.entries(query).map(([property, condition]) => {
        if (condition instanceof Date) {
            filterFields += `${property}$eq:${condition.toISOString()},`;
        } else if (condition instanceof Object) {
            Object.entries(condition as Record<string, unknown>).map(
                ([operator, value]) => {
                    if (
                        validOperators.includes(operator) &&
                        !(value instanceof Object)
                    ) {
                        filterFields += `${property}${operator}:${value},`;
                    }

                    if (
                        validOperators.includes(operator) &&
                        (value instanceof Date)
                    ) {
                        filterFields += `${property}${operator}:${value.toISOString()},`;
                    }
                },
            );
        } else {
            filterFields += `${property}$eq:${condition},`;
        }
    });
    return filterFields.replace(/,\s*$/, '');
};

export const deserializeQuery = (value: string): Record<string, unknown> => {
    const filterFields: string[] = value.split(',');
    let filterQuery = {};

    filterFields.map((filterField) => {
        const firstIndex: number = filterField.indexOf('$');
        const secondIndex: number = filterField.indexOf(':');

        if (firstIndex === -1 || secondIndex === -1) {
            return;
        }

        const field = filterField.slice(0, firstIndex);
        const operator = filterField.slice(firstIndex, secondIndex);
        const condition = filterField.slice(secondIndex + 1);

        const parseCondition = !isNaN(Number(condition))
            ? Number(condition)
            : !isNaN(new Date(condition).getDate())
            ? new Date(condition)
            : condition;

        if (validOperators.includes(operator)) {
            // @ts-ignore
            const prev = filterQuery[field] || {};
            filterQuery = {
                ...filterQuery,
                [field]: { ...prev, [operator]: parseCondition },
            };
        }
    });

    return filterQuery;
};
