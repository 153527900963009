import { noop } from 'lodash';
import React, { useContext } from 'react';
import { IconButtonImpl } from '../../../Forms/IconButton';
import { BaseBoxImpl } from '../../../Layout/BaseBox';
import { StackImpl } from '../../../Layout/Stack';
import Heading from '../../../Typography/Heading';
import Text from '../../../Typography/Text';
import { DialogContext } from '../DialogContext';

export interface DialogHeaderProps {
    title: string;
    subtitle?: string;
}

export const DialogHeader = (props: DialogHeaderProps) => {
    const { title, subtitle } = props;
    const { showCloseButton, onClose = noop } = useContext(DialogContext);

    return (
        <StackImpl justify='space-between' align='flex-start' w='100%'>
            <BaseBoxImpl
                width={showCloseButton ? 'calc(100% - 2.5rem)' : undefined}
            >
                {title && (
                    <Heading as='h6' color='blueGray.650'>
                        {title}
                    </Heading>
                )}
                {subtitle && (
                    <Text color='blueGray.600' size='sm'>
                        {subtitle}
                    </Text>
                )}
            </BaseBoxImpl>
            {showCloseButton && (
                <IconButtonImpl
                    position='absolute'
                    top='.5rem'
                    right='.75rem'
                    icon='close'
                    aria-label='Close dialog'
                    variant='ghost'
                    hover={{
                        bg: 'blueGray.offWhite',
                        border: '1px solid',
                        borderColor: 'blueGray.200',
                    }}
                    onClick={onClose}
                />
            )}
        </StackImpl>
    );
};
