import { TrovaTripMicroApps } from '../trova.microapps';
import { Environment, TrovaTripAppsDomainMap } from '../app.constants';
import { parseAsBool, normalizeUrl } from './object.utils';
import invariant from 'tiny-invariant';

export const isSluggedTripPagePath = (tripPath: string): boolean => {
    const newTripPagePathRegex =
        /^\/{0,1}([a-zA-Z0-9-]+)\/([a-zA-Z0-9-]+)\/([\w\W]+)/i;
    return newTripPagePathRegex.test(tripPath);
};

export const parseTripPagePath = (
    record: Record<string, unknown>,
    uriEncode: boolean = false,
): {
    tripPagePrefix: string; // the slugs /area/country
    tripPagePathWithExtension: string; // original html file path name with extension
    tripPagePathNormalized: string; // original html file path name without extension
    tripPageFullPathCreate: (normalized: boolean) => string;
} => {
    const slugs =
        (record?.tripPath as string)?.split('/').filter(Boolean) || [];

    let tripPagePath = slugs.pop() || '';
    tripPagePath = uriEncode ? encodeURIComponent(tripPagePath) : tripPagePath;

    const tripPagePrefix = (slugs.length && `/${slugs.join('/')}`) || '';
    const tripPagePathNormalized = tripPagePath.replace('.html', '');

    const tripPageFullPathCreate = (normalized = true): string => {
        if (normalized) {
            return `${tripPagePrefix}/${tripPagePathNormalized}`;
        }
        return `${tripPagePrefix}/${tripPagePath}`;
    };

    return {
        tripPagePrefix,
        tripPagePathNormalized,
        tripPagePathWithExtension: tripPagePath,
        tripPageFullPathCreate,
    };
};

export const isTripPageNextGenFeatureOn = (): boolean => {
    // I have to think better on how this should work
    console.info(
        `@TROVA - TROVA_TRIP_PAGES feature check requested, checking env values: 
    TROVA_TRIP_PAGES,REACT_APP_TROVA_TRIP_PAGES,VITE_APP_TROVA_TRIP_PAGES`,
    );
    if (typeof process === 'undefined') {
        console.warn(
            '@TROVA - method not supported in your environment. You must enable NodeJS Process',
        );
        return false;
    }
    const processEnvValues = [
        process.env.TROVA_TRIP_PAGES,
        process.env.REACT_APP_TROVA_TRIP_PAGES,
        process.env.VITE_APP_TROVA_TRIP_PAGES,
    ];

    return processEnvValues.some((value) => parseAsBool(value));
};

export const createTripPagePreviewUrl = (
    record: {
        id: string;
        tripPath: string;
        useLegacyPreviewUrl?: boolean;
        operator: string;
    },
    baseOrigin: string,
    customQueryParams?: Record<string, string>,
): string => {
    const readyForNextGen = isSluggedTripPagePath(record.tripPath);
    const useLegacyUri =
        typeof record.useLegacyPreviewUrl === 'undefined' ||
        record.useLegacyPreviewUrl === true ||
        !readyForNextGen;

    let previewPageUriBuilder: URL;
    const initParams = {
        tripId: record.id,
        operatorId: record.operator,
        applyresetcss: 'true',
        ...(customQueryParams && customQueryParams),
    };

    const searchParameters = new URLSearchParams(initParams);
    if (useLegacyUri) {
        previewPageUriBuilder = new URL(
            `/api/me/pages/trip?${searchParameters.toString()}`,
            baseOrigin,
        );
    } else {
        searchParameters.set('tripPath', record.tripPath);
        previewPageUriBuilder = new URL(
            `/api/me/pages/preview/trip?${searchParameters.toString()}`,
            baseOrigin,
        );
    }

    const uri = previewPageUriBuilder.toString();
    console.info('@TROVA - New TrovaTrip Page Preview URL Requested: ', uri);
    return uri;
};

export const getTrovaTripPageUrl = (
    tripPath: string,
    environment: Environment = process.env.ENVIRONMENT as Environment,
): string => {
    invariant(
        environment,
        'Inavlid argument [environment] of type Environment',
    );
    let baseUrl: string;
    const trovaDomainForEnv = TrovaTripAppsDomainMap[environment];
    invariant(
        trovaDomainForEnv,
        `TrovaTripAppsDomainMap is not defined for [environment] arg ${environment}`,
    );

    if (isSluggedTripPagePath(tripPath)) {
        baseUrl =
            TrovaTripMicroApps.TROVA_TRIP_PAGES.trovaTripPagesUrl(environment);
    } else {
        // this is legacy like:https://trovatrip.com/trips/costa-rica-with-jade-chynoweth-dec-2022
        // redirects to CPANEL trips.trovatrip.com
        baseUrl = `${trovaDomainForEnv.public}/trips`;
    }
    console.info('@TROVA - New TrovaTrip Page URL Requested: ', baseUrl);
    return normalizeUrl(`${baseUrl}/${tripPath}`);
};
