import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    InternalAxiosRequestConfig,
    AxiosResponse,
    isAxiosError
} from 'axios';

export type HttpClientRequestConfig = InternalAxiosRequestConfig;
export type HttpClientResponse = AxiosResponse;
export type HttpClientInstance = AxiosInstance;
export const isHttpClientError = isAxiosError;

export const toApiResponse = (response?: AxiosResponse): ApiResponse => {
    const checkHTTPStatusCode = (code: number) => code >= 200 && code <= 308;
    return {
        success: response ? checkHTTPStatusCode(response.status) : false,
        data: response?.data,
        status: response?.status || 0,
        statusText: response?.statusText,
        headers: response?.headers,
    };
};
export interface ApiResponse<T = any> {
    success: boolean;
    error?: {
        responseCode?: string | number;
        message: string;
        stackTrace?: string;
    };
    data: T | Array<T>;
    status: number;
    statusText?: string;
    headers?: any;
}

export interface ApiResponseError<T = unknown> extends Error {
    config: AxiosRequestConfig;
    code?: string;
    request?: unknown;
    response?: AxiosResponse<T>;
    isAxiosError: boolean;
    toJSON: () => object;
}

export enum RequestMethod {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    PATCH = 'patch',
    DELETE = 'delete',
}

export interface RestClient<TEntity = any> {
    path: string;
    httpClient: AxiosInstance | object;
    unauthorizedResponseHandler?: () => void;
    create: (
        entity: TEntity,
        params?: { [key: string]: any },
    ) => Promise<ApiResponse<TEntity>>;
    get: (params?: { [key: string]: any }) => Promise<ApiResponse<TEntity>>;
    getById: (
        id: number | string,
        params?: { [key: string]: any },
    ) => Promise<ApiResponse<TEntity>>;
    update: (
        id: number | string,
        entity: TEntity,
        params?: { [key: string]: any },
        patch?: boolean,
    ) => Promise<ApiResponse<TEntity>>;
    delete: (
        id: number | string,
        params?: { [key: string]: any },
    ) => Promise<ApiResponse<TEntity>>;
    // specialized APIs
    count: (params?: { [key: string]: any }) => Promise<ApiResponse<number>>;
    request(
        method: RequestMethod,
        data?: unknown,
    ): Promise<ApiResponse<TEntity>>;
}

export type RestClientConfigParams = {
    baseUrl?: string; // if base isn't given, local network is assumed
    path: string;
    requestTransform?: (data: any, headers: any) => any;
    responseTransform?: (data: any, headers: any) => any;
    headers?: Record<string, string>;
    requestInterceptor?: [
        (
            request: HttpClientRequestConfig,
        ) => HttpClientRequestConfig | Promise<HttpClientRequestConfig>,
        (error: unknown) => void,
    ];
    responseInterceptor?: [
        (
            response: HttpClientResponse,
        ) => HttpClientResponse | Promise<HttpClientResponse>,
        (error: unknown) => void,
    ];
};

class RestClientImpl<TEntity = any> implements RestClient<TEntity> {
    path: string;
    httpClient: AxiosInstance;
    requestInterceptor: number | undefined;
    responseInterceptor: number | undefined;

    static toApiReponse(apiResponse: any | ApiResponse) {
        return toApiResponse(apiResponse);
    }
    static createInstance<TEnity = any>(
        config: RestClientConfigParams,
    ): RestClientImpl<TEnity> {
        return new RestClientImpl<TEnity>(config);
    }
    constructor(config: RestClientConfigParams) {
        this.path = config.path;
        this.httpClient = axios.create({
            baseURL: config.baseUrl, // if base isn't given, local network is assumed
            transformRequest: (data, headers) => {
                const contentType = headers['Content-Type'];
                let transformedData;
                switch (contentType) {
                    case 'application/json':
                        transformedData = JSON.stringify(data);
                        break;
                    default:
                        transformedData = data;
                }
                return config.requestTransform
                    ? config.requestTransform(transformedData, headers)
                    : transformedData;
            },
            transformResponse: config.responseTransform,
            headers: { 'Content-Type': 'application/json', ...config.headers },
        });

        this.useInterceptors(config);
    }

    create<TCreateParams>(
        entity: TCreateParams | TEntity,
        params?: { [key: string]: any },
    ): Promise<ApiResponse<TEntity>> {
        return this.httpClient
            .post(this.path, entity, { params })
            .then((resp) => {
                return toApiResponse(resp);
            });
    }
    get(params?: { [key: string]: any }): Promise<ApiResponse<TEntity>> {
        return this.httpClient.get(this.path, { params }).then((resp) => {
            return toApiResponse(resp);
        });
    }
    getById(
        id: string | number,
        params?: { [key: string]: any },
    ): Promise<ApiResponse<TEntity>> {
        return this.httpClient
            .get(`${this.path}/${id}`, { params })
            .then((resp) => {
                return toApiResponse(resp);
            });
    }
    update<TUpdateParams>(
        id: string | number,
        entity: TUpdateParams | TEntity,
        params?: { [key: string]: any },
        patch?: boolean /*PATCH updates partially, PUT replaces entire entity */,
    ): Promise<ApiResponse<TEntity>> {
        return this.httpClient[patch ? 'patch' : 'put'](
            `${this.path}/${id}`,
            entity,
            { params },
        ).then((resp) => {
            return toApiResponse(resp);
        });
    }
    delete(
        id: string | number,
        params?: { [key: string]: any },
    ): Promise<ApiResponse<TEntity>> {
        return this.httpClient
            .delete(`${this.path}/${id}`, { params })
            .then((resp) => {
                return toApiResponse(resp);
            });
    }

    request(
        method: RequestMethod,
        data?: unknown,
    ): Promise<ApiResponse<TEntity>> {
        return this.httpClient
            .request({
                url: this.path,
                method,
                data,
            })
            .then((resp) => {
                return toApiResponse(resp);
            });
    }

    count(params?: { [key: string]: any }): Promise<ApiResponse<number>> {
        /// TODO: Override this locally as not all APIs return a count value
        throw new Error(
            'Not Implemented: count. You must override this method and implement specifics.',
        );
    }

    unauthorizedResponseHandler() {
        throw new Error('Not Implemented: unauthorizedResponseHandler');
    }

    ejectInterceptors() {
        if (this.requestInterceptor) {
            this.httpClient.interceptors.request.eject(this.requestInterceptor);
        }

        if (this.responseInterceptor) {
            this.httpClient.interceptors.response.eject(
                this.responseInterceptor,
            );
        }
    }

    private useInterceptors(config: RestClientConfigParams) {
        if (config.requestInterceptor) {
            this.requestInterceptor = this.httpClient.interceptors.request.use(
                ...config.requestInterceptor,
            );
        }

        if (config.responseInterceptor) {
            this.responseInterceptor =
                this.httpClient.interceptors.response.use(
                    ...config.responseInterceptor,
                );
        }
    }
}

export default RestClientImpl;
