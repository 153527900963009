/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import DateTimeComponent from './DateTimeComponent';
import DateTimeProps from './DateTime.types';
import moment, { Moment } from 'moment';
import { forwardRef, useEffect, useState } from 'react';
import InputMaskWrapper from '../InputMaskWrapper';

interface DatePickerProps extends DateTimeProps {
    inputMask?: string;
    inputMaskChar?: string;

    /**
     * Forces the triggering of the `onChange` callback even if the value is not a valid date.
     */
    forceOnChange?: boolean;
}

const DATE_FORMAT = 'MM/DD/YYYY';

const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
    (props, ref) => {
        const {
            inputMask = '99/99/9999',
            inputMaskChar = '_',
            value,
            errorVariant = 'legacy',
            forceOnChange = false,

            ...rest
        } = props;

        const [privateValue, setPrivateValue] = useState<string | undefined>();

        useEffect(() => {
            setPrivateValue(value);
        }, [value]);

        const _onChange = (newDate: Moment) => {
            const dateString = moment.isMoment(newDate)
                ? newDate.format(DATE_FORMAT)
                : newDate;
            props.onChange && props.onChange(newDate, props.name, dateString);
        };

        const handleChange = (newValue: Moment | string) => {
            if (
                forceOnChange ||
                (moment.isMoment(newValue) && newValue.isValid())
            ) {
                _onChange(newValue as any);
            }
            setPrivateValue(newValue as any);
        };

        const handleCalendarClose = (newValue: Moment | string) => {
            let date = moment(newValue);
            if (!date.isValid()) {
                date = moment(value);
            }
            _onChange(date);
        };

        return (
            <DateTimeComponent
                {...rest}
                ref={ref}
                value={privateValue}
                dateFormat={DATE_FORMAT}
                timeFormat={false}
                onChange={handleChange}
                onCalendarClose={handleCalendarClose}
                errorVariant={errorVariant}
                renderInput={props => (
                    <InputMaskWrapper
                        {...props}
                        mask={inputMask}
                        maskChar={inputMaskChar}
                        icon='calendar'
                        value={privateValue ? props.value : ''}
                    />
                )}
            />
        );
    }
);

export default Object.assign(DatePicker, {
    ABSOLUTE_MINIMUM_DATE: new Date(-8640000000000000),
});
