export enum ArrayFormat {
    BRACKETS = 'brackets',
    COMMA = 'comma',
    REPEAT = 'repeat',
    INDICES = 'indices',
}

export type ParamOptions = {
    arrayFormat: ArrayFormat;
};

const processArrayFormat = (
    key: string,
    value: string[],
    arrayFormat?: ArrayFormat,
): string => {
    switch (arrayFormat) {
        case ArrayFormat.BRACKETS:
            return value.map((v) => `${key}[]=${v}`).join('&');
        case ArrayFormat.REPEAT:
            return value.map((v) => `${key}=${v}`).join('&');
        case ArrayFormat.INDICES:
            return value.map((v, index) => `${key}[${index}]=${v}`).join('&');
        default:
            return value.join(',');
    }
};

export const getUrlWithSearchParams = (
    url: string,
    params?: { [key in string]?: string | number | boolean | string[] },
    options?: ParamOptions,
): string => {
    const searchParams = new URLSearchParams();

    Object.entries(params || {}).forEach(([key, value]) => {
        if (value === undefined || value === null) {
            return;
        }

        searchParams.append(
            key,
            Array.isArray(value)
                ? processArrayFormat(key, value, options?.arrayFormat)
                : value.toString(),
        );
    });

    const searchParamsString = searchParams.toString();

    if (searchParamsString) {
        url += `?${searchParamsString}`;
    }
    return url;
};
