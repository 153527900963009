import {
    Link as LinkChakra,
    LinkProps as LinkPropsChakra,
    ResponsiveValue,
    useBreakpointValue,
} from '@chakra-ui/react';
import { isString } from 'lodash';
import React from 'react';
import type { DesignTokens } from '../../../shared/theme/types';
import { InternalBaseProps } from '../../../shared/theme/interfaces/Base.types';
import { genDataTestId } from '../../../shared/utils/helpers';
import { IconImpl, IconProps } from '../../Media/Icon';
import styles from './Link.styles';

type Sizes = keyof typeof styles['sizes'];
type Variants = keyof typeof styles['variants'];

export interface LinkProps {
    children: LinkPropsChakra['children'];
    href: string;
    size?: ResponsiveValue<Sizes>;
    variant?: ResponsiveValue<Variants>;
    withVisitedState?: boolean;
    isExternal?: LinkPropsChakra['isExternal'];
    color?: DesignTokens['colors'];
    leftIcon?: IconProps['as'];
    rightIcon?: IconProps['as'];
    iconVariant?: ResponsiveValue<IconProps['variant']>;
    className?: string;
}

export interface LinkImplProps
    extends Omit<InternalBaseProps<'a'>, 'href' | 'children' | 'color'>,
        LinkProps {}

const getIconSizeByLinkSize = (linkSize: Sizes): IconProps['size'] => {
    switch (linkSize) {
        case 'sm':
            return 'xss';
        case 'base':
            return 'sm';
        case 'lg':
            return 'md';
    }
};

export const LinkImpl = (props: LinkImplProps) => {
    const {
        children,
        size: sizeProp = 'base',
        variant: variantProp = 'default',
        withVisitedState = false,
        leftIcon: leftIconName,
        rightIcon: rightIconName,
        iconVariant: iconVariantProp = 'outlined',
        ...rest
    } = props;

    const size = useBreakpointValue(
        isString(sizeProp) ? [sizeProp] : sizeProp
    ) as Sizes;

    const variant = useBreakpointValue(
        isString(variantProp) ? [variantProp] : variantProp
    ) as Variants;

    const iconVariant = useBreakpointValue(
        isString(iconVariantProp) ? [iconVariantProp] : iconVariantProp
    ) as IconProps['variant'];

    const commonIconProps = {
        display: 'inline-flex',
        size: getIconSizeByLinkSize(size),
        variant: iconVariant,
    };

    const LeftIcon = () =>
        leftIconName ? (
            <IconImpl as={leftIconName} mr={2} {...commonIconProps} />
        ) : null;

    const RightIcon = () =>
        rightIconName ? (
            <IconImpl as={rightIconName} ml={2} {...commonIconProps} />
        ) : null;

    return (
        <LinkChakra
            data-testid={genDataTestId('link')}
            size={size}
            variant={variant}
            data-with-visited-state={withVisitedState}
            {...rest}
        >
            <LeftIcon /> {children} <RightIcon />
        </LinkChakra>
    );
};

const Link = (props: LinkProps) => <LinkImpl {...props} />;

export default Link;
