export type allowedArgsTypes =
    | string
    | number
    | boolean
    | Error
    | Record<string, any>;

export interface BaseLogger {
    debug(message: string | Error, ...args: allowedArgsTypes[]): void;
    verbose(message: string | Error, ...args: allowedArgsTypes[]): void;
    info(message: string | Error, ...args: allowedArgsTypes[]): void;
    warn(message: string | Error, ...args: allowedArgsTypes[]): void;
    error(message: string | Error, ...args: allowedArgsTypes[]): void;
}

export interface BrowserLogger extends BaseLogger {}

export interface NodeLogger extends BaseLogger {
    middleware: {
        processExecutionId: (req: any, res: any, next: () => {}) => void;
        HTTP: any; // express.core.Handler
    };
}

export enum Level {
    Debug = 'debug',
    Verbose = 'verbose',
    Info = 'info',
    Warn = 'warn',
    Error = 'error',
}
