import { clamp } from 'lodash';
import React, { Children, cloneElement, isValidElement } from 'react';

import {
    RadioGroupProps as RadioGroupPropsChakra,
    useRadioGroup,
} from '@chakra-ui/react';

import { InternalBaseProps } from '../../../shared/theme/interfaces/Base.types';
import { genDataTestId } from '../../../shared/utils/helpers';
import { StackImpl } from '../../Layout/Stack';
import InlineSelectOption from './InlineSelectOption/InlineSelectOption';
import { ColorProps } from '../../../shared/theme/types';

export interface InlineSelectProps {
    children?: RadioGroupPropsChakra['children'];
    className?: RadioGroupPropsChakra['className'];
    defaultValue?: RadioGroupPropsChakra['defaultValue'];
    value?: RadioGroupPropsChakra['value'];
    name?: RadioGroupPropsChakra['name'];
    onChange?: RadioGroupPropsChakra['onChange'];
    optionsToShow?: number;
    width?: RadioGroupPropsChakra['width'];
}

export interface InlineSelectImplProps
    extends Omit<
            InternalBaseProps,
            'defaultValue' | 'children' | 'onChange' | 'width'
        >,
        Omit<RadioGroupPropsChakra, 'children' | 'width' | keyof ColorProps>,
        InlineSelectProps {}

export const InlineSelectImpl = ({
    children = null,
    className = '',
    defaultValue = '',
    value = '',
    name = '',
    onChange = () => {},
    optionsToShow = 6,
    width = 'fit-content',
    ...props
}: InlineSelectImplProps) => {
    const { getRootProps, getRadioProps } = useRadioGroup({
        name,
        value,
        defaultValue,
        onChange,
    });
    const group = getRootProps();

    const calculateHeight = (optionsToShow: number) => {
        const childrenAmount = Children.count(children);
        const optionsToShowClamped = clamp(optionsToShow, 1, childrenAmount);

        const optionHeight = 36;
        const containerUpperPadding = 8;

        return `${
            optionsToShowClamped * optionHeight + containerUpperPadding
        }px`;
    };

    return (
        <StackImpl
            data-testid={genDataTestId('inline-select')}
            position='relative'
            align='stretch'
            className={className}
            py={2}
            border='1px'
            borderColor='blueGray.300'
            borderRadius='md'
            width={width}
            minW={width || '6.25rem'}
            direction='column'
            wrap='nowrap'
            styles={{
                '&::-webkit-scrollbar': {
                    width: '3px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'blueGray.200',
                    borderRadius: '1px',
                },
            }}
            overflowY={
                Children.count(children) > optionsToShow ? 'scroll' : 'initial'
            }
            h={calculateHeight(optionsToShow)}
            {...group}
            {...props}
        >
            {Children.map(children, child => {
                if (!isValidElement(child)) return null;
                if (child.type !== InlineSelectOption) return child;

                const radioProps = getRadioProps({ value: child.props.value });
                return cloneElement(child, {
                    key: child.props.value,
                    ...radioProps,
                });
            })}
        </StackImpl>
    );
};

const InlineSelect = (props: InlineSelectProps) => (
    <InlineSelectImpl {...props} />
);

export default InlineSelect;
