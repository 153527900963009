import React from 'react';
import { BaseBoxImpl } from '../../Layout/BaseBox';
import stylesConfig from './Sidebar.styles';
import { ResponsiveValue, useStyleConfig } from '@chakra-ui/react';
import { DataAttrsType } from '../../../shared/theme/interfaces/Base.types';
import SidebarMenu from './SidebarMenu/SidebarMenu';

import SidebarMenuGroup from './SidebarMenuGroup/SidebarMenuGroup';

type Variants = keyof typeof stylesConfig['variants'];
type Sizes = keyof typeof stylesConfig['sizes'];

export type SidebarProps = {
    children: React.ReactNode;
    className?: string;
    variant?: ResponsiveValue<Variants>;
    isSticky?: boolean;
    [dataAttrKey: DataAttrsType]: unknown;
    size?: ResponsiveValue<Sizes>;
};

const Sidebar = (props: SidebarProps) => {
    const {
        children,
        isSticky = true,
        variant = 'default',
        className,
        size = 'md',
        ...rest
    } = props;
    const styles = useStyleConfig('Sidebar', { variant, isSticky, size });

    return (
        <BaseBoxImpl
            data-testid='sidebar'
            styles={styles}
            className={className}
            {...rest}
        >
            {children}
        </BaseBoxImpl>
    );
};

export default Object.assign(Sidebar, {
    Menu: SidebarMenu,
    Group: SidebarMenuGroup,
});
