import { models } from '@trova-trip/trova-models';
import { lookup } from '@trova-trip/country-data';

type SavedUser = models.users.SavedUser;
type HostProfileResponse = models.hostProfiles.HostProfileResponse;

export const getUserLocationData = (
    user: Pick<SavedUser, 'countryCode' | 'languages'> | null,
): Pick<HostProfileResponse, 'country' | 'languages'> => {
    const { countryCode } = user || {};

    const countryName = lookup.countries({
        alpha2: countryCode?.toUpperCase(),
    })?.[0]?.name;

    const country = {
        name: countryName,
        code: countryCode,
    };
    const languages =
        user?.languages
            ?.flatMap((language) =>
                lookup.languages({ alpha2: language.toLowerCase() }),
            )
            ?.map((language) => ({
                name: language.name,
                code: language.alpha2,
            })) || [];

    return { country, languages };
};
