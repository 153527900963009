import { ReactNode } from 'react';
import { ThemeBreakpoints } from '../../../shared/theme/foundations/breakpoints';
import { GridProps } from '../../Layout/Grid';
import { ResponsiveValue } from '@chakra-ui/react';
import { DataAttrsType } from '../../../shared/theme/interfaces/Base.types';

export type SidebarPlacement = 'top' | 'bottom' | 'left' | 'right';

export enum MainLayoutAreas {
    sidebar = 'sidebar',
    main = 'main',
}

export type CastedResponsiveValue = {
    [K in keyof ThemeBreakpoints]?: string;
};

export type BaseMainConfig = {
    gridTemplateAreas: CastedResponsiveValue | string;
    gridTemplateColumns: CastedResponsiveValue;
};
export type BaseTemplateAreas = Record<SidebarPlacement, string>;

export type MainContentProps = GridProps & {
    children: ReactNode;
    sidebarElement?: ReactNode;
    sidebarConfig?: {
        sidebarColumnWidth?: number;
        placement?: ResponsiveValue<string>;
    };
    columnGap?: number;
    [dataAttrKey: DataAttrsType]: unknown;
};
