import { css } from '@emotion/react';
import { theme } from '../../../shared/theme';

export const input = (disabled: boolean, error: string | string[]) => css`
    input {
        font-family: ${theme.fonts.roboto};
        font-size: ${theme.fontSizes.base};
        color: ${theme.colors.blueGray[650]};
        border-radius: ${theme.radii.md};
        width: 100%;
        height: 2.5rem;
        padding: ${theme.space[2]} ${theme.space[4]};
        background-color: ${disabled
            ? theme.colors.blueGray[50]
            : theme.colors.neutral.white};
        border: ${disabled
            ? `1px solid ${theme.colors.blueGray[400]}`
            : `1px solid ${theme.colors.blueGray[300]}`};
        line-height: ${theme.lineHeights[5]};
        outline: none;

        ${!error &&
        `
        :focus-within {
            border: 1px solid ${theme.colors.blue[500]};
        }
        `}

        ${error &&
        error.length &&
        `border: 1px solid ${theme.colors.red.trova};`}
    }
`;
