import { css } from '@emotion/react';
import type { Theme } from '../../../shared/theme';
import { requiredGlyph } from '../../Legacy/Forms/Input/Input.styles';

export const labelContainer = () => css`
    display: flex;
    justify-content: space-between;
    height: 1rem;
    margin-bottom: 10px;
`;

// need to refactor this to a common shared with Inputs
export const labelSpan = (theme: Theme, required = false) => css`
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeights.medium};
    line-height: 16px;
    color: ${theme.colors.blueGray.dark};
    ${required && requiredGlyph};
`;
export const infoImage = () => css`
    width: 1.2rem;
    height: 1.2rem;
    position: relative;
    display: inline-block;
`;

export const infoImageContainer = () => css`
    position: relative;
    input {
        display: none;
    }
    input:checked + span {
        visibility: visible;
        opacity: 1;
    }
`;

export const tooltip = (theme: Theme) => css`
    visibility: hidden;
    width: 160px;
    background-color: ${theme.colors.blueGray[650]};
    color: ${theme.colors.neutral.white};
    font-size: ${theme.fontSizes.xs};
    text-align: center;
    padding: 1rem;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 100%;
    right: 25%;
    margin-left: -80px;
    opacity: 0;
    transition: opacity 0.3s;
`;
