import { BaseStatusError } from './baseError';

export const bookingErrors = {
    earlyBirdPrice: {
        message:
            "We're sorry, but the early-bird pricing has expired. Please review the new price.",
        code: 1050,
    },
    soldOut: {
        message:
            "Sorry, your trip has sold out. Don't worry, you haven't been charged. You can join the waitlist",
        code: 1051,
    },
    alreadyBooked: {
        message: `Oops! It seems that this traveler is already included on a booking for this trip.`,
        code: 1052,
    },
    bookingError: {
        message:
            'We encountered an error while processing your booking. Please double-check the information you provided and ensure that all details are accurate.',
        code: 1053,
    },
    paymentError: {
        message:
            'Something has gone wrong with your payment, please contact Trovatrip support',
        code: 1054,
    },
    invalidPrice: {
        message:
            'Sorry but the price is invalid, please contact Trovatrip support',
        code: 1055,
    },
    hostCannotBookTrip: {
        message:
            'This email is a registered TrovaTrip host, please book under a different email',
        code: 1056,
    },
    travelerCannotUseCoupon: {
        message: `This code is invalid for this trip. If you have questions, contact Trovatrip support`,
        code: 1057,
    },
    SingleSupplementUnavailable: {
        message:
            "Sorry, we have run out of private rooms at this time. Don't worry, you haven't been charged. You can contact Trovatrip support for further assistance.",
        code: 1058,
    },
    invalidAddOnDeletionRequest: {
        message: (addOnId: string, bookingId: string) =>
            `Cannot delete add-on. Add-on ${addOnId} already deleted on booking with id ${bookingId}.`,
        code: 1059,
    },
};

export class EarlyBirdPrice extends BaseStatusError {
    constructor(message?: string) {
        super(
            bookingErrors.earlyBirdPrice.code,
            message || bookingErrors.earlyBirdPrice.message,
        );
    }
}

export class SoldOut extends BaseStatusError {
    constructor(message?: string) {
        super(
            bookingErrors.soldOut.code,
            message || bookingErrors.soldOut.message,
        );
    }
}

export class AlreadyBooked extends BaseStatusError {
    constructor(message?: string) {
        super(
            bookingErrors.alreadyBooked.code,
            message || bookingErrors.alreadyBooked.message,
        );
    }
}

export class BookingError extends BaseStatusError {
    constructor(message?: string) {
        super(
            bookingErrors.bookingError.code,
            message || bookingErrors.bookingError.message,
        );
    }
}

export class PaymentError extends BaseStatusError {
    constructor(message?: string) {
        super(
            bookingErrors.paymentError.code,
            message || bookingErrors.paymentError.message,
        );
    }
}

export class InvalidPrice extends BaseStatusError {
    constructor(message?: string) {
        super(
            bookingErrors.invalidPrice.code,
            message || bookingErrors.invalidPrice.message,
        );
    }
}

export class HostCannotBookTrip extends BaseStatusError {
    constructor(message?: string) {
        super(
            bookingErrors.hostCannotBookTrip.code,
            message || bookingErrors.hostCannotBookTrip.message,
        );
    }
}

export class TravelerCannotUseCoupon extends BaseStatusError {
    constructor(message?: string) {
        super(
            bookingErrors.travelerCannotUseCoupon.code,
            message || bookingErrors.travelerCannotUseCoupon.message,
        );
    }
}

export class SingleSupplementUnavailable extends BaseStatusError {
    constructor(message?: string) {
        super(
            bookingErrors.SingleSupplementUnavailable.code,
            message || bookingErrors.SingleSupplementUnavailable.message,
        );
    }
}

export class InvalidAddOnDeletionRequest extends BaseStatusError {
    constructor(addOnId: string, bookingId: string) {
        super(
            bookingErrors.invalidAddOnDeletionRequest.code,
            bookingErrors.invalidAddOnDeletionRequest.message(
                addOnId,
                bookingId,
            ),
        );
    }
}
