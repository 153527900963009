import { models } from '@trova-trip/trova-models';
import {
    CostPerThreshold,
    OptionalCostThresholds,
    CompanionCostThresholds,
    WorkshopCostThresholds,
    CostByDayThresholds,
    BaseCostThresholds,
    CostThresholdWithAllCosts,
    CostConfiguration,
} from '../PricingCalculator.types';
import { TripPricing } from '../TripPricing/TripPricing';
import { BaseTripInventoryPricing } from './BaseTripInventoryPricing';
import { fees } from '../Utils/fees.utils';
import { eachDayOfTrip } from '../../utils/date.utils';
import { calculatePredictedPricePerTier } from '../Utils/pricing.utils';

type CostScheduleItem = models.itineraryInventoryItems.CostScheduleItem;

export class TripInventoryPricingImpl
    extends BaseTripInventoryPricing
    implements TripPricing
{
    constructor(config: CostConfiguration) {
        super(config);
    }

    public get costsByDay(): CostByDayThresholds[] {
        const dates = eachDayOfTrip(
            this.config.startDate,
            this.config.tripLength,
        );
        const costsByDay: CostByDayThresholds[] = [];

        for (const date of dates) {
            costsByDay.push({
                day: date,
                singleSupplementPrice: this.config.singleSupplementPrice,
                costPerThreshold: this.calculatedCosts.costsByDay,
            });
        }

        return costsByDay;
    }

    /**
     * @deprecated
     */
    public get baseCosts(): BaseCostThresholds {
        return {
            singleSupplementPrice: this.config.singleSupplementPrice,
            costPerThreshold: this.calculatedCosts.currentCosts,
        };
    }

    public get companionsCosts(): CompanionCostThresholds[] {
        return [
            {
                quantity: this.config.focQuantity,
                costPerThreshold: this.calculatedCosts.focCosts,
            },
        ];
    }

    /**
     * @deprecated
     */
    public get suggestedCosts(): CostPerThreshold[] {
        return [];
    }

    public get currentCosts(): CostPerThreshold[] {
        return this.calculatedCosts.currentCosts;
    }

    public get hostSelectedOptionalsCosts(): OptionalCostThresholds[] {
        return this.calculatedCosts.optionalCosts;
    }

    public get workshopsCosts(): WorkshopCostThresholds[] {
        return this.calculatedCosts.workshopsCosts;
    }

    public get servicesTotalCosts(): CostPerThreshold[] {
        return this.calculatedCosts.servicesTotalCosts;
    }

    public get singleSupplementCostAdjustments(): CostPerThreshold[] {
        return this.calculatedCosts.singleSupplementCostAdjustments;
    }

    public get operatorTotalCosts(): CostPerThreshold[] {
        return this.calculatedCosts.operatorTotalCosts;
    }

    public get singleSupplementFeeCosts(): CostPerThreshold[] {
        return this.calculatedCosts.singleSupplementCosts;
    }

    public get operatorFee(): number {
        return fees.operatorFee;
    }

    public get platformFees(): CostPerThreshold[] {
        return this.calculatedCosts.platformFeeCosts;
    }

    public get travelerTotalCosts(): CostPerThreshold[] {
        return this.calculatedCosts.hostTotalCosts;
    }

    public get hostCostsWithFixedCostsAndPrices(): CostThresholdWithAllCosts[] {
        return this.calculatedCosts.hostCostsWithFixedCostsAndPrices;
    }

    public get hostGroundTransferCost(): CostPerThreshold[] {
        return this.calculatedCosts.hostGroundTransferCosts;
    }

    public addTier(tier: number): void {
        this.addCostPerThreshold(tier);
    }
    public removeTier(tier: number): void {
        this.removeCostPerThreshold(tier);
    }

    public getPredictedPricePerTier(
        costSchedules: CostScheduleItem[],
        tier: number,
    ): CostPerThreshold {
        return calculatePredictedPricePerTier(costSchedules, tier);
    }
}
