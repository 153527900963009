import {
    TableRowProps as TableRowPropsChakra,
    Tr as TrChakra,
} from '@chakra-ui/react';
import React from 'react';

export const TableRowImpl = (props: TableRowPropsChakra) => (
    <TrChakra {...props} />
);

const TableRow = (props: TableRowPropsChakra) => <TableRowImpl {...props} />;

export default TableRow;
