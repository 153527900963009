import { models, constants } from '@trova-trip/trova-models';
import { BigDecimal } from './numbers.utils';

type ReviewReport = models.reviewReports.ReviewReport;
type AllowedRating = constants.reviews.AllowedRating;
/**
 * Calculates the overall rating based on the average of the ratingCounts in the provided report.
 *
 * @param {ReviewReport} report - The report containing rating counts.
 * @returns {number | undefined} The overall rating, or undefined if there are no ratings.
 */
export const calculateOverallRating = (
    report: ReviewReport,
): number | undefined => {
    const { ratingCounts } = report;

    let totalWeight = 0;
    let totalCount = 0;

    for (const ratingStr in ratingCounts) {
        const rating = Number.parseInt(ratingStr) as AllowedRating;
        const count = ratingCounts[rating];
        const weight = rating * count; // Calculate the weighted value
        totalWeight += weight;
        totalCount += count;
    }

    if (totalCount === 0) {
        return undefined; // Return undefined if there are no ratings
    }

    const overallRating = BigDecimal.of(totalWeight).div(totalCount);

    // Return with 1 decimal if not an integer
    // Return with 1 decimal if an integer to add .0
    return parseFloat(overallRating.toFixed(1));
};
