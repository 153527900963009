import hubspotMiddleware from './hubspotMiddleware';
import ga4Middleware from './ga4Middleware';
import facebookPixelMiddleware from './facebookPixelMiddleware';

const sourceMiddleware = [ga4Middleware];
const destinationMiddleware: Record<string, (params: any) => void> = {
    hubspot: hubspotMiddleware,
    "Facebook Pixel": facebookPixelMiddleware,
};

export { sourceMiddleware, destinationMiddleware };
