import { BaseStatusError } from './baseError';

export const httpMessages = {
    badRequest: 'Bad Request',
    unauthorized: 'Unauthorized',
    forbidden: 'Forbidden',
    notFound: 'Not Found',
    methodNotAllowed: 'Method Not Allowed',
    conflict: 'Conflict',
    serverError: 'Internal Server Error',
    notAcceptable: 'Not Acceptable',
};

export class BadRequest extends BaseStatusError {
    constructor(message?: string) {
        super(400, message || httpMessages.badRequest);
    }
}

export class Unauthorized extends BaseStatusError {
    constructor(message?: string) {
        super(401, message || httpMessages.unauthorized);
    }
}

export class Forbidden extends BaseStatusError {
    constructor(message?: string) {
        super(403, message || httpMessages.forbidden);
    }
}

export class NotFound extends BaseStatusError {
    constructor(message?: string) {
        super(404, message || httpMessages.notFound);
    }
}

export class MethodNotAllowed extends BaseStatusError {
    constructor(message?: string) {
        super(405, message || httpMessages.methodNotAllowed);
    }
}

export class Conflict extends BaseStatusError {
    constructor(message?: string) {
        super(409, message || httpMessages.conflict);
    }
}

export class ServerError extends BaseStatusError {
    constructor(message?: string) {
        super(500, message || httpMessages.serverError);
    }
}

export class NotAcceptable extends BaseStatusError {
    constructor(message?: string) {
        super(406, message || httpMessages.notAcceptable);
    }
}
