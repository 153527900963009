import {
    createGoogleContextTemplate,
    createFacebookContextTemplate,
} from './templates';

const create = (context?: Record<string, any>) => {
    return {
        ...createGoogleContextTemplate(),
        traits: { ...createFacebookContextTemplate(context) },
        ...context,
    };
};

export default create;
