import { isEmpty, union } from 'lodash';
import { models, constants } from '@trova-trip/trova-models';
import {
    CostThreshold,
    CostThresholdWithAllCosts,
    ThresholdEarningsForTrip,
    FinalCostThresholds,
    ThresholdEarning,
    TripCostDTO,
    TripRequestCostDTO,
    ItineraryCostDTO,
    CalculatedHostRevenues,
    SuggestedSellPrice,
    CostPerThreshold,
} from '../PricingCalculator.types';
import {
    getCostOutputs,
    roundUp,
    roundDown,
    MAXIMUM_HOST_MARGIN,
    TARGET_MINIMUM_HOST_PROFIT,
    DEFAULT_REMAINING_PRICE_EXTRA,
} from './common.utils';
import { fees } from './fees.utils';
import {
    getTotalTravelerRevenueByBookingStatus,
    getTotalSpotsBookedByBookingStatus,
} from '../../utils/booking.utils';
import { tripPricing } from '../PricingFactories';
import { addDaysToDate } from '../../utils/date.utils';
import { DEFAULT_BOOKINGS_DEADLINE } from '../../app.constants';

type Trip = models.trips.Trip;

const { BookingStatuses } = constants.bookings;

// #region private methods
const _getTripCost = (
    totalRevenue: number,
    costThresholds: CostThresholdWithAllCosts[],
    totalBookings: number,
    earningsAdjustment = 0,
): number => {
    if (!costThresholds?.length) {
        return 0;
    }
    const threshold: CostThresholdWithAllCosts = costThresholds.reduce(
        (currentThreshold, nextThreshold) =>
            totalBookings >= nextThreshold.numberOfTravelers
                ? nextThreshold
                : currentThreshold,
    );
    const { priceWithAllCosts = 0 } = threshold;

    return (
        Math.floor(
            totalRevenue -
                priceWithAllCosts * totalBookings +
                earningsAdjustment,
        ) ?? 0
    );
};

const _getThresholdAtNumberOfTraveler = (
    costThresholds: CostThresholdWithAllCosts[],
    travelerNumber: number,
): CostThresholdWithAllCosts =>
    costThresholds
        .sort(({ numberOfTravelers: a }, { numberOfTravelers: b }) => a - b)
        .reduce(
            (previousThreshold, actualCostThreshold) => {
                const { numberOfTravelers } = actualCostThreshold;
                if (
                    isEmpty(previousThreshold) ||
                    previousThreshold.numberOfTravelers === 0 ||
                    numberOfTravelers <= travelerNumber
                ) {
                    return actualCostThreshold;
                }
                return previousThreshold;
            },
            {
                numberOfTravelers: 0,
                priceWithAllCosts: 0,
                priceWithFixedCost: 0,
                serviceCost: 0,
                transactionCost: 0,
            },
        );

const _getTravelerRowNumbers = (
    costThresholds: Pick<CostThreshold, 'numberOfTravelers'>[],
    itineraryMin: number,
    itineraryMax: number,
    minimumSpots: number,
    maximumSpots: number,
) => {
    const actualThresholdsRowNumbers = costThresholds.map(
        (threshold) => threshold.numberOfTravelers,
    );

    const maxCheck = Math.min(itineraryMax, maximumSpots);
    const minCheck = Math.max(itineraryMin, minimumSpots);
    let incrementer = minCheck;
    const travelerRowNumbers = [];
    while (incrementer < maxCheck) {
        travelerRowNumbers.push(incrementer);
        incrementer++;
        incrementer++;
    }
    travelerRowNumbers.push(maxCheck);

    const rowNumbersUnion = union(
        actualThresholdsRowNumbers,
        travelerRowNumbers,
    );

    return rowNumbersUnion.sort((a, b) => a - b);
};

const _addCostsToTravelerRowNumbers = (
    travelerRowNumbers: Array<number>,
    costThresholds: CostThresholdWithAllCosts[],
): FinalCostThresholds[] =>
    travelerRowNumbers.map((travelerRowNumber) => {
        const thresholdPerTraveler = _getThresholdAtNumberOfTraveler(
            costThresholds,
            travelerRowNumber,
        );
        return {
            ...thresholdPerTraveler,
            travelerRowNumber,
            costPerTraveler: thresholdPerTraveler.priceWithAllCosts,
            totalCost:
                thresholdPerTraveler.priceWithAllCosts * travelerRowNumber,
        };
    });

const _addEarningsAndDisabled = (
    travelerRowsAndCosts: FinalCostThresholds[],
    initialPrice: number,
    remainingPrice: number,
    minimumSpots: number,
    maximumSpots: number,
): Array<ThresholdEarning> =>
    travelerRowsAndCosts
        .map((costsRow) => {
            const { travelerRowNumber, totalCost } = costsRow;
            const initialRevenue = minimumSpots * initialPrice;
            const additionalRevenue =
                (travelerRowNumber - minimumSpots) * remainingPrice;
            return {
                ...costsRow,
                totalEarnings: roundDown(
                    initialRevenue + additionalRevenue - totalCost,
                ),
                disabled: travelerRowNumber > maximumSpots,
            };
        })
        .filter(({ travelerRowNumber }) => travelerRowNumber <= maximumSpots);

const _getProjectedEarningsFromPrices = (
    costThresholds: CostThresholdWithAllCosts[] = [],
    initialPrice: number,
    remainingPrice: number,
    minimumSpots: number,
    maximumSpots: number,
    itineraryMin: number,
    itineraryMax: number,
) => {
    const travelerRowNumbers = _getTravelerRowNumbers(
        costThresholds,
        itineraryMin,
        itineraryMax,
        minimumSpots,
        maximumSpots,
    );

    const travelerRowsAndCosts = _addCostsToTravelerRowNumbers(
        travelerRowNumbers,
        costThresholds,
    );

    const costThresholdsEarnings = _addEarningsAndDisabled(
        travelerRowsAndCosts,
        initialPrice,
        remainingPrice,
        minimumSpots,
        maximumSpots,
    );
    const maxEarningValue = Math.max.apply(
        null,
        costThresholdsEarnings.map(({ totalEarnings }) => totalEarnings),
    );
    const maxValue =
        maxEarningValue + (maxEarningValue * MAXIMUM_HOST_MARGIN) / 100;

    return {
        costThresholdsEarnings,
        maxValue,
    };
};
// #endregion

export const getTripEarnings = (
    trip: TripCostDTO | models.trips.Trip,
    totalPendingAndConfirmedRevenue: number,
    pendingSupplierTravelers: number,
    confirmedTravelers: number,
): number => {
    const { hostTerms, earningsAdjustment } = trip;

    if (!hostTerms) {
        throw new Error('Missing host terms');
    }
    const finalCostThresholds = getCostOutputs(hostTerms);

    if (!finalCostThresholds?.length) {
        return 0;
    }

    return _getTripCost(
        totalPendingAndConfirmedRevenue,
        finalCostThresholds,
        pendingSupplierTravelers + confirmedTravelers,
        earningsAdjustment,
    );
};

export const calculateThresholdEarningsForTrip = (
    trip: TripCostDTO | models.trips.Trip | models.trips.PopulatedTrip,
    costThresholdsWithFixedCosts: CostThresholdWithAllCosts[],
): ThresholdEarningsForTrip => {
    const { minimumSpots = 0, maximumSpots = 0, prices } = trip;

    const { initial: initialPrice = 0, remainingPrice = 0 } = prices || {};

    const { costThresholdsEarnings, maxValue } =
        _getProjectedEarningsFromPrices(
            costThresholdsWithFixedCosts,
            initialPrice,
            remainingPrice,
            minimumSpots,
            maximumSpots,
            minimumSpots,
            maximumSpots,
        );

    return { costThresholdsEarnings, maxValue };
};

export const getHostRevenues = (
    trip: TripCostDTO | Trip,
    bookings: models.bookings.BookingExtractedData[],
): CalculatedHostRevenues => {
    const {
        hostTerms,
        earningsAdjustment,
        minimumSpots = 0,
        startDate,
        bookingsDeadline = DEFAULT_BOOKINGS_DEADLINE,
    } = trip as Trip;

    if (!hostTerms) {
        return {
            projectedEarnings: 0,
            collectedEarnings: 0,
        };
    }
    const finalCostThresholds = getCostOutputs(hostTerms);

    const projectedEarningsStatuses = [
        BookingStatuses.CANCELLED,
        BookingStatuses.PENDING,
        BookingStatuses.CONFIRMED,
    ];

    const collectedEarningsStatuses = [
        BookingStatuses.CANCELLED,
        BookingStatuses.CONFIRMED,
    ];

    // Filter out cancelled bookings that do not meet the deadline
    const bookingsToCalculateEarnings = bookings.filter(
        ({ confirmedDate, cancellationRequestDate, status }) => {
            if (status !== BookingStatuses.CANCELLED) {
                return true;
            }

            return (
                !!confirmedDate &&
                !!cancellationRequestDate &&
                !!startDate &&
                cancellationRequestDate >=
                    addDaysToDate(startDate, -bookingsDeadline)
            );
        },
    );

    const totalPendingAndConfirmedRevenueForPotentialEarnings =
        getTotalTravelerRevenueByBookingStatus(
            bookingsToCalculateEarnings,
            projectedEarningsStatuses,
        );

    const totalConfirmedAndPaidInFullRevenueForCollectedEarnings =
        getTotalTravelerRevenueByBookingStatus(
            bookingsToCalculateEarnings,
            collectedEarningsStatuses,
            true,
        );

    const totalPendingAndConfirmedSpots = getTotalSpotsBookedByBookingStatus(
        bookingsToCalculateEarnings,
        projectedEarningsStatuses,
    );

    const totalConfirmedAndPaidInFullSpots = getTotalSpotsBookedByBookingStatus(
        bookingsToCalculateEarnings,
        collectedEarningsStatuses,
        true,
    );

    const totalSpotsForCollectedEarnings =
        totalPendingAndConfirmedSpots < minimumSpots
            ? totalPendingAndConfirmedSpots
            : totalConfirmedAndPaidInFullSpots > minimumSpots
            ? totalConfirmedAndPaidInFullSpots
            : minimumSpots;

    const projectedEarnings = _getTripCost(
        totalPendingAndConfirmedRevenueForPotentialEarnings,
        finalCostThresholds,
        totalPendingAndConfirmedSpots,
        earningsAdjustment,
    );

    const collectedEarnings = _getTripCost(
        totalConfirmedAndPaidInFullRevenueForCollectedEarnings,
        finalCostThresholds,
        totalSpotsForCollectedEarnings,
        earningsAdjustment,
    );

    return {
        projectedEarnings,
        collectedEarnings,
    };
};

const _getActualEarnings = (
    pricePerTraveler: number,
    initialPrice: number,
    minimumSpots: number,
) => {
    const remainingPrice = initialPrice + DEFAULT_REMAINING_PRICE_EXTRA;
    const exactServiceCost = roundUp(initialPrice * (fees.serviceFee / 100));
    const exactTransactionCost = roundUp(
        initialPrice * (fees.transactionFee / 100),
    );
    const priceWithAllCosts =
        pricePerTraveler + exactServiceCost + exactTransactionCost;

    const totalCost = priceWithAllCosts * minimumSpots;

    const initialRevenue = minimumSpots * initialPrice;

    return roundUp(initialRevenue - totalCost);
};

// Katie's Algorithm
const _getSuggestedSellPrice = (
    travelerTotalCosts: CostPerThreshold[],
    minimumSpots: number,
    minimumHostEarnings: number,
): SuggestedSellPrice => {
    const totalCost =
        travelerTotalCosts.reduce(
            (
                currentLowestThreshold: CostPerThreshold | null,
                threshold: CostPerThreshold,
            ) => {
                if (threshold.numberTravelers <= minimumSpots) {
                    if (
                        !currentLowestThreshold ||
                        threshold.numberTravelers >
                            currentLowestThreshold.numberTravelers
                    ) {
                        return threshold;
                    }
                }
                return currentLowestThreshold;
            },
            null,
        )?.pricePerTraveler ?? 0;

    const servicePercentage = fees.serviceFee / 100;
    const transactionPercentage = fees.transactionFee / 100;
    const hostProfitPerTraveler = minimumHostEarnings / minimumSpots;
    const hostPercentageOfPrice = 1 - servicePercentage - transactionPercentage;

    let initialPrice =
        roundUp((hostProfitPerTraveler + totalCost) / hostPercentageOfPrice) -
        1;

    const actualEarnings = _getActualEarnings(
        totalCost,
        initialPrice,
        minimumSpots,
    );

    if (actualEarnings > minimumHostEarnings) {
        initialPrice =
            initialPrice +
            roundUp((actualEarnings - minimumHostEarnings) / minimumSpots);
    } else if (actualEarnings < minimumHostEarnings) {
        initialPrice =
            initialPrice +
            roundUp((minimumHostEarnings - actualEarnings) / minimumSpots);
    }

    const remainingPrice = initialPrice + DEFAULT_REMAINING_PRICE_EXTRA;

    return {
        totalCost,
        initialPrice,
        remainingPrice,
    };
};

export const getSuggestedSellPriceFromTripRequest = (
    tripRequest: TripRequestCostDTO,
    itinerary: ItineraryCostDTO,
    minimumHostEarnings = TARGET_MINIMUM_HOST_PROFIT,
): SuggestedSellPrice => {
    const { minimumSpots = 0 } = itinerary;

    const travelerTotalCosts = tripPricing()
        .setTripRequestWithPopulateData(tripRequest)
        .setItineraryWithPopulateData(itinerary)
        .build().travelerTotalCosts;

    return _getSuggestedSellPrice(
        travelerTotalCosts,
        minimumSpots,
        minimumHostEarnings,
    );
};

export const getSuggestedSellPriceFromTrip = (
    trip: TripCostDTO,
    minimumHostEarnings = TARGET_MINIMUM_HOST_PROFIT,
): SuggestedSellPrice => {
    const { minimumSpots = 0 } = trip;

    const travelerTotalCosts = tripPricing()
        .setTripWithPopulateData(trip)
        .build().travelerTotalCosts;

    return _getSuggestedSellPrice(
        travelerTotalCosts,
        minimumSpots,
        minimumHostEarnings,
    );
};
