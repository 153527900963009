/**
 * @deprecated Use ApiResponseStatus from responses.ts instead.
 */
export enum ApiResponseStatus {
    SUCCESS = 'success',
    ERROR = 'error',
}

/**
 * @deprecated Use ErrorApiResponse from responses.ts instead.
 */
export type ErrorApiResponse = {
    status: ApiResponseStatus.ERROR;
    code: number;
    message: string;
};

/**
 * @deprecated Use SuccessApiResponse from responses.ts instead.
 */
export type SuccessApiResponse<T = any> = {
    status: ApiResponseStatus.SUCCESS;
    data: T;
};

/**
 * @deprecated Use ApiResponse from responses.ts instead.
 */
export type ApiResponse<T> = SuccessApiResponse<T> | ErrorApiResponse;
