import { BaseStatusError } from './baseError';

export const socialEngagementErrors = {
    userInvalid: {
        message: (socialEngagementUserId: string) =>
            `Social engagement user ${socialEngagementUserId} is invalid`,
        code: 1700,
    },
    userMismatched: {
        message: (socialEngagementUserId: string, userId: string) =>
            `Social engagement user ${socialEngagementUserId} is not associated with user ${userId}`,
        code: 1701,
    },
    usernameMissing: {
        message: (socialEngagementUserId: string, channel: string) =>
            `Social engagement user ${socialEngagementUserId} is missing a username for channel ${channel}`,
        code: 1702,
    },
    profileMissing: {
        message: (socialEngagementUserId: string, channel: string) =>
            `Social engagement user ${socialEngagementUserId} is missing a profile for channel ${channel}`,
        code: 1703,
    },
    profileUrlMissing: {
        message: (socialEngagementUserId: string, channel: string) =>
            `Social engagement user ${socialEngagementUserId} is missing a profile URL for channel ${channel}`,
        code: 1704,
    },
    channelNotFound: {
        message: (channel: string) =>
            `Channel ${channel} could not be found in the social engagement system`,
        code: 1710,
    },
};

export class SocialEngagementUserInvalidError extends BaseStatusError {
    constructor(socialEngagementUserId: string) {
        super(
            socialEngagementErrors.userInvalid.code,
            socialEngagementErrors.userInvalid.message(socialEngagementUserId),
        );
    }
}

export class SocialEngagementUserMismatchedError extends BaseStatusError {
    constructor(socialEngagementUserId: string, userId: string) {
        super(
            socialEngagementErrors.userMismatched.code,
            socialEngagementErrors.userMismatched.message(
                socialEngagementUserId,
                userId,
            ),
        );
    }
}

export class SocialEngagementUsernameMissingError extends BaseStatusError {
    constructor(socialEngagementUserId: string, channel: string) {
        super(
            socialEngagementErrors.usernameMissing.code,
            socialEngagementErrors.usernameMissing.message(
                socialEngagementUserId,
                channel,
            ),
        );
    }
}

export class SocialEngagementProfileMissingError extends BaseStatusError {
    constructor(socialEngagementUserId: string, channel: string) {
        super(
            socialEngagementErrors.profileMissing.code,
            socialEngagementErrors.profileMissing.message(
                socialEngagementUserId,
                channel,
            ),
        );
    }
}

export class SocialEngagementProfileUrlMissingError extends BaseStatusError {
    constructor(socialEngagementUserId: string, channel: string) {
        super(
            socialEngagementErrors.profileUrlMissing.code,
            socialEngagementErrors.profileUrlMissing.message(
                socialEngagementUserId,
                channel,
            ),
        );
    }
}

export class SocialEngagementChannelNotFoundError extends BaseStatusError {
    constructor(channel: string) {
        super(
            socialEngagementErrors.channelNotFound.code,
            socialEngagementErrors.channelNotFound.message(channel),
        );
    }
}
