import {
    TableCellProps as TableCellPropsChakra,
    Td as TdChakra,
} from '@chakra-ui/react';
import React from 'react';
import { InternalBaseProps } from '../../../shared/theme/interfaces/Base.types';
import {
    SizeProps,
    PaddingProps,
    MarginProps,
} from '../../../shared/theme/types';

export interface TableCellProps extends SizeProps, PaddingProps, MarginProps {
    children?: TableCellPropsChakra['children'];
    isNumeric?: TableCellPropsChakra['isNumeric'];
    textAlign?: InternalBaseProps['textAlign'];
    colSpan?: TableCellPropsChakra['colSpan'];
}

export const TableCellImpl = (props: TableCellPropsChakra) => (
    <TdChakra {...props} />
);

const TableCell = (props: TableCellProps) => <TableCellImpl {...props} />;

export default TableCell;
