import {
    ModalProps as ModalPropsChakra,
    ResponsiveValue,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { DataAttrsType } from '../../../shared/theme/interfaces/Base.types';
import { DesignTokens, SizeProps } from '../../../shared/theme/types';
import { useBreakpointValue, useDataProps } from '../../../shared/hooks';
import { genDataTestId } from '../../../shared/utils/helpers';
import { StackImpl, StackProps } from '../../Layout/Stack';
import { CardImpl } from '../../Surfaces/Card';
import { DialogBody } from './components/DialogBody';
import { DialogFooter } from './components/DialogFooter';
import { DialogHeader } from './components/DialogHeader';
import { DialogModal } from './components/DialogModal';
import { ContextProvider } from './DialogContext';

type Position = 'top' | 'centered' | 'bottom';

const mediumSize = { base: '100%', sm: '28.125rem' };

const sizes = {
    md: mediumSize,
    lg: { ...mediumSize, md: '35.3125rem' },
};

export interface DialogProps extends SizeProps {
    children: React.ReactNode;
    isOpen: boolean;
    backgroundColor?: DesignTokens['colors'];
    showCloseButton?: boolean;
    closeOnOverlayClick?: boolean;
    blockScrollOnMount?: boolean;
    scrollBehavior?: 'inside' | 'outside';
    position?: ResponsiveValue<Position>;
    size?: keyof typeof sizes;
    onClose?: () => void;
    className?: string;
    contentContainerProps?: Pick<StackProps, 'align' | 'justify'>;
    [dataAttributeKey: DataAttrsType]: unknown;
}

export interface DialogImplProps
    extends DialogProps,
        Omit<ModalPropsChakra, 'onClose' | 'children' | 'size'> {}

export const DialogImpl = (props: DialogImplProps) => {
    const {
        children,
        size = 'md',
        backgroundColor = 'neutral.white',
        showCloseButton = true,
        closeOnOverlayClick = true,
        position: positionProp = 'centered',
        isOpen = false,
        blockScrollOnMount = true,
        scrollBehavior = 'outside',
        onClose,
        width,
        minWidth = sizes[size],
        maxWidth,
        height,
        minHeight,
        maxHeight,
        contentContainerProps,
        ...rest
    } = props;

    const handleClose = useCallback(() => {
        onClose && onClose();
    }, [onClose]);

    const position = useBreakpointValue(positionProp) as Position;

    const isBottomPosition = position === 'bottom';

    const sizeProps = {
        width,
        minWidth,
        maxWidth,
        height,
        minHeight,
        maxHeight,
    };

    const bottomDialogStyles = {
        ...(isBottomPosition && {
            borderBottomRadius: '0',
            minW: '0',
            w: '100vw',
        }),
    };

    return (
        <DialogModal
            isOpen={isOpen}
            closeOnOverlayClick={closeOnOverlayClick}
            blockScrollOnMount={blockScrollOnMount}
            scrollBehavior={scrollBehavior}
            onClose={handleClose}
            position={position}
            {...rest}
        >
            <CardImpl
                data-testid={genDataTestId('dialog')}
                position='relative'
                bg={backgroundColor}
                py={{ md: 6 }}
                shadow={5}
                rounded='xl'
                {...sizeProps}
                {...bottomDialogStyles}
            >
                <ContextProvider value={{ showCloseButton, onClose, position }}>
                    <StackImpl
                        direction='column'
                        spacing={5}
                        {...contentContainerProps}
                    >
                        {children}
                    </StackImpl>
                </ContextProvider>
            </CardImpl>
        </DialogModal>
    );
};

const Dialog = (props: DialogProps) => {
    const dataProps = useDataProps(props);
    return (
        <DialogImpl
            isOpen={props.isOpen}
            children={props.children}
            size={props.size}
            width={props.width}
            minWidth={props.minWidth}
            maxWidth={props.maxWidth}
            height={props.height}
            minHeight={props.minHeight}
            maxHeight={props.maxHeight}
            backgroundColor={props.backgroundColor}
            showCloseButton={props.showCloseButton}
            closeOnOverlayClick={props.closeOnOverlayClick}
            blockScrollOnMount={props.blockScrollOnMount}
            scrollBehavior={props.scrollBehavior}
            position={props.position}
            onClose={props.onClose}
            className={props.className}
            contentContainerProps={props.contentContainerProps}
            {...dataProps}
        />
    );
};

export default Object.assign(Dialog, {
    Header: DialogHeader,
    Body: DialogBody,
    Footer: DialogFooter,
});
