import { isString } from 'lodash';
import React, { useContext } from 'react';
import { SizeProps } from '../../../../shared/theme/types';
import { BaseBoxImpl } from '../../../Layout/BaseBox';
import { LayoutProps } from '../../../Layout/types';
import Text from '../../../Typography/Text';
import { DialogContext } from '../DialogContext';

export interface DialogBodyProps extends SizeProps {
    children: React.ReactNode;
    overflow?: LayoutProps['overflow'];
}

export const DialogBody = (props: DialogBodyProps) => {
    const {
        children,
        width,
        minWidth,
        maxWidth,
        height,
        minHeight,
        maxHeight,
        overflow,
    } = props;

    const { position = 'centered' } = useContext(DialogContext);

    const childrenArray = React.Children.toArray(children);
    const isTextChild =
        childrenArray.length === 1 && isString(childrenArray[0]);

    const bottomDialogBodyStyles =
        position === 'bottom'
            ? {
                  maxH: '25vh',
                  overflow: 'auto',
              }
            : {};

    return (
        <BaseBoxImpl
            w={width}
            minW={minWidth}
            maxW={maxWidth}
            h={height}
            minH={minHeight}
            maxH={maxHeight}
            overflow={overflow}
            {...bottomDialogBodyStyles}
        >
            {isTextChild ? <Text>{children}</Text> : children}
        </BaseBoxImpl>
    );
};
