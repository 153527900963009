import React from 'react';
import MainInnerContent from './MainInnerContent';
import { MainContentProps } from './MainContent.types';
import { MainContentImpl } from './MainContentImpl';

const MainContent = ({ columnGap = 6, ...rest }: MainContentProps) => (
    <MainContentImpl
        testId='content'
        defaultPlacement='left'
        columnGap={6}
        {...rest}
        minHeight={{
            lg: '100vh',
            base: 'inherit',
        }}
        paddingX={{
            base: 4,
            md: 6,
        }}
    />
);

export default Object.assign(MainContent, {
    Inner: MainInnerContent,
});
