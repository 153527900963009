export interface StatusCodeError {
    name: string;
    message: string;
    stack?: string;
    code?: number;
}

export class BaseStatusError extends Error implements StatusCodeError {
    constructor(public readonly code: number = 400, message: string) {
        super(message);
    }
}
