import { constants, models } from '@trova-trip/trova-models';
import { generateShortId } from './idGenerator.utils';
import { differenceInDays } from 'date-fns';

type Trip = models.trips.BaseTrip;
type AddOnsSelected = models.checkout.AddOnsSelected;
type BookingAddOn = models.bookings.AddOn;
type BookingCheckout = models.checkout.BookingCheckout;
type Service = models.services.Service;
type PaymentMethod = constants.checkout.PaymentMethod;

export const getOptionalActivitiesForTripFromDTO = (
    trip: Trip,
    addOnsSelected: AddOnsSelected[],
): {
    optionalActivities: models.services.Service[];
    optionalServices: models.services.Service[];
} => {
    const addOnsServiceIds = addOnsSelected.map((addOn) => addOn.serviceId);

    const filterServicesById = (service: models.services.Service) =>
        service._id ? addOnsServiceIds.includes(service._id.toString()) : false;

    const optionalActivities =
        trip.servicesByDay
            ?.flatMap((day) => day.map((dayService) => dayService.service))
            .filter((service): service is models.services.Service =>
                filterServicesById(service as models.services.Service),
            ) || [];

    const optionalServices =
        trip.additionalOptionalServices?.filter(
            (service): service is models.services.Service =>
                filterServicesById(service as models.services.Service),
        ) || [];

    return {
        optionalActivities,
        optionalServices,
    };
};

export const isSingleSupplementRequested = (
    addOnsSelected: AddOnsSelected[],
): boolean => {
    return addOnsSelected.some(
        (addOn) =>
            addOn.name === constants.bookings.SINGLE_SUPPLEMENT_EXTRA_NAME,
    );
};

/**
 * @deprecated
 * Returns a list of selected addOns for each traveler.
 * It replicates the same addOns selected for every traveler in the booking.
 */
export const generateBookingAddOnsFromDTO = (
    bookingCheckout: BookingCheckout,
): BookingAddOn[] => {
    const { addOnsSelected, customer, additionalParticipants } =
        bookingCheckout;

    const allParticipants = [
        customer,
        ...(additionalParticipants ? additionalParticipants : []),
    ];

    return allParticipants.flatMap((user) =>
        addOnsSelected.map(
            (addOn) =>
                <BookingAddOn>{
                    addOnId: generateShortId(),
                    type: addOn.type,
                    quantity: addOn.quantity,
                    service: addOn.serviceId,
                    user: user._id,
                    unitPriceWithFee: addOn.price,
                },
        ),
    );
};

/**
 * Verifies if any of the travelers email is repeated
 * @param emails
 */
export const areTravelersUsingTheSameEmail = (emails: string[]): Boolean => {
    const lowerCaseEmails = emails.map((email) => email.toLowerCase());
    const emailsSet = new Set(lowerCaseEmails);
    return emails.length !== emailsSet.size;
};

/**
 * Returns a boolean indicating if it the minimum due payment is available for a trip, for the selected payment method.
 * @param trip
 * @param paymentMethod - selected payment method
 * @param isLegacyTrip
 */
export const isMinimumDuePaymentAvailable = (
    startDate: Date,
    paymentMethod: PaymentMethod,
    isLegacyTrip?: boolean,
): boolean => {
    const paymentDeadline = isLegacyTrip
        ? constants.checkout.MIN_DUE_PAYMENT_DEADLINE_REZDY
        : constants.checkout.MIN_DUE_PAYMENT_DEADLINE;

    if (startDate) {
        const daysTillTrip = differenceInDays(startDate, new Date());
        return (
            daysTillTrip > paymentDeadline &&
            paymentMethod === constants.checkout.PaymentMethod.PAY_DOWN
        );
    }
    return false;
};
