import { models } from '@trova-trip/trova-models';
import {
    CostConfiguration,
    CostPerThreshold,
    OptionalCostThresholds,
    WorkshopCostThresholds,
    CalculatedCosts,
} from '../PricingCalculator.types';
import { fees } from '../Utils/fees.utils';
import {
    getCostPerThreshold,
    getStandardCosts,
    getTotalCosts,
    getHostCostsWithFixedCostsAndPrices,
    getTierPriceForInventoryItems,
} from '../Utils/pricing.utils';
import {
    getThresholdByTier,
    convertCostPerThresholdToCostThreshold,
} from '../Utils/common.utils';

export class BaseTripInventoryPricing {
    protected config: CostConfiguration;
    protected calculatedCosts: CalculatedCosts;

    constructor(config: CostConfiguration) {
        this.config = config;
        this.calculatedCosts = this.initPricing();
    }

    private initPricing(): CalculatedCosts {
        const {
            costSchedule,
            tripLength,
            focQuantity,
            singleSupplementPrice,
            roomsQuantity,
            hostGroundTransferCost,
            prices,
            minimumSpots,
        } = this.config;

        const costsByDay = getStandardCosts({
            costSchedule,
            priceDivisor: tripLength,
        });
        const currentCosts = getStandardCosts({
            costSchedule,
            priceDivisor: 1,
        });
        const focCosts = getStandardCosts({
            costSchedule,
            priceMultiplier: focQuantity,
        });
        const optionalCosts = this.buildHostSelectedOptionalsCost();
        const workshopsCosts = this.buildWorkshopsCost();
        const singleSupplementCosts = getStandardCosts({
            costSchedule,
            customPrice: singleSupplementPrice,
        });
        const singleSupplementCostAdjustments = getStandardCosts({
            costSchedule,
            customPrice: singleSupplementPrice * roomsQuantity,
        });
        const hostGroundTransferCosts = getStandardCosts({
            costSchedule,
            customPrice: hostGroundTransferCost,
        });
        const platformFeeCosts = this.buildPlatformFeeCost();
        const servicesTotalCosts = getTotalCosts({
            costSchedule,
            totals: [
                workshopsCosts,
                optionalCosts,
                focCosts,
                singleSupplementCostAdjustments,
            ],
        });
        const operatorTotalCosts = getTotalCosts({
            costSchedule,
            totals: [currentCosts, servicesTotalCosts],
        });
        const hostTotalCosts = getTotalCosts({
            costSchedule,
            totals: [
                operatorTotalCosts,
                singleSupplementCosts,
                platformFeeCosts,
                hostGroundTransferCosts,
            ],
        });
        const hostCostsWithFixedCostsAndPrices =
            getHostCostsWithFixedCostsAndPrices(
                hostTotalCosts,
                prices,
                minimumSpots,
            );

        return {
            costsByDay,
            currentCosts,
            focCosts,
            optionalCosts,
            workshopsCosts,
            servicesTotalCosts,
            singleSupplementCostAdjustments,
            operatorTotalCosts,
            singleSupplementCosts,
            platformFeeCosts,
            hostGroundTransferCosts,
            hostTotalCosts,
            hostCostsWithFixedCostsAndPrices,
        };
    }

    private buildHostSelectedOptionalsCost(): OptionalCostThresholds[] {
        const { hostSelectedOptionalServices, costSchedule } = this.config;

        if (!hostSelectedOptionalServices) {
            return [];
        }

        return hostSelectedOptionalServices.map(
            ({ activity: { name = '', price = 0 }, numberOptingIn = 0 }) => {
                const costPerThreshold = costSchedule.map((costPerThreshold) =>
                    getCostPerThreshold({
                        costPerThreshold,
                        customPrice: price * numberOptingIn,
                    }),
                );

                return {
                    name,
                    price,
                    quantity: numberOptingIn,
                    costPerThreshold,
                };
            },
        );
    }

    private buildWorkshopsCost(): WorkshopCostThresholds[] {
        const { workshops, costSchedule } = this.config;

        return workshops.map(({ service }) => {
            const {
                name = '',
                hoursRequested,
                hoursAvailable,
                pricePerHour = 0,
            } = service as models.services.WorkshopSpace;
            const workshopHoursRequested =
                hoursRequested ?? hoursAvailable ?? 0;

            const costPerThreshold = costSchedule.map((costPerThreshold) =>
                getCostPerThreshold({
                    costPerThreshold,
                    customPrice: workshopHoursRequested * pricePerHour,
                }),
            );

            return {
                name,
                hours: workshopHoursRequested,
                costPerThreshold,
            };
        });
    }

    private buildPlatformFeeCost(): CostPerThreshold[] {
        const { costSchedule } = this.config;

        return costSchedule.map((costPerThreshold) =>
            getCostPerThreshold({
                costPerThreshold,
                customPrice: costPerThreshold.platformFee ?? fees.platformFee,
                priceDivisor: 1,
            }),
        );
    }

    protected addCostPerThreshold(tier: number): void {
        const { costSchedule } = this.config;
        const hasTier = getThresholdByTier(costSchedule, tier);

        if (hasTier) {
            return;
        }

        const constThresholds = convertCostPerThresholdToCostThreshold(costSchedule);
        const newTier = getTierPriceForInventoryItems(constThresholds, tier);
        const newCostSchedule = [...costSchedule, newTier].sort(
            (a, b) => a.numberTravelers - b.numberTravelers,
        );
        this.config.costSchedule = newCostSchedule;
        this.calculatedCosts = this.initPricing();
    }

    protected removeCostPerThreshold(tier: number): void {
        const { costSchedule } = this.config;
        const hasTier = getThresholdByTier(costSchedule, tier);

        if (!hasTier) {
            return;
        }

        const newCostSchedule = costSchedule.filter(
            (costPerThreshold) => costPerThreshold.numberTravelers !== tier,
        );
        this.config.costSchedule = newCostSchedule;
        this.calculatedCosts = this.initPricing();
    }
}
