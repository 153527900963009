import { constants, models } from '@trova-trip/trova-models';
import { compareAsc } from 'date-fns';
import type { Environment } from '../app.constants';
import { TrovaTripAppsDomainMap } from '../app.constants';
import { asISOZeroTimeString } from './date.utils';

type Availability = models.itineraries.Availability;
type ItineraryPackageLevels = models.itineraries.ItineraryPackageLevels;
type PackageLevel = constants.itinerary.PackageLevel;
type ItineraryInventoryItem =
    models.itineraryInventoryItems.ItineraryInventoryItem;

type ItineraryTierInfo = {
    name: string;
    number: constants.itinerary.TIER;
    isLocked: boolean;
};

export const isItineraryTierLockedForUser = (
    itineraryTier: number,
    userTier: number = 0,
): boolean => {
    if (userTier <= 0) {
        return true;
    }

    return userTier < itineraryTier;
};

// @deprecated as soon as we start using host to determine elibility
export const isItineraryAvailableForAllHosts = (
    itinerary: models.itineraries.Itinerary,
): boolean => {
    return !!(
        itinerary.itineraryInventoryEnabled &&
        itinerary.tier === constants.itinerary.TIER.Tier1
    );
};

export const getItineraryTierInfoForUser = (
    itinerary: models.itineraries.Itinerary,
    user: models.users.User,
): ItineraryTierInfo => {
    if (!itinerary.tier) {
        throw new Error(
            `Itinerary tier is needed to get the itinerary tier info for itinerary ${
                itinerary.id || itinerary._id
            }`,
        );
    }

    const isHostQualified = constants.user.HOST_QUALIFIED_STATUSES.includes(
        user.status,
    );
    const tier = itinerary.tier as constants.itinerary.TIER;
    const tierName = constants.itinerary.TierNames[tier];

    const isLocked = isItineraryAvailableForAllHosts(itinerary)
        ? false
        : !isHostQualified ||
          isItineraryTierLockedForUser(itinerary.tier, user.itineraryTier);

    return {
        name: tierName,
        number: itinerary.tier,
        isLocked,
    };
};

export const isItineraryAvailableForDate = (
    availability: Availability,
    startDate: Date | string,
): boolean => {
    const formattedStartDate = asISOZeroTimeString(startDate);

    const isBetweenAvailableRange =
        formattedStartDate >=
            asISOZeroTimeString(availability.firstAvailableDate) &&
        formattedStartDate <=
            asISOZeroTimeString(availability.lastAvailableDate);

    const isInBlackoutDates = availability.blackoutDates.some(
        (blackoutDate) =>
            asISOZeroTimeString(blackoutDate) === formattedStartDate,
    );

    return isBetweenAvailableRange && !isInBlackoutDates;
};

export const getItineraryPackagesFirstAvailableDate = (
    itinerary: models.itineraries.Itinerary,
): Date | undefined => {
    const { packages } = itinerary;
    const sortedPackagesValidityPeriodsByStartDate = Object.values(packages)
        .flatMap(
            (itineraryPackage: models.itineraries.ItineraryPackage) =>
                itineraryPackage.validityPeriods as models.itineraries.ValidityPeriod[],
        )
        .filter((validityPeriod) => validityPeriod !== undefined)
        .sort((validityPeriodA, validityPeriodB) =>
            compareAsc(validityPeriodA.startDate, validityPeriodB.startDate),
        );

    return sortedPackagesValidityPeriodsByStartDate.length > 0
        ? sortedPackagesValidityPeriodsByStartDate[0].startDate
        : undefined;
};

export const getInitialPackage = (
    packages: models.itineraries.ItineraryPackageLevels,
): constants.itinerary.PackageLevel => {
    return (
        (packages.standard.enabled &&
            constants.itinerary.PackageLevel.STANDARD) ||
        (packages.economy.enabled &&
            constants.itinerary.PackageLevel.ECONOMY) ||
        (packages.premium.enabled &&
            constants.itinerary.PackageLevel.PREMIUM) ||
        constants.itinerary.PackageLevel.STANDARD
    );
};

type GetPublicItineraryPageUrlParams = {
    type: 'public';
    urlPath: string;
};

type GetPortalItineraryPageUrlParams = {
    type: 'portal';
    id: string;
};

type GetItineraryPageUrlParams = (
    | GetPublicItineraryPageUrlParams
    | GetPortalItineraryPageUrlParams
) & {
    /**
     * Includes the domain on the URL. By default it's `true`.
     */
    includeDomain?: boolean;
};

/**
 * Returns the itinerary page URL of the portal or the public page.
 *
 * @param {GetItineraryPageUrlParams} params
 * @returns string
 *
 * @example - Public URL with domain:
 * https://staging-trovatrip.com/itineraries/south-america/ecuador/Galapagos-Darwin_Islands_by_Land_and_Sea
 *
 * @example - Portal URL without domain:
 * /app/host/itineraries/5ebd79efa71c6600368e26ca/description
 */
export const getItineraryPageUrl = (
    params: GetItineraryPageUrlParams,
): string => {
    const { type, includeDomain = true } = params;

    const env = process.env.ENVIRONMENT as Environment;
    const domainMap = TrovaTripAppsDomainMap[env];

    const domain = includeDomain ? domainMap[type] : '';

    if (type === 'portal') {
        const { id } = params;
        return `${domain}/app/host/itineraries/${id}/description`;
    }

    const { urlPath } = params;
    return `${domain}/itineraries/${urlPath}`;
};

/**
 * Gets the enabled packages from the itineraries packages
 * @param {ItineraryPackageLevels} packages itineraries packages
 * @returns {PackageLevel[]} enabled packages
 */
export const getEnabledPackages = (
    packages: ItineraryPackageLevels,
): PackageLevel[] => {
    const packageKeys = Object.keys(packages) as PackageLevel[];
    return packageKeys.filter(
        (packageValue) => packages[packageValue]?.enabled,
    );
};

/**
 * Checks if the itinerary inventory item is available.
 * It is available if the quantity available is greater than the sum of the quantity pending and quantity confirmed, and it is not deleted.
 * @param itineraryInventoryItem
 * @returns boolean
 */
export const isItineraryInventoryItemAvailable = (
    itineraryInventoryItem: ItineraryInventoryItem,
): boolean => {
    const { quantityAvailable, quantityPending, quantityConfirmed, deleted } =
        itineraryInventoryItem;
    return quantityAvailable > quantityPending + quantityConfirmed && !deleted;
};
