import React from 'react';

import { RadioProps as RadioPropsChakra, useRadio } from '@chakra-ui/react';

import { InternalBaseProps } from '../../../../shared/theme/interfaces/Base.types';
import { BaseBoxImpl } from '../../../Layout/BaseBox';

export interface InlineSelectOptionProps {
    children?: RadioPropsChakra['children'];
    isDisabled?: RadioPropsChakra['isDisabled'];
    value?: RadioPropsChakra['value'];
}

export interface InlineSelectOptionImplProps
    extends InternalBaseProps,
        InlineSelectOptionProps {}

export const InlineSelectOptionImpl = ({
    children = null,
    ...props
}: InlineSelectOptionImplProps) => {
    const {
        getInputProps,
        getCheckboxProps,
        state: { isChecked, isDisabled },
    } = useRadio({ ...props });

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
        <BaseBoxImpl as='label'>
            <input {...input} />
            <BaseBoxImpl
                cursor='pointer'
                p={2}
                color='blueGray.650'
                disabled={{ color: 'blueGray.300', cursor: 'default' }}
                hover={
                    isDisabled ? {} : { bg: isChecked ? 'blue.100' : 'blue.20' }
                }
                checked={isDisabled ? {} : { bg: 'blueGray.100' }}
                {...checkbox}
            >
                {children}
            </BaseBoxImpl>
        </BaseBoxImpl>
    );
};

const InlineSelectOption = (props: InlineSelectOptionProps) => (
    <InlineSelectOptionImpl {...props} />
);

export default InlineSelectOption;
