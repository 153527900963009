import { models, constants } from '@trova-trip/trova-models';
import { add, sub } from 'date-fns';
import { dropTime } from './date.utils';

/**
 * @name calculateScheduleDate
 * @description
 * Calculate the scheduled date property for a scheduled hook.
 *
 * @param {models.hooks.TimingConfig} timing - the offset and specific time to schedule the hook
 * @param {any} modelData - the model data for the hook. Used for reference dates.
 */
export const calculateScheduleDate = (
    timing?: models.hooks.TimingConfig,
    modelData?: any,
): Date => {
    if (!timing) {
        return new Date();
    }

    const {
        days,
        hours,
        minutes,
        relativeOperator,
        dateField,
        isScheduledAtSpecificTime,
        timeOfDayHours,
        timeOfDayMinutes,
        timeZone,
    } = timing;

    const refDate: Date = dateField
        ? new Date(modelData[dateField])
        : new Date();

    if (Number.isNaN(refDate.getTime())) {
        throw new Error(
            `The value for the key '${dateField}' is an invalid date.`,
        );
    }

    const operators = {
        [constants.hooks.RelativeOperator.After]: add,
        [constants.hooks.RelativeOperator.Before]: sub,
    };

    const operator = operators[relativeOperator];
    const adjustedDate = operator(refDate, { days, hours, minutes });

    const scheduledDate = isScheduledAtSpecificTime
        ? add(dropTime(adjustedDate, timeZone), {
              hours: timeOfDayHours,
              minutes: timeOfDayMinutes,
          })
        : adjustedDate;

    return scheduledDate;
};

/**
 * @name calculateRepeatEndDate
 * @description
 * Calculate the scheduled end date property for a scheduled repeating hook.
 * Will be calculated relative to either a model field or an already-calculated
 * start date (date of the first scheduled hook in a sequence)
 *
 * @param {models.hooks.TimingConfig} timing - the offset and specific time to schedule the hook
 * @param {Date} scheduledDate - the date of the first scheduled email in this batch
 * @param {any} modelData - the model data for the hook. Used for reference dates.
 */
export const calculateRepeatEndDate = (
    timing: models.hooks.TimingConfig,
    scheduledDate: Date,
    modelData?: Record<string, unknown>,
): Date => {
    const {
        repeatUntilDays,
        repeatUntilHours,
        repeatUntilMinutes,
        repeatUntilRelativeOperator = constants.hooks.RelativeOperator.After,
        repeatUntilDateField,
    } = timing;

    const refDate: Date =
        repeatUntilDateField && modelData
            ? (modelData[repeatUntilDateField] as Date)
            : scheduledDate;

    const operators = {
        [constants.hooks.RelativeOperator.After]: add,
        [constants.hooks.RelativeOperator.Before]: sub,
    };

    const operator = operators[repeatUntilRelativeOperator];
    return operator(refDate, {
        days: repeatUntilDays,
        hours: repeatUntilHours,
        minutes: repeatUntilMinutes,
    });
};

/**
 * @name getRepeatedDates
 * @description
 * Given a date (the first scheduled date for a set of repeating email hooks)
 * an interval, and an end date (last possible date to schedule a hook), creates
 * an array of dates in this range to schedule email hooks for.
 *
 * @param {Date} scheduleDate - the date of the first scheduled email in this batch
 * @param {Date} endDate - the latest date to schedule an email for
 * @param {number} interval - the number of days to increment by
 */
export const getRepeatedDates = (
    scheduledDate: Date,
    endDate: Date,
    interval: number,
): Date[] => {
    const dates: Date[] = [];
    let startDate = scheduledDate;

    while (startDate < endDate) {
        dates.push(new Date(startDate));

        // Increment scheduled date by repeat frequency (days)
        startDate.setDate(startDate.getDate() + interval);
    }

    return dates;
};

/**
 * @name isRepeatDateFieldValid
 * @description
 * Checks whether a hook's timing config has a valid repeatUntilDateField.
 * Since repeatUntilDateField is optional, return true if it isn't included.
 * Otherwise, need to check that the field (ex. startDate) exists on the modelData
 *
 * @param {any} modelData - the model data for the hook.
 * @param {models.hooks.TimingConfig} timing - the offset and specific time to schedule the hook
 */
export const isRepeatDateFieldValid = (
    modelData: any,
    timing: models.hooks.TimingConfig,
): boolean => {
    const { repeatUntilDateField } = timing;
    return !(repeatUntilDateField && !modelData[repeatUntilDateField]);
};
