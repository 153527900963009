import { models, constants } from '@trova-trip/trova-models';
import isBefore from 'date-fns/isBefore';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';

type Journey = models.groundTransfers.Journey;
type JourneyStatus = models.groundTransfers.Journey['status'];
type AddOn = models.bookings.AddOn;
type GroundTransferAddOn = models.groundTransfers.GroundTransferAddOn;
type GroundTransferBookingStatus =
    constants.groundTransfers.GroundTransferBookingStatus;

const { AddOnTypes } = constants.bookings;
const { JourneyStatus, GroundTransferBookingStatus } =
    constants.groundTransfers;

/**
 * Checks if a journey is free to cancel. A journey is free to cancel if
 * the `cancellable` flag is `true` and the `freeCancellationUntil` date is in the future.
 *
 * @param journey
 *
 * @return `true` if the journey is free to cancel, `false` otherwise
 */
export const isJourneyCancellationFree = (
    journey: Required<Pick<Journey, 'cancellationDetails'>>,
): boolean => {
    const currentDate = new Date();
    const {
        cancellationDetails: { cancellable, freeCancellationUntil },
    } = journey;

    /**
     * `freeCancellationUntil` is formatted as `yyyy-MM-dd'T'HH:mm:ss`, which is expected to be in UTC, but missing
     * the time zone portion, so parsing it with `new Date` converts it to the local browser time. We need to force
     * it to UTC in order to compare it with the current date. To do that, we are using the `zonedTimeToUtc` function,
     * which takes the date string and the time zone (UTC in this case) as inputs and returns the correct date object
     * in UTC.
     */
    const cancellationUntilUtcDate = zonedTimeToUtc(
        freeCancellationUntil,
        'UTC',
    );

    return cancellable && isBefore(currentDate, cancellationUntilUtcDate);
};

/**
 * type guard function to check if add-on is a ground transfer add-on
 * @param addOn add-on to check
 * @returns `true` if add-on is a ground transfer add-on, `false` otherwise
 */
export const isGroundTransferAddOn = (
    addOn: AddOn | GroundTransferAddOn,
): addOn is GroundTransferAddOn =>
    addOn.type === AddOnTypes.GROUND_TRANSFER && 'groundTransfer' in addOn;

/**
 * Maps a journey status provided by TRANFERZ to our own ground transfer booking status
 * @param status - status provided by TRANSFERZ
 * @returns our own ground transfer booking status
 */
export const getTransferBookingStatusFromJourneyStatus = (
    status: JourneyStatus,
): GroundTransferBookingStatus => {
    const pendingStatusList = [
        JourneyStatus.NOT_PAID,
        JourneyStatus.AWAITING_APPROVAL,
        JourneyStatus.PENDING,
    ];

    const cancelledStatusList = [
        JourneyStatus.CANCELLED_FREE,
        JourneyStatus.CANCELLED_WITH_COSTS,
    ];

    const driverAssignedStatusList = [
        JourneyStatus.PLANNED,
        JourneyStatus.DRIVER_UNDERWAY,
        JourneyStatus.DRIVER_ARRIVED,
        JourneyStatus.JOURNEY_IN_PROGRESS,
    ];

    const journeyStatusToGroundTransferStatusMap = {
        [JourneyStatus.CONFIRMED]: GroundTransferBookingStatus.CONFIRMED,
        [JourneyStatus.COMPLETED]: GroundTransferBookingStatus.COMPLETED,
    };

    if (pendingStatusList.includes(status)) {
        return GroundTransferBookingStatus.PENDING;
    }

    if (cancelledStatusList.includes(status)) {
        return GroundTransferBookingStatus.CANCELLED;
    }

    if (driverAssignedStatusList.includes(status)) {
        return GroundTransferBookingStatus.DRIVER_ASSIGNED;
    }

    return (
        journeyStatusToGroundTransferStatusMap[
            status as keyof typeof journeyStatusToGroundTransferStatusMap
        ] || GroundTransferBookingStatus.PROCESSING
    );
};
