import { LogLevel } from './LogLevel';
import { NodeCentralizedLogger } from './loggers/NodeCentralizedLogger';
import { NodeConsoleLogger } from './loggers/NodeConsoleLogger';
import { NodeDevLogger } from './loggers/NodeDevLogger';
import { BrowserCentralizedLogger } from './loggers/BrowserCentralizedLogger';
import { BrowserConsoleLogger } from './loggers/BrowserConsoleLogger';
import { BaseLogger } from './loggers/BaseLogger';
import { LoggerConfig } from './Logger.types';
import * as loggerUtils from './Logger.utils';
import { Environment } from '../app.constants';
import { isNode } from './Logger.utils';

const DEFAULT_APP = isNode() ? 'app[api]' : 'app[web]';
const DEFAULT_HOSTNAME = `trova-${Environment.Development}`;
const DEFAULT_ENV = Environment.Development;
const DEFAULT_LEVEL = LogLevel.INFO;
const LOGGER_NAME = 'LoggerProvider';

enum LoggerType {
    NODE_CENTRALIZED = 'Node Centralized',
    NODE_CONSOLE = 'Node Console',
    NODE_DEVELOPMENT = 'Node Development',
    BROWSER_CENTRALIZED = 'Browser Centralized',
    BROWSER_CONSOLE = 'Browser Console',
}

/**
 * Internal logger provider used to access configured internal logger. This should be not used outside of this package.
 */
export class InternalLoggerProvider {
    private configured = false;
    private config: LoggerConfig | undefined;
    private logger: BaseLogger | undefined;

    private static instance: InternalLoggerProvider;

    static getInstance(): InternalLoggerProvider {
        if (!this.instance) {
            this.instance = new InternalLoggerProvider();
        }
        return this.instance;
    }

    configure(config: LoggerConfig): void {
        if (this.configured) {
            throw new Error('Logger already configured!');
        }

        this.configured = true;
        this.config = this.createConfig(config);
        const loggerType = this.determineLoggerType(this.config);
        this.logger = this.createLogger(loggerType, this.config);
        this.logger
            .child({ name: LOGGER_NAME })
            .debug(
                `🪵 Logger configured successfully, using ${loggerType} logger 🪵`,
            );
    }

    getConfig(): LoggerConfig {
        if (!this.config) {
            this.initFallbackLogger();
        }
        return this.config as LoggerConfig;
    }

    getLogger(): BaseLogger {
        if (!this.logger) {
            this.initFallbackLogger();
        }
        return this.logger as BaseLogger;
    }

    private createConfig(config?: LoggerConfig): LoggerConfig {
        return {
            app: config?.app || DEFAULT_APP,
            hostname: config?.hostname || DEFAULT_HOSTNAME,
            env: config?.env || DEFAULT_ENV,
            level:
                config?.level &&
                Object.values(LogLevel).includes(
                    config.level.toLowerCase() as LogLevel,
                )
                    ? config.level.toLowerCase()
                    : DEFAULT_LEVEL,
            ingestionKey: config?.ingestionKey,
        };
    }

    private determineLoggerType(config: LoggerConfig): LoggerType {
        if (loggerUtils.isNode()) {
            if (loggerUtils.isProductionBuild()) {
                if (config.ingestionKey) {
                    return LoggerType.NODE_CENTRALIZED;
                }
                return LoggerType.NODE_CONSOLE;
            }
            return LoggerType.NODE_DEVELOPMENT;
        }

        if (loggerUtils.isProductionBuild() && config.ingestionKey) {
            return LoggerType.BROWSER_CENTRALIZED;
        }

        return LoggerType.BROWSER_CONSOLE;
    }

    private createLogger(type: LoggerType, config: LoggerConfig): BaseLogger {
        switch (type) {
            case LoggerType.NODE_CENTRALIZED:
                return new NodeCentralizedLogger(config);
            case LoggerType.NODE_CONSOLE:
                return new NodeConsoleLogger(config);
            case LoggerType.NODE_DEVELOPMENT:
                return new NodeDevLogger(config);
            case LoggerType.BROWSER_CENTRALIZED:
                return new BrowserCentralizedLogger(config);
            case LoggerType.BROWSER_CONSOLE:
                return new BrowserConsoleLogger(config);
        }
    }

    private initFallbackLogger() {
        this.config = this.createConfig();
        const loggerType = this.determineLoggerType(this.config);
        this.logger = this.createLogger(loggerType, this.config);
        this.logger
            .child({ name: LOGGER_NAME })
            .warn(
                `⚠️ Logger not configured, using ${loggerType} logger as fallback 🦄`,
            );
    }
}

/**
 * The logger provider used to configure all loggers. {@link LoggerProvider.configure} must be called before any
 * loggers are created, otherwise a fallback configuration will be used.
 */
export class LoggerProvider {
    static configure(config: LoggerConfig): void {
        return InternalLoggerProvider.getInstance().configure(config);
    }
}
