import { BaseLogger, LoggerChildOptions } from './BaseLogger';
import { LoggerConfig } from '../Logger.types';
import { Logger as PinoLogger, LoggerOptions as PinoLoggerOptions } from 'pino';
import { LogLevel } from '../LogLevel';
import { BrowserConsoleLogger } from './BrowserConsoleLogger';
import logdna from '@logdna/browser';

const UNKNOWN_LOGGER_NAME = 'Browser';

/**
 * Logs to browser console and sends to Mezmo
 */
export class BrowserCentralizedLogger extends BrowserConsoleLogger {
    protected createLogger(config: LoggerConfig): PinoLogger {
        if (!config.ingestionKey) {
            throw new Error('No logger ingestion key provided');
        }

        logdna.init(config.ingestionKey, {
            env: config.env,
            app: config.app,
            hostname: config.hostname,
            console: false,
        });

        const transmit: NonNullable<PinoLoggerOptions['browser']>['transmit'] =
            {
                level: config.level,
                send: (level, logEvent) => {
                    const { bindings, messages, ts } = logEvent;
                    const name = bindings.length
                        ? bindings[0].name
                        : UNKNOWN_LOGGER_NAME;

                    let [meta, message] = messages;
                    if (!message) {
                        message = meta;
                        meta = undefined;
                    }

                    const line = `[${name}] ${message}`;
                    const context = {
                        ...meta,
                        time: ts,
                    };

                    logdna.log(line, context, level as LogLevel);
                },
            };

        return this.createBrowserLogger(config, {
            transmit,
            serialize: true,
        });
    }

    child(options: LoggerChildOptions): BaseLogger {
        const childLogger = this.internalLogger.child(
            { name: options.name },
            { msgPrefix: this.getMessagePrefix(options) },
        );
        return new BrowserCentralizedLogger(childLogger);
    }
}
