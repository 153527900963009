import { BaseLogger, LoggerChildOptions } from './BaseLogger';
import { LoggerConfig } from '../Logger.types';
import pino, { Logger as PinoLogger } from 'pino';

const MEZMO_MESSAGE_KEY = 'message';

/**
 * Logs to console, formatted for Mezmo automatic JSON parsing:
 * https://docs.mezmo.com/2.8/docs/log-parsing#json-parsing
 */
export class NodeConsoleLogger extends BaseLogger {
    protected createLogger(config: LoggerConfig): PinoLogger {
        return pino({
            level: config.level,
            messageKey: MEZMO_MESSAGE_KEY,
            formatters: {
                level: (label) => ({ level: label }),
            },
        });
    }

    child(options: LoggerChildOptions): BaseLogger {
        const childLogger = this.internalLogger.child(
            {},
            { msgPrefix: this.getMessagePrefix(options) },
        );
        return new NodeConsoleLogger(childLogger);
    }
}
