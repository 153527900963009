export enum IconName {
    Plus,
    Upload,
    Search,
    DropdownArrow,
    Location,
    Minus,
    Close,
    RightArrow,
    Offsite,
    link,
    Filter,
    Dot,
    Instagram,
    Trash,
    Pencil,
    Copy,
    Download,
    Info,
    Warning,
    Success,
    Error,
    OutlinedCheck,
    HalfCircle,
    OutlinedCircle,
    Lock,
}
