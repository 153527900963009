import {
    TableBodyProps as TableBodyPropsChakra,
    Tbody as TbodyChakra,
} from '@chakra-ui/react';
import React from 'react';

export const TableBodyImpl = (props: TableBodyPropsChakra) => (
    <TbodyChakra {...props} />
);

const TableBody = (props: TableBodyPropsChakra) => <TableBodyImpl {...props} />;

export default TableBody;
