import {
    AccordionItem as AccordionItemChakra,
    AccordionItemProps as AccordionItemPropsChakra,
    As,
} from '@chakra-ui/react';
import React from 'react';
import { genDataTestId } from '../../../../shared/utils/helpers';
import { useOmitForbiddenProps } from '../../../../shared/hooks';
import { InternalBaseProps } from '../../../../shared/theme/interfaces/Base.types';

export interface AccordionItemProps {
    children?: AccordionItemPropsChakra['children'];
    as?: As;
    isDisabled?: boolean;
}

export interface AccordionItemImplProps
    extends AccordionItemProps,
        Omit<InternalBaseProps, 'children'> {}

export const AccordionItemImpl = (props: AccordionItemImplProps) => (
    <AccordionItemChakra
        data-testid={genDataTestId('accordion-item')}
        {...props}
    />
);

const AccordionItem = (props: AccordionItemProps) => {
    const filteredProps = useOmitForbiddenProps(props);
    return <AccordionItemImpl {...filteredProps} />;
};

export default AccordionItem;
