import { orderBy, omitBy, isUndefined } from 'lodash';

const toListItems = <T extends string | number | boolean | null>(
    dataObj: Record<string, T>,
    sorted: boolean,
): Array<{ value: string; label: T }> => {
    return orderBy(
        Object.entries(dataObj).map(([key, value]) => {
            return {
                value: key,
                label: value,
            };
        }),
        sorted ? ['label'] : undefined,
    );
};

const parseTextForSpecialCharacters = (
    text?: string | null,
): string | undefined | null => {
    if (!text) return text;
    const specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    return specialChars.test(text) ? `"${text}"` : text;
};

const parseAsBool = (value: string | undefined) =>
    value?.toString().toLowerCase() === 'false' ||
    value?.toString().toLowerCase() === '0' ||
    value?.toString().toLowerCase() === 'no'
        ? false
        : !!value;

const normalizeUrl = (url: string | undefined): string => {
    if (!url) return '';
    const [protocol, path] = url.split('://');
    const sanitizedPath = path.replace(/\/+/g, '/');
    return `${protocol}://${sanitizedPath}`;
};

const removeUndefinedProps = <T extends object | null | undefined>(obj: T): T => {
    return omitBy(obj, isUndefined) as T;
};

/**
 * Get the correct phrase for an unknown number of items. Ex. "1 item" vs. "2 items"
 * @param count The number of items (typically a variable, not an actual value)
 * @param singularNoun Word for a single item
 * @param pluralNoun Word for a group of items (or no items)
 * @returns Phrase with the count followed by the correct noun
 *
 * @example const roomQuantity = 1
 * pluralize(roomQuantity, 'room', 'rooms')  // returns '1 room'
 */
const pluralize = (
    count: number,
    singularNoun: string,
    pluralNoun: string,
): string => {
    return `${count} ${count === 1 ? singularNoun : pluralNoun}`;
};

export {
    toListItems,
    parseTextForSpecialCharacters,
    parseAsBool,
    normalizeUrl,
    pluralize,
    removeUndefinedProps,
};
