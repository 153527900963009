import { BaseLogger, LoggerChildOptions } from './BaseLogger';
import { LoggerConfig } from '../Logger.types';
import { PrettyOptions } from 'pino-pretty';
import pino, { Logger as PinoLogger } from 'pino';

/**
 * Logs to console, formatted for local development
 */
export class NodeDevLogger extends BaseLogger {
    protected createLogger(config: LoggerConfig): PinoLogger {
        return pino({
            level: config.level,
            transport: {
                target: 'pino-pretty',
                options: {
                    colorize: true,
                    ignore: 'pid,hostname',
                    translateTime: 'SYS:yyyy-mm-dd hh:mm:ss TT',
                } as PrettyOptions,
            },
        });
    }

    child(options: LoggerChildOptions): BaseLogger {
        const childLogger = this.internalLogger.child({ name: options.name });
        return new NodeDevLogger(childLogger);
    }
}
