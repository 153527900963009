import { constants, models } from '@trova-trip/trova-models';
import {
    CostThreshold,
    Currency,
    DayService,
    HostSelectedOptionalServices,
    Itinerary,
    Trip,
    TripAdditionalCosts,
} from '../PricingCalculator.types';
import { getTrovaSingleSupplementFee, fees } from './fees.utils';
import { roundUp } from './common.utils';

type ItineraryPackageLevels = models.itineraries.ItineraryPackageLevels;
type ValidityPeriod = models.itineraries.ValidityPeriod;
type ItineraryPackage = models.itineraries.ItineraryPackage;
type Service = models.services.Service;
type BaseItinerary = models.itineraries.BaseItinerary;
type PackageLevel = constants.itinerary.PackageLevel;
type ItineraryInventoryItem = models.itineraryInventoryItems.ItineraryInventoryItem;
type CostScheduleItem = models.itineraryInventoryItems.CostScheduleItem;
type AccommodationPrice = models.itineraryInventoryItems.AccommodationPrice;

const { ServiceType } = constants.services;

/**
 * @param {number} price price to convert
 * @param {number} [exchangeRate=1] currency exchange rate
 * @returns converted price to USD
 */
export const convertPriceToUSD = (price: number, exchangeRate = 1): number =>
    roundUp(price * exchangeRate);

/**
 * @param {number} price price to convert
 * @param {number} exchangeRate currency exchange rate
 * @param {number} feeProportion fee to be applied to the margin
 * @returns converted price to USD with margin applied
 */
export const convertPriceToUSDWithMargin = (
    price: number,
    currencyExchangeRate: number,
    feeProportion: number,
): number => {
    const convertedPrice = convertPriceToUSD(price, currencyExchangeRate);
    return roundUp(convertedPrice * feeProportion);
};

/**
 * Similar to ***convertPriceToUSD*** but this function takes a ***Currency*** object as parameter
 * @param {Currency} currency local currency to convert
 * @param {number} amount price to convert
 * @returns converted price to USD
 */
export const convertCurrencyToUSD = (
    currency: Currency,
    amount: number,
): number => roundUp(amount * currency.exchangeRate);

/**
 * @param {number} amount price to convert
 * @param {number} [currencyExchangeRate=1] currency exchange rate
 * @returns converted price to local currency
 */
export const convertUSDToLocalCurrency = (
    amount: number,
    currencyExchangeRate = 1,
): number => Math.floor(amount * (1 / currencyExchangeRate));

/**
 * @param {Array<CostThreshold>} costThresholds cost thresholds on the trip or itinerary
 * @param {number} currencyExchangeRate currency exchange rate
 * @returns converted cost thresholds
 */
export const convertCostThresholdsToUSD = (
    costThresholds: Array<CostThreshold>,
    currencyExchangeRate: number,
): Array<CostThreshold> =>
    costThresholds.map((threshold: CostThreshold) => {
        const { price, margin } = threshold;
        if (margin) {
            return threshold;
        }
        return {
            ...threshold,
            price: convertPriceToUSD(price, currencyExchangeRate),
        };
    });

/**
 * @param {TripAdditionalCosts} additionalCosts additional costs on the trip or itinerary
 * @param {number} currencyExchangeRate currency exchange rate
 * @param {number} [singleSupplementFee=1] single supplement fee
 * @returns converted additional costs to USD
 */
export const convertAdditionalCostsToUSD = (
    {
        singleSupplement = {
            price: 0,
            bookable: true,
            quantity: 0,
        },
        additionalFixedOperatorCost,
    }: TripAdditionalCosts,
    currencyExchangeRate: number,
    singleSupplementFee: number = 1,
): TripAdditionalCosts => ({
    singleSupplement: {
        price: convertPriceToUSD(
            singleSupplement.price * singleSupplementFee,
            currencyExchangeRate,
        ),
        bookable: singleSupplement.bookable,
        quantity: singleSupplement.quantity,
    },
    additionalFixedOperatorCost:
        additionalFixedOperatorCost &&
        convertPriceToUSD(additionalFixedOperatorCost, currencyExchangeRate),
});

/**
 * @param {Itinerary} itinerary itinerary to convert
 * @param {number} exchangeRate currency exchange rate
 * @returns the itinerary with converted prices to USD
 */
export const convertItineraryPricesToUSD = (
    itinerary: Itinerary,
    exchangeRate?: number,
    withMargin: boolean = false,
): Itinerary => {
    const pricesConvertedItinerary = { ...itinerary };
    const currencyExchangeRate =
        exchangeRate && exchangeRate > 0
            ? exchangeRate
            : itinerary.currency.exchangeRate;
    // USD no need for any conversion
    if (!currencyExchangeRate) {
        console.info(
            '@TROVA: [convertItineraryPricesToUSD] No exchange rate found. Conversion not needed.',
        );
        return pricesConvertedItinerary;
    }

    if (pricesConvertedItinerary.tourLeaderPrice > 0) {
        pricesConvertedItinerary.tourLeaderPrice = convertPriceToUSD(
            pricesConvertedItinerary.tourLeaderPrice,
            currencyExchangeRate,
        );
    }

    if (pricesConvertedItinerary.startingPrice > 0) {
        pricesConvertedItinerary.startingPrice = convertPriceToUSD(
            pricesConvertedItinerary.startingPrice,
            currencyExchangeRate,
        );
    }

    if (pricesConvertedItinerary.packages) {
        const singleSupplementFee = withMargin
            ? 1 + getTrovaSingleSupplementFee() / 100
            : 1;
        pricesConvertedItinerary.packages = convertItineraryPackagesPrices(
            pricesConvertedItinerary.packages,
            currencyExchangeRate,
            singleSupplementFee,
        );
    }

    if (pricesConvertedItinerary.additionalOptionalServices) {
        pricesConvertedItinerary.additionalOptionalServices =
            convertAdditionalOptionalServicesToUSD(
                pricesConvertedItinerary.additionalOptionalServices as models.services.Service[],
                currencyExchangeRate,
                withMargin,
            );
    }

    if (pricesConvertedItinerary.servicesByDay) {
        pricesConvertedItinerary.servicesByDay = convertServicesByDayToUSD(
            pricesConvertedItinerary.servicesByDay,
            currencyExchangeRate,
            withMargin,
        );
    }

    return pricesConvertedItinerary;
};

/**
 * @param {Array<HostSelectedOptionalServices>} [hostSelectedOptionalServices=[]]
 * ***hostSelectedOptionalServices*** to convert
 * @param {number} exchangeRate currency exchange rate
 * @returns converted ***hostSelectedOptionalServices*** to USD
 */
export const convertHostSelectedOptionalServicesToUSD = (
    hostSelectedOptionalServices: Array<HostSelectedOptionalServices> = [],
    exchangeRate: number,
): Array<HostSelectedOptionalServices> =>
    hostSelectedOptionalServices.map(({ activity, ...service }) => ({
        ...service,
        activity: {
            ...activity,
            price: convertPriceToUSD(activity.price, exchangeRate),
        },
    }));

/**
 * Used for operators working on trips
 * this function only converts the ***operatorFee*** price
 * @param {Trip} trip trip to convert
 * @param {number} currentExchangeRate currency exchange rate
 * @returns trip with prices in USD (operatorFee, platformFee) converted to the local currency
 */
export const convertTripToLocalCurrency = (
    trip: Trip,
    currentExchangeRate: number,
): Trip => {
    const { prices, lockedExchangeRate, costThresholds, additionalCosts } =
        trip;
    const exchangeRate = lockedExchangeRate || currentExchangeRate;

    const convertedCostThresholds: CostThreshold[] =
        convertPlatformFeesInCostThresholds(costThresholds, exchangeRate);

    let convertedItinerary = trip.itinerary as BaseItinerary | undefined;

    if (convertedItinerary?.packages) {
        convertedItinerary = {
            ...convertedItinerary,
            packages: convertPlatformFeesInPackages(
                convertedItinerary.packages,
                currentExchangeRate,
            ),
        };
    }

    const hostGroundTransferCost = additionalCosts?.hostGroundTransferCost
        ? convertUSDToLocalCurrency(
              additionalCosts.hostGroundTransferCost,
              exchangeRate,
          )
        : 0;

    return {
        ...trip,
        costThresholds: convertedCostThresholds,
        itinerary: convertedItinerary,
        prices: {
            ...prices,
            operatorFee: convertUSDToLocalCurrency(
                prices.operatorFee,
                exchangeRate,
            ),
        },
        additionalCosts: {
            ...trip.additionalCosts,
            hostGroundTransferCost,
        },
    };
};

/**
 * Used for operators working on trips
 * this function only converts the platform fee at each cost threshold
 * @param {CostThreshold[]} costThresholds cost thresholds to convert
 * @param {number} exchangeRate currency exchange rate
 * @returns converted cost thresholds to the selected currency
 */
export const convertPlatformFeesInCostThresholds = (
    costThresholds: CostThreshold[],
    exchangeRate: number,
): CostThreshold[] => {
    return costThresholds.map((threshold) => ({
        ...threshold,
        platformFee: convertUSDToLocalCurrency(
            threshold.platformFee || 0,
            exchangeRate,
        ),
    }));
};

/**
 * Used for operators working on itineraries
 * this function only converts the platform fee at each cost threshold within the itinerary packages
 * @param {ItineraryPackageLevels} packages itinerary packages to convert
 * @param {number} exchangeRate currency exchange rate
 * @returns converted itinerary packages to the selected currency
 */
export const convertPlatformFeesInPackages = (
    packages: ItineraryPackageLevels,
    exchangeRate: number,
): ItineraryPackageLevels => {
    return Object.entries(packages).reduce(
        (
            updatedPackages: ItineraryPackageLevels,
            [packageName, packageValue]: [string, ItineraryPackage],
        ) => {
            const { validityPeriods } = packageValue;

            if (!validityPeriods) {
                return updatedPackages;
            }

            const updatedValidityPeriods = validityPeriods.map(
                (validityPeriod) => ({
                    ...validityPeriod,
                    costThresholds: convertPlatformFeesInCostThresholds(
                        validityPeriod.costThresholds,
                        exchangeRate,
                    ),
                }),
            );

            const updatePackage: ItineraryPackage = {
                ...packageValue,
                validityPeriods: updatedValidityPeriods,
            };

            return {
                ...updatedPackages,
                [packageName as PackageLevel]: updatePackage,
            };
        },
        packages,
    );
};

/**
 * @param {Trip} trip trip to convert
 * @param {number} currentExchangeRate currency exchange rate
 * @param {boolean} [withMargin=false] flag to indicate whether the margin should
 * be applied to the conversion
 * @returns trip with converted prices to USD
 */
export const convertTripToUSD = (
    trip: Trip,
    currentExchangeRate: number,
    withMargin: boolean = false,
): Trip => {
    const {
        costThresholds,
        additionalCosts,
        hostSelectedOptionalServices,
        additionalOptionalServices,
        lockedExchangeRate,
        itinerary,
        servicesByDay,
        prices,
        itineraryInventoryItem,
    } = trip;
    const exchangeRate = lockedExchangeRate || currentExchangeRate;
    const singleSupplementFee = withMargin
        ? 1 +
          (prices.singleSupplementFee || getTrovaSingleSupplementFee()) / 100
        : 1;
    
    let convertedItineraryInventoryItem: ItineraryInventoryItem | undefined;
    if (
        itineraryInventoryItem &&
        typeof itineraryInventoryItem !== 'string' &&
        (itineraryInventoryItem as ItineraryInventoryItem)?.costSchedule
    ) {
        convertedItineraryInventoryItem = convertItineraryInventoryItemToUSD(
            itineraryInventoryItem,
            exchangeRate,
        );
    }

    let response = {
        ...trip,
        costThresholds: convertCostThresholdsToUSD(
            costThresholds,
            exchangeRate,
        ),
        additionalCosts: {
            ...additionalCosts,
            ...convertAdditionalCostsToUSD(
                additionalCosts,
                exchangeRate,
                singleSupplementFee,
            ),
        },
        itinerary: convertItineraryPricesToUSD(itinerary as any, exchangeRate),
        servicesByDay: convertServicesByDayToUSD(
            servicesByDay,
            exchangeRate,
            withMargin,
        ),
        additionalOptionalServices: convertAdditionalOptionalServicesToUSD(
            additionalOptionalServices,
            exchangeRate,
            withMargin,
        ),
        ...(convertedItineraryInventoryItem && {
            itineraryInventoryItem: convertedItineraryInventoryItem,
        }),
    };

    if (Array.isArray(hostSelectedOptionalServices)) {
        response.hostSelectedOptionalServices =
            convertHostSelectedOptionalServicesToUSD(
                hostSelectedOptionalServices,
                exchangeRate,
            );
    }

    // @ts-expect-error
    return response;
};

/**
 * @param {ServicesByDay} servicesByDay services by day to convert
 * @param {number} exchangeRate currency exchange rate
 * @param {boolean} [withMargin=false] flag to indicate whether the margin should
 * be applied to the conversion
 * @returns converted ***ServicesByDay*** prices to USD
 */
export const convertServicesByDayToUSD = (
    servicesByDay: models.services.ServicesByDay = [],
    exchangeRate: number,
    withMargin: boolean = false,
): Array<Array<DayService>> => {
    const newServicesByDay = servicesByDay.map((day) => {
        return day.map((dayService) => {
            const service = dayService.service as Service;
            const newService = { ...service };
            if (service.price) {
                newService.price = withMargin
                    ? convertPriceToUSDWithMargin(
                          service.price,
                          exchangeRate,
                          fees.travelerOptionalFee,
                      )
                    : convertPriceToUSD(service.price, exchangeRate);
            }
            if (
                service.type === ServiceType.WORKSHOP_SPACE &&
                service.pricePerHour
            ) {
                (newService as models.services.WorkshopSpace).pricePerHour =
                    withMargin
                        ? convertPriceToUSDWithMargin(
                              service.pricePerHour,
                              exchangeRate,
                              fees.travelerOptionalFee,
                          )
                        : convertPriceToUSD(service.pricePerHour, exchangeRate);
            }
            return {
                ...dayService,
                service: newService,
            };
        });
    });
    return newServicesByDay;
};

/**
 * @param {Array<Service>} additionalOptionalServices additional optional services to convert
 * @param {number} exchangeRate currency exchange rate
 * @param {boolean} [withMargin=false] flag to indicate whether the margin should
 * be applied to the conversion
 * @returns converted ***additionalOptionalServices*** prices to USD
 */
export const convertAdditionalOptionalServicesToUSD = (
    additionalOptionalServices: models.services.Service[] = [],
    exchangeRate: number,
    withMargin: boolean = false,
): models.services.Service[] => {
    return additionalOptionalServices.map((service) => {
        if (service.price) {
            const updatedPrice = withMargin
                ? convertPriceToUSDWithMargin(
                      service.price,
                      exchangeRate,
                      fees.travelerAdditionalOptionalFee,
                  )
                : convertPriceToUSD(service.price, exchangeRate);
            return {
                ...service,
                price: updatedPrice,
            };
        }
        return service;
    });
};

/**
 * @param {ItineraryPackage} itineraryPackage package level to convert
 * @param {number} currencyExchangeRate currency exchange rate
 * @returns converted package level prices to USD
 */
export const convertPackagePrices = (
    itineraryPackage: ItineraryPackage,
    currencyExchangeRate: number,
    singleSupplementFee?: number,
): ItineraryPackage => ({
    ...itineraryPackage,
    validityPeriods: convertValidityPeriodsPrices(
        itineraryPackage.validityPeriods || [],
        currencyExchangeRate,
        singleSupplementFee,
    ),
});

/**
 * @param {ItineraryPackageLevels} itineraryPackages itinerary packages to convert
 * @param {number} currencyExchangeRate currency exchange rate
 * @returns converted itinerary packages prices to USD
 */
export const convertItineraryPackagesPrices = (
    itineraryPackages: ItineraryPackageLevels,
    currencyExchangeRate: number,
    singleSupplementFee?: number,
): ItineraryPackageLevels => ({
    economy: convertPackagePrices(
        itineraryPackages.economy,
        currencyExchangeRate,
        singleSupplementFee,
    ),
    standard: convertPackagePrices(
        itineraryPackages.standard,
        currencyExchangeRate,
        singleSupplementFee,
    ),
    premium: convertPackagePrices(
        itineraryPackages.premium,
        currencyExchangeRate,
        singleSupplementFee,
    ),
});

/**
 * @param {Array<ValidityPeriod>} validityPeriods itinerary validity periods to convert
 * @param {number} currencyExchangeRate currency exchange rate
 * @returns converted validity periods prices to USD
 */
export const convertValidityPeriodsPrices = (
    validityPeriods: ValidityPeriod[],
    currencyExchangeRate: number,
    singleSupplementFee: number = 1,
): Array<ValidityPeriod> =>
    validityPeriods.map((period) => {
        const costThresholds = period.costThresholds.map((costThreshold) => {
            costThreshold.price = convertPriceToUSD(
                costThreshold.price,
                currencyExchangeRate,
            );
            return costThreshold;
        });

        const additionalCosts = Object.entries(period.additionalCosts).reduce(
            (additionals, [currentKey, currentValue]) => {
                return {
                    ...additionals,
                    [currentKey]: {
                        ...currentValue,
                        price: convertPriceToUSD(
                            currentValue.price * singleSupplementFee,
                            currencyExchangeRate,
                        ),
                    },
                };
            },
            {
                singleSupplement: {
                    price: 0,
                },
            },
        );

        return {
            ...period,
            costThresholds,
            additionalCosts,
        };
    });

/**
 * Converts the cost schedule to USD
 * @param costSchedule cost schedule to convert
 * @param currencyExchangeRate currency exchange rate
 * @returns converted cost schedule to USD
 */
export const convertCostScheduleToUSD = (
    costSchedule: Array<CostScheduleItem>,
    currencyExchangeRate: number,
): Array<CostScheduleItem> =>
    costSchedule.map((threshold: CostScheduleItem) => ({
        ...threshold,
        price: convertPriceToUSD(threshold.price, currencyExchangeRate),
    }));

/**
 * Converts the accommodation prices to USD
 * @param accommodation accommodation prices to convert
 * @param currentExchangeRate currency exchange rate
 * @returns converted accommodation prices to USD
 */
export const convertAccommodationPriceToUSD = (
    accommodation: AccommodationPrice = {},
    currentExchangeRate: number,
): AccommodationPrice => {
    return Object.entries(accommodation).reduce(
        (acc: AccommodationPrice, [key, value]) => ({
            ...acc,
            [key]: convertPriceToUSD(value, currentExchangeRate),
        }),
        {},
    );
};

/**
 * Converts the itinerary inventory item to USD
 * @param itineraryInventoryItem itinerary inventory item to convert
 * @param currentExchangeRate currency exchange rate
 * @returns converted itinerary inventory item to USD
 */
export const convertItineraryInventoryItemToUSD = (
    itineraryInventoryItem: ItineraryInventoryItem,
    currentExchangeRate: number,
): ItineraryInventoryItem => {
    const {
        costSchedule,
        singleSupplementPrice,
        preAccommodationPrice,
        postAccommodationPrice,
        preTransferPrice,
        postTransferPrice,
    } = itineraryInventoryItem;

    return {
        ...itineraryInventoryItem,
        singleSupplementPrice: convertPriceToUSD(
            singleSupplementPrice,
            currentExchangeRate,
        ),
        ...(preTransferPrice && {
            preTransferPrice: convertPriceToUSD(
                preTransferPrice,
                currentExchangeRate,
            ),
        }),
        ...(postTransferPrice && {
            postTransferPrice: convertPriceToUSD(
                postTransferPrice,
                currentExchangeRate,
            ),
        }),
        costSchedule: convertCostScheduleToUSD(
            costSchedule,
            currentExchangeRate,
        ),
        ...(preAccommodationPrice && {
            preAccommodationPrice: convertAccommodationPriceToUSD(
                preAccommodationPrice,
                currentExchangeRate,
            ),
        }),
        ...(postAccommodationPrice && {
            postAccommodationPrice: convertAccommodationPriceToUSD(
                postAccommodationPrice,
                currentExchangeRate,
            ),
        }),
    };
};
