import { BaseStatusError } from './baseError';

export const integrationErrors = {
    invalidEmail: {
        code: 1200,
        message:
            "We're sorry, but the email address you entered appears to be invalid. Please check for any typing errors and try again.",
    },
};

export class InvalidEmail extends BaseStatusError {
    constructor() {
        super(
            integrationErrors.invalidEmail.code,
            integrationErrors.invalidEmail.message,
        );
    }
}
