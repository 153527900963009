import { templates, context } from './context';
import parseImpersonation from './impersonation';

const utils = {
    context,
    templates,
    parseImpersonation,
};

export default utils;
