import { css } from '@emotion/react';
import { theme, Theme } from '../../../shared/theme';
import { ComponentWidth } from '../../Legacy/Forms/Input';

export const list = (
    theme: Theme,
    size: ComponentWidth,
    label: string | undefined
) =>
    css`
        background-color: ${theme.colors.neutral.white};
        box-shadow: ${theme.shadows[5]};
        border-radius: 6px;
        font-size: ${theme.fontSizes.base};
        font-weight: ${theme.fontWeights.regular};
        line-height: 1.5;
        color: ${theme.colors.blueGray[650]};
        padding: ${theme.space[2]} 0.0625rem;
        max-height: 10.5rem;
        overflow: auto;
        position: absolute;
        width: 100%;
        z-index: 1;
        ${!label && `top: 44px;`}
        ${label && `top: 70px;`}
        ${size === ComponentWidth.ExtraSmall && label && `top: 61px;`}
        ${size === ComponentWidth.ExtraSmall && !label && `top: 35px;`}

        &::-webkit-scrollbar {
            width: 0.5rem;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-track {
            height: 90%;
            background-color: ${theme.colors.blueGray[200]};
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: ${theme.colors.neutral[80]};
            border-radius: 5px;
        }
    `;

export const listItem = () =>
    css`
        cursor: pointer;
        padding: ${theme.space[1]} 0 ${theme.space[1]} ${theme.space[3]};
        :hover {
            background-color: ${theme.colors.blue[20]};
        }
    `;

export const listItemMultipleDropdown = () =>
    css`
        padding: ${theme.space[1]} 0 ${theme.space[1]} ${theme.space[3]};
        display: flex;
        :hover {
            background-color: ${theme.colors.blue[20]};
        }
    `;

export const checkboxMultipleDropdown = () =>
    css`
        padding-right: ${theme.space[2]};
    `;

export const childrenMultipleDropdown = () =>
    css`
        padding-top: 0.2rem;
    `;
