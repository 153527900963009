import { TrackEventParameters } from './analytics.types';
import { destinationMiddleware } from './middleware';
import utils from './utils';
import { browserLogger } from '../logger/legacy/BrowserLogger';
import { loadAnalytics as loadSegment } from './loadAnalytics';
class Analytics {
    constructor() {
        this._initialize();
    }

    private _loadedAnalytics: Analytics | undefined = undefined; 

    private _logError = (message: string): void => {
        browserLogger.error(`[Analytics]: ${message}`);
    };

    private _initialize = (): void => {
        try {
            if (typeof window !== 'undefined' && window.analytics) {
                // TODO: investigate possible issue(s) with sourceMiddleware
                // sourceMiddleware.forEach((middleware) => {
                //     window.analytics.addSourceMiddleware(middleware);
                // });
                Object.keys(destinationMiddleware).map((destination) => {
                    window.analytics.addDestinationMiddleware(
                        destination,
                        destinationMiddleware[destination],
                    );
                });
            }
        } catch (error) {
            this._logError(`initialize failed: ${error}`);
        }
    };

    private _user: Record<string, any> | undefined = undefined;

    get currentUser() {
        return this._user;
    }

    initialize = (apiKey: string): Analytics => {
        if (this._loadedAnalytics) {
            return this._loadedAnalytics;
        }
        loadSegment(apiKey);
        this._loadedAnalytics = new Analytics();
        return this._loadedAnalytics;
    }

    setUser = (user: Record<string, any>): this => {
        const newUser = { ...user };
        // remove sensitive fields
        delete newUser.activation;
        delete newUser.password;
        this._user = user;
        return this;
    };

    resetUser = (): this => {
        this._user = undefined;
        return this;
    };

    trackPage = (): this => {
        try {
            const impersonationData = utils.parseImpersonation(this._user);
            window.analytics.page(undefined, undefined, {
                ...impersonationData,
            });
        } catch (error) {
            this._logError(`track page failed: ${error}`);
        }

        return this;
    };

    identifyUser = (traits?: Record<string, any>): this => {
        try {
            const impersonationData = utils.parseImpersonation(this._user);
            let userTraits: Record<string, any> = {
                ...this._user,
                ...traits,
                ...impersonationData,
            };

            delete userTraits.impersonate;

            const userId = this._user?.id || this._user?._id || null;

            window.analytics.identify(userId, userTraits);
        } catch (error) {
            this._logError(`identify user failed: ${error}`);
        }

        return this;
    };

    trackEvent = ({
        eventName,
        properties,
        context,
    }: TrackEventParameters): this => {
        try {
            const impersonationData = utils.parseImpersonation(this._user);
            const templatedContext = utils.context.create({
                ...this._user,
                ...context,
            });

            window.analytics.track(
                eventName,
                { ...properties, ...impersonationData },
                {
                    context: { ...templatedContext },
                },
            );
        } catch (error) {
            this._logError(`track event failed: ${error}`);
        }

        return this;
    };
}

const AnalyticsImpl = new Analytics();

export default AnalyticsImpl as Analytics;
