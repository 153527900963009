import { models, constants } from '@trova-trip/trova-models';
import {
    ValidityPeriodByDay,
    TripCostDTO,
    TripRequestCostDTO,
    ItineraryCostDTO,
} from '../PricingCalculator.types';
import moment from 'moment';
import { StatusError } from '../../errors';
import { roundUp } from './common.utils';

type ValidityPeriod = models.itineraries.ValidityPeriod;
type Service = models.services.Service;
type PackageLevel = constants.itinerary.PackageLevel;

export const getValidityPeriodsByDay = (
    validityPeriods: ValidityPeriod[],
    tripStartDate: Date,
    tripLength: number,
    yearOverYearIncrease: number,
): ValidityPeriodByDay[] => {
    const currentDate = moment(tripStartDate);
    const tripEndDate = moment(tripStartDate).add(tripLength - 1, 'days');
    const days: ValidityPeriodByDay[] = [];

    while (moment(currentDate).isSameOrBefore(tripEndDate)) {
        const existingValidityPeriods = validityPeriods.find(
            ({ startDate, endDate }) =>
                moment(currentDate).isBetween(
                    moment(startDate),
                    moment(endDate),
                    undefined,
                    '[]',
                ),
        );

        if (existingValidityPeriods) {
            days.push({
                day: currentDate.toDate(),
                singleSupplementPrice: roundUp(
                    existingValidityPeriods.additionalCosts?.singleSupplement
                        ?.price || 0,
                ),
                validityPeriod: existingValidityPeriods,
            });
        } else {
            days.push(
                _calculateFromPreviousValidityPeriod(
                    currentDate,
                    validityPeriods,
                    yearOverYearIncrease,
                ),
            );
        }
        currentDate.add(1, 'days');
    }

    return days;
};

const _calculateFromPreviousValidityPeriod = (
    currentDate: moment.Moment,
    validityPeriods: ValidityPeriod[],
    yearOverYearIncrease: number,
): ValidityPeriodByDay => {
    const previousYearDate = moment(currentDate).subtract(1, 'year');
    const maxAttempts = 5;
    for (let i = 0; i < maxAttempts; i++) {
        const oldValidityPeriodExists = validityPeriods.find(
            ({ startDate, endDate }) =>
                moment(previousYearDate).isBetween(
                    moment(startDate),
                    moment(endDate),
                    undefined,
                    '[]',
                ),
        );

        if (oldValidityPeriodExists) {
            return {
                day: currentDate.toDate(),
                singleSupplementPrice: roundUp(
                    oldValidityPeriodExists.additionalCosts?.singleSupplement
                        ?.price || 0,
                ),
                validityPeriod: _calculateNewValidityPeriod(
                    currentDate,
                    oldValidityPeriodExists,
                    yearOverYearIncrease,
                ),
            };
        }

        previousYearDate.subtract(1, 'year');
    }

    const sortValidityPeriodsByEndDate = validityPeriods.sort(
        (dateA, dateB) =>
            new Date(dateA.endDate).getTime() -
            new Date(dateB.endDate).getTime(),
    );

    const closestValidityPeriod: ValidityPeriod =
        sortValidityPeriodsByEndDate.reduce(
            (closest: ValidityPeriod, period: ValidityPeriod) => {
                const endDate = new Date(period.endDate);
                const difference = Math.abs(
                    endDate.getTime() - currentDate.toDate().getTime(),
                );

                if (
                    difference <
                    Math.abs(
                        new Date(closest.endDate).getTime() -
                            currentDate.toDate().getTime(),
                    )
                ) {
                    return period;
                } else {
                    return closest;
                }
            },
        );

    const defaultValidityPeriod = validityPeriods[0];

    const validityPeriod = closestValidityPeriod
        ? _calculateNewValidityPeriod(
              currentDate,
              closestValidityPeriod,
              yearOverYearIncrease,
          )
        : {
              ...defaultValidityPeriod,
              additionalCosts: { singleSupplement: { price: 0 } },
              costThresholds: defaultValidityPeriod.costThresholds.map(
                  (threshold) => ({ ...threshold, price: 0 }),
              ),
          };

    return {
        day: currentDate.toDate(),
        singleSupplementPrice:
            validityPeriod?.additionalCosts.singleSupplement?.price || 0,
        validityPeriod,
    };
};

const _calculateNewValidityPeriod = (
    date: moment.Moment,
    validityPeriod: ValidityPeriod,
    yearOverYearIncrease: number,
): ValidityPeriod => {
    const amountOfYears: number = moment(validityPeriod.startDate).diff(
        moment(date),
        'years',
    );

    return {
        ...validityPeriod,
        costThresholds: validityPeriod.costThresholds.map((threshold) => ({
            ...threshold,
            price: roundUp(
                threshold.price *
                    Math.pow(
                        1 + yearOverYearIncrease / 100,
                        Math.abs(amountOfYears),
                    ),
            ),
        })),
    };
};

export const getWorkshops = (
    trip?: TripCostDTO,
    tripRequest?: TripRequestCostDTO,
): models.services.DayService<models.services.WorkshopSpace>[] => {
    if (trip?.servicesByDay) {
        return trip.servicesByDay.flatMap((day) =>
            day.filter(
                (
                    dayService,
                ): dayService is models.services.DayService<models.services.WorkshopSpace> => {
                    const { service } = dayService;
                    return (
                        (service as Service).type ===
                        constants.services.ServiceType.WORKSHOP_SPACE
                    );
                },
            ),
        );
    }

    if (tripRequest?.selectedWorkshopSpaces) {
        return tripRequest.selectedWorkshopSpaces.map((service) => {
            return {
                // defaulted to 1 because it is expected by "DayService" type
                // but not used in price calculation
                duration: 1,
                service: {
                    ...service.activity,
                    hoursRequested:
                        service.length ?? service.activity.hoursAvailable ?? 0,
                },
            };
        });
    }

    return [];
};

export const validateParams = (
    validityPeriods: models.itineraries.ValidityPeriod[] | undefined,
    startDate: Date | string | undefined,
    tripLength: number | undefined,
    hostSelectedOptionalServices: any,
): void => {
    if (!validityPeriods) {
        throw new StatusError.ValidityPeriodsError();
    }

    if (!startDate) {
        throw new StatusError.StartDateError();
    }

    if (!tripLength) {
        throw new StatusError.TripLengthError();
    }

    if (!Array.isArray(hostSelectedOptionalServices)) {
        throw new StatusError.HostSelectedOptionalServicesError();
    }
};

export const getNumberOfTravelersPerThreshold = (
    costThresholdsFromItinerary: models.itineraries.CostThreshold[] = [],
    companions: models.common.Companion[] = [],
    costThresholdsFromTrip?: models.itineraries.CostThreshold[],
): number[] => {
    const tiersFromTrip =
        costThresholdsFromTrip?.map(
            ({ numberOfTravelers }) => numberOfTravelers,
        ) || [];

    if (costThresholdsFromTrip) {
        return tiersFromTrip;
    }

    const companionsLength = companions.length;
    const itineraryTiers: number[] =
        costThresholdsFromItinerary.map(
            ({ numberOfTravelers }) => numberOfTravelers,
        ) || [];

    const originalTiers = [...new Set([...itineraryTiers, ...tiersFromTrip])];

    if (companionsLength === 0) {
        originalTiers.sort((a, b) => a - b);
        return originalTiers;
    }

    const adjustedTiers: number[] = itineraryTiers.map(
        (tier) => tier - companionsLength,
    );
    const tiers = [
        ...new Set([...itineraryTiers, ...adjustedTiers, ...tiersFromTrip]),
    ].filter((tier) => tier > 0);
    tiers.sort((a, b) => a - b);
    return tiers;
};

export const getValidityPeriodsFromSelectedPackage = (
    itinerary: ItineraryCostDTO | undefined,
    selectedPackage: PackageLevel | undefined,
): ValidityPeriod[] | undefined => {
    if (selectedPackage && itinerary?.packages) {
        const itineraryPackage = itinerary?.packages[selectedPackage];
        const validityPeriods = itineraryPackage.validityPeriods?.filter(
            ({ blackoutStartDate }) => !blackoutStartDate?.isBlackout,
        );

        return validityPeriods?.length ? validityPeriods : undefined;
    }
};
