export enum ApiResponseStatus {
    SUCCESS = 'success',
    ERROR = 'error',
}

export type ErrorApiResponse = {
    status: ApiResponseStatus.ERROR;
    code: number;
    message: string;
};

export type SuccessApiResponse<T = unknown> = {
    status: ApiResponseStatus.SUCCESS;
    data: T;
};

export type ApiResponse<T> = SuccessApiResponse<T> | ErrorApiResponse;
