import { last } from 'lodash';
import { models } from '@trova-trip/trova-models';
import {
    CostPerThreshold,
    WorkshopCostThresholds,
    CompanionCostThresholds,
    CostByDayThresholds,
    CostThresholdWithAllCosts,
} from '../PricingCalculator.types';

type CostScheduleItem = models.itineraryInventoryItems.CostScheduleItem;
type CostThreshold = models.itineraries.CostThreshold;

export const MAXIMUM_HOST_MARGIN = 30;
export const TARGET_MINIMUM_HOST_PROFIT = 1000;
export const DEFAULT_REMAINING_PRICE_EXTRA = 100;
export const TIER_CUTOFF = 10;

export const roundUp = (amount: number): number => {
    return Math.ceil(amount);
};

/**
 * @description Rounds down a given number to the nearest integer value that is less than or equal to the original number.
 *
 * @param {number} amount - The number to be rounded down.
 * @returns {number} The rounded down integer value.
 */
export const roundDown = (amount: number): number => {
    return Math.floor(amount);
};

/**
 * @description This function sums all values within costPerThreshold
 *
 * @example
 * const validityPeriodsByDay = [
 *   {
 *     costPerThreshold: [
 *       {
 *         numberTravelers: 8,
 *         pricePerTraveler: 100,
 *       },
 *       {
 *         numberTravelers: 10,
 *         pricePerTraveler: 100,
 *       },
 *     ],
 *   },
 *   {
 *     costPerThreshold: [
 *       {
 *         numberTravelers: 8,
 *         pricePerTraveler: 100,
 *       },
 *       {
 *         numberTravelers: 10,
 *         pricePerTraveler: 100,
 *       },
 *     ],
 *   },
 * ];
 *
 * const result = sumPrices(validityPeriodsByDay, 8); // result = 200
 *
 * @param costs the data to be summed up
 * @param numberOfTravelers the number of travelers
 * @param key the property to be summed up - default is pricePerTraveler
 * @returns number
 */
export const sumPrices = (
    costs: (
        | WorkshopCostThresholds
        | CompanionCostThresholds
        | CostByDayThresholds
    )[],
    numberOfTravelers: number,
    key: keyof CostPerThreshold = 'pricePerTraveler',
): number => {
    const totalPrice = costs.reduce(
        (
            total: number,
            {
                costPerThreshold,
            }:
                | WorkshopCostThresholds
                | CompanionCostThresholds
                | CostByDayThresholds,
        ) =>
            total +
            (costPerThreshold.find(
                ({ numberTravelers }: CostPerThreshold) =>
                    numberTravelers === numberOfTravelers,
            )?.[key] || 0),
        0,
    );

    return Math.round(totalPrice);
};

export const getCostOutputs = (
    hostTerms: models.trips.HostTerm[],
): CostThresholdWithAllCosts[] => {
    if (!hostTerms) {
        throw new Error(`Missing host terms`);
    }
    const lastAcceptedTerms = last(hostTerms);

    if (!lastAcceptedTerms?.costOutputs) {
        return [];
    }

    return lastAcceptedTerms.costOutputs.map((costOutput) => ({
        numberOfTravelers: costOutput.numberOfTravelers,
        priceWithFixedCost: costOutput.baseCostPerTraveler,
        serviceCost: costOutput.serviceFeeAmount,
        transactionCost: costOutput.transactionFeeAmount,
        priceWithAllCosts: costOutput.costPerTraveler,
    }));
};

export const getThresholdByTier = (
    costs: CostPerThreshold[],
    tier: number,
): CostPerThreshold | undefined =>
    costs.find(({ numberTravelers }) => numberTravelers === tier);

export const convertToCalculatorThresholds = (
    costSchedule: CostScheduleItem[],
): CostPerThreshold[] => {
    return costSchedule.map(({ numberOfTravelers, price, platformFee }) => ({
        numberTravelers: numberOfTravelers,
        pricePerTraveler: price,
        platformFee,
    }));
};

export const convertCostPerThresholdToCostThreshold = (
    costPerThresholds: CostPerThreshold[],
): CostThreshold[] => {
    return costPerThresholds.map(
        ({ numberTravelers, pricePerTraveler, platformFee }) => ({
            numberOfTravelers: numberTravelers,
            price: pricePerTraveler,
            platformFee,
        }),
    );
};
