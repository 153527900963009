import React, { useCallback, SyntheticEvent } from 'react';
import { SidebarItemProps } from './SidebarItem.types';
import { useStyleConfig } from '@chakra-ui/react';
import { genDataTestId } from '../../../../shared/utils/helpers';
import { CardImpl } from '../../../Surfaces/Card';
import { TextImpl } from '../../../Typography/Text';
import ItemLabel from '../ItemLabel/ItemLabel';

const ItemTitle = ({ children }: { children: string }) => {
    return (
        <TextImpl
            margin={0}
            fontSize='base'
            color='neutral.black'
            lineHeight='6'
            fontWeight='medium'
        >
            {children}
        </TextImpl>
    );
};

const ItemSubtitle = ({ children }: { children: string }) => {
    return (
        <TextImpl
            paddingTop={1}
            fontSize='sm'
            fontWeight='medium'
            lineHeight='4'
            color='blueGray.500'
        >
            {children}
        </TextImpl>
    );
};

const SidebarItem = (props: SidebarItemProps) => {
    const { title, subtitle, isSelected = false, id, onClick, labels } = props;
    const itemClicked = useCallback(
        (event: SyntheticEvent) => {
            onClick ? onClick() : event.stopPropagation();
        },
        [id, onClick]
    );

    const styles = useStyleConfig('SidebarItem', { isSelected });

    return (
        <CardImpl
            data-testid={genDataTestId(`sidebar__item-${id}`)}
            onClick={itemClicked}
            styles={styles}
            as='li'
        >
            <ItemTitle>{title}</ItemTitle>
            {subtitle ? <ItemSubtitle>{subtitle}</ItemSubtitle> : null}
            {labels
                ? labels.map(label => (
                      <ItemLabel key={label.text} {...label}>
                          {label.text}
                      </ItemLabel>
                  ))
                : null}
        </CardImpl>
    );
};

export default SidebarItem;
