import {
    Accordion as AccordionChakra,
    AccordionProps as AccordionPropsChakra,
    As,
    ResponsiveValue,
} from '@chakra-ui/react';
import React from 'react';
import { genDataTestId } from '../../../shared/utils/helpers';
import { useOmitForbiddenProps } from '../../../shared/hooks';
import { InternalBaseProps } from '../../../shared/theme/interfaces/Base.types';
import {
    DesignTokens,
    MarginProps,
    PositionProps,
} from '../../../shared/theme/types';

import AccordionHeader from './components/AccordionHeader';
import AccordionItem from './components/AccordionItem';
import AccordionPanel from './components/AccordionPanel';

export interface AccordionProps extends MarginProps, PositionProps {
    children?: React.ReactNode;
    as?: As;
    display?: InternalBaseProps['display'];
    index?: AccordionPropsChakra['index'];
    defaultIndex?: AccordionPropsChakra['defaultIndex'];
    onChange?: AccordionPropsChakra['onChange'];
    allowMultiple?: boolean;
    allowToggle?: boolean;
    disableAnimation?: boolean;
    background?:
        | ResponsiveValue<DesignTokens['colors']>
        | InternalBaseProps['background'];
}

export interface AccordionImplProps
    extends AccordionProps,
        Omit<InternalBaseProps, 'onChange' | 'background'> {}

export const AccordionImpl = (props: AccordionImplProps) => {
    const { disableAnimation, ...rest } = props;
    return (
        <AccordionChakra
            data-testid={genDataTestId('accordion')}
            reduceMotion={disableAnimation}
            {...rest}
        />
    );
};

const Accordion = (props: AccordionProps) => {
    const filteredProps = useOmitForbiddenProps(props);
    return <AccordionImpl {...filteredProps} />;
};

export default Object.assign(Accordion, {
    Item: AccordionItem,
    Header: AccordionHeader,
    Panel: AccordionPanel,
});
