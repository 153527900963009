import { BaseStatusError } from './baseError';

export const authMessages = {
    unauthorizedError: {
        code: 1150,
        message: 'Invalid email/password',
    },
    passwordAttemptsError: {
        code: 1151,
        message: 'Oops, too many attempts...',
    },
};

export const jwtMessages = {
    invalidSessionTokenError: {
        code: 1175,
        message: 'Invalid session token',
    },
    invalidSessionError: {
        code: 1176,
        message: 'Invalid Session',
    },
};

export class AuthorizationErrors {
    static Unauthorized(message?: string): BaseStatusError {
        throw new BaseStatusError(
            authMessages.unauthorizedError.code,
            message ?? authMessages.unauthorizedError.message,
        );
    }

    static PasswordAttempts(message?: string): BaseStatusError {
        throw new BaseStatusError(
            authMessages.passwordAttemptsError.code,
            message ?? authMessages.passwordAttemptsError.message,
        );
    }
}

export class JwtErrors {
    static InvalidSession(message?: string): BaseStatusError {
        throw new BaseStatusError(
            jwtMessages.invalidSessionError.code,
            message ?? jwtMessages.invalidSessionError.message,
        );
    }

    static InvalidSessionToken(message?: string): BaseStatusError {
        throw new BaseStatusError(
            jwtMessages.invalidSessionTokenError.code,
            message ?? jwtMessages.invalidSessionTokenError.message,
        );
    }
}
