import { BaseStatusError } from './baseError';

export const contextMessages = {
    contextInitializationError: {
        code: 1300,
        message: 'Failed to initialize the app context',
    },
    unhandledContextError: {
        code: 1301,
        message: 'Internal context error',
    },
    missingUserContext: {
        code: 1302,
        message: 'You must connect a user or execute in a context.',
    },
    missingExecutionContext: {
        code: 1303,
        message: 'You must connect a execution environment or execute in a context.',
    }
};

export class ContextInitializationError extends BaseStatusError {
    constructor(message?: string) {
        super(
            contextMessages.contextInitializationError.code,
            message || contextMessages.contextInitializationError.message,
        );
    }
}

export class UnhandledContextError extends BaseStatusError {
    constructor(message?: string) {
        super(
            contextMessages.unhandledContextError.code,
            message || contextMessages.unhandledContextError.message,
        );
    }
}

export class MissingUserContextError extends BaseStatusError {
    constructor(message?: string) {
        super(
            contextMessages.missingUserContext.code,
            message || contextMessages.missingUserContext.message,
        );
    }
}

export class MissingExecutionContextError extends BaseStatusError {
    constructor(message?: string) {
        super(
            contextMessages.missingExecutionContext.code,
            message || contextMessages.missingExecutionContext.message,
        );
    }
}
