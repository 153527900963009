import { BaseStatusError } from './baseError';

export const groundTransfersErrors = {
    arrivalAndDepartureMissing: {
        message: 'Both arrival and departure are missing to get quotes',
        code: 1601,
    },
    notSupportedTimeZones: {
        message: 'Origin and destination must be on the same time zone',
        code: 1602,
    },
    transferzApi: {
        message: (path: string, errorMessage: string) =>
            `Error while calling external provider API with path ${path}: ${errorMessage}`,
        code: 1603,
    },
};

export class ArrivalAndDepartureMissingError extends BaseStatusError {
    constructor() {
        super(
            groundTransfersErrors.arrivalAndDepartureMissing.code,
            groundTransfersErrors.arrivalAndDepartureMissing.message,
        );
    }
}

export class NotSupportedTimeZonesError extends BaseStatusError {
    constructor() {
        super(
            groundTransfersErrors.notSupportedTimeZones.code,
            groundTransfersErrors.notSupportedTimeZones.message,
        );
    }
}

export class TransferzApiError extends BaseStatusError {
    constructor(path: string, errorMessage: string) {
        super(
            groundTransfersErrors.transferzApi.code,
            groundTransfersErrors.transferzApi.message(path, errorMessage),
        );
    }
}
