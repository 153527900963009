import { useMemo } from 'react';
import { MainContentProps } from './MainContent.types';
import {
    BaseMainConfig,
    BaseTemplateAreas,
    CastedResponsiveValue,
    MainLayoutAreas,
} from './MainContent.types';

const { main: m, sidebar: s } = MainLayoutAreas;

export const baseTemplateWithoutSidebar = `'${m} ${m}'`;

const baseTemplateAreas: BaseTemplateAreas = {
    top: `'${s} ${s}' '${m} ${m}'`,
    right: `'${m} ${s}'`,
    bottom: `'${m} ${m}' '${s} ${s}'`,
    left: `'${s} ${m}'`,
};

const getTemplateColumns = (side: 'left' | 'right', size: number) => {
    return side === 'left'
        ? `${size}px minmax(0,1fr)`
        : `minmax(0,1fr) ${size}px`;
};

const useMainContentConfig = (
    defaultPlacement: 'left' | 'right',
    sidebarConfig: MainContentProps['sidebarConfig'],
    showSidebar: boolean
) => {
    const layoutConfig = useMemo(() => {
        const sidebarColumnWidth = sidebarConfig?.sidebarColumnWidth || 288;
        const defaultColumns = getTemplateColumns(
            defaultPlacement,
            sidebarColumnWidth
        );

        const baseMainConfig: BaseMainConfig = {
            gridTemplateAreas: {
                base: baseTemplateAreas['top'],
                lg: baseTemplateAreas[defaultPlacement],
            },
            gridTemplateColumns: {
                base: defaultColumns,
                lg: defaultColumns,
            },
        };

        if (sidebarConfig) {
            const { placement } = sidebarConfig;
            if (placement) {
                Object.keys(placement).forEach(elem => {
                    const placementKey = elem as keyof CastedResponsiveValue;
                    const placementValue = (placement as CastedResponsiveValue)[
                        placementKey
                    ] as string;

                    (baseMainConfig.gridTemplateAreas as CastedResponsiveValue)[
                        placementKey
                    ] =
                        baseTemplateAreas[
                            placementValue as keyof BaseTemplateAreas
                        ];

                    if (
                        placementValue === 'right' ||
                        placementValue === 'left'
                    ) {
                        baseMainConfig.gridTemplateColumns[
                            placementKey
                        ] = getTemplateColumns(
                            placementValue,
                            sidebarColumnWidth
                        );
                    }
                });
            }
        }

        if (!showSidebar) {
            baseMainConfig.gridTemplateAreas = baseTemplateWithoutSidebar;
        }

        return baseMainConfig;
    }, [sidebarConfig, showSidebar]);

    return layoutConfig;
};

export default useMainContentConfig;
