import { ResponsiveValue, useStyleConfig } from '@chakra-ui/react';
import React, { MouseEventHandler } from 'react';
import { useBreakpointValue } from '../../../../shared/hooks';
import { genDataTestId } from '../../../../shared/utils/helpers';
import { Variants } from './LinkCard.styles';
import {
    CardProps,
    CardImpl,
    BaseCardWithLinkImplProps,
} from '../shared/CardImpl';
import {
    MarginProps,
    PaddingProps,
    SizeProps,
    FlexItemProps,
    PositionProps,
} from '../../../../shared/theme/types';

type OnClickProps =
    | { onClick: CardProps['onClick']; linkProps?: never }
    | { linkProps: BaseCardWithLinkImplProps['linkProps']; onClick?: never };

export type LinkCardProps = MarginProps &
    PaddingProps &
    SizeProps &
    FlexItemProps &
    PositionProps &
    Pick<CardProps, 'spacing' | 'children' | 'overflow'> &
    OnClickProps & {
        variant?: ResponsiveValue<Variants>;
    };
export type LinkCardImplProps = LinkCardProps;

export const LinkCardImpl = (props: LinkCardImplProps) => {
    const {
        linkProps,
        variant: variantProp = 'dropShadow',
        onClick,
        ...rest
    } = props;

    const variant = useBreakpointValue(variantProp);
    const styles = useStyleConfig('LinkCard', { variant });

    const onClickHandler: MouseEventHandler<HTMLDivElement> = event => {
        if (onClick) {
            event.preventDefault();
            onClick(event);
        }
    };

    return (
        <CardImpl
            as='a'
            data-testid={genDataTestId('link-card')}
            linkProps={{
                ...linkProps,
                href: linkProps?.href ? linkProps.href : '#',
            }}
            styles={styles}
            onClick={onClickHandler}
            {...rest}
        />
    );
};

const LinkCard = (props: LinkCardProps) => <LinkCardImpl {...props} />;

export default LinkCard;
