export interface ImpersonationData {
    impersonated: boolean;
    impersonater: string | null;
}

const parseImpersonation = (
    user: Record<string, any> | undefined,
): ImpersonationData => {
    return {
        // needs to explicitly return true or false, otherwise it doesn't appear in segment
        impersonated:
            user && user.impersonate && user.impersonate !== 'user'
                ? true
                : false,
        impersonater: user?.impersonate || '',
    };
};

export default parseImpersonation;
