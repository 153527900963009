import { constants, models } from '@trova-trip/trova-models';
import { TrovaTripAppsDomainMap, Environment } from '../app.constants';

const { Group } = constants.user;

type HostHomeComputedProgress = constants.host.HostHomeComputedProgress;
const { HostHomeComputedProgress } = constants.host;

type Host = models.hosts.Host;
type HostHomeProgress = constants.host.HostHomeProgress;
type HostHomeProgressStep = models.hosts.HostHomeProgressStep;
type HostHomeProgressStepKey = constants.host.HostHomeProgressStepKey;
type User = models.users.BaseUser;
const UserStatuses = constants.user.UserStatuses;

const {
    MAX_EARNINGS_ADVANCE_LIMIT_NEW_HOST,
    MAX_EARNINGS_ADVANCE_LIMIT_RENEWED_HOST,
} = constants.host;

const PERCENT_PRECISION = 100;

export const isQualifiedHost = (
    user: Pick<User, 'group' | 'status'> | undefined,
): boolean => {
    return (
        user?.group === Group.HOST &&
        constants.user.HOST_QUALIFIED_STATUSES.includes(user.status)
    );
};

export const maxHomeProgress = (
    progress1: HostHomeProgress | HostHomeComputedProgress,
    progress2: HostHomeProgress | HostHomeComputedProgress,
): HostHomeProgress | HostHomeComputedProgress => {
    return constants.host.HOST_HOME_PROGRESS_ORDER[progress1] >
        constants.host.HOST_HOME_PROGRESS_ORDER[progress2]
        ? progress1
        : progress2;
};

export const compareHomeProgress = (
    progress1: HostHomeProgress | HostHomeComputedProgress,
    progress2: HostHomeProgress | HostHomeComputedProgress,
): number => {
    const difference =
        constants.host.HOST_HOME_PROGRESS_ORDER[progress1] -
        constants.host.HOST_HOME_PROGRESS_ORDER[progress2];
    if (difference < 0) {
        return -1;
    }
    if (difference > 0) {
        return 1;
    }
    return 0;
};

export const isHomeProgressBlocked = (
    user: Pick<User, 'group' | 'status'> | undefined,
    homeProgress: HostHomeProgress,
): boolean => {
    return (
        !isQualifiedHost(user) &&
        compareHomeProgress(
            homeProgress,
            HostHomeComputedProgress.RECEIVED_SUFFICIENT_RESPONSES,
        ) > 0
    );
};

export const computeHomeProgress = (
    user: Pick<User, 'group' | 'status'> | undefined,
    host: Pick<Host, 'homeProgress'>,
): HostHomeProgress | HostHomeComputedProgress => {
    if (isQualifiedHost(user)) {
        return maxHomeProgress(
            host.homeProgress,
            HostHomeComputedProgress.RECEIVED_SUFFICIENT_RESPONSES,
        );
    }

    return host.homeProgress;
};

const populateHomeProgressStep = (
    stepTemplate: Readonly<HostHomeProgressStep>,
    homeProgress: HostHomeProgress | HostHomeComputedProgress,
): HostHomeProgressStep => {
    let percent = 0;
    let steps: HostHomeProgressStep[] | undefined;

    if (stepTemplate.steps?.length) {
        steps = [];

        for (const childStepTemplate of stepTemplate.steps) {
            const childStep = populateHomeProgressStep(
                childStepTemplate,
                homeProgress,
            );
            percent += childStep.percent / stepTemplate.steps.length;
            steps.push(childStep);
        }

        //  Round to nearest .01
        percent = Math.round(percent * PERCENT_PRECISION) / PERCENT_PRECISION;
    } else if (
        stepTemplate.value &&
        compareHomeProgress(homeProgress, stepTemplate.value) >= 0
    ) {
        percent = 1;
    }

    return {
        ...stepTemplate,
        percent,
        steps,
    };
};

export const buildHomeProgressSteps = (
    homeProgress: HostHomeProgress | HostHomeComputedProgress,
): HostHomeProgressStep[] => {
    const rootStepTemplate: HostHomeProgressStep = {
        key: 'temp' as HostHomeProgressStepKey,
        name: 'temp',
        order: 0,
        percent: 0,
        steps: constants.host.HostHomeProgressStepsTemplate,
    };

    const { steps } = populateHomeProgressStep(rootStepTemplate, homeProgress);

    return steps as HostHomeProgressStep[];
};

/**
 * Get a unique page path for a user
 * @param user - user to get the unique page path for
 * @param stringLength - length of random string to append to the user's name
 * @returns
 */
export const getPagePath = (user: User, stringLength = 5): string => {
    const name = user.firstName || user.displayName || '';

    let nameWithoutSpecialChars = name
        .replace(/[^a-zA-Z0-9\s]/g, '')
        .replace(/\s/g, '-')
        .toLowerCase();

    if (stringLength === 0) {
        return nameWithoutSpecialChars;
    }

    const availableCharacters = `0123456789abcdefghijklmnopqrstuvwxyz`;

    const randomString = Array(stringLength)
        .fill(availableCharacters)
        .map((char) => char[Math.floor(Math.random() * char.length)])
        .join('');

    const pagePath = `${nameWithoutSpecialChars}-${randomString}`;

    // remove multiple hyphens in a row
    return pagePath.replace(/-{2,}/g, '-');
};

/**
 * Check if the host public profile is complete
 * @param user the user associated with the profile
 * @returns
 */
export const isHostPublicProfileCompleted = (
    user: Partial<Pick<User, 'group' | 'profilePictureUrl' | 'bio'>>,
): boolean => {
    if (!user?.group || user.group !== Group.HOST) {
        console.warn('isHostPublicProfileCompleted: user is not a host');
        return false;
    }

    // these properties are required to consider the host public profile as complete
    const hasProfilePicture = !!user?.profilePictureUrl;
    const hasBio = !!user?.bio;

    return hasProfilePicture && hasBio;
};

/**
 * Remove the '@' sign from the beginning of a social handle, if present
 * @param handle the social handle
 * @returns formatted handle (string)
 */
export const sanitizeSocialHandle = (handle: string): string => {
    return handle.slice(handle[0] === '@' ? 1 : 0);
};

/**
 * Build the host public profile URL
 * @param pagePath page path of the host
 * @param environment environment to build the URL for
 * @returns
 */
export const getHostPublicProfileUrl = (
    pagePath: string,
    environment: Environment,
): string => {
    const baseURL = TrovaTripAppsDomainMap[environment].marketing;
    const hostPublicProfileURL = `${baseURL}/host/profiles/${pagePath}`;

    return hostPublicProfileURL;
};

/**
 * Build the full URL of the profile image (Gumlet domain)
 * @param user user to get the profile image URL for
 * @param environment environment to build the URL for
 * @returns string
 */
export const getProfileImageUrl = (
    user: Pick<User, 'profilePictureUrl'>,
    environment: Environment,
): string => {
    const { profilePictureUrl } = user;
    const baseUrl = TrovaTripAppsDomainMap[environment].images;
    return profilePictureUrl ? `${baseUrl}${profilePictureUrl}` : '';
};

/**
 * Retrieves the maximum earnings advance limit for a host user.
 *
 * @param user
 * @returns The maximum earnings advance limit depending on the user status if the user is defined; otherwise, `undefined`.
 */
export const getMaxEarningsAdvanceLimit = (
    user: User | undefined,
): number | undefined => {
    if (!user) {
        return undefined;
    }

    const isRenewedUser = user.status === UserStatuses.RENEWED;

    return isRenewedUser
        ? MAX_EARNINGS_ADVANCE_LIMIT_RENEWED_HOST
        : MAX_EARNINGS_ADVANCE_LIMIT_NEW_HOST;
};
