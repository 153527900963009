import React, { ReactNode } from 'react';
import {
    MenuList as MenuListChakra,
    MenuListProps as MenuListPropsChakra,
} from '@chakra-ui/react';
import { genDataTestId } from '../../../../shared/utils/helpers';

export interface MenuListProps {
    children?: ReactNode;
}

export interface MenuListImplProps extends MenuListProps, MenuListPropsChakra {}

export const MenuListImpl = (props: MenuListImplProps) => (
    <MenuListChakra
        data-testid={genDataTestId('menu-list')}
        paddingY={2}
        paddingX={0.5}
        borderRadius='md'
        boxShadow={5}
        minWidth='auto'
        {...props}
    />
);

const MenuList = (props: MenuListProps) => <MenuListImpl {...props} />;

export default MenuList;
