import {
    buildInsurancePolicyResponse,
    isInsurancePolicyAddOn,
} from './insurance.utils';
import { constants, models } from '@trova-trip/trova-models';

type AddOn = models.bookings.AddOn;

const AddOnTypes = constants.bookings.AddOnTypes;

/**
 * Build the add-ons response for a given traveler. Insurance policy add-ons
 * belonging to other travelers are filtered out.
 * @param travelerUserId The traveler's user ID
 * @param addOns The booking add-ons
 * @param insuranceClaimsUrl The URL to the insurance claims page
 */
export const buildTravelerAddOnsResponse = (
    travelerUserId: string,
    addOns: AddOn[] | undefined,
    insuranceClaimsUrl: string,
): AddOn[] | undefined => {
    if (!addOns) {
        return;
    }

    const addOnsByTravelerUserId = addOns.filter(
        (addOn) =>
            addOn.user === travelerUserId ||
            addOn.type !== AddOnTypes.INSURANCE_POLICY,
    );
    return buildAddOnsWithInsurancePolicies(
        addOnsByTravelerUserId,
        insuranceClaimsUrl,
    );
};

/**
 * Build the add-ons with populated insurance policies
 * @param addOns The booking add-ons
 * @param insuranceClaimsUrl The URL to the insurance claims page
 */
export const buildAddOnsWithInsurancePolicies = (
    addOns: AddOn[] = [],
    insuranceClaimsUrl: string,
): AddOn[] => {
    return addOns.map((addOn) => {
        if (
            isInsurancePolicyAddOn(addOn) &&
            typeof addOn.insurancePolicy !== 'string'
        ) {
            return {
                ...addOn,
                insurancePolicy: buildInsurancePolicyResponse(
                    addOn.insurancePolicy,
                    insuranceClaimsUrl,
                ),
            };
        }
        return addOn;
    });
};
