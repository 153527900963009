const loadSegment = (segmentApiKey: string) => {
    try {
        if (!window) {
            throw new Error('The Window object is not available');
        }
        const analytics = (window.analytics = window.analytics || []);
        if (!analytics.initialize)
            if (analytics.invoked)
                window.console &&
                    console.error &&
                    console.error('Segment snippet included twice.');
            else {
                analytics.invoked = !0;
                analytics.methods = [
                    'trackSubmit',
                    'trackClick',
                    'trackLink',
                    'trackForm',
                    'pageview',
                    'identify',
                    'reset',
                    'group',
                    'track',
                    'ready',
                    'alias',
                    'debug',
                    'page',
                    'once',
                    'off',
                    'on',
                    'addSourceMiddleware',
                    'addIntegrationMiddleware',
                    'setAnonymousId',
                    'addDestinationMiddleware',
                ];
                analytics.factory = function (methodKey) {
                    return function () {
                        const methods = Array.prototype.slice.call(arguments);
                        methods.unshift(methodKey);
                        // @ts-expect-error
                        analytics.push(methods);
                        return analytics;
                    };
                };
                for (let index = 0; index < analytics.methods.length; index++) {
                    const key = analytics.methods[index];
                    // @ts-expect-error
                    analytics[key] = analytics.factory(key);
                }
                analytics.load = function (key, options) {
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.async = !0;
                    script.src =
                        'https://cdn.segment.com/analytics.js/v1/' +
                        key +
                        '/analytics.min.js';
                    const scriptElement =
                        document.getElementsByTagName('script')[0];
                    // @ts-expect-error
                    scriptElement.parentNode.insertBefore(
                        script,
                        scriptElement,
                    );
                    analytics._loadOptions = options;
                };
                analytics._writeKey = segmentApiKey;
                analytics.SNIPPET_VERSION = '4.15.3';
                analytics.load(segmentApiKey);
                analytics.page();
            }
    } catch (error) {
        console.error('Segment analytics failed to start.', error);
    }
};

export const loadAnalytics = (apiKey: string) => loadSegment(apiKey);
