import React from 'react';

import { isNumber } from 'lodash';
import { AccordionImpl } from '../../../Surfaces/Accordion';

export interface SidebarMenuProps {
    children?: React.ReactNode;
    initialOpenGroupIndex?: number;
}
const SidebarMenu = (props: SidebarMenuProps) => {
    const { initialOpenGroupIndex, children } = props;

    return (
        <AccordionImpl
            paddingBottom={4}
            allowMultiple
            defaultIndex={
                isNumber(initialOpenGroupIndex)
                    ? [initialOpenGroupIndex]
                    : undefined
            }
        >
            {children}
        </AccordionImpl>
    );
};

export default SidebarMenu;
