import React from 'react';
import Accordion from '../../../Surfaces/Accordion';
import Icon from '../../../Media/Icon';
import styled from '@emotion/styled';

const StyledIcon = styled(Icon)<
    Pick<SidebarMenuGroupHeaderProps, 'isExpanded'>
>`
    transition: all 0.5s;
    transform: rotate(${props => (props.isExpanded ? '90' : '0')}deg);
`;

export type SidebarMenuGroupHeaderProps = {
    isExpanded: boolean;
    title: string;
};

const SidebarMenuGroupHeader = ({
    title,
    isExpanded,
}: SidebarMenuGroupHeaderProps) => (
    <Accordion.Header
        data-testid='sidebar__menu'
        padding={4}
        height={12}
        textTransform='uppercase'
        fontSize='sm'
        fontWeight='bold'
        color='neutral.60'
        lineHeight={4}
        text={title}
        rightElement={
            <StyledIcon
                isExpanded={isExpanded}
                size='lg'
                as='arrow-right'
                color='neutral.60'
            />
        }
    />
);

export default SidebarMenuGroupHeader;
