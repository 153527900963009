import { css } from '@emotion/react';
import { theme } from '../../../../../shared/theme';

export default css`
    .rdtSwitch {
        cursor: pointer;
        color: ${theme.colors.blueGray[650]};
        border-radius: ${theme.radii.base};
        font-weight: ${theme.fontWeights.medium};
        margin: 0;
        padding: 0;
        vertical-align: middle;
        width: 100%;
        :hover {
            background-color: ${theme.colors.blue[20]};
        }
    }

    .rdtPrev,
    .rdtNext {
        width: 2.1875rem;
        height: 2.1875rem;
        color: ${theme.colors.blueGray[650]};
        border-radius: ${theme.radii.base};
        cursor: pointer;
        font-size: ${theme.fontSizes['2xl']};
        span {
            line-height: 1.25;
            vertical-align: middle;
        }
        :hover {
            background-color: ${theme.colors.blue[20]};
        }
    }

    .dow {
        border-bottom: 1px solid ${theme.colors.blueGray[300]};
        text-align: center;
        font-size: ${theme.fontSizes.xs};
        font-weight: ${theme.fontWeights.regular};
        padding: ${theme.space[3]} 0 ${theme.space[2]} 0;
        color: ${theme.colors.blueGray[500]};
    }
`;
