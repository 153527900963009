import { models } from '@trova-trip/trova-models';
import {
    CostPerThreshold,
    OptionalCostThresholds,
    CompanionCostThresholds,
    WorkshopCostThresholds,
    CostByDayThresholds,
    BaseCostThresholds,
    CostThresholdWithAllCosts,
    CostConfig,
} from '../PricingCalculator.types';
import { TripPricing } from './TripPricing';
import { BaseTripPricing } from './BaseTripPricing';
import { calculatePredictedPricePerTier } from '../Utils/pricing.utils';

class TripPricingImpl extends BaseTripPricing implements TripPricing {
    constructor(config: CostConfig) {
        super(config);
    }

    public get costsByDay(): CostByDayThresholds[] {
        return this._costs.costsByDay;
    }

    public get baseCosts(): BaseCostThresholds {
        return this._costs.baseCosts;
    }

    public get companionsCosts(): CompanionCostThresholds[] {
        return this._costs.companionsCosts;
    }

    public get suggestedCosts(): CostPerThreshold[] {
        return this._costs.suggestedCosts;
    }

    public get currentCosts(): CostPerThreshold[] {
        return this._costs.currentCosts;
    }

    public get hostSelectedOptionalsCosts(): OptionalCostThresholds[] {
        return this._costs.hostSelectedOptionalsCosts;
    }

    public get workshopsCosts(): WorkshopCostThresholds[] {
        return this._costs.workshopsCosts;
    }

    public get servicesTotalCosts(): CostPerThreshold[] {
        return this._costs.servicesTotalCosts;
    }

    public get singleSupplementCostAdjustments(): CostPerThreshold[] {
        return this._costs.singleSupplementCostAdjustments;
    }

    public get operatorTotalCosts(): CostPerThreshold[] {
        return this._costs.operatorTotalCosts;
    }

    public get singleSupplementFeeCosts(): CostPerThreshold[] {
        return this._costs.singleSupplementFeeCosts;
    }

    public get operatorFee(): number {
        return this._costs.operatorFee;
    }

    public get platformFees(): CostPerThreshold[] {
        return this._costs.platformFees;
    }

    public get travelerTotalCosts(): CostPerThreshold[] {
        return this._costs.travelerTotalCosts;
    }

    public get hostCostsWithFixedCostsAndPrices(): CostThresholdWithAllCosts[] {
        return this._costs.hostCostsWithFixedCostsAndPrices;
    }

    public get hostGroundTransferCost(): CostPerThreshold[] {
        return this._costs.hostGroundTransferCost;
    }

    public addTier(tier: number): void {
        this._addTier(tier);
    }
    public removeTier(tier: number): void {
        this._removeTier(tier);
    }

    public getPredictedPricePerTier(
        costThresholds: models.itineraries.CostThreshold[],
        tier: number,
    ): CostPerThreshold {
        return calculatePredictedPricePerTier(
            costThresholds,
            tier,
        );
    }
}

export default TripPricingImpl;
