import {
    TableHeadProps as TableHeadPropsChakra,
    Thead as TheadChakra,
} from '@chakra-ui/react';
import React from 'react';

export const TableHeadImpl = (props: TableHeadPropsChakra) => (
    <TheadChakra {...props} />
);

const TableHead = (props: TableHeadPropsChakra) => <TableHeadImpl {...props} />;

export default TableHead;
