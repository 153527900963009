import {
    Environment,
    isProduction,
    TrovaTripAppsDomainMap,
} from './app.constants';

export enum TrovaTripMicroAppsConfigKeys {
    TROVA_CATALOG = 'TROVA_CATALOG',
    TROVA_TRIP_PAGES = 'TROVA_TRIP_PAGES',
    TROVA_HOST_PROFILE = 'TROVA_HOST_PROFILE',
}

export enum MicroAppsModuleTypes {
    esm = 'esm',
    umd = 'umd',
}

interface MicroAppConfig {
    name: string;
    uri: (environment: Environment) => string;
    moduleType: MicroAppsModuleTypes;
}

interface TripPagesConfig extends MicroAppConfig {
    trovaTripPagesUrl: (environment: Environment) => string;
}

interface HostProfileConfig extends MicroAppConfig {
    trovatripHostProfileUrl: (environment: Environment) => string;
}

export interface TrovaTripMicroAppsConfig {
    [key: string]: MicroAppConfig;
    [TrovaTripMicroAppsConfigKeys.TROVA_TRIP_PAGES]: TripPagesConfig;
    [TrovaTripMicroAppsConfigKeys.TROVA_HOST_PROFILE]: HostProfileConfig;
}

export const TrovaTripMicroApps: TrovaTripMicroAppsConfig = {
    [TrovaTripMicroAppsConfigKeys.TROVA_CATALOG]: {
        name: 'trova-catalog',
        uri: (environment) =>
            `${TrovaTripAppsDomainMap[environment].microapps}/app/trova-catalog/bootstrap.js`,
        moduleType: MicroAppsModuleTypes.esm,
    },
    [TrovaTripMicroAppsConfigKeys.TROVA_TRIP_PAGES]: {
        name: 'trova-trip-pages',
        uri: (environment) =>
            `${TrovaTripAppsDomainMap[environment].microapps}/app/trova-trip-pages/trova-app.js`,
        trovaTripPagesUrl: (environment) =>
            `${TrovaTripAppsDomainMap[environment].marketing}/${
                isProduction(environment) ? 'trip' : 'sb-trips'
            }`,
        moduleType: MicroAppsModuleTypes.esm,
    } as TripPagesConfig,
    [TrovaTripMicroAppsConfigKeys.TROVA_HOST_PROFILE]: {
        name: 'trova-host-profile',
        uri: (environment) =>
            `${TrovaTripAppsDomainMap[environment].microapps}/app/trova-host-profile/trova-app.js`,
        trovatripHostProfileUrl: (environment) =>
            `${TrovaTripAppsDomainMap[environment].marketing}/host/profiles`,
        moduleType: MicroAppsModuleTypes.esm,
    } as HostProfileConfig,
};
