import React from 'react';
import { AccordionItem } from '../../../Surfaces/Accordion';
import SidebarMenuGroupHeader from '../SidebarMenuGroupHeader/SidebarMenuGroupHeader';
import SidebarMenuGroupPanel from '../SidebarMenuGroupPanel/SidebarMenuGroupPanel';
import { SidebarMenuGroupProps } from './SidebarMenuGroup.types';

const SidebarMenuGroup = ({ title, items }: SidebarMenuGroupProps) => (
    <AccordionItem key={title}>
        {({ isExpanded }) => (
            <>
                <SidebarMenuGroupHeader title={title} isExpanded={isExpanded} />
                <SidebarMenuGroupPanel items={items} />
            </>
        )}
    </AccordionItem>
);

export default SidebarMenuGroup;
