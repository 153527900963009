import { css } from '@emotion/react';
import type { Theme } from '../../../../shared/theme';

export const disabledSpan = (theme: Theme) => css`
    box-sizing: border-box;
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeights.bold};
    line-height: 16px;
    padding-top: 9px;
    color: ${theme.colors.neutral[60]};
    display: block;
`;

export const errorSpan = (theme: Theme) => css`
    box-sizing: border-box;
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeights.bold};
    line-height: 16px;
    padding-top: 9px;
    color: ${theme.colors.red.trova};
    display: block;
`;
