import React from 'react';
import { DataAttrsType } from '../../../../shared/theme/interfaces/Base.types';
import { DesignTokens } from '../../../../shared/theme/types';
import { StackImpl } from '../../../Layout/Stack';
import Icon, { IconProps } from '../../../Media/Icon';
import Text from '../../../Typography/Text';

export type ItemLabelProps = {
    leftIconProps?: Pick<IconProps, 'color' | 'as'>;
    children: string;
    [dataAttributeKey: DataAttrsType]: unknown;
    color?: DesignTokens['colors'];
};

export const ItemLabel = (props: ItemLabelProps) => {
    const { leftIconProps, children, color = 'blueGray.500' } = props;

    return (
        <StackImpl paddingTop={1} alignItems='center' spacing={1}>
            {leftIconProps ? (
                <Icon display='inline-flex' {...leftIconProps} />
            ) : null}
            {children ? (
                <Text
                    fontSize='sm'
                    fontWeight='medium'
                    lineHeight={5}
                    color={color}
                >
                    {children}
                </Text>
            ) : null}
        </StackImpl>
    );
};

export default ItemLabel;
