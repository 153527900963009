import React from 'react';
import { GridImpl, GridItemImpl } from '../../Layout/Grid';
import useMainContentConfig from './useMainContentConfig';
import { MainLayoutAreas, MainContentProps } from './MainContent.types';
import { genDataTestId } from '../../../shared/utils/helpers';

type MainContentImplProps = MainContentProps & {
    defaultPlacement: 'left' | 'right';
    testId: string;
};

export const MainContentImpl = ({
    sidebarElement,
    testId = 'content',
    sidebarConfig,
    defaultPlacement,
    children,
    columnGap,
    ...rest
}: MainContentImplProps) => {
    const showSideBar = !!sidebarElement;
    const { gridTemplateAreas, gridTemplateColumns } = useMainContentConfig(
        defaultPlacement,
        sidebarConfig,
        showSideBar
    );

    return (
        <GridImpl
            data-testid={genDataTestId(`main-${testId}-layout`)}
            gap={columnGap}
            maxWidth='container.2xl'
            gridAutoRows='max-content'
            width='full'
            height='full'
            gridTemplateAreas={gridTemplateAreas}
            gridTemplateColumns={gridTemplateColumns}
            {...rest}
        >
            {showSideBar ? (
                <GridItemImpl
                    area={MainLayoutAreas.sidebar}
                    data-testid={genDataTestId(`main-${testId}-sidebar`)}
                >
                    {sidebarElement}
                </GridItemImpl>
            ) : null}

            <GridItemImpl
                area={MainLayoutAreas.main}
                data-testid={genDataTestId(`main-${testId}-body`)}
            >
                {children}
            </GridItemImpl>
        </GridImpl>
    );
};
