import { InternalLoggerProvider } from './LoggerProvider';
import { Logger } from './Logger.types';

/**
 * Factory to create loggers. All loggers should be obtained through here.
 */
export class LoggerFactory {
    static create(name: string): Logger {
        return InternalLoggerProvider.getInstance().getLogger().child({ name });
    }
}
