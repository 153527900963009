import { css } from '@emotion/react';
import { theme, Theme } from '../../../shared/theme';
import { ComponentWidth } from '../../Legacy/Forms/Input';

export const mainContainer = (theme: Theme, size: ComponentWidth) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    ${size === ComponentWidth.ExtraSmall && `width:89px;`}
    ${size === ComponentWidth.Small && `width: 157px;`}
    ${size === ComponentWidth.Medium && `width: 250px;`}
    ${size === ComponentWidth.Large && `width: 343px;`}
    ${size === ComponentWidth.ExtraLarge && `width: 436px;`}
    ${size === ComponentWidth.Flexible && `width: 100%;`}
    @media (max-width: ${theme.breakpoints.sm}) {
        width: 100%;
    }
`;

export const inputContainer = (
    theme: Theme,
    error: string | string[] | undefined,
    size: ComponentWidth,
    disabled: boolean
) => css`
    box-sizing: border-box;
    border-radius: ${theme.radii.md};
    border: ${disabled
        ? `1px solid ${theme.colors.blueGray[400]}`
        : `1px solid ${theme.colors.blueGray[300]}`};
    ${size !== ComponentWidth.ExtraSmall && `height: 2.5rem;`}
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.space[2]} ${theme.space[4]};
    font-size: ${theme.fontSizes.base};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.space[5]};
    color: ${disabled
        ? theme.colors.blueGray[400]
        : theme.colors.blueGray[650]};
    background-color: ${disabled
        ? theme.colors.blueGray[50]
        : theme.colors.neutral.white};
    ${disabled === false && `cursor: pointer;`}
    ${!error &&
    `
    :focus-within {
        border: 1px solid ${theme.colors.blue[600]};
    }`}
    ${size === ComponentWidth.ExtraSmall &&
    `border-radius: 5px;padding: 5px 9px; font-size: ${theme.fontSizes.sm};height: 31px;margin-top: 0px;line-height: 16px;`}
    ${error && error.length && `border: 1px solid ${theme.colors.red.trova};`}
    ${size === ComponentWidth.Flexible && `width: 100%;`}
`;

export const input = (theme: Theme, size: ComponentWidth) => css`
    box-sizing: border-box;
    font-family: ${theme.fonts.roboto};
    font-size: ${theme.fontSizes.base};
    color: ${theme.colors.blueGray[650]};
    border: transparent;
    cursor: pointer;
    ::-webkit-search-decoration,
    ::-webkit-search-cancel-button,
    ::-webkit-search-results-button,
    ::-webkit-search-results-decoration {
        display: none;
    }
    :disabled {
        background: transparent;
        color: ${theme.colors.blueGray[400]};
        cursor: default;
    }
    :focus {
        border: transparent;
    }
    :focus-visible {
        outline: 0;
    }
    ${size === ComponentWidth.Small && `width:99px;`}
    ${size === ComponentWidth.Medium && `width:190px;`}
    ${size === ComponentWidth.ExtraSmall && `width:50px;`}
    ${size === ComponentWidth.Flexible && `width:100%;`}
    @media (max-width: ${theme.breakpoints.sm}) {
        width: 100%;
    }
`;

export const inputContainerMultipleDropdown = (
    theme: Theme,
    error: string | string[] | undefined,
    listVisible: boolean,
    size: ComponentWidth,
    disabled: boolean
) => css`
    box-sizing: border-box;
    border-radius: ${theme.radii.md};
    ${size !== ComponentWidth.ExtraSmall && `height: 2.5rem;`}
    border: ${disabled
        ? `1px solid ${theme.colors.blueGray[400]}`
        : `1px solid ${theme.colors.blueGray[300]}`};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.space[2]} ${theme.space[4]};
    font-size: ${theme.fontSizes.base};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.lineHeights[5]};
    color: ${disabled
        ? theme.colors.blueGray[400]
        : theme.colors.blueGray[650]};
    background-color: ${disabled
        ? theme.colors.blueGray[50]
        : theme.colors.neutral.white};
    ${!error && listVisible && `border: 1px solid ${theme.colors.blue[600]};`}
    ${error && error.length && `border: 1px solid ${theme.colors.red.trova};`}
    :disabled {
        background-color: transparent;
        color: ${theme.colors.blueGray[400]};
        cursor: default;
    }
    ${size === ComponentWidth.ExtraSmall &&
    `height:31px; border-radius: 5px;
    font-size: ${theme.fontSizes.sm};`}
    ${size === ComponentWidth.Small && `width:159px;`}
    ${size === ComponentWidth.Medium && `width:249px;`}
    ${size === ComponentWidth.Flexible && `width: 100%;`}
    @media (max-width: ${theme.breakpoints.sm}) {
        width: 100%;
    }
    input {
        display: none;
    }
`;

export const inputMultipleDropdown = (theme: Theme) => css`
    box-sizing: border-box;
    font-size: ${theme.fontSizes.base};
    line-height: ${theme.lineHeights[5]};
    color: ${theme.colors.blueGray[650]};
    border: transparent;
    float: right;
    :disabled {
        background-color: ${theme.colors.neutral.white};
    }
    :focus {
        border: transparent;
    }

    min-width: 95px;
    flex: 1;
    display: block;
    padding: 0 0 ${theme.space[1]} ${theme.space[1]};
`;

export const buttonsContainer = (size: ComponentWidth) =>
    css`
        padding-top: 0.125rem;
        height: 22px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        ${size === ComponentWidth.Small && `max-width:95px;`}
        ${size === ComponentWidth.Medium && `max-width:190px;`}
        ${size === ComponentWidth.Large && `max-width:285px;`}
        ${size === ComponentWidth.ExtraLarge && `max-width:380px;`}
    `;

export const button = () =>
    css`
        background: ${theme.colors.neutral[80]};
        padding: 2px;
        margin: 0 5px 5px 0;
        border-radius: 3px;
        display: flex;
    `;

export const closeButton = () => css`
    cursor: pointer;
    width: 0.8rem;
    margin-left: 2px;
`;

export const iconContainer = (
    disabled: boolean,
    size: ComponentWidth,
    multiple: boolean
) => css`
    ${disabled === false && `cursor: pointer;`}
    margin-top: 2px;
    ${size === ComponentWidth.ExtraSmall && `margin: 2px 0 0 0;`}
    ${size === ComponentWidth.ExtraSmall && multiple && `margin: 2px 0 0 0; `}
`;

export const loadingIcon = () => css`
    height: 1rem;
    margin-right: 20px;
`;
