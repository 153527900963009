/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

import { IconSize } from './IconSize';
import IconProps from './Icon.types';
import { icon } from './Icon.styles';
import { theme } from '../../../../shared/theme';

const Icon: React.FC<IconProps> = ({
    name,
    size = IconSize.Md,
    color = theme.colors.blueGray[650],
    className,
    title = '',
}) => {
    return (
        <svg
            role="img"
            aria-label={title}
            data-testid="icon"
            css={icon(name, size, color)}
            className={className}
        >
            <title>{title}</title>
        </svg>
    );
};

export default Icon;
