import { models } from '@trova-trip/trova-models';
import TripPricingImpl from '../TripPricing/TripPricingImpl';
import { TripInventoryPricingImpl } from '../TripInventoryPricing/TripInventoryPricingImpl';
import { TripPricing } from '../TripPricing/TripPricing';
import {
    TripCostDTO,
    TripRequestCostDTO,
    ItineraryCostDTO,
    CostConfig,
    CostConfiguration,
    CalculationType,
} from '../PricingCalculator.types';
import { TripPricingBuilder } from './TripPricingBuilder';
import {
    getWorkshops,
    validateParams,
    getValidityPeriodsByDay,
    getValidityPeriodsFromSelectedPackage,
    getNumberOfTravelersPerThreshold,
} from '../Utils/builder.utils';
import {
    getRoomQuantityForSingleSupplement,
    getAdjustedTiersForCompanions,
} from '../Utils/pricing.utils';
import { convertToCalculatorThresholds } from '../Utils/common.utils';

class TripPricingBuilderImpl implements TripPricingBuilder {
    private _trip: TripCostDTO | undefined;
    private _tripRequest: TripRequestCostDTO | undefined;
    private _itinerary: ItineraryCostDTO | undefined;

    public setTripWithPopulateData(trip: TripCostDTO): TripPricingBuilder {
        this._trip = trip;
        return this;
    }

    public setItineraryWithPopulateData(
        itinerary: ItineraryCostDTO,
    ): TripPricingBuilder {
        this._itinerary = itinerary;
        return this;
    }

    public setTripRequestWithPopulateData(
        tripRequest: TripRequestCostDTO,
    ): TripPricingBuilder {
        this._tripRequest = tripRequest;
        return this;
    }

    /**
     * @deprecated tripPricing no longer handle currency conversion
     */
    public setCurrency(
        currency: models.currencies.Currency,
    ): TripPricingBuilder {
        return this;
    }

    public build(): TripPricing {
        if (this._trip && this._trip.itineraryInventoryItem) {
            return new TripInventoryPricingImpl(
                this.buildWithTripAndInventory(this._trip),
            );
        }

        if (
            this._tripRequest &&
            this._tripRequest.itineraryInventoryItem &&
            this._itinerary
        ) {
            return new TripInventoryPricingImpl(
                this.buildWithTripRequestAndInventory(
                    this._tripRequest,
                    this._itinerary,
                ),
            );
        }

        if (this._trip) {
            return new TripPricingImpl(this._buildWithTrip(this._trip));
        }

        if (this._tripRequest && this._itinerary) {
            return new TripPricingImpl(
                this._buildWithTripRequest(this._tripRequest, this._itinerary),
            );
        }

        throw new Error(
            'Error: You need to provide a trip or a tripRequest and an itinerary to get the trip costs',
        );
    }

    // #region private methods
    private _buildWithTrip(trip: TripCostDTO): CostConfig {
        const {
            host,
            companions = [],
            yearOverYearIncrease = 0,
            itinerary,
            startDate,
            servicesByDay,
            hostSelectedOptionalServices = [],
            costThresholds: costThresholdsFromTrip,
            additionalCosts: additionalCostsFromTrip,
            hostRooms = [],
            prices,
            minimumSpots,
            selectedPackage,
        } = trip;

        const validityPeriods = getValidityPeriodsFromSelectedPackage(
            itinerary,
            selectedPackage,
        );

        const tripLength = servicesByDay?.length;
        const workshops = getWorkshops(trip);

        validateParams(
            validityPeriods,
            startDate,
            tripLength,
            hostSelectedOptionalServices,
        );

        const validityPeriodsByDayFromItinerary = getValidityPeriodsByDay(
            validityPeriods!,
            startDate!,
            tripLength!,
            yearOverYearIncrease,
        );

        const numberOfTravelersPerThreshold = getNumberOfTravelersPerThreshold(
            validityPeriodsByDayFromItinerary[0].validityPeriod.costThresholds,
            companions,
            costThresholdsFromTrip,
        );

        const singleSupplementPrice =
            additionalCostsFromTrip?.singleSupplement?.price || 0;

        const hostGroundTransferCost =
            additionalCostsFromTrip?.hostGroundTransferCost || 0;

        const isHosted = Boolean(host?.length);

        return {
            hostSelectedOptionalServices,
            companions,
            startDate: startDate!,
            yearOverYearIncrease,
            tripLength: tripLength!,
            workshops,
            validityPeriodsByDay: validityPeriodsByDayFromItinerary,
            numberOfTravelersPerThreshold,
            singleSupplementPrice,
            costThresholdsFromTrip,
            hostRooms,
            isHosted,
            prices,
            minimumSpots,
            hostGroundTransferCost,
        };
    }

    private _buildWithTripRequest(
        tripRequest: TripRequestCostDTO,
        itinerary: ItineraryCostDTO,
    ): CostConfig {
        const {
            host,
            companions = [],
            startDate,
            tripLength,
            hostSelectedOptionalServices = [],
            itinerary: tripRequestItinerary,
            hostRooms = [],
            selectedPackage,
        } = tripRequest;
        const {
            yearOverYearIncrease = 0,
            currency,
            hostGroundTransferCost = 0,
        } = itinerary;
        const workshops = getWorkshops(undefined, tripRequest);

        const periods = getValidityPeriodsFromSelectedPackage(
            tripRequestItinerary || itinerary,
            selectedPackage,
        );

        const yoy =
            tripRequestItinerary?.yearOverYearIncrease || yearOverYearIncrease;

        validateParams(
            periods,
            startDate,
            tripLength,
            hostSelectedOptionalServices,
        );

        const validityPeriodsByDayFromItinerary = getValidityPeriodsByDay(
            periods!,
            new Date(startDate!),
            tripLength!,
            yoy,
        );

        const numberOfTravelersPerThreshold = getNumberOfTravelersPerThreshold(
            validityPeriodsByDayFromItinerary[0].validityPeriod.costThresholds,
            companions,
        );

        const singleSupplementPrice =
            validityPeriodsByDayFromItinerary[0].validityPeriod.additionalCosts
                .singleSupplement.price || 0;

        const isHosted = Boolean(host);

        return {
            hostSelectedOptionalServices,
            companions,
            startDate: new Date(startDate!),
            yearOverYearIncrease: yoy,
            tripLength: tripLength!,
            workshops,
            validityPeriodsByDay: validityPeriodsByDayFromItinerary,
            numberOfTravelersPerThreshold,
            singleSupplementPrice,
            hostRooms,
            isHosted,
            hostGroundTransferCost,
        };
    }

    private buildWithTripAndInventory(trip: TripCostDTO): CostConfiguration {
        const {
            host,
            startDate,
            costThresholds,
            companions,
            servicesByDay,
            additionalCosts,
            minimumSpots,
            prices,
            hostRooms,
            hostSelectedOptionalServices = [],
        } = trip;
        const { singleSupplement, hostGroundTransferCost = 0 } =
            additionalCosts || {};

        if (!startDate) {
            throw new Error('TRIP PRICING: Trip must have a start date');
        }

        if (!costThresholds) {
            throw new Error(
                'TRIP PRICING: Trip must have cost thresholds defined',
            );
        }

        const focQuantity = (companions?.length ?? 0) + (host?.length ?? 0);
        const tripLength = servicesByDay?.length ?? 0;
        const singleSupplementPrice = singleSupplement?.price ?? 0;
        const workshops = getWorkshops(trip);
        const costSchedule = convertToCalculatorThresholds(costThresholds);
        const roomsQuantity = getRoomQuantityForSingleSupplement(hostRooms);

        return {
            startDate: new Date(startDate),
            focQuantity,
            tripLength,
            roomsQuantity,
            minimumSpots,
            prices,
            singleSupplementPrice,
            hostGroundTransferCost,
            workshops,
            costSchedule,
            hostSelectedOptionalServices,
        };
    }

    private buildWithTripRequestAndInventory(
        tripRequest: TripRequestCostDTO,
        itinerary: ItineraryCostDTO,
    ): CostConfiguration {
        const {
            host,
            companions = [],
            startDate,
            tripLength,
            hostSelectedOptionalServices = [],
            hostRooms = [],
            itineraryInventoryItem,
        } = tripRequest;
        const { hostGroundTransferCost = 0 } = itinerary;
        const { singleSupplementPrice = 0, costSchedule = [] } =
            itineraryInventoryItem || {};

        if (!startDate) {
            throw new Error(
                'TRIP PRICING: Trip Request must have a start date',
            );
        }

        if (!tripLength) {
            throw new Error(
                'TRIP PRICING: Trip Request must have a trip length',
            );
        }

        const focQuantity = (companions?.length ?? 0) + (host ? 1 : 0);
        const workshops = getWorkshops(undefined, tripRequest);
        const costThresholds = getAdjustedTiersForCompanions(
            costSchedule,
            focQuantity,
            CalculationType.INVENTORY_ITEM,
        );
        const costSchedules = convertToCalculatorThresholds(costThresholds);
        const roomsQuantity = getRoomQuantityForSingleSupplement(hostRooms);

        return {
            startDate: new Date(startDate),
            focQuantity,
            tripLength,
            roomsQuantity,
            singleSupplementPrice,
            hostGroundTransferCost,
            workshops,
            costSchedule: costSchedules,
            hostSelectedOptionalServices,
        };
    }
    // #endregion
}

export default TripPricingBuilderImpl;
