import React from 'react';
import {
    Menu as MenuChakra,
    MenuProps as MenuPropsChakra,
    MenuButton as MenuButtonChakra,
} from '@chakra-ui/react';
import { MenuButton, MenuList, MenuListItem } from './components';
import type { MenuListItemProps } from './components';
import { genDataTestId } from '../../../shared/utils/helpers';
import { IconProps } from '../../Media/Icon';
import { IconButtonProps } from '../../../components/Forms/IconButton';
import { MenuIconButton } from './components/MenuButton';

type MenuSharedProps = {
    onOpen?: MenuPropsChakra['onOpen'];
    onClose?: MenuPropsChakra['onClose'];
    defaultIsOpen?: MenuPropsChakra['defaultIsOpen'];
    options: MenuListItemProps[];
    closeOnSelect?: MenuPropsChakra['closeOnSelect'];
    onChange?: (value: string) => void;
    justifyItems?: MenuListItemProps['justify'];
};

type DefaultMenuProps = {
    variant?: 'default';
    label: string;
    rightIcon?: IconProps['as'];
    leftIcon?: IconProps['as'];
} & MenuSharedProps;

type IconButtonMenuProps = {
    variant?: 'iconButton';
    iconButtonProps: IconButtonProps;
} & MenuSharedProps;

export type MenuProps = DefaultMenuProps | IconButtonMenuProps;

export type MenuImplProps = Omit<MenuPropsChakra, 'children'> & MenuProps;

const getMenuButtonProps = (props: MenuProps) => {
    if (props.variant === 'iconButton') {
        const { iconButtonProps } = props as IconButtonMenuProps;
        return {
            as: MenuIconButton,
            ...iconButtonProps,
        };
    } else {
        const { leftIcon, rightIcon, label } = props as DefaultMenuProps;
        return {
            as: MenuButton,
            children: label,
            leftIcon,
            rightIcon,
        };
    }
};

export const MenuImpl = (props: MenuImplProps) => {
    const {
        options,
        onChange,
        closeOnSelect = true,
        justifyItems = 'center',
        variant = 'default',
        ...rest
    } = props;

    const menuButtonProps = getMenuButtonProps(props);

    return (
        <MenuChakra
            {...rest}
            closeOnSelect={closeOnSelect}
            gutter={2}
            data-testid={genDataTestId('menu')}
            placement='bottom-end'
        >
            <MenuButtonChakra {...(menuButtonProps as IconButtonProps)} />
            <MenuList>
                {options.map(({ onClick, ...rest }) => (
                    <MenuListItem
                        key={rest.value}
                        onClick={value => {
                            onChange && onChange(value);
                            onClick && onClick(value);
                        }}
                        {...rest}
                        justify={justifyItems}
                    />
                ))}
            </MenuList>
        </MenuChakra>
    );
};

const Menu = (props: MenuProps) => <MenuImpl {...props} />;

export default Menu;
