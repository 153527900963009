import { css } from '@emotion/react';
import { theme } from '../../../../../shared/theme';

export default css`
    .rdtPicker {
        position: absolute;
        margin-top: ${theme.space[1]};
        z-index: 99999 !important;
        box-shadow: ${theme.shadows[5]};
        border: none;
        width: 15.625rem;
    }
`;
