/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Moment } from 'moment';
import { forwardRef } from 'react';
import { useTheme } from '../../../shared/hooks';
import { ComponentWidth } from '../../Legacy/Forms/Input/ComponentWidth';
import {
    StyledDateTime,
    detailSpan,
    disabledSpan,
    errorSpan,
    labelContainer,
    labelSpan,
    mainContainer,
} from './DateTime.styles';
import { DateTimeComponentProps } from './DateTime.types';

const DateTimeComponent = forwardRef(
    (
        {
            placeholder,
            onChange,
            onCalendarClose,
            error,
            errorVariant,
            size = ComponentWidth.Medium,
            label,
            disabled,
            disabledText,
            detail,
            className,
            value,
            timeFormat,
            dateFormat,
            renderInput,
            dateTimeInputProps,
            input,
            style,
        }: DateTimeComponentProps,
        ref
    ) => {
        const theme = useTheme();
        const disabledProp = disabled ? true : false;
        const { selectableDates } = dateTimeInputProps || {};

        const isRestricted =
            selectableDates?.dates && selectableDates.dates.length > 0;

        const isValidDate = (current: Moment) => {
            if (!isRestricted) {
                return true;
            }

            const { dates, isSelectable } = selectableDates!;

            const restrictionFound = dates.some(restriction => {
                if (Array.isArray(restriction)) {
                    const [start, end] = restriction;
                    return current.isBetween(start, end, undefined, '[]');
                } else {
                    return current.isSame(restriction, 'day');
                }
            });

            return isSelectable ? restrictionFound : !restrictionFound;
        };

        return (
            <div css={mainContainer(size)} className={className} style={style}>
                {label && (
                    <div css={labelContainer()}>
                        <span css={labelSpan(theme)}>{label}</span>
                    </div>
                )}
                <StyledDateTime
                    ref={ref}
                    isValidDate={isRestricted ? isValidDate : undefined}
                    timeFormat={timeFormat}
                    dateFormat={dateFormat}
                    inputProps={{ placeholder, disabled: disabledProp }}
                    disabled={disabledProp}
                    onChange={onChange}
                    onClose={onCalendarClose}
                    error={error}
                    errorVariant={errorVariant}
                    value={value}
                    renderInput={renderInput}
                    input={input}
                />
                {detail && !error && (
                    <span css={detailSpan(theme)}>{detail}</span>
                )}
                {disabled && disabledText && (
                    <span css={disabledSpan()}>{disabledText}</span>
                )}
                {error && typeof error === 'string' && (
                    <span css={errorSpan(theme, errorVariant)}>{error}</span>
                )}
                {error && Array.isArray(error) && (
                    <span css={errorSpan(theme, errorVariant)}>
                        {error.reduce((a: string, b: string) => {
                            return a.concat(', ').concat(b);
                        })}
                    </span>
                )}
            </div>
        );
    }
);

export default DateTimeComponent;
