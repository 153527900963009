import { css } from '@emotion/react';
import { theme, Theme } from '../../../../shared/theme';
import { ComponentWidth } from './ComponentWidth';

const getComponentWidth = (size: ComponentWidth): string => {
    switch (size) {
        case ComponentWidth.ExtraSmall:
            return 'min-width: 64px; max-width:100px;';
        case ComponentWidth.Small:
            return 'width: 157px;';
        case ComponentWidth.Medium:
            return 'width: 250px;';
        case ComponentWidth.Large:
            return 'width: 343px;';
        case ComponentWidth.ExtraLarge:
            return 'width: 436px;';
        default:
        case ComponentWidth.Flexible:
            return 'width: 100%;';
    }
};

const baseInputStyles = (...props: any) => {
    const [theme, error, size]: [Theme, string[], ComponentWidth] = props;
    return css`
        box-sizing: border-box;
        border-radius: 10px;
        border: 1px solid ${theme.colors.neutral[80]};
        min-height: 50px;
        margin-top: 10px;
        padding: 0.5rem 0.75rem;
        font-family: ${theme.fonts.roboto};
        font-size: ${theme.fontSizes.base};
        line-height: 19px;
        background-color: ${theme.colors.neutral.white};
        ${size === ComponentWidth.ExtraSmall &&
        `border-radius: 5px;padding: 5px 9px; width: 100%;font-size: ${theme.fontSizes.sm};min-height: 31px;margin-top: 0px;line-height: 16px;`}
        color: ${theme.colors.blueGray[650]};
        ${!error &&
        `:focus {
        border: 1px solid ${theme.colors.blue[500]};
        }`}
        :disabled {
            background-color: ${theme.colors.blueGray[100]};
        }
        ${error && error.length && `border-color: ${theme.colors.red.trova};`}

        ${getComponentWidth(size)};

        @media (max-width: ${theme.breakpoints.sm}) {
            width: 100%;
        }
    `;
};
export const mainContainer = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    @media (max-width: ${theme.breakpoints.sm}) {
        width: 100%;
    }
`;

export const input = (
    theme: Theme,
    error: string | string[] | undefined,
    size: ComponentWidth
) => css`
    && {
        ${baseInputStyles(theme, error, size)}
    }
`;

export const labelContainer = (size: ComponentWidth) => css`
    display: flex;
    justify-content: space-between;
    height: 1rem;

    ${getComponentWidth(size)};
`;

export const requiredGlyph = css`
    &&::after {
        content: '*';
        color: ${theme.colors.red.trova};
        font-weight: 500;
        display: inline-block;
        margin-left: 5px;
    }
`;
export const labelSpan = (theme: Theme, required: boolean = false) => css`
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeights.medium};
    line-height: 16px;
    color: ${theme.colors.blueGray.dark};
    ${required && requiredGlyph.styles}
`;

export const infoImageContainer = () => css`
    position: relative;
    input {
        display: none;
    }
    input:checked + span {
        visibility: visible;
        opacity: 1;
    }
`;

export const infoImage = () => css`
    width: 1.2rem;
    height: 1.2rem;
    position: relative;
    display: inline-block;
`;

export const tooltip = (theme: Theme) => css`
    visibility: hidden;
    width: 160px;
    background-color: ${theme.colors.blueGray[650]};
    color: ${theme.colors.neutral.white};
    font-size: ${theme.fontSizes.xs};
    text-align: center;
    padding: 1rem;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 100%;
    right: 25%;
    margin-left: -80px;
    opacity: 0;
    transition: opacity 0.3s;
`;

export const detailSpan = (theme: Theme) => css`
    font-size: ${theme.fontSizes.sm};
    line-height: 16px;
    margin-top: 9px;
    color: ${theme.colors.blueGray.dark};
`;

export const disabledSpan = (theme: Theme) => css`
    box-sizing: border-box;
    font-size: ${theme.fontSizes.sm};
    line-height: 16px;
    padding-top: 9px;
    color: ${theme.colors.blueGray[650]};
`;

export const errorSpan = (theme: Theme) => css`
    box-sizing: border-box;
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeights.bold};
    line-height: 16px;
    padding-top: 9px;
    color: ${theme.colors.red.trova};
`;

export const inputContainer = (
    theme: Theme,
    error: string | string[] | undefined
) => css`
    && {
        box-sizing: border-box;
        border-radius: 10px;
        border: 1px solid ${theme.colors.neutral[80]};
        min-height: 50px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 0.75rem;
        font-size: ${theme.fontSizes.base};
        line-height: 19px;
        color: ${theme.colors.blueGray[650]};
        background-color: ${theme.colors.neutral.white};
        ${!error &&
        `
        :focus-within {
            border: 1px solid ${theme.colors.blue[500]};
        }`}
        ${error && error.length && `border-color: ${theme.colors.red.trova};`}
        svg {
            margin-left: 12px;
        }
    }
`;

export const inputWithIcon = (theme: Theme, size: ComponentWidth) => css`
    box-sizing: border-box;
    font-size: ${theme.fontSizes.base};
    line-height: 19px;
    color: ${theme.colors.blueGray[650]};
    border: transparent;
    width: 90%;
    :disabled {
        background-color: ${theme.colors.neutral.white};
    }
    :focus {
        border: transparent;
    }
    ${size === ComponentWidth.Small && `width:99px;`}
    ${size === ComponentWidth.Medium && `width:190px;`}
    @media (max-width: ${theme.breakpoints.sm}) {
        width: 100%;
    }
`;
