import {
    createGoogleContextTemplate,
    createFacebookContextTemplate,
} from './templates';
import * as templateTypes from './context.types';

import create from './create';

const templates = {
    createGoogleContextTemplate,
    createFacebookContextTemplate,
};

const context = {
    create,
};

export { templates, context };
export type { templateTypes };
