import React from 'react';
import BaseBox, { BaseBoxProps } from '../../Layout/BaseBox';
import { MainContentProps } from './MainContent.types';
import { MainContentImpl } from './MainContentImpl';

type MainInnerContent = Omit<
    MainContentProps,
    'sidebarConfig' | 'templateColumns'
> & {
    sidebarConfig?: MainContentProps['sidebarConfig'] & {
        isSticky?: boolean;
        offsetTop?: number;
    };
};

const MainInnerContent = ({
    columnGap = 6,
    sidebarElement,
    children,
    sidebarConfig,
    ...rest
}: MainInnerContent) => {
    const showSidebar = !!sidebarElement;
    const {
        isSticky = true,
        offsetTop = 2,
        sidebarColumnWidth = 250,
        ...sidebarConfigRest
    } = sidebarConfig || {};

    const stickyStyles = isSticky
        ? {
              position: {
                  base: 'static',
                  '2lg': 'sticky',
              },

              top: offsetTop || {
                  base: 2,
              },
          }
        : {};

    return (
        <MainContentImpl
            testId='inner-content'
            defaultPlacement='right'
            sidebarElement={
                showSidebar ? (
                    <BaseBox {...(stickyStyles as BaseBoxProps)}>
                        {sidebarElement}
                    </BaseBox>
                ) : null
            }
            sidebarConfig={{ sidebarColumnWidth, ...sidebarConfigRest }}
            {...rest}
        >
            {children}
        </MainContentImpl>
    );
};

export default MainInnerContent;
