import { Logger as PinoLogger } from 'pino';
import { LogLevel } from '../LogLevel';
import { Logger, LoggerConfig } from '../Logger.types';

export interface LoggerChildOptions {
    name: string;
}

export abstract class BaseLogger implements Logger {
    protected readonly internalLogger: PinoLogger;

    constructor(config: LoggerConfig);
    constructor(pinoLogger: PinoLogger);
    constructor(options: LoggerConfig | PinoLogger) {
        this.internalLogger = this.isLoggerConfig(options)
            ? this.createLogger(options)
            : options;
    }

    protected abstract createLogger(config: LoggerConfig): PinoLogger;

    abstract child(options: LoggerChildOptions): BaseLogger;

    getInternalLogger(): PinoLogger {
        return this.internalLogger;
    }

    error(message: string, ...args: unknown[]): void {
        return this.log(LogLevel.ERROR, message, ...args);
    }

    warn(message: string, ...args: unknown[]): void {
        return this.log(LogLevel.WARN, message, ...args);
    }

    info(message: string, ...args: unknown[]): void {
        return this.log(LogLevel.INFO, message, ...args);
    }

    debug(message: string, ...args: unknown[]): void {
        return this.log(LogLevel.DEBUG, message, ...args);
    }

    private log(level: LogLevel, message: string, ...args: unknown[]): void {
        const { msg, meta } = this.parse(message, args);
        if (meta) {
            return this.internalLogger[level](meta, msg);
        }
        return this.internalLogger[level](msg);
    }

    private parse(
        message: string,
        args: unknown[],
    ): { msg: string; meta?: Record<string, unknown> } {
        let msg = message;
        const data: unknown[] = [];

        for (const arg of args) {
            if (['string', 'number', 'boolean'].includes(typeof arg) || !arg) {
                msg += ` ${arg}`;
            } else {
                data.push(arg);
            }
        }
        if (!data.length) {
            return { msg };
        }

        return { meta: this.parseMeta(data), msg };
    }

    private parseMeta(data: unknown[]): Record<string, unknown> {
        if (data.length === 1) {
            const [value] = data;

            if (value instanceof Error) {
                return {
                    err: value,
                };
            }
            if (Array.isArray(value)) {
                return { data: value };
            }
            return { ...(value as Record<string, unknown>) };
        }

        const meta: { data?: unknown[]; err?: Error } = {};

        for (const value of data) {
            if (value instanceof Error && !meta.err) {
                meta.err = value;
            } else {
                if (!meta.data) {
                    meta.data = [];
                }
                meta.data.push(value);
            }
        }

        return meta;
    }

    private isLoggerConfig(
        options: LoggerConfig | PinoLogger,
    ): options is LoggerConfig {
        return (options as LoggerConfig).app !== undefined;
    }

    protected getMessagePrefix(options: LoggerChildOptions): string {
        return `[${options.name}] `;
    }
}
