import { models } from '@trova-trip/trova-models';
import { MiddlewareFunction } from './middleware/middleware.types';

declare global {
    interface Window {
        console: Console;
        gtag?: any;
        gtagId?: string;
        analytics: {
            addDestinationMiddleware: (
                destinationName: string,
                middleware: MiddlewareFunction,
            ) => Promise<unknown>;
            addSourceMiddleware: (
                middleware: MiddlewareFunction,
            ) => Promise<unknown>;
            page: (
                category?: string,
                name?: string,
                properties?: Record<string, any>,
                options?: Record<string, any>,
                callback?: (...params: any) => any,
            ) => Promise<any>;
            identify: (
                userId?: string,
                traits?: Record<string, any>,
                options?: Record<string, any>,
                callback?: (...params: any) => any,
            ) => Promise<any>;
            track: (
                event: string,
                properties?: Record<string, any>,
                options?: Record<string, any>,
                callback?: (...params: any) => any,
            ) => Promise<any>;
            ready: (callback: () => any) => void;
            initialize?: void;
            invoked?: boolean;
            methods: string[];
            factory: (methods: string) => void;
            load: (segmentApiKey: string, event?: undefined) => void;
            _loadOptions: undefined;
            _writeKey: string;
            SNIPPET_VERSION: string;
        };
    }
}

export enum SegmentEventType {
    PAGE = 'page',
    TRACK = 'track',
    IDENTIFY = 'identify',
}

export interface ImpersonateableUser extends models.users.BaseUser {
    impersonate?: string;
}

export interface TrackEventParameters {
    eventName: string;
    properties?: Record<string, unknown>;
    context?: Record<string, unknown>;
}
