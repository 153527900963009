export const UserStatuses = {
    CREATED: 'created',
    READY_TO_QUALIFY: 'ready-to-qualify',
    QUALIFYING: 'qualifying',
    QUALIFIED: 'qualified',
    PLANNING: 'planning',
    LAUNCHED: 'launched',
    CONFIRMED: 'confirmed',
    RENEWED: 'renewed',
};

export const PreQualifiedHostStatuses = new Set([
    UserStatuses.CREATED,
    UserStatuses.READY_TO_QUALIFY,
    UserStatuses.QUALIFYING,
]);

export const QualifiedHostStatuses = new Set([
    UserStatuses.QUALIFIED,
    UserStatuses.PLANNING,
    UserStatuses.LAUNCHED,
    UserStatuses.CONFIRMED,
    UserStatuses.RENEWED,
]);

export const PlanningHostStatuses = new Set([
    UserStatuses.PLANNING,
    UserStatuses.LAUNCHED,
    UserStatuses.CONFIRMED,
    UserStatuses.RENEWED,
]);

export const END_OF_TRIP_PLANS = {
    RETURNING_HOME: 'Returning home',
    CONTINUING_TRAVELS: 'Continuing travels',
    UNDECIDED: 'Undecided',
};

export const TRAVELER_PREFERENCES = {
    earlyBird: 'EARLY_BIRD',
    nightOwl: 'NIGHT_OWL',
    adrenalineJunkie: 'ADRENALINE_JUNKIE',
    leisureEnthusiast: 'LEISURE_ENTHUSIAST',
    planner: 'PLANNER',
    wanderer: 'WANDERER',
    lifeOfTheParty: 'LIFE_OF_THE_PARTY',
    loverOfAloneTime: 'LOVER_OF_ALONE_TIME',
};

export const QUALIFIED_SURVEY_AGE = 18;
export const QUALIFIED_SURVEY_BUDGET_DOLLARS = 2000;
export const DEFAULT_MINIMUM_PAYMENT_AMOUNT = 200;

export const PAYMENT_DUE_DAYS_BEFORE_TRIP_START = 90;
