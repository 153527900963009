import { roundUp } from './common.utils';

const TROVA_TRAVELER_PAY_DOWN_PERCENTAGE: number = 25; // percentage

export const fees = {
    transactionFee: 2.9, // percentage
    operatorFee: 200, // USD
    serviceFee: 10, // percentage
    singleSupplementFee: 33, // Percent
    hostOptionalFee: 13, // Percent
    travelerOptionalFee: 1.1, // Proportion
    travelerAdditionalOptionalFee: 1.229, // Proportion
    platformFee: 200, // USD
};

export const getTrovaServiceFee = (): number =>
    // should look into the user tier and service fee fields
    // TODO: add exceptions
    fees.serviceFee;

export const getTrovaTransactionFee = (): number => fees.transactionFee;

export const getTrovaSingleSupplementFee = (): number =>
    fees.singleSupplementFee;

export const getTrovaHostOptionalFee = (): number => fees.hostOptionalFee;

export const getTrovaOperatorFee = (): number =>
    // should look into trovaOperatorFee. Fixed $200 by now
    fees.operatorFee;

export const getOperatorFeeCost = (operatorFee: number | undefined): number => {
    return roundUp(operatorFee || fees.operatorFee);
};

export const getTrovaPayDownPercentage = (): number =>
    TROVA_TRAVELER_PAY_DOWN_PERCENTAGE;
