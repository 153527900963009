import _ from 'underscore';
import { Country, Currency, Language, Lookup, Subdivision } from "./types";

export default function init(o: {
  countries: ReadonlyArray<Country>,
  currencies: ReadonlyArray<Currency>,
  languages: ReadonlyArray<Language>,
  subdivisions: ReadonlyArray<Subdivision>,
}): Lookup {
  return {
    countries: (search as typeof search<Country>).bind(null, o.countries),
    currencies: (search as typeof search<Currency>).bind(null, o.currencies),
    languages: (search as typeof search<Language>).bind(null, o.languages),
    subdivisions: (search as typeof search<Subdivision>).bind(null, o.subdivisions),
  };
}

function search<T extends Record<string, unknown>>(data: ReadonlyArray<T>, query: Partial<T>): T[] {
  var q = _.pairs(query);

  return data.filter(function(d) {
    return q.filter(function(v) {
      var prop = d[v[0]];

      if(_.isArray(prop)) return prop.indexOf(v[1]) >= 0;

      return prop == v[1];
    }).length == q.length;
  });
}
