import { models } from '@trova-trip/trova-models';
import {
    CostConfig,
    CostPerThreshold,
    OptionalCostThresholds,
    CompanionCostThresholds,
    WorkshopCostThresholds,
    CostByDayThresholds,
    BaseCostThresholds,
    CostThresholdWithAllCosts,
} from '../PricingCalculator.types';
import { sumPrices, roundUp } from '../Utils/common.utils';
import { getOperatorFeeCost, fees } from '../Utils/fees.utils';
import {
    getRoomQuantityForSingleSupplement,
    getDynamicCostPerThreshold,
} from '../Utils/pricing.utils';
import { TripPricing } from './TripPricing';

type Costs = Omit<
    TripPricing,
    'getPredictedPricePerTier' | 'addTier' | 'removeTier'
>;

type CostsKeys = keyof Costs;

export class BaseTripPricing {
    protected _config: CostConfig;
    protected _costs: Costs;

    constructor(config: CostConfig) {
        this._config = config;
        this._costs = this._getCosts();
    }

    private _cachedValues: Partial<Costs> = {};

    private _getCachedCosts<T>(key: CostsKeys, cb: () => T): T {
        if (this._cachedValues[key]) {
            // @ts-ignore: Ignore generic T error
            return this._cachedValues[key];
        }

        const response = cb();
        // @ts-ignore: Ignore generic T error
        this._cachedValues[key] = response;
        return response;
    }

    private _getCosts(): Costs {
        return {
            costsByDay: this._getCachedCosts<CostByDayThresholds[]>(
                'costsByDay',
                this._buildCostByDayThresholds.bind(this),
            ),
            baseCosts: this._getCachedCosts<BaseCostThresholds>(
                'baseCosts',
                this._buildBaseCostThresholds.bind(this),
            ),
            companionsCosts: this._getCachedCosts<CompanionCostThresholds[]>(
                'companionsCosts',
                this._buildCompanionsThresholds.bind(this),
            ),
            suggestedCosts: this._getCachedCosts<CostPerThreshold[]>(
                'suggestedCosts',
                this._buildSuggestedCostThresholds.bind(this),
            ),
            currentCosts: this._getCachedCosts<CostPerThreshold[]>(
                'currentCosts',
                this._buildActualCostThresholds.bind(this),
            ),
            hostSelectedOptionalsCosts: this._getCachedCosts<
                OptionalCostThresholds[]
            >(
                'hostSelectedOptionalsCosts',
                this._buildHostSelectedOptionalsThresholds.bind(this),
            ),
            workshopsCosts: this._getCachedCosts<WorkshopCostThresholds[]>(
                'workshopsCosts',
                this._buildWorkshopsThresholds.bind(this),
            ),
            servicesTotalCosts: this._getCachedCosts<CostPerThreshold[]>(
                'servicesTotalCosts',
                this._buildServicesTotal.bind(this),
            ),
            singleSupplementCostAdjustments: this._getCachedCosts<
                CostPerThreshold[]
            >(
                'singleSupplementCostAdjustments',
                this._buildSingleSupplementCostAdjustments.bind(this),
            ),
            operatorTotalCosts: this._getCachedCosts<CostPerThreshold[]>(
                'operatorTotalCosts',
                this._buildOperatorTotalThresholds.bind(this),
            ),
            singleSupplementFeeCosts: this._getCachedCosts<CostPerThreshold[]>(
                'singleSupplementFeeCosts',
                this._buildSingleSupplementThresholds.bind(this),
            ),
            operatorFee: getOperatorFeeCost(this._config.prices?.operatorFee),
            platformFees: this._getCachedCosts<CostPerThreshold[]>(
                'platformFees',
                this._buildPlatformFees.bind(this),
            ),
            travelerTotalCosts: this._getCachedCosts<CostPerThreshold[]>(
                'travelerTotalCosts',
                this._buildTotalHostCostThresholds.bind(this),
            ),
            hostCostsWithFixedCostsAndPrices: this._getCachedCosts<
                CostThresholdWithAllCosts[]
            >(
                'hostCostsWithFixedCostsAndPrices',
                this._buildHostCostsWithFixedCostsAndPrices.bind(this),
            ),
            hostGroundTransferCost: this._getCachedCosts<CostPerThreshold[]>(
                'hostGroundTransferCost',
                this._buildHostGroundTransferCost.bind(this),
            ),
        };
    }

    // #region base costs
    private _buildCostByDayThresholds(
        roundNumbersUp = true,
    ): CostByDayThresholds[] {
        const { validityPeriodsByDay, tripLength } = this._config;

        return validityPeriodsByDay.map(
            ({ day, validityPeriod, singleSupplementPrice }) => {
                const costPerThreshold = getDynamicCostPerThreshold(
                    validityPeriod,
                    this._config,
                );

                const costPerThresholds: CostPerThreshold[] =
                    costPerThreshold.map(
                        ({
                            numberTravelers,
                            pricePerTraveler,
                            platformFee,
                        }) => {
                            const price = roundNumbersUp
                                ? roundUp(pricePerTraveler / tripLength)
                                : pricePerTraveler / tripLength;

                            const calculatedPlatformFee = roundNumbersUp
                                ? roundUp(
                                      (platformFee ?? fees.platformFee) /
                                          tripLength,
                                  )
                                : (platformFee ?? fees.platformFee) /
                                  tripLength;

                            return {
                                numberTravelers,
                                pricePerTraveler: price,
                                platformFee: calculatedPlatformFee,
                            };
                        },
                    );

                const singleSupplementPricePerDay: number = roundNumbersUp
                    ? roundUp(singleSupplementPrice / tripLength)
                    : singleSupplementPrice / tripLength;

                return {
                    day,
                    singleSupplementPrice: singleSupplementPricePerDay,
                    costPerThreshold: costPerThresholds,
                };
            },
        );
    }

    private _buildBaseCostThresholds(): BaseCostThresholds {
        const { numberOfTravelersPerThreshold } = this._config;

        const costsByDay = this._buildCostByDayThresholds(false);

        const costPerThreshold: CostPerThreshold[] =
            numberOfTravelersPerThreshold.map((numberTravelers) => ({
                numberTravelers,
                pricePerTraveler: sumPrices(costsByDay, numberTravelers),
            }));

        const singleSupplementPrice: number = roundUp(
            costsByDay.reduce(
                (total, { singleSupplementPrice }) =>
                    total + singleSupplementPrice,
                0,
            ),
        );

        return {
            singleSupplementPrice,
            costPerThreshold,
        };
    }

    private _buildCompanionsThresholds(): CompanionCostThresholds[] {
        const companionsLength = this._config.companions.length;
        if (companionsLength === 0) {
            return [];
        }

        const baseCosts = this._getCachedCosts<BaseCostThresholds>(
            'baseCosts',
            this._buildBaseCostThresholds.bind(this),
        );

        const costPerThreshold = baseCosts.costPerThreshold.map(
            ({ numberTravelers, pricePerTraveler }) => {
                const price =
                    (companionsLength * pricePerTraveler) / numberTravelers;
                return {
                    numberTravelers: numberTravelers,
                    pricePerTraveler: roundUp(price),
                };
            },
        );

        return [
            {
                quantity: companionsLength,
                costPerThreshold,
            },
        ];
    }

    private _buildSuggestedCostThresholds(): CostPerThreshold[] {
        const { numberOfTravelersPerThreshold } = this._config;
        const baseCosts = this._getCachedCosts<BaseCostThresholds>(
            'baseCosts',
            this._buildBaseCostThresholds.bind(this),
        );
        const companionsCosts = this._getCachedCosts<CompanionCostThresholds[]>(
            'companionsCosts',
            this._buildCompanionsThresholds.bind(this),
        );

        return numberOfTravelersPerThreshold.map((numberOfTravelers) => {
            const base =
                baseCosts.costPerThreshold.find(
                    ({ numberTravelers }) =>
                        numberTravelers === numberOfTravelers,
                )?.pricePerTraveler || 0;
            const companions = sumPrices(companionsCosts, numberOfTravelers);

            const subtotal = base + companions;

            return {
                numberTravelers: numberOfTravelers,
                pricePerTraveler: subtotal,
            };
        });
    }

    private _buildActualCostThresholds(): CostPerThreshold[] {
        const { numberOfTravelersPerThreshold, costThresholdsFromTrip } =
            this._config;
        const suggestedCosts = this._getCachedCosts(
            'suggestedCosts',
            this._buildSuggestedCostThresholds.bind(this),
        );

        return numberOfTravelersPerThreshold.map((numberTravelers) => {
            const costThresholdFromTrip = costThresholdsFromTrip?.find(
                (threshold) => threshold.numberOfTravelers === numberTravelers,
            );

            if (costThresholdFromTrip) {
                return {
                    numberTravelers: costThresholdFromTrip.numberOfTravelers,
                    pricePerTraveler: roundUp(costThresholdFromTrip.price),
                };
            }

            const suggestedCostThresholds = suggestedCosts.find(
                (threshold) => threshold.numberTravelers === numberTravelers,
            );

            return {
                numberTravelers:
                    suggestedCostThresholds?.numberTravelers || numberTravelers,
                pricePerTraveler: roundUp(
                    suggestedCostThresholds?.pricePerTraveler || 0,
                ),
            };
        });
    }

    private _buildHostGroundTransferCost(): CostPerThreshold[] {
        const { hostGroundTransferCost, numberOfTravelersPerThreshold } =
            this._config;

        return numberOfTravelersPerThreshold.map((numberTravelers) => {
            return {
                numberTravelers,
                pricePerTraveler: roundUp(
                    hostGroundTransferCost / numberTravelers,
                ),
            };
        });
    }

    // #endregion base costs

    // #region services
    private _buildHostSelectedOptionalsThresholds(): OptionalCostThresholds[] {
        const { hostSelectedOptionalServices, numberOfTravelersPerThreshold } =
            this._config;

        if (!hostSelectedOptionalServices) {
            return [];
        }

        return hostSelectedOptionalServices.map((service) => {
            const activityPrice = service.activity.price || 0;
            const costPerThreshold = numberOfTravelersPerThreshold.map(
                (numberOfTravelers) => ({
                    numberTravelers: numberOfTravelers,
                    pricePerTraveler: roundUp(
                        (activityPrice * service.numberOptingIn) /
                            numberOfTravelers,
                    ),
                }),
            );

            return {
                name: service.activity.name || '',
                price: activityPrice,
                quantity: service.numberOptingIn || 0,
                costPerThreshold,
            };
        });
    }

    private _buildWorkshopsThresholds(): WorkshopCostThresholds[] {
        const { workshops, numberOfTravelersPerThreshold } = this._config;
        return workshops.map(({ service }) => {
            const actualService = service as models.services.WorkshopSpace;
            const workshopHoursRequested =
                actualService.hoursRequested ??
                actualService.hoursAvailable ??
                0;

            const costPerThreshold = numberOfTravelersPerThreshold.map(
                (numberOfTravelers) => ({
                    numberTravelers: numberOfTravelers,
                    pricePerTraveler: roundUp(
                        (workshopHoursRequested *
                            (actualService.pricePerHour || 0)) /
                            numberOfTravelers,
                    ),
                }),
            );

            return {
                name: actualService.name || '',
                hours: workshopHoursRequested,
                costPerThreshold,
            };
        });
    }
    // #endregion services

    // #region totals
    private _buildSingleSupplementThresholds(): CostPerThreshold[] {
        let singleSupplementCost = this._config.singleSupplementPrice;

        // If we are calculating the cost thresholds from the trip-request
        // we need to take the single supplement price from the itinerary
        if (!this._config.costThresholdsFromTrip) {
            const baseCosts = this._getCachedCosts<BaseCostThresholds>(
                'baseCosts',
                this._buildBaseCostThresholds.bind(this),
            );

            singleSupplementCost = baseCosts.singleSupplementPrice;
        }

        return this._config.numberOfTravelersPerThreshold.map(
            (numberTravelers) => ({
                numberTravelers,
                pricePerTraveler: roundUp(
                    singleSupplementCost / numberTravelers,
                ),
            }),
        );
    }

    private _buildSingleSupplementCostAdjustments(): CostPerThreshold[] {
        const baseCosts = this._getCachedCosts<BaseCostThresholds>(
            'baseCosts',
            this._buildBaseCostThresholds.bind(this),
        );

        const singleSupplementCost = baseCosts.singleSupplementPrice;

        const roomsQuantity = getRoomQuantityForSingleSupplement(
            this._config.hostRooms,
        );
        const costAdjustment = singleSupplementCost * (roomsQuantity - 1);

        return this._config.numberOfTravelersPerThreshold.map(
            (numberTravelers) => ({
                numberTravelers,
                pricePerTraveler: roundUp(costAdjustment / numberTravelers),
            }),
        );
    }

    private _buildServicesTotal(): CostPerThreshold[] {
        const workshopsCosts = this._getCachedCosts<WorkshopCostThresholds[]>(
            'workshopsCosts',
            this._buildWorkshopsThresholds.bind(this),
        );
        const hostSelectedOptionalsCosts = this._getCachedCosts<
            OptionalCostThresholds[]
        >(
            'hostSelectedOptionalsCosts',
            this._buildHostSelectedOptionalsThresholds.bind(this),
        );

        return this._config.numberOfTravelersPerThreshold.map(
            (numberOfTravelers) => {
                const workshops = sumPrices(workshopsCosts, numberOfTravelers);

                const hostSelectedOptionals = sumPrices(
                    hostSelectedOptionalsCosts,
                    numberOfTravelers,
                );

                const subtotal = workshops + hostSelectedOptionals;

                return {
                    numberTravelers: numberOfTravelers,
                    pricePerTraveler: subtotal,
                };
            },
        );
    }

    private _buildOperatorTotalThresholds(): CostPerThreshold[] {
        const actualCosts = this._getCachedCosts<CostPerThreshold[]>(
            'currentCosts',
            this._buildActualCostThresholds.bind(this),
        );
        const servicesTotalCosts = this._getCachedCosts<CostPerThreshold[]>(
            'servicesTotalCosts',
            this._buildServicesTotal.bind(this),
        );
        const singleSupplementCostAdjustmentCosts = this._getCachedCosts<
            CostPerThreshold[]
        >(
            'singleSupplementCostAdjustments',
            this._buildSingleSupplementCostAdjustments.bind(this),
        );

        return this._config.numberOfTravelersPerThreshold.map(
            (numberOfTravelers) => {
                const baseCost =
                    actualCosts.find(
                        ({ numberTravelers }) =>
                            numberTravelers === numberOfTravelers,
                    )?.pricePerTraveler || 0;

                const servicesCost =
                    servicesTotalCosts.find(
                        ({ numberTravelers }) =>
                            numberTravelers === numberOfTravelers,
                    )?.pricePerTraveler || 0;

                const singleSupplementCostAdjustment =
                    singleSupplementCostAdjustmentCosts.find(
                        ({ numberTravelers }) =>
                            numberTravelers === numberOfTravelers,
                    )?.pricePerTraveler || 0;

                const subtotal =
                    baseCost + servicesCost + singleSupplementCostAdjustment;
                return {
                    numberTravelers: numberOfTravelers,
                    pricePerTraveler: roundUp(subtotal),
                };
            },
        );
    }

    private _buildPlatformFees(): CostPerThreshold[] {
        const { numberOfTravelersPerThreshold, costThresholdsFromTrip } =
            this._config;

        const costsByDay = this._buildCostByDayThresholds(false);

        return numberOfTravelersPerThreshold.map((numberTravelers) => {
            const platformFeeFromTrip =
                costThresholdsFromTrip &&
                costThresholdsFromTrip.find(
                    (threshold) =>
                        threshold.numberOfTravelers === numberTravelers,
                )?.platformFee;

            if (platformFeeFromTrip) {
                return {
                    numberTravelers,
                    pricePerTraveler: platformFeeFromTrip,
                };
            }

            const platformFeeFromItinerary = sumPrices(
                costsByDay,
                numberTravelers,
                'platformFee',
            );

            return {
                numberTravelers,
                pricePerTraveler: platformFeeFromItinerary ?? fees.platformFee,
            };
        });
    }

    private _buildTotalHostCostThresholds(): CostPerThreshold[] {
        const operatorTotalCosts = this._getCachedCosts<CostPerThreshold[]>(
            'operatorTotalCosts',
            this._buildOperatorTotalThresholds.bind(this),
        );
        const singleSupplementFeeCosts = this._getCachedCosts<
            CostPerThreshold[]
        >(
            'singleSupplementFeeCosts',
            this._buildSingleSupplementThresholds.bind(this),
        );
        const platformFees = this._getCachedCosts<CostPerThreshold[]>(
            'platformFees',
            this._buildPlatformFees.bind(this),
        );

        const hostTransferCosts = this._getCachedCosts<CostPerThreshold[]>(
            'hostGroundTransferCost',
            this._buildHostGroundTransferCost.bind(this),
        );

        return operatorTotalCosts.map(
            ({ numberTravelers, pricePerTraveler }) => {
                const singleSupplementCost =
                    singleSupplementFeeCosts.find(
                        (threshold) =>
                            threshold.numberTravelers === numberTravelers,
                    )?.pricePerTraveler || 0;

                const platformFee =
                    platformFees.find(
                        (threshold) =>
                            threshold.numberTravelers === numberTravelers,
                    )?.pricePerTraveler ?? fees.platformFee;

                const hostGroundTransferCost =
                    hostTransferCosts.find(
                        (threshold) =>
                            threshold.numberTravelers === numberTravelers,
                    )?.pricePerTraveler ?? 0;

                return {
                    numberTravelers,
                    pricePerTraveler: roundUp(
                        pricePerTraveler +
                            platformFee +
                            singleSupplementCost +
                            hostGroundTransferCost,
                    ),
                };
            },
        );
    }

    private _buildHostCostsWithFixedCostsAndPrices(): CostThresholdWithAllCosts[] {
        const { prices, minimumSpots } = this._config;

        if (!prices || !minimumSpots) {
            return [];
        }

        const {
            initial = 0,
            remainingPrice = 0,
            serviceFee = 0,
            transactionFee = 0,
        } = prices;

        const totalHostCosts = this._getCachedCosts<CostPerThreshold[]>(
            'travelerTotalCosts',
            this._buildTotalHostCostThresholds.bind(this),
        );

        return totalHostCosts.map(({ numberTravelers, pricePerTraveler }) => {
            const hostPrice =
                numberTravelers <= minimumSpots ? initial : remainingPrice;
            const exactServiceCost = hostPrice * (serviceFee / 100);
            const exactTransactionCost = hostPrice * (transactionFee / 100);
            const priceWithAllCosts =
                pricePerTraveler + exactServiceCost + exactTransactionCost;

            return {
                numberOfTravelers: numberTravelers,
                priceWithFixedCost: pricePerTraveler,
                serviceCost: roundUp(exactServiceCost),
                transactionCost: roundUp(exactTransactionCost),
                priceWithAllCosts: roundUp(priceWithAllCosts),
            };
        });
    }
    // #endregion totals

    // #region utilities
    protected _addTier(tier: number): void {
        const { numberOfTravelersPerThreshold } = this._config;

        if (numberOfTravelersPerThreshold.includes(tier)) {
            return;
        }

        const tiers = [...numberOfTravelersPerThreshold, tier];

        tiers.sort((a, b) => a - b);

        this._config.numberOfTravelersPerThreshold = tiers;

        this._cachedValues = {};
        this._costs = this._getCosts();
    }

    protected _removeTier(tier: number): void {
        const { numberOfTravelersPerThreshold } = this._config;

        if (!numberOfTravelersPerThreshold.includes(tier)) {
            return;
        }

        const tiers = [...numberOfTravelersPerThreshold].filter(
            (numberOfTravelers) => numberOfTravelers !== tier,
        );

        this._config.numberOfTravelersPerThreshold = tiers;

        this._cachedValues = {};
        this._costs = this._getCosts();
    }
    // #endregion utilities
}

export default BaseTripPricing;
