import _ from 'underscore';
import continents from './data/continents';
import regions from './data/regions';
import countriesAll from './data/countries.json';
import currenciesAll from './data/currencies.json';
import languagesAll from './data/languages.json';
import initLookup from './lookup';

import getSymbol from 'currency-symbol-map';
import { iso31662 } from 'iso-3166';
import {
    CallingCodes,
    CallingCountries,
    Countries,
    Currencies,
    Currency,
    Languages,
    Subdivisions,
    Writeable,
} from './types';

export type {
    Country,
    Currency,
    Language,
    Subdivision,
    Continent,
    Region,
} from './types';

export { continents };
export { regions };

// @ts-ignore https://github.com/microsoft/TypeScript/issues/17867
export const countries: Countries = {
    all: countriesAll,
};

_.each(countriesAll, function (country) {
    // prefer assigned country codes over inactive ones
    var exportedAlpha2 = countries[country.alpha2];
    if (!exportedAlpha2 || exportedAlpha2.status === 'deleted') {
        (countries as Writeable<Countries>)[country.alpha2] = country;
    }

    var exportedAlpha3 = countries[country.alpha3];
    if (!exportedAlpha3 || exportedAlpha3.status === 'deleted') {
        (countries as Writeable<Countries>)[country.alpha3] = country;
    }
});

// @ts-ignore https://github.com/microsoft/TypeScript/issues/17867
export const currencies: Currencies = {
    all: currenciesAll as Currency[],
};

_.each(currenciesAll as Writeable<Currency>[], (currency) => {
    //If the symbol isn't available, default to the currency code
    var symbol = getSymbol(currency.code);
    if (symbol == '?') {
        symbol = currency.code;
    }

    currency.symbol = symbol;
    (currencies as Writeable<Currencies>)[currency.code] = currency;
});

// @ts-ignore https://github.com/microsoft/TypeScript/issues/17867
export const languages: Languages = {
    all: languagesAll,
};

// Note that for the languages there are several entries with the same alpha3 -
// eg Dutch and Flemish. Not sure how to best deal with that - here whichever
// comes last wins.
_.each(languagesAll, function (language) {
    (languages as Writeable<Languages>)[language.alpha2] = language;
    (languages as Writeable<Languages>)[language.bibliographic] = language;
    (languages as Writeable<Languages>)[language.alpha3] = language;
});

const subdivisionsAll = iso31662.map(({ code, name, parent }) => ({
    name,
    countryCode: parent,
    regionCode: code.split('-')[1],
    code,
}));

export const subdivisions: Subdivisions = {
    all: subdivisionsAll,
};

export const lookup = initLookup({
    countries: countries.all,
    currencies: currencies.all,
    languages: languages.all,
    subdivisions: subdivisions.all,
});

// @ts-ignore https://github.com/microsoft/TypeScript/issues/17867
const callingCountries: CallingCountries = { all: [] };

var callingCodesAll = _.reduce(
    countriesAll,
    function (codes, country) {
        if (country.countryCallingCodes && country.countryCallingCodes.length) {
            (callingCountries.all as Writeable<CallingCountries['all']>).push(
                country,
            );

            (callingCountries as Writeable<CallingCountries>)[country.alpha2] =
                country;
            (callingCountries as Writeable<CallingCountries>)[country.alpha3] =
                country;

            _.each(country.countryCallingCodes, function (code) {
                if (codes.indexOf(code) == -1) {
                    codes.push(code);
                }
            });
        }
        return codes;
    },
    [] as string[],
);

delete (callingCountries as Writeable<CallingCountries>)['']; // remove empty alpha3s
export { callingCountries };

callingCodesAll.sort(function (a, b) {
    var parse = function (str: string) {
        return parseInt(str);
    };
    var splitA = _.map(a.split(' '), parse);
    var splitB = _.map(b.split(' '), parse);

    if (splitA[0] < splitB[0]) {
        return -1;
    } else if (splitA[0] > splitB[0]) {
        return 1;
    } else {
        // Same - check split[1]
        if (splitA[1] === undefined && splitB[1] !== undefined) {
            return -1;
        } else if (splitA[1] !== undefined && splitB[1] === undefined) {
            return 1;
        } else if (splitA[1] < splitB[1]) {
            return -1;
        } else if (splitA[1] > splitB[1]) {
            return 1;
        } else {
            return 0;
        }
    }
});

export const callingCodes: CallingCodes = {
    all: callingCodesAll,
};
