import { models } from '@trova-trip/trova-models';
import {
    QUALIFIED_SURVEY_AGE,
    QUALIFIED_SURVEY_BUDGET_DOLLARS,
} from '../business.constants';

/**
 * Determine if the specified survey is qualified.
 * @param {models.survey.AudienceSurveySubmission} submission - The survey submission.
 */
const isAudienceSurveySubmissionQualified = (
    submission: models.survey.AudienceSurveySubmission,
): boolean => {
    return (
        submission.answers.age >= QUALIFIED_SURVEY_AGE &&
        submission.answers.budget >= QUALIFIED_SURVEY_BUDGET_DOLLARS
    );
};

export const AudienceSurvey = {
    isQualified: isAudienceSurveySubmissionQualified,
};
