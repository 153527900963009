import React from 'react';
import { InternalBaseProps } from '../../../../shared/theme/interfaces/Base.types';
import { genDataTestId } from '../../../../shared/utils/helpers';
import { CardImpl, CardProps } from '../shared/CardImpl';
import { ColorSchemes } from './SelectableCard.styles';
import { useStyleConfig } from '@chakra-ui/react';
import noop from 'lodash/noop';
import useKeyboardEvents, {
    Key,
} from '../../../../shared/hooks/useKeyboardEvents';

export interface SelectableCardProps
    extends Pick<
        CardProps,
        | 'spacing'
        | 'isFullHeight'
        | 'className'
        | 'onClick'
        | 'children'
        | 'width'
    > {
    colorScheme?: ColorSchemes;
    isSelected?: boolean;
    isDisabled?: boolean;
}

export interface SelectableCardImplProps
    extends Omit<InternalBaseProps, 'as'>,
        SelectableCardProps {}

export const SelectableCardImpl = (props: SelectableCardImplProps) => {
    const {
        colorScheme = 'default',
        spacing,
        isSelected = false,
        isDisabled = false,
        onClick = noop,
        ...rest
    } = props;

    const styles = useStyleConfig('SelectableCard', {
        colorScheme,
        isSelected,
        isDisabled,
    });

    const { handleKeyboardEvent } = useKeyboardEvents<HTMLDivElement>();

    const handleClick = (
        e:
            | React.MouseEvent<HTMLDivElement, MouseEvent>
            | React.KeyboardEvent<HTMLDivElement>
    ) => {
        if (!isDisabled) {
            onClick(e);
        }
    };

    const keysToHandle = [Key.Spacebar, Key.Enter];

    return (
        <CardImpl
            spacing={spacing}
            role='button'
            data-testid={genDataTestId('selectable-card')}
            sx={styles}
            onClick={handleClick}
            onKeyDown={handleKeyboardEvent(keysToHandle, handleClick)}
            // tabIndex is still in 'rest' props so it can be overridden
            // -1 is not keyboard focusable and 0 is keyboard focusable
            // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex
            tabIndex={isDisabled ? -1 : 0}
            {...rest}
        />
    );
};

const SelectableCard = (props: SelectableCardProps) => (
    <SelectableCardImpl {...props} />
);

export default SelectableCard;
