import { last, inRange } from 'lodash';
import moment from 'moment';
import { constants, models } from '@trova-trip/trova-models';
import { tripPricing } from './PricingFactories';
import {
    CostThreshold,
    CostThresholdWithAllCosts,
    CostThresholdWithFixedCosts,
    DayOfTheWeek,
    FixedTripCosts,
    FoundCostThreshold,
    HostPrices,
    HostSelectedOptionalServices,
    ItineraryAdditionalCosts,
    Trip,
    TripPrices,
    TripCostDTO,
    SummaryPricingData,
    SummaryPricing,
    TotalToPayData,
} from './PricingCalculator.types';
import {
    fees,
    getTrovaPayDownPercentage,
    getTrovaSingleSupplementFee,
} from './Utils/fees.utils';
import {
    roundUp,
    getCostOutputs,
    TARGET_MINIMUM_HOST_PROFIT,
    DEFAULT_REMAINING_PRICE_EXTRA,
} from './Utils/common.utils';
import {
    convertPriceToUSD,
    convertPriceToUSDWithMargin,
} from './Utils/conversion.utils';

type ValidityPeriod = models.itineraries.ValidityPeriod;

const calculatePriceByTripLength = (
    price: number,
    tripLength: number,
): number => price / tripLength;

const doesBelongToPeriod = (
    date: moment.Moment,
    startDate: Date,
    endDate: Date,
): boolean =>
    moment(date).isBetween(
        startDate,
        endDate,
        undefined,
        `[]`, // inclusion of both start and end date
    );

const getCostThresholdsPerDay = (
    period: ValidityPeriod,
    tripLength: number,
    date: moment.Moment,
): Array<CostThreshold> => {
    const dayOfTheWeek: DayOfTheWeek = <DayOfTheWeek>moment(date).day();

    return period.costThresholds
        .filter(({ daysOfTheWeek = [] }) =>
            daysOfTheWeek.includes(dayOfTheWeek),
        )
        .map(({ numberOfTravelers, price }) => {
            const dayPrice = calculatePriceByTripLength(price, tripLength);
            return { numberOfTravelers, price: dayPrice };
        });
};

const addAdditionalCostsPrice = (
    period: ValidityPeriod,
    tripLength: number,
): ItineraryAdditionalCosts =>
    Object.entries(period.additionalCosts).reduce(
        (additionals, [currentKey, currentValue]) => ({
            ...additionals,
            [currentKey]: {
                ...currentValue,
                price: calculatePriceByTripLength(
                    currentValue.price,
                    tripLength,
                ),
            },
        }),
        {},
    );

const getExistentThresholdForNumberOfTravelers = (
    costThresholds: Array<CostThreshold> = [],
    numberOfTravelers: number,
): CostThreshold | undefined =>
    costThresholds
        ? costThresholds.find(
              ({ numberOfTravelers: existentNumberOfTravelers }) =>
                  existentNumberOfTravelers === numberOfTravelers,
          )
        : undefined;

const calculateAdditionalCostsFrom = (
    newAdditionalCosts: ItineraryAdditionalCosts,
    oldAdditionalCosts: ItineraryAdditionalCosts,
): ItineraryAdditionalCosts =>
    Object.entries(newAdditionalCosts).reduce(
        (summarizedAdditionals, [currentKey, currentValue]) => {
            const oldAdditional = oldAdditionalCosts
                ? // @ts-ignore implicit any
                  oldAdditionalCosts[currentKey]
                : undefined;
            const newAdditional = {
                [currentKey]: {
                    ...currentValue,
                    price:
                        currentValue.price +
                        (oldAdditional ? oldAdditional.price : 0),
                },
            };

            return {
                ...summarizedAdditionals,
                ...newAdditional,
            };
        },
        {},
    );

const calculateCostThresholdsFrom = (
    costThresholds: Array<CostThreshold>,
    oldCostThresholds: Array<CostThreshold>,
): Array<CostThreshold> => {
    const summarizedCostThresholds: Array<CostThreshold> = [];
    return costThresholds.reduce((summarizedThresholds, currentThreshold) => {
        const existentThresholdsForNumberOfTravelers =
            getExistentThresholdForNumberOfTravelers(
                oldCostThresholds,
                currentThreshold.numberOfTravelers,
            );

        if (existentThresholdsForNumberOfTravelers) {
            existentThresholdsForNumberOfTravelers.price +=
                currentThreshold.price;
            summarizedThresholds.push(existentThresholdsForNumberOfTravelers);
        } else {
            summarizedThresholds.push(currentThreshold);
        }

        return summarizedThresholds;
    }, summarizedCostThresholds);
};
const getValidityPeriodForDate = (
    validityPeriods: Array<ValidityPeriod>,
    date: moment.Moment,
): ValidityPeriod | undefined =>
    validityPeriods.find(
        ({ startDate, endDate }: { startDate: Date; endDate: Date }) =>
            doesBelongToPeriod(date, startDate, endDate),
    );

const updateCostThresholdsIfApply = (
    validityPeriods: Array<ValidityPeriod>,
    date: moment.Moment,
    oldCostThresholds: Partial<Trip>,
    // oldCostThresholds: Trip,
    tripLength: number,
): FoundCostThreshold => {
    const period: ValidityPeriod | undefined = getValidityPeriodForDate(
        validityPeriods,
        date,
    );
    const periodFound: boolean = !!period;
    if (!period) {
        return { periodFound, newCostThresholds: [] };
    }

    const additionalCosts = addAdditionalCostsPrice(period, tripLength);
    const thresholdsPerDay = getCostThresholdsPerDay(period, tripLength, date);

    const newCostThresholds = calculateCostThresholdsFrom(
        thresholdsPerDay,
        oldCostThresholds.costThresholds || [],
    );

    const newAdditionalCosts = calculateAdditionalCostsFrom(
        additionalCosts,
        oldCostThresholds.additionalCosts || {},
    );

    return {
        periodFound,
        costThresholds: {
            costThresholds: newCostThresholds,
            additionalCosts: newAdditionalCosts,
        },
    };
};

const getAmountOfYearsToApply = (
    validityPeriods: Array<ValidityPeriod>,
    startDate: Date,
): number => {
    let previousYearDate = moment(startDate).subtract(1, `year`);
    const maxAttempts = 5;

    for (let i = 0; i < maxAttempts; i++) {
        const period = getValidityPeriodForDate(
            validityPeriods,
            previousYearDate,
        );

        if (period) {
            return i + 1;
        }

        previousYearDate = moment(previousYearDate).subtract(1, `year`);
    }

    return 0;
};

const applyYearOverYearIncreaseForThresholds = (
    validityPeriods: Array<ValidityPeriod>,
    momentStartDate: Date,
    thresholds: Partial<Trip>,
    percentageIncrease: number,
): Partial<Trip> => {
    const amountOfYears: number = getAmountOfYearsToApply(
        validityPeriods,
        momentStartDate,
    );

    const originalThresholds = thresholds.costThresholds || [];

    const newCostThresholds: Array<CostThreshold> = originalThresholds.map(
        (threshold) => {
            const newPrice = roundUp(
                threshold.price *
                    Math.pow(1 + percentageIncrease / 100, amountOfYears),
            );
            return {
                ...threshold,
                price: newPrice,
            };
        },
    );

    return { ...thresholds, costThresholds: newCostThresholds };
};

const getDefaultCostThresholds = (
    validityPeriods: Array<ValidityPeriod>,
): Pick<Trip, 'costThresholds' | 'additionalCosts'> => {
    const numberOfTravelers: Array<number> =
        validityPeriods[0].costThresholds.map(
            ({ numberOfTravelers }) => numberOfTravelers,
        );
    const costThresholds: Array<CostThreshold> = numberOfTravelers.map(
        (travelers) => ({
            numberOfTravelers: travelers,
            price: 0,
        }),
    );

    return {
        costThresholds,
        additionalCosts: { singleSupplement: { price: 0 } },
    };
};

const calculateNewCostThresholdsFromOldValidityPeriods = (
    validityPeriods: Array<ValidityPeriod>,
    date: moment.Moment,
    newCostThresholds: Partial<Trip>,
    tripLength: number,
    // any isn't ideal, but line 641 is returning a strange type,
): Partial<Trip> | any => {
    let previousYearDate: moment.Moment = moment(date).subtract(1, `year`);

    const maxAttempts = 5;

    for (let i = 0; i < maxAttempts; i++) {
        const { periodFound, costThresholds } = updateCostThresholdsIfApply(
            validityPeriods,
            previousYearDate,
            newCostThresholds,
            tripLength,
        );

        if (periodFound) {
            return costThresholds;
        }

        previousYearDate = moment(previousYearDate).subtract(1, `year`);
    }

    return getDefaultCostThresholds(validityPeriods);
};

const roundFinalCostThresholds = (
    calculatedCostThresholds: Partial<Trip>,
): Pick<Trip, 'costThresholds' | 'additionalCosts'> => {
    const { costThresholds = [], additionalCosts = {} } =
        calculatedCostThresholds;

    const roundedCostThresholds = costThresholds.map((threshold) => ({
        ...threshold,
        price: Math.round(threshold.price),
    }));

    const roundedAdditionalCosts = Object.entries(additionalCosts).reduce(
        (additionals, [currentKey, currentValue]) => {
            // if (currentValue && !isNumber(currentValue)) {
            return {
                ...additionals,
                [currentKey]: {
                    // @ts-ignore it should always have a price
                    ...currentValue,
                    // @ts-ignore
                    price: Math.round(currentValue.price),
                },
            };
        },
        {},
    );

    return {
        costThresholds: roundedCostThresholds,
        additionalCosts: roundedAdditionalCosts,
    };
};

const calculateCostThresholdsFromValidityPeriods = (
    validityPeriods: Array<ValidityPeriod>,
    startDate: Date,
    tripLength: number,
    yearOverYearIncrease = 0,
): Pick<Trip, 'costThresholds' | 'additionalCosts'> => {
    const tripEndDate = moment(startDate).add(tripLength - 1, `day`);
    let newCostThresholds = {};
    let applyIncrease = false;

    let currentDate = moment(startDate);
    while (!moment(currentDate).isAfter(tripEndDate)) {
        const { periodFound, costThresholds } = updateCostThresholdsIfApply(
            validityPeriods,
            currentDate,
            newCostThresholds,
            tripLength,
        );
        newCostThresholds = costThresholds ? costThresholds : newCostThresholds;

        if (!periodFound) {
            newCostThresholds =
                calculateNewCostThresholdsFromOldValidityPeriods(
                    validityPeriods,
                    currentDate,
                    newCostThresholds,
                    tripLength,
                );
            applyIncrease = true;
        }

        currentDate = moment(currentDate).add(1, `day`);
    }

    if (applyIncrease) {
        newCostThresholds = applyYearOverYearIncreaseForThresholds(
            validityPeriods,
            startDate,
            newCostThresholds,
            yearOverYearIncrease,
        );
    }

    return roundFinalCostThresholds(newCostThresholds);
};

const calculateHostPriceFromCostThreshold = (
    costThreshold: CostThreshold,
    tripPrices: TripPrices,
): number => {
    const { price, margin } = costThreshold;
    if (margin) {
        return price + margin;
    }
    const { operatorFee, serviceFee, transactionFee } = tripPrices;
    const serviceFeePrice = roundUp(price * (serviceFee / 100));
    const transactionFeePrice = roundUp(price * (transactionFee / 100));
    return price + operatorFee + serviceFeePrice + transactionFeePrice;
};

const getHostSelectedOptionalServicesPrice = (
    hostSelectedOptionalServices: Array<HostSelectedOptionalServices>,
): number => {
    const hostSelectedOptionalServicesPrice =
        hostSelectedOptionalServices && hostSelectedOptionalServices.length
            ? hostSelectedOptionalServices.reduce(
                  (
                      total: number,
                      {
                          numberOptingIn,
                          activity,
                      }: HostSelectedOptionalServices,
                  ) =>
                      numberOptingIn > 0
                          ? total + numberOptingIn * activity.price
                          : total,
                  0,
              )
            : 0;
    return hostSelectedOptionalServicesPrice;
};

const getWorkshopsPriceFromServicesByDay = (
    servicesByDay: models.services.ServicesByDay = [],
): number => {
    const workshops = servicesByDay.flatMap((day) =>
        day.filter(
            (
                dayService,
            ): dayService is models.services.DayService<models.services.WorkshopSpace> => {
                const { service } = dayService;
                const actualService = service as models.services.Service;
                return (
                    actualService.type ===
                    constants.services.ServiceType.WORKSHOP_SPACE
                );
            },
        ),
    );
    const workshopsPrice = workshops.reduce((total, { service }) => {
        const actualService = service as models.services.WorkshopSpace;
        const hoursAvailable = actualService.hoursAvailable as number;
        const pricePerHour = actualService.pricePerHour as number;
        return hoursAvailable > 0
            ? total + pricePerHour * hoursAvailable
            : total;
    }, 0);
    return workshopsPrice;
};

const ensureDescendingPrice = (
    costThresholdsWithFixedCosts: CostThresholdWithFixedCosts[] = [],
): CostThresholdWithFixedCosts[] => {
    const clonedCostThresholdsWithFixedCosts = [
        ...costThresholdsWithFixedCosts,
    ];
    return clonedCostThresholdsWithFixedCosts
        .reverse()
        .map((threshold, index, allThresholds) => {
            const nextThreshold = allThresholds[index + 1];

            if (
                nextThreshold &&
                nextThreshold.priceWithFixedCost <= threshold.priceWithFixedCost
            ) {
                allThresholds[index + 1] = {
                    ...nextThreshold,
                    priceWithFixedCost: threshold.priceWithFixedCost,
                };
            }
            return threshold;
        })
        .reverse();
};

// TODO - remove this hack once we have a better way of stabiliizing prices in the trip
// that way we can update our pricing algorithm safely in the future
// we have to keep this hack until 2023-10-08T00:00:00.000+00:00
const dateEnsureDescendingPriceAlgorithmUpdateUpdated = new Date(
    '2021-09-20T21:07:00.000+00:00',
);
const datePredictivePricingAlgorithmImplemented = new Date(
    '2022-12-28T12:00:00.000+00:00',
);

const calculateCostsWithFixedCosts = (
    costThresholds: CostThreshold[],
    fixedTripCosts: FixedTripCosts,
    hostTerms?: models.trips.HostTerm[],
): CostThresholdWithFixedCosts[] => {
    const {
        hostSelectedOptionalServices,
        servicesByDay,
        additionalCosts,
        prices,
    } = fixedTripCosts;
    const hostSelectedOptionalServicesPrice =
        getHostSelectedOptionalServicesPrice(hostSelectedOptionalServices);
    const workshopsPrice = getWorkshopsPriceFromServicesByDay(
        servicesByDay as models.services.ServicesByDay,
    );
    const { singleSupplement, additionalFixedOperatorCost = 0 } =
        additionalCosts;
    const { price: singleSupplementPrice } = singleSupplement || { price: 0 };
    const { operatorFee } = prices;

    const preFixedCost =
        hostSelectedOptionalServicesPrice +
        workshopsPrice +
        singleSupplementPrice +
        additionalFixedOperatorCost;

    const costThresholdsWithFixedCosts = costThresholds.map((costThreshold) => {
        const { numberOfTravelers, price, margin } = costThreshold;
        const fixedCost = margin ? 0 : preFixedCost / numberOfTravelers;
        const extraFee = margin ? margin : operatorFee;
        const priceWithFixedCost = roundUp(price + extraFee + fixedCost);
        return { ...costThreshold, priceWithFixedCost };
    });

    const termsAcceptedDateString = hostTerms && last(hostTerms)?.dateAccepted;

    const shouldDescend =
        !termsAcceptedDateString ||
        new Date(termsAcceptedDateString) >
            dateEnsureDescendingPriceAlgorithmUpdateUpdated;

    if (shouldDescend) {
        return ensureDescendingPrice(costThresholdsWithFixedCosts);
    }
    return costThresholdsWithFixedCosts;
};

const _calculateHostCostsWithFixedCostsAndPricesLegacy = (
    costThresholds: CostThreshold[],
    fixedTripCosts: FixedTripCosts,
    hostPrices: HostPrices,
    hostTerms?: models.trips.HostTerm[],
): CostThresholdWithAllCosts[] => {
    const costThresholdsWithFixedCosts = calculateCostsWithFixedCosts(
        costThresholds,
        fixedTripCosts,
        hostTerms,
    );
    const { serviceFee, transactionFee } = fixedTripCosts.prices;

    const { minimumSpots, initialPrice, remainingPrice } = hostPrices;
    return costThresholdsWithFixedCosts.map((costThresholdsWithFixedCosts) => {
        const { priceWithFixedCost, numberOfTravelers, margin } =
            costThresholdsWithFixedCosts;

        let priceWithAllCosts;
        let serviceCost = 0;
        let transactionCost = 0;
        // margin already added in fixed cost calculation
        if (margin) {
            priceWithAllCosts = priceWithFixedCost;
        } else {
            const hostPrice =
                numberOfTravelers <= minimumSpots
                    ? initialPrice
                    : remainingPrice;
            const exactServiceCost = hostPrice * (serviceFee / 100);
            const exactTransactionCost = hostPrice * (transactionFee / 100);
            priceWithAllCosts = roundUp(
                priceWithFixedCost + exactServiceCost + exactTransactionCost,
            );
            serviceCost = roundUp(exactServiceCost);
            transactionCost = roundUp(exactTransactionCost);
        }

        return {
            ...costThresholdsWithFixedCosts,
            priceWithAllCosts,
            serviceCost,
            transactionCost,
        };
    });
};

const calculateHostCostsWithFixedCostsAndPrices = (
    trip: TripCostDTO,
): CostThresholdWithAllCosts[] => {
    const { hostTerms } = trip;
    const termsAcceptedDateString = hostTerms && last(hostTerms)?.dateAccepted;
    const shouldExecuteOldAlgorithm =
        termsAcceptedDateString &&
        new Date(termsAcceptedDateString) <
            datePredictivePricingAlgorithmImplemented;

    if (shouldExecuteOldAlgorithm) {
        const { costThresholds, minimumSpots = 0, prices } = trip;

        const { initial: initialPrice = 0, remainingPrice = 0 } = prices || {};

        const fixedTripCosts: any = getFixedTripCosts(trip);
        return _calculateHostCostsWithFixedCostsAndPricesLegacy(
            costThresholds!,
            fixedTripCosts,
            {
                minimumSpots,
                initialPrice,
                remainingPrice,
            },
            hostTerms,
        );
    }

    return tripPricing().setTripWithPopulateData(trip).build()
        .hostCostsWithFixedCostsAndPrices;
};

const calculateTotalSingleSupp = (
    singleSupplement: number,
    singleSupplementFee: number,
): number => roundUp(singleSupplement * (1 + singleSupplementFee / 100));

const getFixedTripCosts = (trip: TripCostDTO) => {
    const {
        hostSelectedOptionalServices,
        servicesByDay,
        additionalCosts,
        prices,
    } = trip;

    return {
        hostSelectedOptionalServices,
        servicesByDay,
        additionalCosts,
        prices,
    };
};

// Katie's Algorithm
const calculateDefaultHostPrices = (
    costThresholds: CostThreshold[],
    fixedTripCosts: FixedTripCosts,
    minimumSpots: number,
    hostTerms?: models.trips.HostTerm[],
): HostPrices => {
    const costThresholdsWithFixedCosts = calculateCostsWithFixedCosts(
        costThresholds,
        fixedTripCosts,
        hostTerms,
    );
    const { serviceFee, transactionFee } = fixedTripCosts.prices;
    const lowThreshold: CostThresholdWithFixedCosts | null =
        costThresholdsWithFixedCosts.reduce(
            (
                currentLowestThreshold: CostThresholdWithFixedCosts | null,
                threshold: CostThresholdWithFixedCosts,
            ) => {
                if (threshold.numberOfTravelers <= minimumSpots) {
                    return threshold;
                }
                return currentLowestThreshold;
            },
            null,
        );
    if (!lowThreshold) {
        throw new Error(`Missing compatible threshold`);
    }
    const servicePercentage = serviceFee / 100;
    const transactionPercentage = transactionFee / 100;
    const hostProfitPerTravler = TARGET_MINIMUM_HOST_PROFIT / minimumSpots;
    const hostPercentageOfPrice = 1 - servicePercentage - transactionPercentage;

    const initialPrice = roundUp(
        (hostProfitPerTravler + lowThreshold.priceWithFixedCost) /
            hostPercentageOfPrice,
    );

    return {
        initialPrice,
        remainingPrice: initialPrice + DEFAULT_REMAINING_PRICE_EXTRA,
        minimumSpots,
    };
};

const calculateThresholdPricePerTravelerForHost = (
    tripPricingData: Pick<Trip, 'minimumSpots' | 'hostTerms'>,
    travelersTierNumber: number,
): number => {
    if (!tripPricingData?.hostTerms) {
        throw new Error(`Missing host terms`);
    }
    const costOutputs = getCostOutputs(tripPricingData.hostTerms);

    const correctedTravelersTierNumber =
        travelersTierNumber < tripPricingData.minimumSpots
            ? tripPricingData.minimumSpots
            : travelersTierNumber;
    return (
        costOutputs?.find((costOutput, index) => {
            // check inRange docs -> end range is excluded
            // will return the last element when reached
            if (costOutputs.length - 1 === index) {
                return true;
            }

            const lowLimit = costOutput.numberOfTravelers;
            const highLimit = costOutputs[index + 1].numberOfTravelers;

            return inRange(correctedTravelersTierNumber, lowLimit, highLimit);
        })?.priceWithAllCosts || 0
    );
};

const calculateTravelerOptionalServiceCostWithMargin = (
    servicePrice: number,
    currencyExchangeRate: number,
): number => {
    return convertPriceToUSDWithMargin(
        servicePrice,
        currencyExchangeRate,
        fees.travelerOptionalFee,
    );
};

const calculateTravelerAdditionalOptionalServiceCostWithMargin = (
    servicePrice: number,
    currencyExchangeRate: number,
): number => {
    return convertPriceToUSDWithMargin(
        servicePrice,
        currencyExchangeRate,
        fees.travelerAdditionalOptionalFee,
    );
};

const calculateTravelerSingleSupplementCostWithMargin = (
    singleSupplementPrice: number,
    currencyExchangeRate: number,
    singleSupplementFee?: number,
): number => {
    const fee =
        1 + (singleSupplementFee || getTrovaSingleSupplementFee()) / 100;
    return convertPriceToUSD(singleSupplementPrice * fee, currencyExchangeRate);
};

/**
 * Calculates the deposit amount for a booking
 * @param subtotalAmount - The trip price amount for all travelers
 * @param addOnsPlusInsuranceAmount - The price of all add-ons plus insurance
 * @returns The deposit amount
 */
const calculateDepositAmount = (
    subtotalAmount: number,
    addOnsPlusInsuranceAmount: number = 0,
): number => {
    const payDownWithoutAddOnsAmount =
        (subtotalAmount * getTrovaPayDownPercentage()) / 100;

    return payDownWithoutAddOnsAmount + addOnsPlusInsuranceAmount;
};

/**
 * Calculates the subtotal (travel amount), total, and minimum payment of a booking
 * @param data
 * @param data.tripPrice - The price of the trip per person
 * @param data.travelersQuantity - The number of travelers
 * @param data.addOnsTotal - The total price of all add-ons
 * @param data.insurancePrice - The price of insurance purchased (or 0 if not purchased)
 * @param data.discountTotal - The total amount of discounts applied
 * @returns Object with subtotal (travel amount), subtotalWithAddons (subtotal plus addons), total and minimum payment
 */
const calculateSummaryPricing = ({
    tripPrice,
    travelersQuantity,
    addOnsTotal,
    insurancePrice,
    discountTotal,
}: SummaryPricingData): SummaryPricing => {
    const subtotal = tripPrice * travelersQuantity;

    // Total
    const subtotalWithAddons = subtotal + addOnsTotal;
    const tripTotal = subtotalWithAddons - discountTotal;
    // The trip total is never expected to go negative.
    // Ensuring this allows us to guarantee that the full
    // insurance price is always paid.
    const safeTripTotal = Math.max(0, tripTotal);
    const total = safeTripTotal + insurancePrice;

    // Minimum payment
    const addOnsPlusInsurance = addOnsTotal + insurancePrice;
    const minimumPayment = calculateDepositAmount(
        subtotal,
        addOnsPlusInsurance,
    );
    const effectiveMinimumPayment =
        minimumPayment > total ? total : minimumPayment;

    return {
        subtotal,
        subtotalWithAddons,
        total,
        minimumPayment: effectiveMinimumPayment,
    };
};

/**
 * Calculates how much will be due now based on different factors
 * @param data
 * @param data.total - The total price of the booking
 * @param data.minimumPayment - The minimum payment amount
 * @param data.isSoldOut - Whether or not the trip is sold out
 * @param data.isMinimumPaymentAvailable - Whether or not the minimum payment is available
 * @param data.paymentOptionAmount - The amount to pay for the current payment option (if one is selected), or undefined
 * @returns The total due now
 */
const calculateTotalToPay = ({
    total,
    minimumPayment,
    isSoldOut,
    isMinimumPaymentAvailable,
    paymentOptionAmount,
}: TotalToPayData): number => {
    let totalToPay = total;
    if (isSoldOut) {
        totalToPay = 0;
    } else if (paymentOptionAmount !== undefined) {
        totalToPay = paymentOptionAmount;
    } else if (isMinimumPaymentAvailable) {
        totalToPay = minimumPayment;
    }
    return totalToPay;
};

export {
    calculateCostThresholdsFromValidityPeriods,
    calculateDefaultHostPrices,
    calculateHostPriceFromCostThreshold,
    calculateCostsWithFixedCosts,
    calculateTravelerOptionalServiceCostWithMargin,
    calculateHostCostsWithFixedCostsAndPrices,
    calculateTotalSingleSupp,
    calculateThresholdPricePerTravelerForHost,
    calculateTravelerAdditionalOptionalServiceCostWithMargin,
    calculateTravelerSingleSupplementCostWithMargin,
    calculateDepositAmount,
    calculateSummaryPricing,
    calculateTotalToPay,
};
