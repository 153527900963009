import { css } from '@emotion/react';
import { theme, Theme } from '../../../../shared/theme';

export const mainContainer = () => css`
    display: grid;
    grid-template-areas: 'checkbox';

    > * {
        grid-area: checkbox;
    }
`;

export const input = () => css`
    opacity: 0;
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;
    z-index: 100;

    &:disabled {
        cursor: default;

        + span {
            background-color: ${theme.colors.blueGray[100]};
        }
    }
`;

export const detailSpan = (theme: Theme) => css`
    box-sizing: border-box;
    font-family: ${theme.fonts.roboto};
    font-weight: ${theme.fontWeights.regular};
    font-size: ${theme.fontSizes.sm};
    line-height: 18px;
    color: ${theme.colors.blueGray[650]};
    margin-left: 2rem;
    align-self: center;
    cursor: pointer;
`;

export const checkbox = (error?: string | string[]) =>
    css`
        display: inline-grid;
        border-radius: 4px;
        border: 1px solid ${theme.colors.neutral[80]};
        ${error &&
        error.length &&
        `border: 1px solid ${theme.colors.red.trova};`}
        background-color: ${theme.colors.neutral.white};
        width: 25px;
        height: 25px;
        box-sizing: border-box;
        cursor: pointer;
        img {
            width: 17px;
            height: 17px;
            transition: transform 0.1s ease-in 25ms;
            transform: scale(0);
            transform-origin: bottom left;
            margin: auto;
        }
        input:checked + & img {
            transform: scale(1);
        }
        input:focus + & {
            box-shadow: 0 0 0 0.05em #fff,
                0 0 0.15em 0.1em ${theme.colors.neutral[80]};
        }
        input:disabled + & {
            cursor: default;
        }
    `;
