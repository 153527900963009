import { css } from '@emotion/react';
import { theme } from '../../../../../shared/theme';

export default css`
    .rdtDay {
        line-height: 2rem;
        width: 14.285%;
        text-align: center;
        font-size: 0.8125rem;
        border-radius: ${theme.radii.base};
        color: ${theme.colors.blueGray[650]};
        margin: 0;
        padding: 0;
        font-weight: ${theme.fontWeights.regular};
        :hover {
            cursor: pointer;
            background: ${theme.colors.blueGray[100]};
        }
    }

    .rdtOld,
    .rdtNew {
        color: ${theme.colors.blueGray[300]};
    }

    .rdtMonth,
    .rdtYear {
        width: 25%;
        cursor: pointer;
        line-height: 3.5rem;
        border-radius: ${theme.radii.base};
        text-align: center;
        font-weight: ${theme.fontWeights.regular};
        margin: 0;
        padding: 0;
        :hover {
            background: ${theme.colors.blueGray[100]};
        }
    }

    .rdtMonths,
    .rdtYears {
        margin: 0;
        padding: 0;
    }

    .rdtTimeToggle {
        :hover {
            background: ${theme.colors.blueGray[50]};
            cursor: pointer;
        }
    }

    .rdtActive {
        background-color: ${theme.colors.red.trova} !important;
        color: ${theme.colors.neutral.white};
        box-shadow: none;
        :hover {
            background-color: ${theme.colors.red.trova};
            color: ${theme.colors.neutral.white};
        }
    }

    .rdtDisabled,
    .rdtDisabled:hover {
        background: none;
        color: ${theme.colors.blueGray[300]};
        cursor: not-allowed;
    }
`;
