// @ts-nocheck
import postRobot from 'post-robot';

export type CrossDomainWindow = Window | null;
export type MessageType = string;
export type Data = Record<string, unknown>;

export type Message = {
    messageType: MessageType;
    data?: Data;
};

export type Listener<T> = (event: {
    data: T;
    origin: string;
    source: CrossDomainWindow;
}) => any;

type DataOfMessageType<MT extends MessageType, M extends Message> = Extract<
    M,
    { messageType: MT }
>['data'] extends never
    ? Data
    : Extract<M, { messageType: MT }>['data'];

export type PostMessenger<M extends Message = never> = {
    on: <MT extends M['messageType']>(
        messageType: MT,
        listener: Listener<DataOfMessageType<MT, M>>,
    ) => ReturnType<typeof postRobot.on>;

    send: <MT extends M['messageType']>(
        targetWindow: CrossDomainWindow,
        messageType: MT,
        data: DataOfMessageType<MT, M>,
    ) => ReturnType<typeof postRobot.send>;

    getParent: () => CrossDomainWindow;
};

/**
 * Creates a postMessage messenger for cross-domain communication.
 *
 * See more here: https://trovatrip.slite.com/app/docs/5Z-zccrxdB5K13
 *
 * @template M - The messages type to be used, this is always required to specify.
 * @param domain - The required target domain for cross-domain communication.
 * @param targetWindow - An optional target window for communication.
 * @returns An object containing methods for handling cross-domain communication.
 */
export function createPostMessenger<M extends Message = never>(
    domain: string,
    targetWindow?: CrossDomainWindow,
): PostMessenger<M> {
    return {
        /**
         * Registers a listener for a specific message type.
         *
         * @template MT - The message type to listen for.
         * @param messageType - The message type to listen for.
         * @param listener - The callback function to handle incoming messages.
         * @returns The registration result from the listener which is cancelable.
         */
        on: <MT extends M['messageType']>(
            messageType: MT,
            listener: Listener<DataOfMessageType<MT, M>>,
        ): ReturnType<typeof postRobot.on> => {
            return postRobot.on(
                messageType,
                { domain, window: targetWindow },
                listener,
            );
        },

        /**
         * Sends a message to the target window.
         *
         * @template MT - The message type to send.
         * @param targetWindow - The target window to send the message to.
         * @param messageType - The message type to send.
         * @param data - The data to send with the message.
         * @returns The result of the message send operation which could be a back response with data.
         */
        send: async <MT extends M['messageType']>(
            targetWindow: CrossDomainWindow,
            messageType: MT,
            data: DataOfMessageType<MT, M>,
        ): ReturnType<typeof postRobot.send> => {
            return await postRobot.send(targetWindow, messageType, data);
        },

        /**
         * Retrieves the parent window if available.
         *
         * @returns The parent window if available, otherwise null.
         */
        getParent: (): CrossDomainWindow => {
            const parentOrCurrent = window.parent;
            const current = window.self;

            if (parentOrCurrent && parentOrCurrent !== current) {
                return parentOrCurrent;
            }
            return null;
        },
    };
}
