import { Level, BrowserLogger, allowedArgsTypes } from './Logger.types';

class BrowserTransport {
    public logger(
        message: string,
        level: Level,
        ...args: allowedArgsTypes[]
    ): void {
        const LevelColors: any = {
            debug: '#87CEEB',
            verbose: '#D26DBA',
            info: '#8BF0A3',
            warn: '#F0E68C',
            error: '#FF6347',
        };

        console.log(
            `%c@TROVA:${level.toUpperCase()} %c- ${message}`,
            `color: ${LevelColors[level]};`,
            `color: inherit`,
            ...args,
        );
    }
}

class BrowserLoggerImpl implements BrowserLogger {
    private static instance: BrowserLoggerImpl;
    private transport: BrowserTransport = new BrowserTransport();

    public static getInstance(): BrowserLoggerImpl {
        if (!BrowserLoggerImpl.instance) {
            BrowserLoggerImpl.instance = new BrowserLoggerImpl();
        }

        return BrowserLoggerImpl.instance;
    }

    public debug(message: string, ...args: allowedArgsTypes[]): void {
        this.transport.logger(message, Level.Debug, ...args);
    }

    public verbose(message: string, ...args: allowedArgsTypes[]): void {
        this.transport.logger(message, Level.Verbose, ...args);
    }

    public info(message: string, ...args: allowedArgsTypes[]): void {
        this.transport.logger(message, Level.Info, ...args);
    }

    public warn(message: string, ...args: allowedArgsTypes[]): void {
        this.transport.logger(message, Level.Warn, ...args);
    }

    public error(message: string, ...args: allowedArgsTypes[]): void {
        this.transport.logger(message, Level.Error, ...args);
    }
}

/**
 * @deprecated Use new logger when available
 */
export const browserLogger: BrowserLogger = BrowserLoggerImpl.getInstance();
