import { BaseStatusError } from './baseError';

export const discountErrors = {
    nonExistent: { message: "Discount ({code}) doesn't exist.", code: 1400 },
    nonValid: { message: 'Discount ({code}) is not valid.', code: 1401 },
    nonActive: { message: 'Discount ({code}) is not active.', code: 1402 },
    hasExpired: { message: 'Discount ({code}) has expired.', code: 1403 },
    runOutOfUsages: {
        message: 'Discount ({code}) run out of usages.',
        code: 1404,
    },
    nonValidForTrip: {
        message: 'Discount ({code}) is not valid for this trip.',
        code: 1405,
    },
};

export class NonExistentDiscountError extends BaseStatusError {
    constructor(discountCode: string) {
        super(
            discountErrors.nonExistent.code,
            buildErrorMessage(discountCode, discountErrors.nonExistent.message),
        );
    }
}

export class NonValidDiscountError extends BaseStatusError {
    constructor(discountCode: string) {
        super(
            discountErrors.nonValid.code,
            buildErrorMessage(discountCode, discountErrors.nonValid.message),
        );
    }
}

export class NonActiveDiscountError extends BaseStatusError {
    constructor(discountCode: string) {
        super(
            discountErrors.nonActive.code,
            buildErrorMessage(discountCode, discountErrors.nonActive.message),
        );
    }
}

export class HasExpiredDiscountError extends BaseStatusError {
    constructor(discountCode: string) {
        super(
            discountErrors.hasExpired.code,
            buildErrorMessage(discountCode, discountErrors.hasExpired.message),
        );
    }
}

export class RunOutOfUsagesDiscountError extends BaseStatusError {
    constructor(discountCode: string) {
        super(
            discountErrors.runOutOfUsages.code,
            buildErrorMessage(
                discountCode,
                discountErrors.runOutOfUsages.message,
            ),
        );
    }
}

export class NonValidForTripDiscountError extends BaseStatusError {
    constructor(discountCode: string) {
        super(
            discountErrors.nonValidForTrip.code,
            buildErrorMessage(
                discountCode,
                discountErrors.nonValidForTrip.message,
            ),
        );
    }
}

const buildErrorMessage = (
    discountCode: string,
    baseErrorMessage: string,
): string => {
    return baseErrorMessage.replace('{code}', discountCode);
};
