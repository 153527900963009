import { MiddlewareArguments } from './middleware.types';

export default function facebookPixelMiddleware({ payload, next }: MiddlewareArguments) {
    const callType = payload.type();
    const newPayload = { ...payload };

    if (callType === 'track') {
        if(newPayload.obj?.properties) {
            newPayload.obj.properties.content_type = 'destination';
            newPayload.obj.properties.content_ids = payload.obj.properties.tripId;
        }
    }

    next(newPayload);
}
