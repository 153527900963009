import { BaseLogger, LoggerChildOptions } from './BaseLogger';
import { LoggerConfig } from '../Logger.types';
import pino, {
    Logger as PinoLogger,
    LoggerOptions as PinoLoggerOptions,
    stdSerializers,
} from 'pino';
import { LogLevel } from '../LogLevel';
import { formatDateTime } from '../../utils/date.utils';

const DATE_TIME_FORMAT = 'yyyy-MM-dd hh:mm:ss aa';

enum NumericLogLevel {
    ERROR = 50,
    WARN = 40,
    INFO = 30,
    DEBUG = 20,
}

/**
 * Logs to browser console only
 */
export class BrowserConsoleLogger extends BaseLogger {
    protected createLogger(config: LoggerConfig): PinoLogger {
        return this.createBrowserLogger(config);
    }

    protected createBrowserLogger(
        config: LoggerConfig,
        options?: PinoLoggerOptions['browser'],
    ): PinoLogger {
        const levels: Record<number, LogLevel> = {
            [NumericLogLevel.ERROR]: LogLevel.ERROR,
            [NumericLogLevel.WARN]: LogLevel.WARN,
            [NumericLogLevel.INFO]: LogLevel.INFO,
            [NumericLogLevel.DEBUG]: LogLevel.DEBUG,
        };

        return pino({
            serializers: {
                err: (err: unknown) =>
                    err instanceof Error ? stdSerializers.err(err) : err,
            },
            level: config.level,
            browser: {
                ...options,
                write: ({
                    time,
                    level,
                    name,
                    msg,
                    ...meta
                }: {
                    time?: number;
                    level?: number;
                    name?: string;
                    msg?: string;
                }) => {
                    const levelStr = levels[level ?? NumericLogLevel.INFO];
                    const formattedTime = formatDateTime(
                        time ? new Date(time) : new Date(),
                        DATE_TIME_FORMAT,
                    );
                    const line = `[${formattedTime}] ${levelStr.toUpperCase()} (${name}): ${msg}`;

                    if (meta && Object.keys(meta).length) {
                        console[levelStr](line, meta);
                    } else {
                        console[levelStr](line);
                    }
                },
            },
        });
    }

    child(options: LoggerChildOptions): BaseLogger {
        const childLogger = this.internalLogger.child({ name: options.name });
        return new BrowserConsoleLogger(childLogger);
    }
}
