import { models, constants } from '@trova-trip/trova-models';
import regression, { DataPoint } from 'regression';
import PredictivePricingImpl from '../PredictivePricing/PredictivePricingImpl';
import {
    CostConfig,
    CostPerThreshold,
    CalculatePriceInput,
    CalculateCostScheduleInput,
    OptionalCostThresholds,
    WorkshopCostThresholds,
    CostThresholdWithAllCosts,
    CalculationType,
} from '../PricingCalculator.types';
import { getRoomsConfigForOperators } from '../../utils/trip.utils';
import {
    roundUp,
    sumPrices,
    getThresholdByTier,
    convertToCalculatorThresholds,
    TIER_CUTOFF,
} from './common.utils';
import { fees } from './fees.utils';

type ValidityPeriod = models.itineraries.ValidityPeriod;
type Prices = models.trips.Prices;
type CostThreshold = models.itineraries.CostThreshold;
type CostScheduleItem = models.itineraryInventoryItems.CostScheduleItem;

export const getRoomQuantityForSingleSupplement = (
    hostRooms: models.tripRequest.HostRoom[] = [],
): number => {
    if (hostRooms.length === 0) {
        return 0;
    }
    const configRooms = getRoomsConfigForOperators(hostRooms);
    const singleRooms = configRooms.find(
        ({ roomType }) =>
            roomType === constants.tripRequest.OperatorHostRoomType.SINGLE,
    );

    return singleRooms?.quantity ?? 0;
};

export const getAdjustedTiersForCompanions = (
    costThresholds: models.itineraries.CostThreshold[],
    companionsLength: number,
    calculationType: CalculationType,
): models.itineraries.CostThreshold[] => {
    if (companionsLength <= 0) {
        return costThresholds;
    }

    const shiftedLeftThresholds = costThresholds
        .filter(
            ({ numberOfTravelers }) =>
                numberOfTravelers - companionsLength > 0,
        )
        .map(({ numberOfTravelers, price, platformFee }) => ({
            numberOfTravelers: numberOfTravelers - companionsLength,
            price,
            platformFee,
        }));

    const originalThresholds = shiftedLeftThresholds
        .filter(({ numberOfTravelers }) => {
            const originalTier = numberOfTravelers + companionsLength;
            return (
                originalTier > 0 &&
                !shiftedLeftThresholds.some(
                    ({ numberOfTravelers }) =>
                        numberOfTravelers === originalTier,
                )
            );
        })
        .map(({ numberOfTravelers }) => {
            const originalTier = numberOfTravelers + companionsLength;
            const { pricePerTraveler, platformFee } =
                calculationType === CalculationType.SLOPE
                    ? calculatePredictedPricePerTier(
                          shiftedLeftThresholds,
                          originalTier,
                      )
                    : getTierPriceForInventoryItems(
                          shiftedLeftThresholds,
                          originalTier,
                      );
            return {
                numberOfTravelers: originalTier,
                price: pricePerTraveler,
                platformFee,
            };
        });

    const mergedThresholds = [
        ...shiftedLeftThresholds,
        ...originalThresholds,
    ].sort((a, b) => a.numberOfTravelers - b.numberOfTravelers);

    return _adjustPlatformFeesOnThresholds(costThresholds, mergedThresholds);
};

const _adjustPlatformFeesOnThresholds = (
    originalThresholds: models.itineraries.CostThreshold[],
    currentThresholds: models.itineraries.CostThreshold[],
): models.itineraries.CostThreshold[] => {
    const adjustedThresholds = currentThresholds.map(
        ({ numberOfTravelers: originalNumberOfTravelers, price }) => {
            if (originalNumberOfTravelers <= TIER_CUTOFF) {
                return {
                    numberOfTravelers: originalNumberOfTravelers,
                    price,
                    platformFee: fees.platformFee,
                };
            }

            const platformFee: number | undefined = originalThresholds.find(
                ({ numberOfTravelers }) =>
                    numberOfTravelers === originalNumberOfTravelers,
            )?.platformFee;

            return {
                numberOfTravelers: originalNumberOfTravelers,
                price,
                platformFee,
            };
        },
    );

    const thresholdsWithMissingPlatformFees = adjustedThresholds.map(
        (threshold, index) => {
            if (threshold.platformFee !== undefined) {
                return threshold;
            }

            const platformFee = _getPlatformFeeBetweenTwoTiers(
                adjustedThresholds,
                threshold.numberOfTravelers,
                index,
            );

            return {
                ...threshold,
                platformFee,
            };
        },
    );

    return thresholdsWithMissingPlatformFees;
};

/**
 * Calculate the platform fee between two tiers,
 * which will be applied after the tier cutoff (10th traveler)
 * @param adjustedThresholds
 * @param currentTier
 * @param currentIndex
 * @returns {number} platform fee
 */
const _getPlatformFeeBetweenTwoTiers = (
    adjustedThresholds: models.itineraries.CostThreshold[],
    currentTier: number,
    currentIndex: number,
): number => {
    const previousTier = adjustedThresholds[currentIndex - 1];

    const nextTier = adjustedThresholds[currentIndex + 1];

    let platformFee = fees.platformFee;

    if (previousTier?.platformFee && nextTier?.platformFee) {
        const previousDataPoint: DataPoint =
            previousTier.numberOfTravelers > TIER_CUTOFF
                ? [previousTier.numberOfTravelers, previousTier.platformFee]
                : [TIER_CUTOFF, fees.platformFee];
        const pointToCalculatePlatformFeeSlope: DataPoint[] = [
            previousDataPoint,
            [nextTier.numberOfTravelers, nextTier.platformFee],
        ];

        platformFee = calculateSlope(
            pointToCalculatePlatformFeeSlope,
            currentTier,
        );
    }

    return platformFee;
};

export const calculateSlope = (
    dataPoints: DataPoint[],
    tier: number,
): number => {
    const priceSlope = regression.linear(dataPoints as DataPoint[], {
        order: 1,
    });

    const predictedPrice = priceSlope.predict(tier)[1];

    return predictedPrice > 0 ? roundUp(predictedPrice) : 0;
};

const _getMissingTiersOnItinerary = (
    costThresholds: models.itineraries.CostThreshold[],
    config: CostConfig,
): models.itineraries.CostThreshold[] => {
    const { numberOfTravelersPerThreshold } = config;
    const clonedConstThreshold = [...costThresholds];

    const tiersOnItinerary = clonedConstThreshold.map(
        ({ numberOfTravelers }) => numberOfTravelers,
    );

    const missingTiersOnItinerary = numberOfTravelersPerThreshold.filter(
        (numberOfTravelers) => !tiersOnItinerary.includes(numberOfTravelers),
    );

    missingTiersOnItinerary.forEach((tier) => {
        const predictedPrice = calculatePredictedPricePerTier(
            clonedConstThreshold,
            tier,
        );
        clonedConstThreshold.push({
            numberOfTravelers: predictedPrice.numberTravelers,
            price: predictedPrice.pricePerTraveler,
            platformFee: predictedPrice.platformFee,
        });
    });

    clonedConstThreshold.sort(
        ({ numberOfTravelers: a }, { numberOfTravelers: b }) => a - b,
    );

    return clonedConstThreshold;
};

export const calculatePredictedPricePerTier = (
    costThresholds: models.itineraries.CostThreshold[],
    tier: number,
): CostPerThreshold => {
    return new PredictivePricingImpl(
        costThresholds,
        tier,
    ).getPredictedPricePerTier();
};

export const getTierPriceForInventoryItems = (
    costSchedule: CostScheduleItem[],
    tier: number,
): CostPerThreshold => {
    const costThresholds = convertToCalculatorThresholds(costSchedule);
    const existingTier = getThresholdByTier(costThresholds, tier);

    if (existingTier) {
        return existingTier;
    }

    if (tier <= 0) {
        return {
            numberTravelers: tier,
            pricePerTraveler: 0,
            platformFee: 0,
        };
    }

    const closestTierToTheLeft = costThresholds.reduce((prev, curr) =>
        curr.numberTravelers <= tier &&
        curr.numberTravelers > prev.numberTravelers
            ? curr
            : prev,
    );

    const closestTierToTheRight = costThresholds.reduce((prev, curr) =>
        curr.numberTravelers >= tier &&
        curr.numberTravelers < prev.numberTravelers
            ? curr
            : prev,
    );

    if (!closestTierToTheLeft) {
        const firstTier = costThresholds.reduce((prev, curr) =>
            prev.numberTravelers < curr.numberTravelers ? prev : curr,
        );

        return {
            ...firstTier,
            numberTravelers: tier,
        };
    }

    if (!closestTierToTheRight) {
        const lastTier = costThresholds.reduce((prev, curr) =>
            prev.numberTravelers > curr.numberTravelers ? prev : curr,
        );

        return {
            ...lastTier,
            numberTravelers: tier,
        };
    }

    return {
        ...closestTierToTheLeft,
        numberTravelers: tier,
    };
};

export const getDynamicCostPerThreshold = (
    validityPeriod: models.itineraries.ValidityPeriod,
    config: CostConfig,
): CostPerThreshold[] => {
    const { numberOfTravelersPerThreshold, companions } = config;
    const clonedCostThresholds = [...validityPeriod.costThresholds];

    const adjustedTiersForCompanions = getAdjustedTiersForCompanions(
        clonedCostThresholds,
        companions.length,
        CalculationType.SLOPE,
    );

    const costThresholds = _getMissingTiersOnItinerary(
        adjustedTiersForCompanions,
        config,
    );

    return costThresholds
        .map(({ numberOfTravelers, price, platformFee }) => {
            return {
                numberTravelers: numberOfTravelers,
                pricePerTraveler: roundUp(price),
                platformFee,
            };
        })
        .filter(({ numberTravelers }) =>
            numberOfTravelersPerThreshold.includes(numberTravelers),
        );
};

export const getCostPerThreshold = (
    input: CalculatePriceInput,
): CostPerThreshold => {
    const { customPrice, priceMultiplier, priceDivisor, costPerThreshold } =
        input;
    const { numberTravelers, pricePerTraveler } = costPerThreshold;
    const divisor = priceDivisor ?? numberTravelers;
    const price = customPrice ?? pricePerTraveler;
    return {
        numberTravelers,
        pricePerTraveler:
            numberTravelers === 0
                ? 0
                : roundUp(
                      (priceMultiplier ? price * priceMultiplier : price) /
                          divisor,
                  ),
    };
};

const getTotalsPerThreshold = (data: CalculatePriceInput): CostPerThreshold => {
    const { costPerThreshold, totals = [] } = data;
    let pricePerTraveler = 0;

    for (const costs of totals) {
        if (
            costs[0] &&
            (costs[0] as CostPerThreshold).pricePerTraveler !== undefined
        ) {
            const constThresholds = costs as CostPerThreshold[];
            pricePerTraveler +=
                getThresholdByTier(
                    constThresholds,
                    costPerThreshold.numberTravelers,
                )?.pricePerTraveler ?? 0;
        } else {
            const constThresholds = costs as
                | OptionalCostThresholds[]
                | WorkshopCostThresholds[];

            pricePerTraveler += sumPrices(
                constThresholds,
                costPerThreshold.numberTravelers,
            );
        }
    }

    return {
        numberTravelers: costPerThreshold.numberTravelers,
        pricePerTraveler,
    };
};

export const getStandardCosts = (
    input: CalculateCostScheduleInput,
): CostPerThreshold[] => {
    return input.costSchedule.map((costPerThreshold) =>
        getCostPerThreshold({
            costPerThreshold,
            ...input,
        }),
    );
};

export const getTotalCosts = (
    input: CalculateCostScheduleInput,
): CostPerThreshold[] => {
    return input.costSchedule.map((costPerThreshold) =>
        getTotalsPerThreshold({
            costPerThreshold,
            ...input,
        }),
    );
};

export const getHostCostsWithFixedCostsAndPrices = (
    costs: CostPerThreshold[],
    prices?: Prices,
    minimumSpots?: number,
): CostThresholdWithAllCosts[] => {
    if (!prices || !minimumSpots) {
        return [];
    }

    const {
        initial = 0,
        remainingPrice = 0,
        serviceFee = 0,
        transactionFee = 0,
    } = prices;

    return costs.map(({ numberTravelers, pricePerTraveler }) => {
        const hostPrice =
            numberTravelers <= minimumSpots ? initial : remainingPrice;
        const exactServiceCost = hostPrice * (serviceFee / 100);
        const exactTransactionCost = hostPrice * (transactionFee / 100);
        const priceWithAllCosts =
            pricePerTraveler + exactServiceCost + exactTransactionCost;

        return {
            numberOfTravelers: numberTravelers,
            priceWithFixedCost: pricePerTraveler,
            serviceCost: roundUp(exactServiceCost),
            transactionCost: roundUp(exactTransactionCost),
            priceWithAllCosts: roundUp(priceWithAllCosts),
        };
    });
};
