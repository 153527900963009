import { BaseLogger, LoggerChildOptions } from './BaseLogger';
import { LoggerConfig } from '../Logger.types';
import pino, { Logger as PinoLogger } from 'pino';

/**
 * Logs are sent to Mezmo
 */
export class NodeCentralizedLogger extends BaseLogger {
    protected createLogger(config: LoggerConfig): PinoLogger {
        if (!config.ingestionKey) {
            throw new Error('No logger ingestion key provided');
        }

        return pino({
            level: config.level,
            transport: {
                target: 'pino-logdna',
                options: {
                    key: config.ingestionKey,
                    env: config.env,
                    app: config.app,
                    hostname: config.hostname,
                },
            },
        });
    }

    child(options: LoggerChildOptions): BaseLogger {
        const childLogger = this.internalLogger.child(
            {},
            { msgPrefix: this.getMessagePrefix(options) },
        );
        return new NodeCentralizedLogger(childLogger);
    }
}
