import { addDays, subDays } from 'date-fns';
import { constants, models } from '@trova-trip/trova-models';
import { createTripEndDate } from './date.utils';

type Accommodation = models.services.Accommodation;
type Trip = models.trips.Trip;
type Service = models.services.Service;
type ServiceType = constants.services.ServiceType;
type WorkshopSpace = models.services.WorkshopSpace;
type ServicesByDay = models.services.ServicesByDay;
type PopulatedDayWorkshop = models.services.PopulatedDayService<WorkshopSpace>;

const { ServiceTiming, ServiceType } = constants.services;
const DEFAULT_ACCOMMODATION_NUMBER_OF_NIGHTS = 1;

/**
 * Get the start and end dates of an accommodation
 * @param accommodation The accommodation service
 */
export const getAccommodationStartEndDates = (
    accommodation: Accommodation,
): [startDate: Date | null, endDate: Date | null] => {
    const startDate = accommodation.checkInDay ?? null;
    const endDate =
        accommodation.checkInDay && accommodation.numberOfNights
            ? addDays(accommodation.checkInDay, accommodation.numberOfNights)
            : null;

    return [startDate, endDate];
};

/**
 * Calculate the check-in day of an accommodation based on the trip dates
 * @param accommodation The accommodation service
 * @param trip The trip
 */
export const calculateAccommodationCheckInDay = (
    accommodation: Accommodation,
    trip: Required<Pick<Trip, 'startDate' | 'servicesByDay'>>,
): Date | undefined => {
    switch (accommodation.timing) {
        case ServiceTiming.PRE_TRIP:
            return subDays(
                trip.startDate,
                accommodation.numberOfNights ??
                    DEFAULT_ACCOMMODATION_NUMBER_OF_NIGHTS,
            );
        case ServiceTiming.POST_TRIP:
            return createTripEndDate(trip.startDate, trip.servicesByDay.length);
        default:
            return accommodation.checkInDay;
    }
};

/**
 * Find a bookable service by id and type
 * @param services
 * @param serviceId
 * @param serviceType
 * @returns
 */
export const findBookableServiceByIdAndType = (
    services: Service[],
    serviceId: string,
    serviceType: ServiceType,
): Service | undefined => {
    return services.find(
        (service) =>
            service.bookable !== false &&
            service.type === serviceType &&
            service._id?.toString() === serviceId,
    );
};

/**
 * Calculates the dates of each service day depending on the start date. Each date
 * will be separated by index in an array, each index represents a day of
 * the services by day.
 *
 * @param servicesByDay - All the services separated by day of a Trip or Itinerary
 * @param startDate - The date that the trip or trip request of those services by day starts
 * @returns {Date[]} - an array of Date objects separated by index which represents a day.
 */
export const getServicesByDayDates = (
    servicesByDay: ServicesByDay | undefined,
    startDate: Date | undefined,
): Date[] => {
    if (!startDate) {
        return [];
    }

    const length = servicesByDay ? servicesByDay.length : 0;
    const start = new Date(startDate);

    const dates = [...Array(length).keys()].map((_, index) => {
        return addDays(start, index);
    });

    return dates;
};

export interface WorkshopDetail {
    id: string;
    name: string;
    description: string;
    day: number;
    date: Date;
    hoursAvailable: number;
    hoursRequestedByHost: number;
}

export type SelectedWorkshops = Array<
    [string, { _id: string; length: number }]
>;

/**
 * Returns a map object of the selected workshops by host provided.
 *
 * @param selectedWorkshops
 * @returns {Record<string, number>} - A map object represented by a key which is the workshop ID and the value which is the requested hours.
 */
const getRequestedHoursByWorkshopId = (
    selectedWorkshops: SelectedWorkshops,
): Record<string, number> => {
    return selectedWorkshops.reduce((acc, [id, obj]) => {
        acc[id] = obj.length;
        return acc;
    }, {} as Record<string, number>);
};

/**
 * Type guard to check if the service is a Workshop Space.
 *
 * @param service
 * @returns boolean
 */
const isWorkshopSpace = (
    service: Service | string,
): service is WorkshopSpace => {
    if (typeof service === 'string') {
        return false;
    }
    return service.type === ServiceType.WORKSHOP_SPACE;
};

/**
 * Returns the amount of requested hours by the host of the provided workshop.
 *
 * @param workshop - The workshop object or workshop ID to get the hours requested
 * @param selectedWorkshops - The array of selected workshops by the host
 * @returns {number} - The amount of requested hours by the host only if the workshop was selected.
 */
export const getWorkshopRequestedHours = (
    workshop: string | WorkshopSpace,
    selectedWorkshops?: SelectedWorkshops,
): number => {
    let hoursRequested: number = 0;

    if (isWorkshopSpace(workshop)) {
        hoursRequested = workshop.hoursRequested || 0;
    }

    if (selectedWorkshops && !hoursRequested) {
        const requestedHoursByWorkshopId =
            getRequestedHoursByWorkshopId(selectedWorkshops);
        const id = isWorkshopSpace(workshop) ? workshop._id || '' : workshop;
        hoursRequested = requestedHoursByWorkshopId[id] || 0;
    }

    return hoursRequested;
};

/**
 * Filter workshop details by selected workshops by host.
 *
 * @param workshopsToFilter - The array of workshop details to filter.
 * @param selectedWorkshops - The array containing selected workshops by host.
 * @returns {WorkshopDetail[]} - The filtered array of workshop details.
 */
export const filterWorkshopDetailsBySelectedWorkshops = (
    workshopsToFilter: WorkshopDetail[],
    selectedWorkshops: SelectedWorkshops,
): WorkshopDetail[] => {
    const requestedHours = getRequestedHoursByWorkshopId(selectedWorkshops);
    return workshopsToFilter.filter(({ id }) => !!requestedHours[id]);
};

/**
 * Returns details of all the workshops from the given `servicesByDay`. The start date
 * is required to calculate the workshop date also.
 *
 * Optionally it could be provided the workshops that were selected by the host to add
 * more information to each workshop detail about how many hours of those workshops they
 * requested.
 *
 * @param servicesByDay - All the services separated by day of a Trip or Itinerary
 * @param startDate - The date that the trip or trip request of those services by day starts
 * @param selectedWorkshops - Optional parameter to calculate the requested hours of each workshop by the host
 * @returns {WorkshopDetail[]} - an array of workshop details where the information could vary depending on the `selectedWorkshops` parameter
 */
export const getWorkshopDetailsFromServicesByDay = (
    servicesByDay: ServicesByDay | undefined,
    startDate: Date | undefined,
    selectedWorkshops?: SelectedWorkshops,
): WorkshopDetail[] => {
    if (!servicesByDay || !startDate) {
        return [];
    }

    const dates = getServicesByDayDates(servicesByDay, startDate);

    const workshopDetails = servicesByDay.flatMap((day, dayIndex) => {
        const workshops = day
            .filter((dayService): dayService is PopulatedDayWorkshop => {
                const service = dayService.service as Service;
                return service.type === ServiceType.WORKSHOP_SPACE;
            })
            .map((dayService) => dayService.service);

        const details: WorkshopDetail[] = workshops.map((workshop) => {
            const {
                _id: id = '',
                name = '',
                description = '',
                hoursAvailable,
            } = workshop;

            const detail: WorkshopDetail = {
                id,
                name,
                description,
                hoursAvailable,
                hoursRequestedByHost: getWorkshopRequestedHours(
                    workshop,
                    selectedWorkshops,
                ),
                day: dayIndex + 1,
                date: dates[dayIndex],
            };

            return detail;
        });

        return details;
    });

    return workshopDetails;
};
