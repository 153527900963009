import { models } from '@trova-trip/trova-models';

type Companion = models.common.Companion;
type ItineraryPackageLevels = models.itineraries.ItineraryPackageLevels;
type Service = models.services.Service;
type SavedItineraryInventoryItem =
    models.itineraryInventoryItems.SavedItineraryInventoryItem;
type WorkshopSpace = models.services.DayService<models.services.WorkshopSpace>;
type Prices = models.trips.Prices;

//TODO replace these with trova-models reference
export interface Currency {
    exchangeRate: number;
}

export type DayOfTheWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export interface CostThreshold extends Cost {
    daysOfTheWeek?: Array<DayOfTheWeek>;
    numberOfTravelers: number;
    margin?: number;
    platformFee?: number;
}

export interface CostThresholdWithFixedCosts {
    numberOfTravelers: number;
    priceWithFixedCost: number;
    margin?: number;
}

export interface CostThresholdWithAllCosts
    extends Omit<CostThresholdWithFixedCosts, 'margin'> {
    priceWithAllCosts: number;
    serviceCost: number;
    transactionCost: number;
}

export interface Cost {
    price: number;
    name?: string;
    quantity?: number;
    selected?: boolean;
    bookable?: boolean;
}

export interface ItineraryAdditionalCosts {
    singleSupplement?: Cost;
}

export interface TripAdditionalCosts extends ItineraryAdditionalCosts {
    [index: string]: Cost | number | undefined;
    additionalFixedOperatorCost?: number;
    hostGroundTransferCost?: number;
}

export interface ValidityPeriod {
    startDate: Date;
    endDate: Date;
    costThresholds: Array<CostThreshold>;
    blackoutStartDate: {
        isBlackout: boolean;
        // 0 = Sunday - 6 = Saturday
        daysOfTheWeek: Array<0 | 1 | 2 | 3 | 4 | 5 | 6>;
    };
    additionalCosts: ItineraryAdditionalCosts;
}

export interface FoundCostThreshold {
    periodFound: boolean;
    newCostThresholds?: Array<CostThreshold>;
    costThresholds?: {
        costThresholds: Array<CostThreshold>;
        additionalCosts?: ItineraryAdditionalCosts;
    };
}

export interface ValidityPeriodWithAdditionalCost extends ValidityPeriod {
    additionalCosts: {
        singleSupplement?: Cost;
        [key: string]: Cost | undefined;
    };
}

export interface Accomodations<T> {
    beforeTrip: {
        double: T;
        single: T;
    };
    afterTrip: {
        double: T;
        single: T;
    };
}

export interface Transfers<T> {
    beforeTrip: T;
    afterTrip: T;
}

export interface HostSelectedOptionalServices {
    activity: Cost;
    numberOptingIn: number;
}

export interface Itinerary {
    minimumSpots: number;
    maximumSpots: number;
    additionalOptionalServices: Array<Service>;
    currency: Currency;
    tourLeaderPrice: number;
    startingPrice: number;
    servicesByDay: Array<Array<DayService>>;
    packages: ItineraryPackageLevels;
}

export interface TripPrices {
    initial: number;
    remainingPrice: number;
    operatorFee: number;
    serviceFee: number;
    transactionFee: number;
    singleSupplementFee: number;
}

export interface HostPrices extends Pick<TripPrices, 'remainingPrice'> {
    minimumSpots: number;
    initialPrice: number;
}

export interface Trip
    extends Omit<
        models.trips.Trip,
        | 'additionalOptionalServices'
        | 'prices'
        | 'hostSelectedOptionalServices'
        | 'additionalCosts'
    > {
    prices: TripPrices;
    costThresholds: Array<CostThreshold>;
    additionalCosts: TripAdditionalCosts;
    hostSelectedOptionalServices: Array<HostSelectedOptionalServices>;
    earningsAdjustment: number;
    additionalOptionalServices: models.services.Service[];
    minimumSpots: number;
    maximumSpots: number;
}

export interface FixedTripCosts
    extends Pick<
        Trip,
        | 'hostSelectedOptionalServices'
        | 'servicesByDay'
        | 'additionalCosts'
        | 'prices'
    > {}

export interface DayService {
    service: Service | string;
    duration: number;
}

export interface FinalCostThresholds
    extends Omit<
        CostThresholdWithAllCosts,
        'numberOfTravelers' | 'priceWithAllCosts'
    > {
    travelerRowNumber: number;
    costPerTraveler: number;
    totalCost: number;
}

export interface ThresholdEarning extends FinalCostThresholds {
    totalEarnings: number;
    disabled: boolean;
}

export type TripCostDTO = Partial<
    Pick<
        Trip,
        | 'minimumSpots'
        | 'maximumSpots'
        | 'prices'
        | 'hostTerms'
        | 'host'
        | 'companions'
        | 'yearOverYearIncrease'
        | 'startDate'
        | 'servicesByDay'
        | 'hostSelectedOptionalServices'
        | 'costThresholds'
        | 'additionalCosts'
        | 'lockedExchangeRate'
        | 'hostRooms'
        | 'selectedPackage'
        | 'earningsAdjustment'
    >
> & {
    currency?: models.currencies.Currency;
    itinerary?: {
        packages?: models.itineraries.ItineraryPackageLevels;
    };
    itineraryInventoryItem?: SavedItineraryInventoryItem;
};

export type TripRequestCostDTO = Partial<
    Pick<
        models.tripRequest.BaseTripRequest,
        | 'host'
        | 'companions'
        | 'tripLength'
        | 'quantityHostRooms'
        | 'hostRooms'
        | 'selectedPackage'
    >
> & {
    startDate?: string;
    hostSelectedOptionalServices?: Array<HostSelectedOptionalServices>;
    selectedWorkshopSpaces?: Array<{
        length: number;
        activity: models.services.WorkshopSpace;
    }>;
    itinerary?: {
        yearOverYearIncrease?: number;
        additionalOptionalServices?: models.itineraries.Itinerary['additionalOptionalServices'];
        packages: ItineraryPackageLevels;
    };
    itineraryInventoryItem?: SavedItineraryInventoryItem;
};

export type ItineraryCostDTO = Partial<
    Pick<
        models.itineraries.Itinerary,
        | 'minimumSpots'
        | 'packages'
        | 'currency'
        | 'yearOverYearIncrease'
        | 'additionalOptionalServices'
        | 'hostGroundTransferCost'
        | 'itineraryInventoryEnabled'
    >
>;

export interface CostPerThreshold {
    numberTravelers: number;
    pricePerTraveler: number;
    platformFee?: number;
}

export interface BaseCostThresholds {
    singleSupplementPrice: number;
    costPerThreshold: Array<CostPerThreshold>;
}

export interface CostByDayThresholds {
    day: Date;
    singleSupplementPrice: number;
    costPerThreshold: Array<CostPerThreshold>;
}

export interface ValidityPeriodByDay {
    day: Date;
    singleSupplementPrice: number;
    validityPeriod: models.itineraries.ValidityPeriod;
}

export interface OptionalCostThresholds {
    name: string;
    price: number;
    quantity: number;
    costPerThreshold: Array<CostPerThreshold>;
}

export interface CompanionCostThresholds {
    quantity: number;
    costPerThreshold: Array<CostPerThreshold>;
}

export interface WorkshopCostThresholds {
    name: string;
    hours: number;
    costPerThreshold: Array<CostPerThreshold>;
}

export enum OptName {
    PreSingle = 'Pre-trip Accommodation',
    PostSingle = 'Post-trip Accommodation',
    PreDouble = 'Pre-trip Double Accommodation',
    PostDouble = 'Post-trip Double Accommodation',
    PreTransfer = 'Pre-trip Transfer',
    PostTransfer = 'Post-trip Transfer',
}

export enum OptType {
    Accommodation,
    Transfer,
}

export type ThresholdEarningsForTrip = {
    costThresholdsEarnings: Array<ThresholdEarning>;
    maxValue: number;
};

/**
 * @deprecated
 */
export type CostConfig = {
    hostSelectedOptionalServices?: Array<HostSelectedOptionalServices>;
    companions: Array<Companion>;
    startDate: Date;
    yearOverYearIncrease: number;
    tripLength: number;
    workshops: Array<models.services.DayService<models.services.WorkshopSpace>>;
    validityPeriodsByDay: Array<ValidityPeriodByDay>;
    numberOfTravelersPerThreshold: number[];
    singleSupplementPrice: number;
    costThresholdsFromTrip?: Array<models.itineraries.CostThreshold>;
    hostRooms: Array<models.tripRequest.HostRoom>;
    isHosted: boolean;
    prices?: TripPrices;
    minimumSpots?: number;
    hostGroundTransferCost: number;
};

export type CostConfiguration = {
    startDate: Date;
    focQuantity: number;
    tripLength: number;
    roomsQuantity: number;
    minimumSpots?: number;
    prices?: Prices;
    singleSupplementPrice: number;
    hostGroundTransferCost: number;
    workshops: Array<WorkshopSpace>;
    costSchedule: Array<CostPerThreshold>;
    hostSelectedOptionalServices?: Array<HostSelectedOptionalServices>;
};

type BasePriceInput = {
    customPrice?: number;
    priceMultiplier?: number;
    priceDivisor?: number;
    totals?: Array<
        OptionalCostThresholds[] | WorkshopCostThresholds[] | CostPerThreshold[]
    >;
};

export type CalculatePriceInput = {
    costPerThreshold: CostPerThreshold;
} & BasePriceInput;

export type CalculateCostScheduleInput = {
    costSchedule: Array<CostPerThreshold>;
} & BasePriceInput;

export type CalculatedCosts = {
    costsByDay: CostPerThreshold[];
    currentCosts: CostPerThreshold[];
    focCosts: CostPerThreshold[];
    optionalCosts: OptionalCostThresholds[];
    workshopsCosts: WorkshopCostThresholds[];
    servicesTotalCosts: CostPerThreshold[];
    singleSupplementCostAdjustments: CostPerThreshold[];
    operatorTotalCosts: CostPerThreshold[];
    singleSupplementCosts: CostPerThreshold[];
    platformFeeCosts: CostPerThreshold[];
    hostGroundTransferCosts: CostPerThreshold[];
    hostTotalCosts: CostPerThreshold[];
    hostCostsWithFixedCostsAndPrices: CostThresholdWithAllCosts[];
};

export type CalculatedHostRevenues = {
    projectedEarnings: number;
    collectedEarnings: number;
};

export type SuggestedSellPrice = {
    totalCost: number;
    initialPrice: number;
    remainingPrice: number;
};

export interface SummaryPricingData {
    tripPrice: number;
    travelersQuantity: number;
    addOnsTotal: number;
    insurancePrice: number;
    discountTotal: number;
}

export interface SummaryPricing {
    /**
     * The trip price multiplied by the number of travelers.
     */
    subtotal: number;
    /**
     * The subtotal plus the total price of all addons.
     */
    subtotalWithAddons: number;
    /**
     * The subtotal plus the total price of all addons,
     * minus any discounts applied, plus the price of any selected insurance.
     * The total can never be less than the full price of insurance
     * since insurance must always be paid in full.
     */
    total: number;
    /**
     * Minimum payment available to pay for a booking.
     * Includes the full price of any selected insurance
     * as insurance must always be paid in full.
     */
    minimumPayment: number;
}

export interface TotalToPayData {
    total: number;
    minimumPayment: number;
    isSoldOut: boolean;
    isMinimumPaymentAvailable: boolean;
    paymentOptionAmount?: number;
}

export enum CalculationType {
    SLOPE = 'SLOPE',
    INVENTORY_ITEM = 'INVENTORY_ITEM',
}
