import { countries } from '@trova-trip/country-data';
import { constants, models } from '@trova-trip/trova-models';
import { format, isAfter, isBefore, sub, subDays, subHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import differenceInDays from 'date-fns/differenceInDays';
import parseISO from 'date-fns/parseISO';
import startOfDay from 'date-fns/startOfDay';
import isEmpty from 'lodash/isEmpty';
import {
    DEFAULT_BOOKINGS_DEADLINE,
    LIVE_NOT_CONFIRMED_BOOKINGS_DEADLINE,
} from '../app.constants';

type Trip = models.trips.Trip;
type Service = models.services.Service;
type HostRoom = models.tripRequest.HostRoom;
type OperatorRoomConfig = models.tripRequest.OperatorRoomConfig;
type UpcomingTripStatus = models.hostHomeTab.UpcomingTripStatus;
type MappedTripStatusForHostHomeTab = Record<
    UpcomingTripStatus,
    models.hostHomeTab.TripStatuses
>;
type ItineraryPackage = models.itineraries.ItineraryPackage;

const TRIP_STATUS = constants.trips.TRIP_STATUS;
const PENDING_TRIP_REQUEST = constants.tripRequest.tripRequestStatuses.PENDING;
const COUNTRIES = countries.all;
const LAST_REFUNDABLE_DAY_BEFORE_TRIP =
    constants.trips.LAST_REFUNDABLE_DAY_BEFORE_TRIP;

export const generateTripName = (
    host: models.users.BaseUser,
    countryCode: string,
): string => {
    const countryName = COUNTRIES.find(
        (country) => country.alpha2 === countryCode,
    )?.name;

    const hostName = host.displayName
        ? `${host.displayName}`
        : `${host.firstName} ${host.lastName}`;

    return `${countryName} with ${hostName}`;
};

/**
 * If a trip is live but not confirmed, trip close date should be the trip
 * start date minus 90 days. If a trip is confirmed, trip close date should
 * be the trip start date minus the trip's bookingsDeadline.
 * @param trip - The trip to get start date, status, and bookings deadline from
 */
export const getTripCloseDate = (trip: Trip): Date => {
    const {
        startDate,
        status,
        bookingsDeadline = DEFAULT_BOOKINGS_DEADLINE,
    } = trip;
    const closeWindow =
        status === TRIP_STATUS.LIVE
            ? LIVE_NOT_CONFIRMED_BOOKINGS_DEADLINE
            : bookingsDeadline;
    return sub(new Date(startDate as Date), { days: closeWindow });
};

export const getTripCurrentPrice = (trip: Trip) => {
    return trip.hasReachedMinSpots
        ? trip.prices?.remainingPrice
        : trip.prices?.initial;
};

/**
 * Return the ID of the first host in the trip's host array.
 * @param {Trip} trip - The trip to get the host ID from.
 * @returns {string|undefined}
 */
export const getPrimaryHostId = (trip: Trip): string | undefined => {
    const host = trip.host.length > 0 ? trip.host[0] : undefined;

    let hostId;
    if (typeof host === 'string') {
        hostId = host;
    } else if (host) {
        hostId = host._id.toString();
    }

    return hostId;
};

export const getTripOptionalActivities = (trip: Trip): Service[] => {
    const { servicesByDay } = trip;
    return (
        servicesByDay?.flatMap((day) =>
            day
                .filter((dayService) => {
                    const service = dayService.service as Service;
                    return (
                        service.type === 'activity' && !service.includedActivity
                    );
                })
                .map(({ service }) => service as Service),
        ) || []
    );
};

export const getRoomsConfigForOperators = (
    rooms: HostRoom[] = [],
): OperatorRoomConfig[] => {
    const roomsQtyByType = rooms.reduce(
        (accumulator: { [key: string]: number }, room: HostRoom) => {
            accumulator[room.roomType] = (accumulator[room.roomType] || 0) + 1;
            return accumulator;
        },
        {},
    );
    const oneBedQty =
        roomsQtyByType[constants.tripRequest.HostRoomType.ONE_BED] || 0;
    const sharedBedQty =
        roomsQtyByType[constants.tripRequest.HostRoomType.SHARED_BED] || 0;
    const twoBedsQty =
        roomsQtyByType[constants.tripRequest.HostRoomType.TWO_BEDS] || 0;

    const roomsForOperator: OperatorRoomConfig[] = [];
    if (oneBedQty > 0) {
        roomsForOperator.push({
            roomType: constants.tripRequest.OperatorHostRoomType.SINGLE,
            quantity: oneBedQty,
        });
    }
    if (sharedBedQty > 0) {
        roomsForOperator.push({
            roomType: constants.tripRequest.OperatorHostRoomType.DOUBLE,
            quantity: sharedBedQty,
        });
    }
    if (twoBedsQty > 0) {
        roomsForOperator.push({
            roomType: constants.tripRequest.OperatorHostRoomType.TWIN,
            quantity: twoBedsQty,
        });
    }
    // sort by quantity desc
    return roomsForOperator.sort((a, b) => b.quantity - a.quantity);
};

/**
 * Determines whether a trip has launched or not.
 *
 * @param {Trip} trip - The trip object to check.
 * @returns {boolean} True if the trip has not yet launched, false otherwise.
 */
export const hasTripLaunched = (trip: Trip): boolean => {
    const now = new Date();
    if (!trip?.launchDate) {
        return false;
    }

    return new Date(trip.launchDate) < now;
};

/**
 * Maps a trip model status to the Host Home UI statuses.
 * @param {Trip['status']} tripStatus - The trip status to map.
 * @returns {models.hostHomeTab.TripStatuses} True if the trip has not yet launched, false otherwise.
 */
export const getHomeTripStatus = (
    tripStatus: UpcomingTripStatus,
): models.hostHomeTab.TripStatuses => {
    const originalTripStatus = TRIP_STATUS;
    const currentTripStatus = models.hostHomeTab.TripStatuses;

    const statuses: MappedTripStatusForHostHomeTab = {
        [PENDING_TRIP_REQUEST]: currentTripStatus.Requested,
        [originalTripStatus.CREATED]: currentTripStatus.Requested,
        [originalTripStatus.PARTNER_APPROVED]: currentTripStatus.Requested,
        [originalTripStatus.TROVA_PRICING_APPROVED]: currentTripStatus.Pending,
        [originalTripStatus.HOST_APPROVED]: currentTripStatus.Pending,
        [originalTripStatus.LIVE]: currentTripStatus.Live,
        [originalTripStatus.READY_TO_CONFIRM]: currentTripStatus.Live,
        [originalTripStatus.EARLY_CONFIRMED]: currentTripStatus.Confirmed,
        [originalTripStatus.CONFIRMED]: currentTripStatus.Confirmed,
        [originalTripStatus.CLOSED]: currentTripStatus.Closed,
    };

    return statuses[tripStatus];
};

/**
 * Verifies if ground transfers can be enabled for the trip
 * @param trip The trip object
 */
export const canEnableGroundTransfers = (
    trip: Pick<Trip, 'additionalCosts'>,
): boolean => {
    return Boolean(trip.additionalCosts?.hostGroundTransferCost);
};

/**
 * Verifies if the single supplement is available for the trip
 * @param trip The trip object
 */
export const isSingleSupplementAvailable = (
    trip: Pick<Trip, 'additionalCosts'>,
): boolean => {
    return Boolean(trip.additionalCosts?.singleSupplement.bookable);
};

/**
 * Retrieves a package based on the itinerary and trip selected package
 * @param trip The trip object
 */
export const getSelectedPackage = (
    trip: Pick<Trip, 'selectedPackage' | 'itinerary'>,
): ItineraryPackage | null => {
    const { itinerary, selectedPackage } = trip;

    if (!itinerary || isEmpty(itinerary) || typeof itinerary === 'string') {
        return null;
    }

    return itinerary.packages[selectedPackage];
};

/**
 * Check if the trip deadline has been exceeded the 90 days
 * @param trip The trip object
 */
export const isTripDeadlineExceeded = (trip: Trip): boolean => {
    const { startDate } = trip;
    if (!startDate) {
        return false;
    }

    const ninetyDays = LIVE_NOT_CONFIRMED_BOOKINGS_DEADLINE;
    const deadline = subDays(startDate, ninetyDays);
    return isBefore(deadline, new Date());
};

/**
 * Check if the trip has started based on the trip start date and the current date.
 * Since we don't actually know the trip time zone, we're erring on the side of saying the trip has started
 * early rather than risking say it hasn't started when it actually has.
 *
 * @param trip - The trip object containing the start date.
 * @param currentDate - This date is just for testing purposes, by default it uses the current date.
 * @returns `true` if the trip has "started", `false` otherwise.
 */
export const hasTripStarted = (
    trip: Trip,
    currentDate: Date = new Date(),
): boolean => {
    if (!trip?.startDate) {
        return false;
    }

    const tripStartDate =
        typeof trip.startDate === 'string'
            ? parseISO(trip.startDate)
            : trip.startDate;

    /**
     * We're using the Kiribati time zone because it's the worst case scenario for this check.
     */
    const KIRIBATI_OFFSET_FROM_UTC = 14;

    const tripStartDateWithOffset = subHours(
        tripStartDate,
        KIRIBATI_OFFSET_FROM_UTC,
    );

    return isAfter(currentDate, tripStartDateWithOffset);
};

/**
 * Calculates the number of days until the start of the trip.
 *
 * @param trip
 * @returns The number of days until the trip starts or `undefined` if the start date is not available.
 */

export const getDaysUntilTripStarts = (trip: Trip): number | undefined => {
    if (!trip?.startDate) {
        return undefined;
    }

    const startDate = startOfDay(new Date(trip.startDate));
    const now = startOfDay(new Date());

    return differenceInDays(startDate, now);
};

/**
 * Gets the refundable date of the trip, which is 91 days before the trip start date.
 *
 * @param trip
 * @returns The refundable date formatted (e.g. 10/10/2024) or `undefined` if the start date is not available.
 */
export const getFormattedTripRefundableDate = (
    trip: Trip,
): string | undefined => {
    if (!trip?.startDate) {
        return undefined;
    }

    const startDate = utcToZonedTime(trip.startDate, 'UTC');
    const refundableDate = subDays(startDate, LAST_REFUNDABLE_DAY_BEFORE_TRIP);

    return format(refundableDate, 'MM/dd/yyyy');
};
