type PromiseMap<T> = {
    [K in keyof T]: Promise<T[K]>;
};

type PromiseAllMapResult<T> = {
    [K in keyof T]: T[K] extends Promise<infer U> ? U : T[K];
};

type PromiseAllSettledMapResult<T> = {
    [K in keyof T]: PromiseSettledResult<T[K]>;
};

type ResolvedMap<T> = PromiseAllMapResult<T> & PromiseAllSettledMapResult<T>;

type PromiseAllFunction = <T>(
    promises: readonly (T | PromiseLike<T>)[],
) => Promise<T[]>;

type PromiseAllSettledFunction = <T>(
    promises: readonly (T | PromiseLike<T>)[],
) => Promise<PromiseSettledResult<T>[]>;

type PromiseMethod = PromiseAllFunction | PromiseAllSettledFunction;

/**
 * Executes the provided Promise method with the given PromiseMap.
 * @template T - Generic type for the executePromiseMap function
 * @param {PromiseMap<T>} promiseMap - Mapping of promises
 * @param {PromiseMethod} promiseMethod - Promise method (Promise.all / Promise.allSettled)
 */
async function executePromiseMap<T>(
    promiseMap: PromiseMap<T>,
    promiseMethod: PromiseMethod,
): Promise<ResolvedMap<T>> {
    const keys = Object.keys(promiseMap);
    const promiseEntries = Object.values(promiseMap);
    const results = await promiseMethod(promiseEntries);

    const output = keys.reduce((acc, key, index) => {
        return { ...acc, [key]: results[index] };
    }, {} as ResolvedMap<T>);

    return output;
}

/**
 * Resolves all promises in the PromiseMap using Promise.all.
 * @template T - Generic type for the promiseAllMap function
 * @param {PromiseMap<T>} promiseMap - Mapping of promises
 */
async function promiseAllMap<T>(
    promiseMap: PromiseMap<T>,
): Promise<PromiseAllMapResult<T>> {
    return executePromiseMap(promiseMap, Promise.all.bind(Promise));
}

/**
 * Resolves all promises in the PromiseMap using Promise.allSettled.
 * @template T - Generic type for the promiseAllSettledMap function
 * @param {PromiseAllSettledMapResult<T>} promiseMap - Mapping of settled promises
 */
async function promiseAllSettledMap<T>(
    promiseMap: PromiseMap<T>,
): Promise<PromiseAllSettledMapResult<T>> {
    return executePromiseMap(promiseMap, Promise.allSettled.bind(Promise));
}

/**
 * Executes an array of promises in batches using Promise.allSettled.
 * @param promises An array of promises to be executed
 * @param batchSize The size of each batch
 */
async function promiseAllSettledInBatches<T>(
    promises: Promise<T>[],
    batchSize: number,
): Promise<PromiseSettledResult<T>[]> {
    const results: PromiseSettledResult<T>[] = [];

    for (let i = 0; i < promises.length; i += batchSize) {
        const batchPromises = promises.slice(i, i + batchSize);
        const batchResults = await Promise.allSettled(batchPromises);
        results.push(...batchResults);
    }

    return results;
}

export { promiseAllMap, promiseAllSettledMap, promiseAllSettledInBatches };
