import React from 'react';
import { ModalImpl } from '../../Modal';
import type { ModalImplProps } from '../../Modal';
import type { DialogProps } from '../Dialog';

interface DialogModalProps extends Omit<ModalImplProps, 'position'> {
    position: DialogProps['position'];
}

export const DialogModal: React.FC<DialogModalProps> = props => {
    const { children, position, ...rest } = props;

    const isCenterPosition = position === 'centered';
    const isBottomPosition = position === 'bottom';

    return (
        <ModalImpl
            autoFocus={false}
            isCentered={isCenterPosition}
            overlayProps={{
                background: 'blackAlpha.400',
                backdropFilter: 'blur(3px)',
            }}
            contentProps={{
                width: { base: 'auto', sm: 'min-content' },
                maxW: { sm: '100%' },
                paddingX: { base: 4, md: 0 },
                boxShadow: 'none',
                ...(isBottomPosition && {
                    width: '100%',
                    paddingX: 0,
                    margin: 0,
                }),
            }}
            contentContainerProps={{
                ...(isBottomPosition && {
                    justifyContent: 'start',
                    alignItems: 'end',
                }),
            }}
            {...rest}
        >
            {children}
        </ModalImpl>
    );
};
