import React from 'react';
import { StackImpl } from '../../../Layout/Stack';
import { ButtonImpl, ButtonProps } from '../../../Forms/Button';

export type DialogFooterProps =
    | {
          children?: React.ReactNode;
          actions?: never;
      }
    | {
          children?: never;
          actions?: ButtonProps[];
      };

export const DialogFooter = (props: DialogFooterProps) => {
    const { actions, children } = props;

    return (
        <>
            {actions ? (
                <StackImpl justify='flex-end' align='center' w='100%'>
                    {actions.map((action, index) => (
                        <ButtonImpl key={index} {...action} />
                    ))}
                </StackImpl>
            ) : (
                children
            )}
        </>
    );
};
