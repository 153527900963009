import * as httpErrors from './httpErrors';
import * as securityErrors from './securityErrors';
import * as bookingErrors from './bookingErrors';
import * as pricingErrors from './pricingErrors';
import * as integrationErrors from './integrationErrors';
import * as authErrors from './authErrors';
import * as contextErrors from './appContextErrors';
import * as discountErrors from './discountErrors';
import * as insuranceErrors from './insuranceErrors';
import * as groundTransfersErrors from './groundTransfersErrors';
import * as itineraryInventoryItemsErrors from './itineraryInventoryItemsErrors';
import * as socialEngagementErrors from './socialEngagementErrors';

const { httpMessages, ...httpStatusErrors } = httpErrors;
const { securityMessages, ...securityStatusErrors } = securityErrors;
const { bookingErrors: bookingErrorsObject, ...bookingStatusErrors } =
    bookingErrors;
const { pricingMessages, ...pricingStatusErrors } = pricingErrors;
const {
    integrationErrors: integrationErrorsObject,
    ...integrationStatusErrors
} = integrationErrors;
const { authMessages, jwtMessages, ...AuthStatusErrors } = authErrors;
const { contextMessages, ...contextStatusErrors } = contextErrors;
const { discountErrors: discountErrorsObject, ...discountStatusErrors } =
    discountErrors;
const { insuranceErrors: insuranceErrorsObject, ...insuranceStatusErrors } =
    insuranceErrors;
const {
    groundTransfersErrors: groundTransfersErrorsObject,
    ...groundTransfersStatusErrors
} = groundTransfersErrors;
const {
    itineraryInventoryItemsErrors: itineraryInventoryItemsErrorsObject,
    ...itineraryInventoryItemsStatusErrors
} = itineraryInventoryItemsErrors;
const {
    socialEngagementErrors: socialEngagementErrorsObject,
    ...socialEngagementStatusErrors
} = socialEngagementErrors;

export const errorMessages = {
    ...httpMessages,
    ...pricingMessages,
    ...contextMessages,
};

export const errorObjects = {
    ...bookingErrorsObject,
    ...integrationErrorsObject,
    ...discountErrorsObject,
    ...insuranceErrorsObject,
    ...groundTransfersErrorsObject,
    ...itineraryInventoryItemsErrorsObject,
    ...socialEngagementErrorsObject,
};

export const errorNameSpaces = {
    authMessages,
    jwtMessages,
    securityMessages,
};

export const StatusError = {
    ...httpStatusErrors,
    ...securityStatusErrors,
    ...bookingStatusErrors,
    ...pricingStatusErrors,
    ...integrationStatusErrors,
    ...AuthStatusErrors,
    ...contextStatusErrors,
    ...discountStatusErrors,
    ...insuranceStatusErrors,
    ...groundTransfersStatusErrors,
    ...itineraryInventoryItemsStatusErrors,
    ...socialEngagementStatusErrors,
};
