import React from 'react';
import {
    MenuItem as MenuItemChakra,
    MenuItemProps as MenuItemPropsChakra,
    useStyleConfig,
} from '@chakra-ui/react';
import { genDataTestId } from '../../../../../shared/utils/helpers';
import { StackProps } from '../../../../Layout/Stack';

export interface MenuListItemProps {
    onClick?: (value: string) => void;
    isDisabled?: MenuItemPropsChakra['isDisabled'];
    isFocusable?: MenuItemPropsChakra['isFocusable'];
    label: string;
    value: string;
    justify?: StackProps['justify'];
}

export interface MenuListItemImplProps
    extends Omit<MenuItemPropsChakra, 'onClick' | 'value'>,
        MenuListItemProps {}

export const MenuListItemImpl = (props: MenuListItemImplProps) => {
    const { label, onClick, value, justify, ...rest } = props;
    const styles = useStyleConfig('MenuListItem', { justify });
    return (
        <MenuItemChakra
            onClick={() => onClick && onClick(value)}
            sx={styles}
            {...rest}
        >
            {label}
        </MenuItemChakra>
    );
};

const MenuListItem = (props: MenuListItemProps) => (
    <MenuListItemImpl data-testid={genDataTestId('menu-item')} {...props} />
);

export default MenuListItem;
