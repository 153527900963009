import { constants, models } from '@trova-trip/trova-models';
import {
    getBookingAddsOnsAmount,
    getBookingStartEndDates,
} from './booking.utils';
import {
    dropTime,
    formatDateUSShort,
    getDifferenceInDays,
    addDaysToDate,
    formatDateUSFull,
} from './date.utils';
import { StatusError } from '../errors';

type AddOn = models.bookings.AddOn;
type Booking = models.bookings.Booking;
type CheckoutAddOn = models.checkout.CheckoutAddOn;
type InsuranceRequest = models.insurance.InsuranceRequest;
type TripWithPopulatedServices = models.trips.TripWithPopulatedServices;
type InsurancePolicyAddOn = models.insurance.InsurancePolicyAddOn;
type InsurancePolicyAddOnResponse =
    models.insurance.InsurancePolicyAddOnResponse;
type InsurancePolicyResponse = models.insurance.InsurancePolicyResponse;
type SavedInsurancePolicy = models.insurance.SavedInsurancePolicy;
type InsuranceStatus = constants.insurance.InsuranceStatus;
type TravelInsuredRequestType = constants.insurance.TravelInsuredRequestType;

const { AddOnTypes, BookingStatuses, InternalCancellationReason } =
    constants.bookings;
const {
    INSURANCE_LOWER_COVERAGE_TIME_WINDOW,
    MIN_INSURED_TRIP_COST,
    MAX_INSURED_TRIP_COST,
    INSURANCE_FREE_LOOK_PERIOD_DAYS,
    TROVA_COMMISSION_RATE,
    InsuranceStatus,
    TravelInsuredRequestType,
} = constants.insurance;

type BuildInsuranceRequestOptions = {
    trip: TripWithPopulatedServices;
    tripPrice: number;
    travelerAddOns: (AddOn | CheckoutAddOn)[];
    depositDate: Date;
    travelerDiscount?: number;
};

const TRAVEL_INSURED_TIMEZONE = 'America/New_York';
const UTC_TIMEZONE = 'UTC';
// These are the booking internal cancellation reasons where we should cancel the insurance policy
const INSURANCE_BOOKING_CANCELLABLE_REASONS = [
    InternalCancellationReason.OPERATOR_FULL_REFUND,
    InternalCancellationReason.TROVA_FULL_REFUND,
    InternalCancellationReason.HOST_FULL_REFUND,
];

/**
 * Builds an insurance request for a given traveler. All provided values should only apply to a single traveler,
 * even if there are multiple travelers on a booking.
 * @param trip The trip
 * @param tripPrice The price of the trip for this traveler
 * @param travelerAddOns The add-ons chosen for this traveler
 * @param depositDate The date of the deposit payment
 * @param travelerDiscount The discount to apply for this traveler
 */
export const buildInsuranceRequestForTraveler = ({
    trip,
    tripPrice,
    travelerAddOns,
    depositDate,
    travelerDiscount = 0,
}: BuildInsuranceRequestOptions): InsuranceRequest => {
    const travelerAddOnsTotal = getBookingAddsOnsAmount(travelerAddOns);
    const travelerInsurancePolicyAddOns = travelerAddOns.filter(
        ({ type }) => type === AddOnTypes.INSURANCE_POLICY,
    );
    const travelerInsurancePolicyAddOnsTotal = getBookingAddsOnsAmount(
        travelerInsurancePolicyAddOns,
    );
    const [departureDate, returnDate] = getBookingStartEndDates(
        trip,
        travelerAddOns,
    );

    const tripCost =
        tripPrice +
        travelerAddOnsTotal -
        travelerInsurancePolicyAddOnsTotal -
        travelerDiscount;

    return {
        departureDate,
        returnDate,
        depositDate,
        tripCountryCode: trip.country,
        tripCost,
    };
};

/**
 * Checks if an add-on is an insurance policy add-on
 * @param addOn The add-on to check
 */
export const isInsurancePolicyAddOn = (
    addOn: AddOn,
): addOn is InsurancePolicyAddOn => {
    return (
        addOn.type === AddOnTypes.INSURANCE_POLICY &&
        !!(addOn as InsurancePolicyAddOn).insurancePolicy
    );
};

/**
 * Checks if an add-on is an insurance policy add-on response
 * @param addOn The add-on to check
 */
export const isInsurancePolicyAddOnResponse = (
    addOn: AddOn,
): addOn is InsurancePolicyAddOnResponse => {
    return (
        addOn.type === AddOnTypes.INSURANCE_POLICY &&
        !!(addOn as InsurancePolicyAddOnResponse).insurancePolicy?._id
    );
};

/**
 * Checks if an insurance policy is a saved insurance policy
 * @param insurancePolicy The insurance policy to check
 */
export const isSavedInsurancePolicy = (
    insurancePolicy: InsurancePolicyAddOn['insurancePolicy'],
): insurancePolicy is SavedInsurancePolicy => {
    return (
        typeof insurancePolicy !== 'string' &&
        !!insurancePolicy._id &&
        !!insurancePolicy.createdDate
    );
};

/**
 * Gets the insurance policy ID from an insurance policy add-on
 * @param insurancePolicyAddOn The insurance policy add-on
 */
export const getInsurancePolicyIdFromAddon = (
    insurancePolicyAddOn: InsurancePolicyAddOn,
): string => {
    const { insurancePolicy } = insurancePolicyAddOn;
    return isSavedInsurancePolicy(insurancePolicy)
        ? insurancePolicy._id.toString()
        : insurancePolicy;
};

/**
 * Given a list of add-ons, finds the insurance policy add-on for a given policy ID
 * @param addOns The list of add-ons to search
 * @param policyId The ID of the policy to find
 */
export const findInsurancePolicyAddOnFromPolicyId = (
    addOns: AddOn[] | undefined,
    policyId: string,
): InsurancePolicyAddOn | undefined => {
    return addOns?.find(
        (addOn) =>
            isInsurancePolicyAddOn(addOn) &&
            getInsurancePolicyIdFromAddon(addOn) === policyId,
    ) as InsurancePolicyAddOn;
};

/**
 * Builds an insurance policy response from an insurance policy and a claims URL
 * @param insurancePolicy The insurance policy
 * @param claimsUrl The claims URL
 */
export const buildInsurancePolicyResponse = (
    insurancePolicy: SavedInsurancePolicy,
    claimsUrl: string,
): InsurancePolicyResponse => {
    const response: InsurancePolicyResponse = {
        _id: insurancePolicy._id,
        coverage: insurancePolicy.coverage,
        status: insurancePolicy.status,
        createdDate: insurancePolicy.createdDate,
        firstName: insurancePolicy.firstName,
        lastName: insurancePolicy.lastName,
    };

    if (insurancePolicy.policyNumber) {
        response.policyNumber = insurancePolicy.policyNumber;
        response.confirmationOfBenefitsUrl =
            insurancePolicy.confirmationOfBenefitsUrl;
        response.claimsUrl = claimsUrl;
    }

    return response;
};

export const getBookingInsurancePolicyIds = (
    booking: Partial<Pick<Booking, 'addOns'>>,
): string[] => {
    if (!booking.addOns?.length) {
        return [];
    }

    return booking.addOns
        .filter((addOn): addOn is InsurancePolicyAddOn =>
            isInsurancePolicyAddOn(addOn),
        )
        .map(({ insurancePolicy }) =>
            typeof insurancePolicy === 'string'
                ? insurancePolicy
                : insurancePolicy._id.toString(),
        );
};

/**
 * Formats a simple date for the Travel Insured API and manifests
 * @param date The simple date to format
 */
export const formatSimpleDateForTravelInsured = (
    date: Date | string,
): string => {
    let dateObj = typeof date === 'string' ? new Date(date) : date;
    dateObj = dropTime(dateObj);
    return formatDateUSShort(dateObj, UTC_TIMEZONE);
};

/**
 * Formats a full date for the Travel Insured manifests
 * @param date The date to format
 */
export const formatDateTimeForTravelInsured = (date: Date | string): string => {
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    return formatDateUSFull(dateObj, TRAVEL_INSURED_TIMEZONE);
};

/**
 * Formats a datetime for the Travel Insured API and manifests
 * @param date The datetime to format
 */
export const formatShortDateTimeForTravelInsured = (
    date: Date | string,
): string => {
    let dateObj = typeof date === 'string' ? new Date(date) : date;
    return formatDateUSShort(dateObj, TRAVEL_INSURED_TIMEZONE);
};

/**
 * Checks if a booking should have its insurance policies cancelled
 * @param booking The booking to check
 */
export const shouldCancelInsurancePolicies = (
    booking: Partial<Pick<Booking, 'status' | 'internalCancellationReason'>>,
): boolean => {
    if (booking.status !== BookingStatuses.CANCELLED) {
        return false;
    }

    return booking.internalCancellationReason
        ? INSURANCE_BOOKING_CANCELLABLE_REASONS.includes(
              booking.internalCancellationReason,
          )
        : false;
};

export const withinInsuranceTimeWindow = (
    currentDate: Date,
    tripStartDate: Date,
): boolean => {
    const daysToStartDate = getDifferenceInDays(currentDate, tripStartDate);
    return daysToStartDate <= INSURANCE_LOWER_COVERAGE_TIME_WINDOW;
};

/**
 * Returns the insured trip cost for a given trip cost, ensuring it is within the allowed range
 * @param tripCost The cost of the trip with addons, excluding insurance
 */
export const calculateInsuredTripCost = (tripCost: number): number => {
    if (tripCost < MIN_INSURED_TRIP_COST) {
        return MIN_INSURED_TRIP_COST;
    }
    if (tripCost > MAX_INSURED_TRIP_COST) {
        return MAX_INSURED_TRIP_COST;
    }
    return tripCost;
};

/**
 * Returns the sum of the policy cost adjustments for a given insurance policy
 * @param insurancePolicy The saved insurance policy to calculate the total cost for
 */
export const totalPolicyCost = (
    insurancePolicy: SavedInsurancePolicy,
): number => {
    return insurancePolicy.ledger.reduce(
        (acc, event) => acc + event.policyCostAdjustmentAmount,
        0,
    );
};

/**
 * Checks if the createdDate is within the insurance free-look time window
 * @param createdDate The date the insurance policy was created
 * @param booking to check if the insurance policy should be cancelled based on the status and internal cancellation reason
 */
export const isInsurancePolicyCancellable = (
    createdDate: Date | string,
    booking?: Partial<Pick<Booking, 'status' | 'internalCancellationReason'>>,
): boolean => {
    if (booking?.status && shouldCancelInsurancePolicies(booking)) {
        return true;
    }

    const parsedDate =
        typeof createdDate === 'string' ? new Date(createdDate) : createdDate;

    const freeLookPeriodEndDate = addDaysToDate(
        parsedDate,
        INSURANCE_FREE_LOOK_PERIOD_DAYS,
    );

    const currentDate = new Date();
    return currentDate <= freeLookPeriodEndDate;
};

/**
 * Calculate the plan cost due to Travel Insured. This is this amount due to Travel Insured
 *  after Trova's commission is taken out.
 * @param planCost The full plan cost
 */
export const calculatePlanCostDueToTravelInsured = (
    planCost: number,
): number => {
    const dueAmount = planCost * (1 - TROVA_COMMISSION_RATE);
    return Math.round(dueAmount * 100) / 100;
};

/**
 * Gets the travel insured request type for a given insurance policy status
 * @param insurancePolicyId The ID of the insurance policy
 * @param status The status of the insurance policy
 */
export const getTravelInsuredRequestType = (
    status: InsuranceStatus,
    insurancePolicyId?: string,
): TravelInsuredRequestType => {
    switch (status) {
        case InsuranceStatus.PURCHASE_PENDING:
            return TravelInsuredRequestType.NEW;
        case InsuranceStatus.MODIFICATION_PENDING:
            return TravelInsuredRequestType.MODIFICATION;
        case InsuranceStatus.CANCELLATION_PENDING:
            return TravelInsuredRequestType.CANCELLATION;
        case InsuranceStatus.CONFIRMED: // Fall through
        case InsuranceStatus.CANCELLED:
            return TravelInsuredRequestType.NONE;
        default:
            throw new StatusError.InsurancePolicyStatusUnsupportedError(
                insurancePolicyId || 'new policy',
                status,
            );
    }
};
