import React from 'react';
import Accordion from '../../../Surfaces/Accordion';
import SidebarItem from '../SidebarItem/SidebarItem';
import { SidebarItemProps } from '../SidebarItem/SidebarItem.types';

export type SidebarMenuGroupPanelProps = {
    items: SidebarItemProps[];
};

const SidebarMenuGroupPanel = ({ items }: SidebarMenuGroupPanelProps) => (
    <Accordion.Panel as='ul'>
        {items.map(item => {
            const { id, subtitle, title, labels, isSelected, onClick } = item;
            return (
                <SidebarItem
                    title={title}
                    subtitle={subtitle}
                    key={id}
                    onClick={onClick}
                    isSelected={isSelected}
                    labels={labels}
                    id={id}
                />
            );
        })}
    </Accordion.Panel>
);

export default SidebarMenuGroupPanel;
