import {
    GoogleContextTemplate,
    FacebookContextTemplate,
} from './context.types';

// covers google analytics and adWords
const createGoogleContextTemplate = (): GoogleContextTemplate => {
    const contextObject = {
        device: {
            id: '0',
            type: 'device',
        },
        app: {
            name: 'segment',
            namespace: 'segment',
            version: '0',
        },
        os: {
            name: 'os',
            version: '0',
        },
    };

    return contextObject;
};

const createFacebookContextTemplate = (
    params?: Record<string, any>,
): FacebookContextTemplate => {
    const contextObject = {
        email: params?.email || '',
        phone: params?.phone || '',
        gender: params?.gender || '',
        firstName: params?.firstName || '',
        lastName: params?.lastName || '',
        address: {
            city: params?.city || '',
            state: params?.state || '',
            postalCode: params?.postalCode || '',
            country: params?.country || '',
        },
    };

    return contextObject;
};

export { createGoogleContextTemplate, createFacebookContextTemplate };
