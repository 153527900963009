import { constants } from '@trova-trip/trova-models';

export const SIMPLE_DATE_TYPE_METADATA = 'simpleDate';
export const PAGING_LIMIT_DEFAULT = 500;
export enum Environment {
    Production = 'production',
    ProductionClone = 'production-clone',
    Staging = 'staging',
    Development = 'develop',
    Local = 'local',
    QA = 'qa',
}

export enum IMAGE_ASSETS_URI_MAP {
    Develop = 'https://local-trova.gumlet.io',
    Staging = 'https://staging-trova.gumlet.io',
    Production = 'https://trovatrip.gumlet.io',
}

export type TrovaDomainMap = {
    portal: string;
    public: string;
    marketing?: string;
    microapps: string;
    checkout: string;
    booking: string;
    images: string;
    api: {
        booking: string;
        auth?: string;
        mobile?: string;
        chat?: string;
        review?: string;
    };
    mobileApp: {
        ios: string;
        android: string;
    };
    travelerFeedback: string;
};

export const TrovaTripAppsDomainMap: Readonly<
    {
        [key in Environment]: TrovaDomainMap;
    }
> = {
    [Environment.Development]: {
        portal: 'https://trova-maven-develop.herokuapp.com',
        public: 'https://dev-trovatrip.com',
        marketing: 'https://24ac36-trovatrip-21025004.hs-sites.com',
        microapps: 'https://trova-apps-dev-xkrfu6i5la-uc.a.run.app',
        checkout: 'https://checkout.dev-trovatrip.com',
        booking: 'https://booking.dev-trovatrip.com',
        images: IMAGE_ASSETS_URI_MAP.Develop,
        api: {
            auth: 'https://trova-auth-api-services-dev-3vv5ltjkfq-uc.a.run.app',
            booking: 'https://booking.dev-trovatrip.com',
            chat: 'https://trova-chat-api-services-dev-u7sw7ry2ga-uc.a.run.app',
            mobile: 'https://mobile.dev-trovatrip.com',
            review: 'https://review.dev-trovatrip.com',
        },
        mobileApp: {
            ios: 'https://apps.apple.com/us/app/trovatrip/id6447015559',
            android:
                'https://play.google.com/store/apps/details?id=com.trovamobile',
        },
        travelerFeedback: 'https://staging-trovatrip.com/trip-feedback.html',
    },
    [Environment.Local]: {
        portal: 'http://localhost:3000',
        public: 'https://dev-trovatrip.com',
        marketing: 'https://24ac36-trovatrip-21025004.hs-sites.com',
        microapps: 'https://trova-apps-dev-xkrfu6i5la-uc.a.run.app',
        checkout: 'https://checkout.dev-trovatrip.com',
        booking: 'https://booking.dev-trovatrip.com',
        images: IMAGE_ASSETS_URI_MAP.Develop,
        api: {
            auth: 'http://localhost:8081',
            booking: 'http://localhost:8080',
            chat: 'http://localhost:8083',
            mobile: 'http://localhost:8082',
            review: 'http://localhost:8084',
        },
        mobileApp: {
            ios: 'https://apps.apple.com/us/app/trovatrip/id6447015559',
            android:
                'https://play.google.com/store/apps/details?id=com.trovamobile',
        },
        travelerFeedback: 'https://staging-trovatrip.com/trip-feedback.html',
    },
    [Environment.Staging]: {
        portal: 'https://my.staging-trovatrip.com',
        public: 'https://staging-trovatrip.com',
        marketing: 'https://24ac36-trovatrip-21025004.hs-sites.com',
        checkout: 'https://checkout.staging-trovatrip.com',
        booking: 'https://booking.staging-trovatrip.com',
        microapps: 'https://trova-apps-staging.herokuapp.com',
        images: IMAGE_ASSETS_URI_MAP.Staging,
        api: {
            auth: 'https://trova-auth-api-services-staging-3vv5ltjkfq-uc.a.run.app',
            booking: 'https://booking.staging-trovatrip.com',
            chat: 'https://trova-chat-api-services-staging-u7sw7ry2ga-uc.a.run.app',
            mobile: 'https://mobile.staging-trovatrip.com',
            review: 'https://review.staging-trovatrip.com',
        },
        mobileApp: {
            ios: 'https://apps.apple.com/us/app/trovatrip/id6447015559',
            android:
                'https://play.google.com/store/apps/details?id=com.trovamobile',
        },
        travelerFeedback: 'https://staging-trovatrip.com/trip-feedback.html',
    },
    [Environment.Production]: {
        portal: 'https://my.trovatrip.com',
        public: 'https://trovatrip.com',
        marketing: 'https://trovatrip.com',
        checkout: 'https://checkout.trovatrip.com',
        booking: 'https://booking.trovatrip.com',
        microapps: 'https://apps.trovatrip.com',
        images: IMAGE_ASSETS_URI_MAP.Production,
        api: {
            auth: 'https://trova-auth-api-services-production-3vv5ltjkfq-uc.a.run.app',
            booking: 'https://booking.trovatrip.com',
            chat: 'https://trova-chat-api-services-production-u7sw7ry2ga-uc.a.run.app',
            mobile: 'https://trova-mobile-api-services-production-u7sw7ry2ga-uc.a.run.app',
            review: 'https://review.trovatrip.com',
        },
        mobileApp: {
            ios: 'https://apps.apple.com/us/app/trovatrip/id6447015559',
            android:
                'https://play.google.com/store/apps/details?id=com.trovamobile',
        },
        travelerFeedback: 'https://trips.trovatrip.com/trip-feedback',
    },
    [Environment.ProductionClone]: {
        portal: 'https://maven-production-clone.herokuapp.com',
        public: 'https://trovatrip.com',
        marketing: 'https://trovatrip.com',
        checkout: 'https://checkout.trovatrip.com',
        booking: 'https://booking.prod-clone-trovatrip.com',
        microapps: 'https://trova-apps-productionclone.herokuapp.com',
        images: IMAGE_ASSETS_URI_MAP.Production,
        api: {
            auth: 'https://trova-auth-api-services-prodclone-3vv5ltjkfq-uc.a.run.app',
            booking: 'https://booking.prod-clone-trovatrip.com',
            chat: 'https://trova-chat-api-services-productionclone-u7sw7ry2ga-uc.a.run.app',
            mobile: 'https://trova-mobile-api-services-productionclone-u7sw7ry2ga-uc.a.run.app',
            review: 'https://trova-review-api-services-productionclone-wu22isxy2q-uc.a.run.app',
        },
        mobileApp: {
            ios: 'https://apps.apple.com/us/app/trovatrip/id6447015559',
            android:
                'https://play.google.com/store/apps/details?id=com.trovamobile',
        },
        travelerFeedback: 'https://staging-trovatrip.com/trip-feedback.html',
    },
    [Environment.QA]: {
        portal: 'https://trova-maven-qa.herokuapp.com',
        public: 'https://qa-trovatrip.com',
        marketing: 'https://24ac36-trovatrip-21025004.hs-sites.com',
        checkout: 'https://checkout.qa-trovatrip.com',
        booking: 'https://booking.qa-trovatrip.com',
        microapps: 'https://trova-apps-qa.herokuapp.com',
        images: IMAGE_ASSETS_URI_MAP.Develop,
        api: {
            auth: 'https://trova-auth-api-services-qa-3vv5ltjkfq-uc.a.run.app',
            booking: 'https://booking.qa-trovatrip.com',
            mobile: 'https://mobile.qa-trovatrip.com',
            chat: 'https://trova-chat-api-services-qa-u7sw7ry2ga-uc.a.run.app',
            review: 'https://review.qa-trovatrip.com',
        },
        mobileApp: {
            ios: 'https://apps.apple.com/us/app/trovatrip/id6447015559',
            android:
                'https://play.google.com/store/apps/details?id=com.trovamobile',
        },
        travelerFeedback: 'https://staging-trovatrip.com/trip-feedback.html',        
    },
};

export const DATE_MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export enum CatalogType {
    Itineraries = 'itineraries',
    Trips = 'trips',
}

export const TripCategories = constants.itinerary.Categories;
export const TripCategoriesKeys = constants.itinerary.CATEGORY_KEY;
export const TripCategoriesListItemsSorted =
    constants.itinerary.CategoriesListItemsSorted;
export const Destinations = constants.itinerary.Destinations;

export const ItineraryTier = { ...constants.itinerary.TIER };
export const ItineraryTierNames = constants.itinerary.TierNames;
export const ItineraryTierSurveysRequired =
    constants.itinerary.TierSurveysRequired;

export const DEFAULT_BOOKINGS_DEADLINE = 50;
export const LIVE_NOT_CONFIRMED_BOOKINGS_DEADLINE = 90;

export const isProduction = (environment: Environment) =>
    environment === Environment.Production ||
    environment === Environment.ProductionClone;
