import { isEmpty, cloneDeep } from 'lodash';

const symbolFor = (obj: any) => Symbol(JSON.stringify(obj));

type TModel = Record<string, any>;

export type TransientItemType = {
    $tkey: symbol;
    $tmodel: TModel;
};

const createTransientObj = (initialObj: TModel = {}): TransientItemType => {
    const clone = isEmpty(initialObj)
        ? { createdDate: new Date().toISOString() }
        : cloneDeep(initialObj);
    return {
        $tkey: symbolFor(clone),
        $tmodel: clone,
    };
};

export class InMemoryListManager {
    #list: Map<symbol, TransientItemType>;

    constructor(initialList: TModel[] = []) {
        this.#list = new Map(
            initialList.map((item) => {
                const tObject = createTransientObj(item);
                return [tObject.$tkey, tObject];
            }),
        );
    }

    get keys() {
        return Array.from<symbol>(this.#list.keys()) || [];
    }

    getSymKeyFor(item: TModel) {
        // can be memoized
        if (!item) return null;

        const symKey = this.keys.find(
            (f) => f.description === JSON.stringify(item),
        );
        return symKey;
    }

    addItem(item: TModel) {
        const newItem = createTransientObj(
            Object.assign({}, item, { createdDate: new Date().toISOString() }),
        );
        this.#list.set(newItem.$tkey, newItem);
        return newItem;
    }

    get count() {
        return this.#list.size;
    }

    clearAll() {
        this.#list.clear();
    }

    deleteItem(key: symbol) {
        return this.#list.delete(key);
    }

    getItem(key: symbol) {
        return this.#list.get(key);
    }

    listItems() {
        return Array.from(this.#list.values());
    }

    itemExists(key: symbol) {
        return this.#list.has(key);
    }

    pushItems(items: TModel[]) {
        items.forEach((item) => {
            this.addItem(item);
        });
        return this.count;
    }

    serialize() {
        return JSON.stringify(
            Array.from(this.#list.values()).map((item) => ({
                ...item.$tmodel,
            })),
        );
    }

    updateItem(key: symbol, updatedObj: TransientItemType) {
        this.#list.set(key, updatedObj);
    }

    [Symbol.iterator]() {
        const self = this;
        return (function* () {
            yield* self.keys;
        })();
    }
}
