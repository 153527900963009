import { constants, models } from '@trova-trip/trova-models';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import isEmpty from 'lodash/isEmpty';
import {
    calculateTravelerAdditionalOptionalServiceCostWithMargin,
    calculateTravelerOptionalServiceCostWithMargin,
    calculateTravelerSingleSupplementCostWithMargin,
} from '../PricingCalculator/PricingCalculator';
import { Environment, TrovaTripAppsDomainMap } from '../app.constants';
import { DEFAULT_MINIMUM_PAYMENT_AMOUNT } from '../business.constants';
import { createTripEndDate, getDifferenceInDays } from './date.utils';
import { generateShortId } from './idGenerator.utils';
import { BigDecimal } from './numbers.utils';
import { getAccommodationStartEndDates } from './service.utils';
import { getTripOptionalActivities } from './trip.utils';

type BookingPayment = models.bookings.BookingPayment;
type BookingPaymentUpdate = models.bookings.BookingPaymentUpdate;
type BookingAddOn = models.bookings.AddOn;
type Booking = models.bookings.BaseBooking;
type AddonTypesType = constants.bookings.AddOnTypes;
type BookingCustomerProfile = models.bookings.BookingCustomerProfile;
type AddOn = models.bookings.AddOn;
type CheckoutAddOn = models.checkout.CheckoutAddOn;
type Accommodation = models.services.Accommodation;
type Service = models.services.Service;
type TripWithPopulatedServices = models.trips.TripWithPopulatedServices;
type BaseTrip = models.trips.BaseTrip;
type BaseAddOn = models.bookings.BaseAddOn;
type TravelerType = constants.travelers.TravelerType;
type TripStatus = constants.trips.TRIP_STATUS;
type BookingStatus = constants.bookings.BookingStatuses;
type Trip = models.trips.Trip;
type SavedBooking = models.bookings.SavedBooking;
type CancellationDetails = models.bookings.CancellationDetails;
type PaymentType = constants.bookings.PaymentType;
export type OccupancyType = Extract<
    constants.services.Occupancy,
    | constants.services.Occupancy.single
    | constants.services.Occupancy.double
    | constants.services.Occupancy.twin
>;
type CouponType = constants.bookings.CouponType;
type PaymentRefund = models.bookings.PaymentRefund;

const { Occupancy, ServiceTiming, ServiceType } = constants.services;
const TripStatuses = constants.trips.TRIP_STATUS;
const TravelerTypes = constants.travelers.TravelerType;
const { AddOnTypes, BookingStatuses, CouponType, PaymentType } =
    constants.bookings;

export type AccommodationsAddOns = {
    [ServiceTiming.PRE_TRIP]?: {
        [Occupancy.single]?: BaseAddOn;
        [Occupancy.double]?: BaseAddOn;
        [Occupancy.twin]?: BaseAddOn;
    };
    [ServiceTiming.POST_TRIP]?: {
        [Occupancy.single]?: BaseAddOn;
        [Occupancy.double]?: BaseAddOn;
        [Occupancy.twin]?: BaseAddOn;
    };
    singleSupplement?: BaseAddOn;
};

export interface IsBookingEditableParams {
    travelerType: TravelerType;
    tripStatus: TripStatus;
    bookingStatus: BookingStatus;
    dueAmount: number;
    rezdyTripId?: string;
}

/**
 * ttalum && gotrova are taken from trova margin as they are trova coupons
 * for repeat travelers
 * */
export const TROVA_DISCOUNTS = ['ttalum', 'gotrova'];

export const isCouponPayment = (paymentType: PaymentType): boolean =>
    CouponType[paymentType as keyof typeof CouponType] !== undefined;

export const isTrovaDiscount = (coupon: string): boolean =>
    TROVA_DISCOUNTS.includes(coupon.toLowerCase());

export const isVoucher = (codeId: string): boolean =>
    codeId.toLowerCase().startsWith('v');

export const isAdjustmentVoucher = (codeId: string): boolean =>
    codeId.toLowerCase().startsWith('r');

export const convertUserToBookingProfile = (
    doc: models.users.User,
    id = '',
): string | models.bookings.BookingCustomerProfile => {
    if (doc === null) {
        return id;
    }

    return {
        _id: doc._id,
        firstName: doc.firstName,
        lastName: doc.lastName,
        email: doc.email,
        gender: doc.gender,
        instagramHandle: doc.instagramHandle,
        dateOfBirth: doc.dateOfBirth,
        phone: doc.phoneNumber,
        countryCode: doc.countryCode,
    };
};

/**
 * Get the total price of the given add-ons - not counting any deleted add-ons
 * @param addOns - The add-ons to total up
 * @returns Sum of add-on prices
 */
export const getBookingAddsOnsAmount = (
    addOns: (Pick<BookingAddOn, 'unitPriceWithFee' | 'quantity'> &
        Partial<Pick<BookingAddOn, 'deleted'>>)[] = [],
): number => {
    const addOnsAmount = addOns
        .filter(({ deleted }) => !deleted)
        .reduce(
            (totalSum, { unitPriceWithFee, quantity }) =>
                totalSum.plus(unitPriceWithFee * quantity),
            BigDecimal.of(0),
        );
    return addOnsAmount.toNumber();
};

/**
 * type guard function to check if payment is a refund
 * @param payment payment to check
 * @returns `true` if payment is REFUND or COUPON_REMOVAL, `false` otherwise
 */
export const isRefundPayment = (
    payment: BookingPayment,
): payment is PaymentRefund =>
    payment.type === PaymentType.REFUND ||
    payment.type === PaymentType.COUPON_REMOVAL;

export const getTotalPaidFromPayments = (
    payments: BookingPayment[] = [],
): BigDecimal => {
    return payments.reduce((totalSum, payment) => {
        if (
            payment.status === constants.bookings.PaymentStatus.SUCCESS &&
            !isRefundPayment(payment)
        ) {
            totalSum = totalSum
                .plus(payment.amount)
                .minus(payment.refundedAmount ?? 0);
        }
        return totalSum;
    }, BigDecimal.of(0));
};

export const getBookingDiscountAmount = (
    payments: BookingPayment[] = [],
): BigDecimal => {
    return payments.reduce((totalSum, payment) => {
        if (isCouponPayment(payment.type)) {
            totalSum = totalSum.plus(payment.amount);
        }
        return totalSum;
    }, BigDecimal.of(0));
};

export const calculateHostDiscountsOnBooking = (
    payments: BookingPayment[],
): number => {
    return payments.reduce((totalDiscount: number, payment: BookingPayment) => {
        const { type, amount = 0 } = payment;

        if (type === PaymentType.DISCOUNT_HOST) {
            return totalDiscount + amount;
        }

        return totalDiscount;
    }, 0);
};

export const getBookingTripUnitPrice = (booking: Booking): number => {
    return booking.travelAmount / booking.totalSpotsBooked;
};

export const getBookingPaymentLink = (
    booking: Booking,
    environment: Environment = process.env.ENVIRONMENT as Environment,
): string => {
    const { orderId, trip } = booking;
    const domain = TrovaTripAppsDomainMap[environment].booking;
    const tripId = typeof trip === 'string' ? trip : trip?._id;
    return `${domain}/trips/${tripId}/orders/${orderId}/payment`;
};
/**
 * Calculates addOn price based on the type (ACTIVITY, ADDITIONAL SERVICE, SINGLE SUPPLEMENT).
 * Will throw an error if the type doesn't exist
 *
 * @param addOnType
 * @param price
 * @param tripCurrencyExchangeRate
 * @returns the price plus the fee
 */
export const calculatePriceWithFeeForAddOn = (
    addOnType: AddonTypesType,
    price: number,
    tripCurrencyExchangeRate = 1,
): number => {
    switch (addOnType) {
        case AddOnTypes.ACTIVITY:
            return calculateTravelerOptionalServiceCostWithMargin(
                price,
                tripCurrencyExchangeRate,
            );
        case AddOnTypes.ADDITIONAL_SERVICE:
            return calculateTravelerAdditionalOptionalServiceCostWithMargin(
                price,
                tripCurrencyExchangeRate,
            );
        case AddOnTypes.SINGLE_SUPPLEMENT:
            return calculateTravelerSingleSupplementCostWithMargin(
                price,
                tripCurrencyExchangeRate,
            );
        default:
            throw new Error(
                'Cannot calculate price with fee for non existing addOn type',
            );
    }
};

export const generateAddOn = (
    userId: string,
    addOnType: AddonTypesType,
    price: number,
    tripCurrencyExchangeRate = 1,
    quantity = 1,
    serviceId?: string,
): BookingAddOn => {
    const unitPriceWithFee = calculatePriceWithFeeForAddOn(
        addOnType,
        price,
        tripCurrencyExchangeRate,
    );

    return {
        addOnId: generateShortId(),
        type: addOnType,
        quantity: quantity,
        service: serviceId,
        user: userId,
        unitPriceWithFee,
        deleted: false,
    };
};

export const refreshPaidAndDueAmount = (
    payments: models.bookings.BookingPayment[],
    totalAmount: number,
): BookingPaymentUpdate => {
    const paidAmount = getTotalPaidFromPayments(payments);
    const dueAmount = BigDecimal.of(totalAmount).minus(paidAmount);

    return {
        payments,
        paidAmount: paidAmount.toNumber(),
        dueAmount: dueAmount.toNumber(),
    };
};

export const getExtractedDataFromBookings = (
    bookings: Booking[],
): models.bookings.BookingExtractedData[] =>
    bookings.map(
        ({
            orderId,
            status,
            totalAmount,
            paidAmount,
            dueAmount,
            createdDate,
            confirmedDate,
            payments,
            customer,
            cancelledByHost,
            additionalParticipants = [],
            travelAmount,
            totalSpotsBooked,
            cancellationDetails,
            cancellationRequestDate,
            id,
            _id,
        }) => {
            const couponVoucherDifference = payments
                ? calculateHostDiscountsOnBooking(payments)
                : 0;

            const travelers: BookingCustomerProfile[] = [
                (customer as BookingCustomerProfile) || {},
                ...(additionalParticipants as BookingCustomerProfile[]),
            ];
            const pendingCancellation = Boolean(
                status === constants.bookings.BookingStatuses.PENDING &&
                    (cancelledByHost || cancellationDetails?.reason),
            );

            return {
                bookingId: _id ?? id,
                orderId,
                status,
                travelers,
                totalAmount,
                travelAmount,
                paidAmount,
                dueAmount,
                createdDate,
                couponVoucherDifference,
                confirmedDate,
                additionalParticipants,
                pendingCancellation,
                totalSpotsBooked,
                cancellationRequestDate,
            };
        },
    );

export const getTotalSpotsBookedByBookingStatus = (
    data: models.bookings.BookingExtractedData[],
    bookingStatuses: constants.bookings.BookingStatuses[],
    paidInFull = false,
): number =>
    data.reduce((total, { status, totalSpotsBooked, dueAmount = 0 }) => {
        if (paidInFull) {
            return dueAmount <= 0 &&
                bookingStatuses.some(
                    (bookingStatus) => bookingStatus === status,
                )
                ? total + totalSpotsBooked
                : total;
        }
        return bookingStatuses.some((bookingStatus) => bookingStatus === status)
            ? total + totalSpotsBooked
            : total;
    }, 0);

export const getTotalTravelerRevenueByBookingStatus = (
    data: models.bookings.BookingExtractedData[],
    bookingStatuses: constants.bookings.BookingStatuses[],
    paidInFull = false,
): number =>
    data.reduce(
        (
            total,
            {
                travelAmount = 0,
                dueAmount = 0,
                couponVoucherDifference,
                status,
            },
        ) => {
            if (paidInFull) {
                return bookingStatuses.some(
                    (bookingStatus) => bookingStatus === status,
                ) && dueAmount <= 0
                    ? total + travelAmount - couponVoucherDifference
                    : total;
            }

            return bookingStatuses.some(
                (bookingStatus) => bookingStatus === status,
            )
                ? total + travelAmount - couponVoucherDifference
                : total;
        },
        0,
    );

/**
 * Get a filtered list of the provided services based on the selected add-ons and type
 * @param services The original list of available services
 * @param addOns The selected add-ons
 * @param type The type of service to filter by
 */
export const filterServicesBySelectedAddOnsAndType = <T extends Service>(
    services: Service[],
    addOns: (AddOn | CheckoutAddOn)[],
    type: T['type'],
): T[] => {
    const addOnServices: T[] = [];

    addOns.forEach((addOn) => {
        if (
            (addOn as AddOn).deleted ||
            addOn.type !== AddOnTypes.ADDITIONAL_SERVICE ||
            !addOn.service
        ) {
            return;
        }

        const serviceId =
            typeof addOn.service === 'string'
                ? addOn.service
                : addOn.service._id?.toString();

        const addOnService = services.find(
            (service) => service._id?.toString() === serviceId,
        );

        if (!addOnService) {
            throw new Error(`Service ${serviceId} from add-on is missing`);
        }

        if (addOnService.type === type) {
            addOnServices.push(addOnService as T);
        }
    });

    return addOnServices;
};

/**
 * Get the start and end dates of a booking, taking into account the trip dates as well as the selected add-ons
 * @param trip The trip
 * @param addOns The selected add-ons
 */
export const getBookingStartEndDates = (
    trip: TripWithPopulatedServices,
    addOns: (AddOn | CheckoutAddOn)[],
): [startDate: Date, endDate: Date] => {
    if (!trip.startDate || !trip.servicesByDay) {
        throw new Error('Trip dates are missing');
    }

    let startDate = trip.startDate;
    let endDate = createTripEndDate(startDate, trip.servicesByDay.length);
    const tripServices = [
        ...trip.additionalOptionalServices,
        ...trip.servicesByDay.flat().map(({ service }) => service),
    ];
    const accommodations = filterServicesBySelectedAddOnsAndType<Accommodation>(
        tripServices,
        addOns,
        ServiceType.ACCOMMODATION,
    );

    for (const accommodation of accommodations) {
        const [accommodationStartDate, accommodationEndDate] =
            getAccommodationStartEndDates(accommodation);

        if (accommodationStartDate && accommodationStartDate < startDate) {
            startDate = accommodationStartDate;
        }

        if (accommodationEndDate && accommodationEndDate > endDate) {
            endDate = accommodationEndDate;
        }
    }

    return [startDate, endDate];
};

/**
 * Returns a unified collection of the optional services that are available (activities, pre/post services, single supplement).
 * @param usdTrip  - a trip already converted to USD with fees applied
 */
export const generateAddOnsFromTrip = (usdTrip: BaseTrip): BaseAddOn[] => {
    const activitiesServices = getTripOptionalActivities(
        usdTrip as unknown as models.trips.Trip,
    );
    const activitiesAddOns = activitiesServices.map(
        (service) =>
            <BaseAddOn>{
                type: AddOnTypes.ACTIVITY,
                service: service,
                unitPriceWithFee: service.price,
            },
    );

    const optionalServices = usdTrip.additionalOptionalServices as Service[];
    const optionalServicesAddOns = optionalServices
        .filter(
            (service) =>
                service.bookable !== false &&
                service.type !== ServiceType.INSURANCE_PLAN,
        )
        .map((service) => {
            return <BaseAddOn>{
                type: AddOnTypes.ADDITIONAL_SERVICE,
                service: service,
                unitPriceWithFee: service?.price,
            };
        });

    const availableAddOns = [...activitiesAddOns, ...optionalServicesAddOns];

    const singleSupplement = usdTrip.additionalCosts?.singleSupplement;
    if (singleSupplement) {
        const { price, bookable } = singleSupplement;
        if (bookable !== false) {
            const singleSupplementAddOn = <CheckoutAddOn>{
                type: AddOnTypes.SINGLE_SUPPLEMENT,
                unitPriceWithFee: price,
            };
            availableAddOns.push(singleSupplementAddOn);
        }
    }

    return availableAddOns.filter((addOn) => addOn.unitPriceWithFee > 0);
};

/**
 * Returns if the room on the passed position is the last one.
 *
 * @param {OccupancyType[]} roomOccupancies
 * @param {number} position
 * @returns {boolean}
 */
const checkIfIsLastRoom = (
    roomOccupancies: OccupancyType[],
    position: number,
): boolean => {
    return position === roomOccupancies.length - 1;
};

/**
 * Return the amount of travelers in each room
 *
 * @param {number} travelersQuantity
 * @param {boolean} isLastRoom
 * @returns {number}
 */
const getTravelersQuantityPerRoom = (
    travelersQuantity: number,
    isLastRoom: boolean = false,
): number => {
    return isLastRoom && travelersQuantity % 2 === 1 ? 1 : 2;
};

/**
 * Calculate the pre/post accommodations prices based on selected configuration.
 *
 * @param {boolean} isPrivateRoomSelected if each traveler should have their own room
 * @param {AccommodationsAddOns} accommodationsAddOns pre/post accommodations addOns
 * @param {OccupancyType[]} roomsOccupancies selected configuration -> ['Twin', 'Double',...]
 * @param {number} travelersQuantity
 * @returns {preTripPrice: number, postTripPrice: number} total price for the pre/post accommodations services
 */
export const getPrePostAddOnsPrice = (
    isPrivateRoomSelected: boolean,
    accommodationsAddOns: AccommodationsAddOns,
    roomsOccupancies: OccupancyType[],
    travelersQuantity: number,
): { preTripPrice: number; postTripPrice: number } => {
    let preTripPrice = 0;
    let postTripPrice = 0;

    const preSingleAddon =
        accommodationsAddOns[ServiceTiming.PRE_TRIP]?.[Occupancy.single];
    const postSingleAddon =
        accommodationsAddOns[ServiceTiming.POST_TRIP]?.[Occupancy.single];

    if (isPrivateRoomSelected) {
        return {
            preTripPrice:
                (preSingleAddon?.unitPriceWithFee ?? 0) * travelersQuantity,
            postTripPrice:
                (postSingleAddon?.unitPriceWithFee ?? 0) * travelersQuantity,
        };
    }

    roomsOccupancies.forEach((roomOccupancy, index) => {
        const isLastRoom: boolean = checkIfIsLastRoom(roomsOccupancies, index);

        const travelersInRoom: number = getTravelersQuantityPerRoom(
            travelersQuantity,
            isLastRoom,
        );

        const shouldUseSingleAddon = isLastRoom && travelersInRoom === 1;

        const preAddonToUse = shouldUseSingleAddon
            ? preSingleAddon
            : accommodationsAddOns[ServiceTiming.PRE_TRIP]?.[roomOccupancy];

        const postAddonToUse = shouldUseSingleAddon
            ? postSingleAddon
            : accommodationsAddOns[ServiceTiming.POST_TRIP]?.[roomOccupancy];

        preTripPrice += preAddonToUse?.unitPriceWithFee ?? 0;
        postTripPrice += postAddonToUse?.unitPriceWithFee ?? 0;
    });

    return { preTripPrice, postTripPrice };
};

export type PaymentsAppSection =
    | 'navbar'
    | 'sidebar'
    | 'header'
    | 'tripDetails'
    | 'paymentOptions'
    | 'submitButtonDivider';

export type PaymentsAppContextType =
    | 'transfers'
    | 'updateBooking'
    | 'remainingBalance';

export type PaymentsAppPaymentOption =
    | `${models.payments.PaymentOptionType.MINIMUM}`
    | `${models.payments.PaymentOptionType.FULL}`
    | `${models.payments.PaymentOptionType.CUSTOM}`
    | `${models.payments.PaymentOptionType.AFFIRM}`;

type WithNone<T> = T | 'none';

export type PaymentsAppURLParams = {
    tripId: string;
    orderId: string;
    env: Environment;
    config?: {
        contextType?: PaymentsAppContextType;
        additionalAmount?: number;
        sections?: {
            show?: WithNone<PaymentsAppSection>[];
        };
        paymentOptions?: {
            show?: WithNone<PaymentsAppPaymentOption>[];
            order?: PaymentsAppPaymentOption[];
            selectedByDefault?: PaymentsAppPaymentOption;
        };
    };
};

/**
 * Generates a URL for the payments app based on provided parameters.
 *
 * @param params - The parameters required to generate the URL.
 * @returns {string} The URL for the payments app with query parameters.
 */
export const getPaymentsAppURL = (params: PaymentsAppURLParams): string => {
    const { env, tripId, orderId, config } = params;

    const baseUrl = `${TrovaTripAppsDomainMap[env].booking}/trips/${tripId}/orders/${orderId}/payment`;
    const queryParams: Record<string, any> = {};

    const itemSeparator = '|';

    if (config?.contextType) {
        queryParams['contextType'] = config.contextType;
    }

    if (config?.additionalAmount) {
        queryParams['additionalAmount'] = config.additionalAmount;
    }

    if (config?.sections) {
        const { show } = config.sections;
        if (show) {
            queryParams['showSections'] = show.join(itemSeparator);
        }
    }

    if (config?.paymentOptions) {
        const { show, order, selectedByDefault } = config.paymentOptions;
        if (show) {
            queryParams['showPaymentOptions'] = show.join(itemSeparator);
        }
        if (order) {
            queryParams['paymentOptionsOrder'] = order.join(itemSeparator);
        }
        if (selectedByDefault) {
            queryParams['selectedPaymentOption'] = selectedByDefault;
        }
    }

    const queryParamsString = isEmpty(queryParams)
        ? ''
        : `?${new URLSearchParams(queryParams).toString()}`;

    return baseUrl + queryParamsString;
};

const BOOKING_STATUSES_EDITABLE = [
    BookingStatuses.PENDING,
    BookingStatuses.CONFIRMED,
];

/**
 * Checks if a booking is editable based on user type, trip status, booking status and wether it's a Rezdy trip.
 *
 * @param travelerType
 * @param tripStatus
 * @param bookingStatus
 * @param dueAmount
 * @param rezdyTripId
 * @returns `true` if the booking is editable; otherwise, `false`.
 */
export const isBookingEditable = ({
    travelerType,
    tripStatus,
    bookingStatus,
    dueAmount,
    rezdyTripId,
}: IsBookingEditableParams): boolean => {
    const isPrimaryTraveler = travelerType === TravelerTypes.TRAVELER_PRIMARY;

    const isRezdyTrip = !!rezdyTripId;

    const tripStatusIsEditable = isBookingEditableForTripStatus(tripStatus);

    const isBookingStatusEditable =
        BOOKING_STATUSES_EDITABLE.includes(bookingStatus);

    const isDueAmountNegative = dueAmount < 0;

    return (
        isPrimaryTraveler &&
        !isRezdyTrip &&
        tripStatusIsEditable &&
        isBookingStatusEditable &&
        !isDueAmountNegative
    );
};

/**
 * Validate that the bookings can be confirmed based on the trip status and the number of travelers.
 * @param trip
 * @param bookings
 * @throws Error if the bookings cannot be confirmed
 */
export const validateBookingConfirmations = (
    trip: Trip,
    bookings: Array<Booking>,
): void => {
    const tripId = trip.id || trip._id;
    if (
        ![
            TripStatuses.READY_TO_CONFIRM,
            TripStatuses.EARLY_CONFIRMED,
            TripStatuses.HOST_APPROVED,
            TripStatuses.CONFIRMED,
            TripStatuses.CLOSED,
        ].includes(trip.status)
    ) {
        throw new Error(
            `Bookings cannot be confirmed unless trip ${tripId} has hit the minimum number of travelers`,
        );
    }
    const totalTravelers = bookings.reduce(
        (total, booking) => total + booking.totalSpotsBooked,
        0,
    );

    const hasReachedMinSpots = totalTravelers >= (trip?.minimumSpots ?? 0);

    if (
        [TripStatuses.READY_TO_CONFIRM, TripStatuses.EARLY_CONFIRMED].includes(
            trip.status,
        ) &&
        !hasReachedMinSpots
    ) {
        throw new Error(
            `Must confirm at least ${trip.minimumSpots} bookings in order to confirm trip ${tripId}`,
        );
    }

    if (trip.status === TripStatuses.HOST_APPROVED && !hasReachedMinSpots) {
        throw new Error(
            `Must confirm at least ${trip.minimumSpots} bookings in order to early-confirm trip ${tripId}`,
        );
    }
};

/**
 * Get the total amount of the insurance add-ons.
 * @param addOns - The add-ons to total up
 * @returns Sum of add-on prices
 */
export const getInsurancePolicyAddOnsAmount = (
    addOns: (Pick<BookingAddOn, 'type' | 'unitPriceWithFee'> &
        Partial<Pick<BookingAddOn, 'deleted'>>)[] = [],
): BigDecimal => {
    const insuranceAmount = addOns
        .filter(
            ({ deleted, type }) =>
                !deleted && type === AddOnTypes.INSURANCE_POLICY,
        )
        .reduce(
            (totalSum, { unitPriceWithFee }) => totalSum.plus(unitPriceWithFee),
            BigDecimal.of(0),
        );
    return insuranceAmount;
};

/**
 * Verifies if the ground transfer addOn was removed
 * @param oldAddOns
 * @param updatedAddOns
 */
export const isGroundTransferAddOnRemoved = (
    oldAddOns: AddOn[] = [],
    updatedAddOns: AddOn[] = [],
): boolean => {
    const originalGroundTransferAddOn = oldAddOns.find(
        ({ type, deleted }) => type === AddOnTypes.GROUND_TRANSFER && !deleted,
    );

    if (!originalGroundTransferAddOn) {
        return false;
    }

    const updatedGroundTransferAddOn = updatedAddOns.find(
        ({ type, deleted, addOnId }) =>
            type === AddOnTypes.GROUND_TRANSFER &&
            deleted &&
            addOnId === originalGroundTransferAddOn.addOnId,
    );

    return !!updatedGroundTransferAddOn;
};

/**
 * Verifies if the booking was cancelled by the traveler.
 *
 * @param bookingCancellationDetails
 * @returns `true` if the booking was cancelled by the traveler; otherwise, `false`.
 */
const isBookingCancelledByTraveler = (
    bookingCancellationDetails: CancellationDetails,
): boolean => !isEmpty(bookingCancellationDetails.refundMethodDescription);

/**
 * Retrieves the booking status based on booking cancellation details.
 *
 * @param booking
 * @returns The booking status or undefined if no booking is provided.
 */
export const getBookingStatus = (
    booking: SavedBooking | undefined,
): BookingStatus | undefined => {
    if (!booking) {
        return undefined;
    }

    return booking.cancellationDetails &&
        isBookingCancelledByTraveler(booking.cancellationDetails)
        ? BookingStatuses.CANCELLED
        : booking.status;
};

/**
 * Checks if a booking is editable based on the status of a trip
 * @param tripStatus
 * @returns Returns `true` if the trip status allows booking edition; otherwise, `false`.
 */
export const isBookingEditableForTripStatus = (
    tripStatus: TripStatus,
): boolean => {
    const TRIP_STATUSES_EDITABLE = [
        TripStatuses.HOST_APPROVED,
        TripStatuses.LIVE,
        TripStatuses.READY_TO_CONFIRM,
        TripStatuses.EARLY_CONFIRMED,
        TripStatuses.CONFIRMED,
    ];

    return TRIP_STATUSES_EDITABLE.includes(tripStatus);
};

/**
 * Creates a companion email for a traveler
 * @param email Traveler email
 * @param index Index of the list
 */
export const createCompanionEmail = (email: string, index: number): string => {
    const separator = '@';
    const emailNameAndDomain = email.split(separator);
    const emailName = emailNameAndDomain[0];
    const emailDomain = emailNameAndDomain[1];
    return `${emailName}+companion${index + 1}@${emailDomain}`;
};

/**
 * Checks if the booking can be paid with Affirm
 * @param booking
 * @param paymentAmount
 * @returns
 */
export const canPayWithAffirm = (
    booking: Booking,
    paymentAmount: number,
): boolean => {
    const { addOns, dueAmount } = booking;

    const hasInsurance = addOns?.some(
        (addOn) => addOn.type === AddOnTypes.INSURANCE_POLICY && !addOn.deleted,
    );
    if (hasInsurance) {
        return false;
    }

    if (paymentAmount < DEFAULT_MINIMUM_PAYMENT_AMOUNT) {
        return false;
    }

    return paymentAmount === dueAmount;
};

/**
 * Returns the coupon type based on the coupon code
 * @param couponCode
 */
export const getCouponType = (couponCode: string): CouponType => {
    if (isVoucher(couponCode)) {
        return CouponType.VOUCHER;
    } else if (isAdjustmentVoucher(couponCode)) {
        return CouponType.VOUCHER_ADJUSTMENT;
    } else if (isTrovaDiscount(couponCode)) {
        return CouponType.DISCOUNT_TROVATRIP;
    }
    return CouponType.DISCOUNT_HOST;
};

/**
 * Calculates the number of days until the payment due date.
 *
 * @param booking - The booking object which contains the payment due date.
 * @returns The number of days until the payment due date, or `undefined` if the payment due date is not provided.
 */
export const getDaysUntilPaymentDueDate = (
    booking: Booking,
): number | undefined => {
    if (!booking.paymentDueDate) {
        return undefined;
    }

    const paymentDueDate = utcToZonedTime(booking.paymentDueDate, 'UTC');
    const today = utcToZonedTime(new Date(), 'UTC');

    return getDifferenceInDays(today, paymentDueDate);
};
