import {
    AccordionPanel as AccordionPanelChakra,
    As,
    ResponsiveValue,
    TypographyProps,
} from '@chakra-ui/react';
import React from 'react';
import { useOmitForbiddenProps } from '../../../../shared/hooks';
import { InternalBaseProps } from '../../../../shared/theme/interfaces/Base.types';
import {
    BorderProps,
    BorderRadiusProps,
    DesignTokens,
    MarginProps,
    PaddingProps,
    SizeProps,
} from '../../../../shared/theme/types';
import { genDataTestId } from '../../../../shared/utils/helpers';

export interface AccordionPanelProps
    extends SizeProps,
        BorderProps,
        BorderRadiusProps,
        PaddingProps,
        MarginProps {
    children?: React.ReactNode;
    as?: As;
    textAlign?: TypographyProps['textAlign'];
    background?:
        | ResponsiveValue<DesignTokens['colors']>
        | InternalBaseProps['background'];
}

export interface AccordionPanelImplProps
    extends AccordionPanelProps,
        Omit<InternalBaseProps, 'borderColor' | 'background'> {}

export const AccordionPanelImpl = (props: AccordionPanelImplProps) => (
    <AccordionPanelChakra
        data-testid={genDataTestId('accordion-panel')}
        padding={0}
        {...props}
    />
);

const AccordionPanel = (props: AccordionPanelProps) => {
    const filteredProps = useOmitForbiddenProps(props);
    return <AccordionPanelImpl {...filteredProps} />;
};

export default AccordionPanel;
