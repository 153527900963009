import React from 'react';
import Stack from '../../Layout/Stack';
import Heading from '../../Typography/Heading';
import Text, { TextProps } from '../../Typography/Text';
import { LinkImpl } from '../../Navigation/Link';
import { ButtonImpl } from '../../Forms/Button';
import Image, { ImageProps } from '../../Media/Image';
import ThumbsDown from '../../../shared/images/thumbs-down.svg';
import FlightTakeoff from '../../../shared/images/flight-takeoff.svg';
import BaseBox from '../../Layout/BaseBox';
import { useBreakpointValue } from '@chakra-ui/react';
import { genDataTestId } from '../../../shared/utils/helpers';
import isString from 'lodash/isString';
import {
    ImagesOnError,
    ErrorProps,
    FeedbackMessageImplProps,
    FeedbackMessageProps,
    ImageMap,
    ImagesKeys,
    SizeConfig,
    Sizes,
    ImagesOnSuccess,
    SuccessProps,
} from './FeedbackMessage.types';

const imagesMap: ImageMap = {
    error: {
        'thumbs-down': { src: ThumbsDown, alt: 'thumbs down' },
    },
    success: {
        'flight-takeoff': { src: FlightTakeoff, alt: 'plane taking off' },
    },
};

const sizeConfig: SizeConfig = {
    sm: {
        imageMaxWidth: '18.125rem',
        fontSize: 'base',
        lineHeight: '6',
        marginTop: 4,
        titleFontSize: '3xl',
        imageSpacing: 6,
    },
    md: {
        imageMaxWidth: '24.125rem',
        fontSize: 'lg',
        lineHeight: '7',
        marginTop: 0,
        titleFontSize: '4xl',
        imageSpacing: 8,
    },
    lg: {
        imageMaxWidth: '34.375rem',
        fontSize: '2xl',
        lineHeight: '8',
        marginTop: 0,
        titleFontSize: '7xl',
        imageSpacing: 10,
    },
};

const contactSupportText = {
    error: 'If you need help, please contact our',
    success: 'If you have any further questions, please reach out to our',
};

const getImageProps = (
    type: FeedbackMessageImplProps['type'],
    image: ImagesKeys,
    size: Sizes
) => {
    let imageProps = { maxWidth: sizeConfig[size].imageMaxWidth } as Pick<
        ImageProps,
        'src' | 'alt' | 'maxWidth'
    >;

    if (type === 'error') {
        imageProps = {
            ...imageProps,
            ...imagesMap.error[image as ImagesOnError],
        };
    }

    if (type === 'success') {
        imageProps = {
            ...imageProps,
            ...imagesMap.success[image as ImagesOnSuccess],
        };
    }

    return imageProps;
};

const FeedbackMessageImpl = (props: FeedbackMessageImplProps) => {
    const {
        title,
        message,
        actions,
        direction = 'horizontal',
        type,
        image,
        showContactSupport = true,
        size: sizeProp = 'lg',
        ...rest
    } = props;

    const size = useBreakpointValue(
        isString(sizeProp) ? [sizeProp] : sizeProp
    ) as Sizes;

    const currentSizeConfig = sizeConfig[size];

    const textProps: Omit<TextProps, 'children'> = {
        fontSize: currentSizeConfig.fontSize,
        lineHeight: currentSizeConfig.lineHeight,
        color: 'blueGray.500',
    };

    return (
        <Stack
            spacing={currentSizeConfig.imageSpacing}
            direction={{
                base: 'column-reverse',
                sm: direction === 'horizontal' ? 'row' : 'column-reverse',
            }}
            wrap='nowrap'
            align='center'
            justify='center'
            data-testid={genDataTestId('feedback-message')}
            width='full'
            {...rest}
        >
            <Stack
                direction='column'
                paddingX={{ base: 4, md: 0 }}
                maxWidth={{
                    base: 'full',
                    md: direction === 'horizontal' ? '52vw' : 'full',
                }}
                width='full'
            >
                <Heading
                    textAlign='start'
                    as='h2'
                    fontSize={currentSizeConfig.titleFontSize}
                    color='blueGray.700'
                >
                    {title}
                </Heading>
                <BaseBox paddingY={4}>
                    {message ? <Text {...textProps}>{message}</Text> : null}
                    {showContactSupport ? (
                        <Text
                            {...textProps}
                            marginTop={
                                message
                                    ? currentSizeConfig.marginTop
                                    : undefined
                            }
                        >
                            {contactSupportText[type]}{' '}
                            <LinkImpl
                                href='https://trovatrip.com/company/contact-us'
                                fontSize={currentSizeConfig.fontSize}
                            >
                                {' '}
                                support team.
                            </LinkImpl>
                        </Text>
                    ) : null}
                </BaseBox>
                {actions?.length ? (
                    <Stack
                        spacing={4}
                        direction={{ base: 'column', md: 'row' }}
                        width='full'
                    >
                        {actions?.map((action, index) => (
                            <ButtonImpl
                                key={index}
                                isFullWidth={{ base: true, md: false }}
                                {...action}
                            />
                        ))}
                    </Stack>
                ) : null}
            </Stack>
            {image ? <Image {...getImageProps(type, image, size)} /> : null}
        </Stack>
    );
};

const Error = (props: ErrorProps) => {
    const { title = 'Oops!', image = 'thumbs-down', ...rest } = props;
    return <FeedbackMessageImpl title={title} image={image} {...rest} />;
};

const Success = (props: SuccessProps) => {
    const { title = 'Yayyy!', image = 'flight-takeoff', ...rest } = props;
    return <FeedbackMessageImpl title={title} image={image} {...rest} />;
};

/**
 * Component that is used to display an `error` or `success` message to the user.
 */
const FeedbackMessage = (props: FeedbackMessageProps) => {
    switch (props.type) {
        case 'error':
            return <Error {...(props as ErrorProps)} />;
        case 'success':
            return <Success {...(props as SuccessProps)} />;
        default:
            return null;
    }
};

export default FeedbackMessage;
