import React from 'react';
import { genDataTestId } from '../../../../shared/utils/helpers';
import { ButtonImpl, ButtonProps } from '../../../Forms/Button';
import IconButton, { IconButtonProps } from '../../../Forms/IconButton';

export interface MenuButtonProps extends ButtonProps {}

export interface MenuIconButtonProps extends IconButtonProps {}

export interface MenuButtonImplProps extends MenuButtonProps {}

export const MenuImplButton = React.forwardRef<
    HTMLButtonElement,
    MenuButtonImplProps
>((props, ref) => (
    <ButtonImpl
        ref={ref}
        data-testid={genDataTestId('menu-button')}
        variant='tertiary'
        color='blueGray.650'
        fontWeight='normal'
        hover={{
            textDecoration: 'none',
        }}
        active={{
            textDecoration: 'none',
        }}
        {...props}
    />
));

export const MenuIconButton = React.forwardRef<
    HTMLButtonElement,
    MenuIconButtonProps
>((props, ref) => <IconButton ref={ref} {...props} />);

const MenuButton = React.forwardRef<HTMLButtonElement, MenuButtonProps>(
    (props, ref) => <MenuImplButton ref={ref} {...props} />
);

export default MenuButton;
