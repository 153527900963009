import { BaseStatusError } from './baseError';

export const securityMessages = {
    unauthorizedError: {
        code: 1000,
        message: 'Unauthorized',
    },
    unauthorizedActionError: {
        code: 1001,
        message: 'Unauthorized action',
    },
    resourceAccessError: {
        code: 1002,
        message: 'Unauthorized resource',
    },
    fieldAccessError: {
        code: 1003,
        message: 'Unauthorized fields',
    },
    unhandledSecurityError: {
        code: 1004,
        message: 'Internal security error',
    },
};

const errorMessagePrefix = '[SECURITY]:';

export class UnauthorizedError extends BaseStatusError {
    constructor(message?: string) {
        super(
            securityMessages.unauthorizedError.code,
            `${errorMessagePrefix} ${
                message ?? securityMessages.unauthorizedError.message
            }`,
        );
    }
}

export class UnauthorizedActionError extends BaseStatusError {
    constructor(message?: string) {
        super(
            securityMessages.unauthorizedActionError.code,
            `${errorMessagePrefix} ${
                message ?? securityMessages.unauthorizedActionError.message
            }`,
        );
    }
}

export class ResourceAccessError extends BaseStatusError {
    constructor(message?: string) {
        super(
            securityMessages.resourceAccessError.code,
            `${errorMessagePrefix} ${
                message ?? securityMessages.resourceAccessError.message
            }`,
        );
    }
}

export class FieldAccessError extends BaseStatusError {
    constructor(message?: string) {
        super(
            securityMessages.fieldAccessError.code,
            `${errorMessagePrefix} ${
                message ?? securityMessages.fieldAccessError.message
            }`,
        );
    }
}

export class UnhandledSecurityError extends BaseStatusError {
    constructor(message?: string) {
        super(
            securityMessages.unhandledSecurityError.code,
            `${errorMessagePrefix} ${
                message ?? securityMessages.unhandledSecurityError.message
            }`,
        );
    }
}
