import { BaseStatusError } from './baseError';

export const pricingMessages = {
    validityPeriodsError: 'ValidityPeriods is missing',
    startDateError: 'StartDate is missing',
    tripLengthError: 'TripLength is missing',
    hostSelectedOptionalServicesError:
        'Host selected optional services is not properly populated',
};

export class ValidityPeriodsError extends BaseStatusError {
    constructor(message?: string) {
        super(1100, message || pricingMessages.validityPeriodsError);
    }
}

export class StartDateError extends BaseStatusError {
    constructor(message?: string) {
        super(1101, message || pricingMessages.startDateError);
    }
}

export class TripLengthError extends BaseStatusError {
    constructor(message?: string) {
        super(1102, message || pricingMessages.tripLengthError);
    }
}

export class HostSelectedOptionalServicesError extends BaseStatusError {
    constructor(message?: string) {
        super(
            1103,
            message || pricingMessages.hostSelectedOptionalServicesError,
        );
    }
}
