import { MiddlewareArguments } from './middleware.types';
import { cloneDeep, pickBy } from 'lodash';

const hubspotMiddleware = ({ payload, next }: MiddlewareArguments) => {
    const callType = payload.type();
    let newPayload = cloneDeep(payload);

    if (callType === 'identify') {
        newPayload.obj.traits.website = payload?.obj?.traits.websiteUrl;
        newPayload.obj.traits.userid = payload?.obj?.traits.id;
        newPayload.obj.traits.portal_account_manager =
            payload?.obj?.traits.accountManager;
        newPayload.obj.traits.phone = payload?.obj?.traits.phoneNumber;
        newPayload.obj.traits.lead_source = payload?.obj?.traits.creationSource;
        newPayload.obj.traits.instagram_handle =
            payload?.obj?.traits.instagramHandle;
        newPayload.obj.traits.host_tier = payload?.obj?.traits.itineraryTier;
        newPayload.obj.traits.host_status = payload?.obj?.traits.status;
        newPayload.obj.traits.contact_type = payload?.obj?.traits.group;

        // links
        newPayload.obj.traits.host_email_capture_link =
            payload?.obj?.traits.emailLink;
        newPayload.obj.traits.host_survey_link =
            payload?.obj?.traits.surveyLink;
        newPayload.obj.traits.portal_referral_link =
            payload?.obj?.traits.referralLink;
    }

    // removes all undefined fields (hubspot will overwrite data with undefined if sent)
    newPayload.obj.traits = pickBy(
        newPayload.obj.traits,
        (value) => value !== undefined,
    );

    next(newPayload);
};

export default hubspotMiddleware;
