import { css } from '@emotion/react';
import { theme } from '../../../../../shared/theme';

export default (props: any) => css`
    font-family: ${theme.fonts.roboto};
    border-radius: ${theme.radii.md};
    border: ${props.disabled
        ? `1px solid ${theme.colors.blueGray[400]}`
        : `1px solid ${theme.colors.blueGray[300]}`};
    color: ${theme.colors.blueGray[650]};
    background-color: ${props.disabled
        ? theme.colors.blueGray[50]
        : theme.colors.neutral.white};
    margin-top: 0;
    height: ${props.input ? '2.5rem' : 'fit-content'};

    .rdtPicker {
        transition: all 150ms linear;
        visibility: hidden;
        opacity: 0;
        width: 100%;
        border-radius: ${theme.radii.md};
        padding: ${theme.space[2]} ${theme.space[3]};
        background-color: ${theme.colors.neutral.white};
        table {
            width: 100%;
        }
        &:after {
            content: none;
        }
        &:before {
            content: none;
        }
    }

    &.rdtOpen {
        width: ${props.input ? '100%' : '15.625rem'};
        .rdtPicker {
            opacity: 1;
            visibility: visible;
        }
    }

    ${!props.error &&
    `
    :focus-within {
        border: 1px solid ${theme.colors.blue[500]};
    }
    `}
    ${props.error &&
    props.error.length &&
    `border: 1px solid ${theme.colors.red.trova};`}
    ${props.error &&
    props.errorVariant === 'default' &&
    `
        border: 1px solid ${theme.colors.red.coral};
        box-shadow: 0 0 0 1px ${theme.colors.red.coral};
    `}
`;
