import {
    ResponsiveValue,
    Table as TableChakra,
    TableContainer,
    TableProps as TablePropsChakra,
    useBreakpointValue,
} from '@chakra-ui/react';
import { isBoolean, isString } from 'lodash';
import React from 'react';
import { genDataTestId } from '../../../shared/utils/helpers';
import { DesignTokens } from '../../../shared/theme/types';
import { borderOutlineStyle } from './Table.styles';
import TableBody from './TableBody';
import TableCell from './TableCell';
import TableHead from './TableHead';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

type Sizes = DesignTokens['components']['Table']['sizes'];
type Variants = DesignTokens['components']['Table']['variants'];

export interface TableProps {
    children: React.ReactNode;
    /**
     * Size of the table
     */
    size?: ResponsiveValue<Sizes>;

    /**
     * Variant of the table
     */
    variant?: ResponsiveValue<Variants>;

    /**
     * Adds a outline border to the table container
     */
    borderOutline?: ResponsiveValue<boolean>;
    className?: string;
}

export interface TableImplProps
    extends Omit<TablePropsChakra, 'size' | 'variant' | 'children'>,
        TableProps {}

export const TableImpl = (props: TableImplProps) => {
    const {
        size: sizeProp = 'md',
        variant: variantProp = 'striped',
        borderOutline: borderOutlineProp = { base: false, md: true },
        ...rest
    } = props;

    const size = useBreakpointValue(
        isString(sizeProp) ? [sizeProp] : sizeProp
    ) as Sizes;

    const variant = useBreakpointValue(
        isString(variantProp) ? [variantProp] : variantProp
    ) as Variants;

    const borderOutline = useBreakpointValue(
        isBoolean(borderOutlineProp) ? [borderOutlineProp] : borderOutlineProp
    ) as boolean;

    return (
        <TableContainer sx={borderOutline ? borderOutlineStyle : undefined}>
            <TableChakra
                data-testid={genDataTestId('table')}
                size={size}
                variant={variant}
                {...rest}
            />
        </TableContainer>
    );
};

export const Table = (props: TableProps) => <TableImpl {...props} />;

export default Object.assign(Table, {
    Head: TableHead,
    Body: TableBody,
    Row: TableRow,
    Header: TableHeader,
    Cell: TableCell,
});
